import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';

import { AppComponent } from './app.component';
import { SceneComponent } from './scene/scene.component';
import { MenuComponent } from './menu/menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OrbitControlsComponent } from './orbit-controls/orbit-controls.component';
import { HeaderComponent } from './header/header.component';
import { MaterialModule } from './material.module';

import { ColorBlockModule } from 'ngx-color/block';
import { LoadedElementComponent } from './loaded-element/loaded-element.component';
// import { SummaryComponent } from './vehicle/summary/summary.component';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './init/keycloak-init.factory';
import { AppRoutingModule } from './app-routing.module';
import { PdfExportComponent } from './pdf-export/pdf-export.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { httpInterceptorProviders } from './lib/communication/http-interceptor-providers';
import { ToastrModule } from 'ngx-toastr';
import { MatButtonModule } from '@angular/material/button';
import { ModalComponent } from './modal/modal.component';

import { ClipboardModule } from 'ngx-clipboard';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LoadingsComponent } from './loadings/loadings.component';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID } from '@angular/core';
import { RatingComponent } from './rating/rating.component';
import { SingleContextComponent } from './single-context/single-context.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { HistoryComponent } from './history/history.component';
import { SubscriptionInfoComponent } from './subscription-info/subscription-info.component';
import { SuggestLargerVehicleComponent } from './suggest-larger-vehicle/suggest-larger-vehicle.component';
import { ImportFileComponent } from './import-file/import-file.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { GarageComponent } from './garage/garage.component';
import { LabelComponent } from './label/label.component';

import { LoadModule } from './load/load.module';
import { ChangeLoadSizeComponent } from './change-load-size/change-load-size.component';
import { CopyLoadComponent } from './copy-load/copy-load.component';
import { CalculationSummaryComponent } from './calculation-summary/calculation-summary.component';
import { ProjectsComponent } from './projects/projects.component';
import { CommonModule as LoadCommonModule } from './load/common/common.module';
import { ProjectCreateComponent } from './projects/project-create/project-create.component';
import { DatesFilterComponent } from './dates-filter/dates-filter.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InfoModule } from './info/info.module';

import { VehicleModule } from './vehicle/vehicle.module';
import { CommonModule as VehicleCommonModule } from './vehicle/common/common.module';
import { DEFAULT_TIMEOUT } from './lib/communication/http-timeout-interceptor';
import { ListComponent } from './loadings/list/list.component';
import { ExportListComponent } from './pdf-export/export-list/export-list.component';
import { ActiveSettingsComponent } from './scene/active-settings/active-settings.component';
import { SettingsModule } from './settings-modal/settings.module';
import { LoaderComponent } from './loader/loader.component';
import { ProjectCommentComponent } from './project-comment/project-comment.component';
import { FormComponent as ProjectCommentFormComponent } from './project-comment/form/form.component';
import { ViewComponent } from './scene/view/view.component';
import { HelperModule } from './lib/helper/helper.module';
import { DirectivesModule } from './directives/directives.module';
import { SceneOptionsComponent } from './scene/scene-options/scene-options.component';
import { SceneToolsComponent } from './scene/scene-tools/scene-tools.component';
import { DirectoryCreateComponent } from './projects/directory-create/directory-create.component';
import { PalletsLoadingModalComponent } from './pallets-loading-modal/pallets-loading-modal.component';
import { MassDistributionChartComponent } from './mass-distribution-chart/mass-distribution-chart.component';
import { PendingListComponent } from './loadings/pending-list/pending-list.component';
import { PendingLoadComponent } from './loadings/pending-list/pending-load/pending-load.component';
import { LoadingStepsComponent } from './loading-steps/loading-steps.component';
import { SharingComponent } from './sharing/sharing.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

registerLocaleData(localePl, 'pl');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeEs, 'es');

// Provider for Keycloak Bearer Interceptor
const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

// Provider for Keycloak Initialization
const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService]
};

@NgModule({
  declarations: [
    AppComponent,
    SceneComponent,
    MenuComponent,
    OrbitControlsComponent,
    HeaderComponent,
    LoadedElementComponent,
    // SummaryComponent,
    PdfExportComponent,
    ModalComponent,
    ConfirmationDialogComponent,
    LoadingsComponent,
    RatingComponent,
    SingleContextComponent,
    ContextMenuComponent,
    HistoryComponent,
    SubscriptionInfoComponent,
    SuggestLargerVehicleComponent,
    ImportFileComponent,
    GarageComponent,
    LabelComponent,
    ChangeLoadSizeComponent,
    CopyLoadComponent,
    CalculationSummaryComponent,
    ProjectsComponent,
    ProjectCreateComponent,
    DatesFilterComponent,
    ListComponent,
    ExportListComponent,
    ActiveSettingsComponent,
    LoaderComponent,
    ProjectCommentComponent,
    ProjectCommentFormComponent,
    ViewComponent,
    SceneOptionsComponent,
    SceneToolsComponent,
    DirectoryCreateComponent,
    PalletsLoadingModalComponent,
    MassDistributionChartComponent,
    PendingListComponent,
    PendingLoadComponent,
    LoadingStepsComponent,
    SharingComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HelperModule,
    HttpClientModule,
    KeycloakAngularModule,
    MaterialModule,
    ColorBlockModule,
    AppRoutingModule,
    MatDialogModule,
    MatButtonModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    ClipboardModule,
    ScrollingModule,
    LoadModule,
    VehicleModule,
    LoadCommonModule,
    DragDropModule,
    InfoModule,
    VehicleCommonModule,
    SettingsModule,
    DirectivesModule
  ],

  providers: [
    DatePipe,
    DecimalPipe,
    //{ provide: LOCALE_ID, useValue: 'pl' },
    KeycloakInitializerProvider,
    KeycloakBearerInterceptorProvider,
    KeycloakService,
    httpInterceptorProviders,
    [{ provide: DEFAULT_TIMEOUT, useValue: 120000 }, provideAnimationsAsync()]
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
