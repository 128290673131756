import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { LoadGroup } from '../../../lib/model/load-group/load-group';
import { UiService } from '../../../services/ui.service';
import { Subject } from 'rxjs';
import { PendingLoadsService } from '../../lib/pending-loads.service';
import { Color } from 'three';

@Component({
  selector: 'app-pending-load',
  templateUrl: './pending-load.component.html',
  styleUrls: ['./pending-load.component.scss']
})
export class PendingLoadComponent implements OnInit, OnDestroy {
  @Input() group: LoadGroup;

  @Input() cnt: number = 1;
  protected fillColor = 'rgb(0, 0, 255)';
  protected readonly maxCount = 10000;

  private unsubscribe$ = new Subject<void>();

  constructor(protected ui: UiService, private pendingLoadsService: PendingLoadsService) {}

  ngOnInit(): void {
    this.cnt = this.group.cnt;
    this.fillColor = new Color(this.group.load.color).getStyle();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected cntChanged(event: any) {
    this.cnt = Math.max(0, Math.min(this.cnt, this.maxCount));
    this.group.updateCount(this.cnt);
  }

  protected increaseCount() {
    this.cnt += 1;
    this.cnt = Math.min(this.cnt, this.maxCount);
    this.group.updateCount(this.cnt);
  }
  protected decreaseCount() {
    this.cnt -= 1;
    this.cnt = Math.max(0, Math.min(this.cnt, this.maxCount));
    this.group.updateCount(this.cnt);
  }

  protected remove() {
    this.pendingLoadsService.remove(this.group.load);
  }
}
