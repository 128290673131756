<div class="text-right with-img">
  <img src="assets/images/vertical-rotation.png" alt="Obracaj dowolnie"
    i18n-alt />
</div>
<div class="help-main">
  <svg class="help-bg" viewBox="0 0 112 129" xmlns="http://www.w3.org/2000/svg">
    <rect class="rect" width="32" height="48" />
    <rect class="rect" x="43" width="69" height="48" />
    <rect class="rect" y="58" width="32" height="71" />
    <rect class="rect" x="43" y="58" width="69" height="71" />
  </svg>
  <h2 class="primary" i18n>Obracaj dowolnie</h2>
  <p i18n>
    Ładunek może zostać obrócony w dowolnym kierunku lub położony na bok / tył,
    jeśli pozwoli to na lepsze rozmieszczenie.
  </p>
  <p i18n>
    Możesz użyć tej opcji, jeśli ładunek nie wymaga transportu w konkretnej
    pozycji stojącej / leżącej.
  </p>
</div>