import { VehicleContext } from '../../lib/model/vehicle-context';
import { PositionerRequest } from '../../lib/communication/positioner.request';
import { LoadMovement } from 'src/app/lib/communication/load-movement';

export class MoveLoadRequest extends PositionerRequest {
  constructor(movement: LoadMovement, context: VehicleContext) {
    console.log('MoveLoadRequest', movement);

    super({
      uuid: context.getUuid(),
      initialTimestamp: context.getInitialTimestamp(),
      loads: context.getLoads(),
      vehicle: context.getVehicle(),
      action: 'move',
      movement,
      orbitControlsState: context.getOrbitControlsState(),
      projectId: context.getProjectId(),
      settings: context.getSettings()
    });
  }
}
