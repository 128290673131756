import { FlooringLabel } from './FlooringLabel';
import { Constants as Config } from 'src/app/config/constants';
import { TextLabel } from './TextLabel';
import { Load } from 'src/app/load/lib/load';

export class FlooringBottomLabel extends FlooringLabel {
  updatePosition(load: Load): void {
    const position = load.mesh.position.clone();
    position.y -= (load.cuboidHull.height / 2 - 20) * Config.DIMENSION_SCALING;
    this.setPosition(position);
  }

  updateCssClass(load: Load): void {
    this.setCssClass(
      'material-label ' +
        (load.floorableBottom ? 'color-enabled' : 'color-disabled') +
        ' ' +
        'scale-' +
        this.textScale.toString().replace('.', '_')
    );
  }

  makeCopy(): TextLabel {
    return new FlooringBottomLabel(this.getText(), this.textScale);
  }
}
