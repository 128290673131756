import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { LiveCollaboration } from './live-collaboration';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from 'src/app/services/profile.service';

type Callback = (...args: any[]) => void;

@Injectable({
  providedIn: 'root'
})
export class LiveCollaborationService extends HttpService {
  private socketEndpoint = environment.serverUrl;
  private socketPath = '/live/';

  private sharedWithUrl = environment.apiUrl + '/database/projects/shared-users';

  private socket!: Socket;

  private authToken: string;

  public currentShare: LiveCollaboration;
  private callbacks = new Map<string, Callback>();

  public constructor(
    http: HttpClient,
    private profileService: ProfileService
  ) {
    super(http);
  }

  // region HTTP side
  private mapShareResults(results: LiveCollaboration[]): LiveCollaboration[] {
    return results
      .map((r) => new LiveCollaboration(r))
      .sort((a: LiveCollaboration, b: LiveCollaboration) => (a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1));
  }

  getSharedUsers(projectID: string): Observable<LiveCollaboration[]> {
    const httpCall = this.http
      .get<any>(`${this.sharedWithUrl}/${projectID}`)
      .pipe(catchError(this.handleError('getSharedUsers', [])), map(this.mapShareResults));
    return httpCall;
  }

  shareWithUser(projectID: string, email: string): Observable<LiveCollaboration[]> {
    const httpCall = this.http
      .post<any>(`${this.sharedWithUrl}/${projectID}`, {
        email
      })
      .pipe(catchError(this.handleError('shareWithUser')), map(this.mapShareResults));
    return httpCall;
  }

  removeShare(share: LiveCollaboration): Observable<LiveCollaboration[]> {
    const httpCall = this.http
      .delete<any>(`${this.sharedWithUrl}/${share.projectID}/${share.shareID}`)
      .pipe(catchError(this.handleError('removeShare')), map(this.mapShareResults));
    return httpCall;
  }
  // endregion HTTP side

  // region websocket side

  setAuthToken(token: string) {
    this.authToken = token;
    if (this.socket) {
      this.disconnect();
      this.connect();
    }
  }

  connect() {
    if (this.socket) {
      return;
    }
    const sessionID = localStorage.getItem('liveSessionID');

    this.socket = io(this.socketEndpoint, {
      path: this.socketPath,
      withCredentials: true,
      transports: ['polling', 'websocket'],
      extraHeaders: {
        Authorization: 'Bearer ' + this.authToken
      },
      auth: {
        sessionID,
        email: this.profileService.currentProfile.email,
        shareID: this.currentShare?.shareID
      }
      /*auth: (cb) => {
        console.log('token', this.authService.getToken());
        cb({ token: this.authService.getToken() });
      }*/
    });

    this.socket.on('session', ({ sessionID }) => {
      this.socket.auth = { sessionID };
      localStorage.setItem('liveSessionID', sessionID);
    });

    this.socket.on('connect', () => {
      console.log('[WS] Connected to the server with ID:', this.socket.id);
      if (this.currentShare) {
        this.join(this.currentShare);
      }
    });

    this.socket.on('disconnect', (reason) => {
      console.log('[WS] Disconnected from the server', reason);
      this.callbacks.forEach((_cb, evt) => {
        this.socket.removeAllListeners(evt);
      });
    });

    this.callbacks.forEach((cb, evt) => {
      this.socket.on(evt, cb);
    });
  }

  join(share: LiveCollaboration) {
    this.connect();
    if (this.socket) {
      this.socket.emit('join-project', share);
    }
  }

  leave(share: LiveCollaboration) {
    this.connect();
    if (this.socket) {
      this.socket.emit('leave-project', share);
    }
  }

  emit(event: string, data: any): void {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  on(event: string, callback: (...args: any[]) => void): void {
    if (this.socket) {
      this.callbacks.set(event, callback);
      this.socket.on(event, callback);
    }
  }

  disconnect(clearListeners = false): void {
    if (this.currentShare) {
      this.leave(this.currentShare);
    }
    if (this.socket) {
      this.socket.disconnect();
      this.socket = undefined;
    }
    if (clearListeners) {
      this.callbacks.clear();
    }
  }

  // endregion websocket side
}
