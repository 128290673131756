import { v4 as uuidv4 } from 'uuid';

import { LoadMesh } from './load-mesh';
import { Position } from 'src/app/lib/positioner/position';
import { CuboidHull } from './cuboid-hull';
import { LoadDisplaySettings } from './load-display-settings';
import { DisplayCacheService } from 'src/app/services/display-cache.service';
import { Box3 } from 'three';
import { Constants as Config } from 'src/app/config/constants';

const allowHorizontalRotation = 0b0001;
const allowVerticalRotation = 0b0010;
const floorableTop = 0b0100;
const floorableBottom = 0b1000;

export abstract class Load {
  // używane w definicjach i instancjach
  uuid: string;
  shape: string;
  name: string;
  color: number; // = 0x00d084; // green
  weight: number = 0;
  //floorableTop: boolean = false;
  //floorableBottom: boolean = false;
  //horizontalRotationFrozen: boolean = false; //brak możliwości obrotu w poziomie // dla algorytmów
  //verticalRotationFrozen: boolean = true; // brak możliwości obrotu w pionie // dla algorytmów
  options: number = 0b0001;
  protrusionLength: number = 0; //dopuszczalny % wystawania w długości [0,100] - default 0
  initialTimestamp: number;

  //używane tylko w definicjach
  projectId?: string;

  // używane tylko w instancjach
  idx: number;
  spaceUuid: string;
  position: Position;
  fixedPosition: boolean; // ręcznie przeniesiono ładunek
  rotationState: number = 0;
  displayOrder?: number; // używane w grupach po lewej

  // tylko do UI, ignorowane po stronie serwera
  selected: boolean;
  #mesh: LoadMesh;
  #displayCache: DisplayCacheService;

  constructor(obj: any, private settings: LoadDisplaySettings, displayCache: DisplayCacheService) {
    Object.assign(this, obj);
    if (this.uuid === undefined) {
      this.generateUuid();
    }
    this.#displayCache = displayCache;

    //this.createMesh(settings);
  }

  abstract get cuboidHull(): CuboidHull;
  abstract get fullName(): string;
  abstract get volume(): number;
  abstract get area(): number;
  abstract get fullDescription(): string;

  abstract get descriptiveDimensions(): number[];

  abstract createMesh(settings: LoadDisplaySettings): LoadMesh;

  public disposeMesh() {
    if (this.#mesh) {
      this.#mesh.dispose(this.#displayCache);
      this.#mesh = undefined;
    }
  }

  get mesh(): LoadMesh {
    return this.#mesh;
  }

  set mesh(mesh: LoadMesh) {
    this.#mesh = mesh;
  }

  get floorableTop() {
    return Config.getBit(this.options, floorableTop);
  }

  set floorableTop(val: boolean) {
    this.options = Config.setBit(this.options, floorableTop, val);
  }

  get floorableBottom() {
    return Config.getBit(this.options, floorableBottom);
  }

  set floorableBottom(val: boolean) {
    this.options = Config.setBit(this.options, floorableBottom, val);
  }

  get horizontalRotationFrozen() {
    return !Config.getBit(this.options, allowHorizontalRotation);
  }

  set horizontalRotationFrozen(val: boolean) {
    this.options = Config.setBit(this.options, allowHorizontalRotation, !val);
  }

  get verticalRotationFrozen() {
    return !Config.getBit(this.options, allowVerticalRotation);
  }

  set verticalRotationFrozen(val: boolean) {
    this.options = Config.setBit(this.options, allowVerticalRotation, !val);
  }

  get floorableAll(): boolean {
    return this.floorableTop && this.floorableBottom;
  }

  set floorableAll(val: boolean) {
    this.floorableTop = val;
    this.floorableBottom = val;
  }

  public is(cls: typeof Load) {
    return this instanceof cls;
  }

  public generateUuid() {
    this.uuid = uuidv4();
  }

  public select() {
    this.selected = true;
    this.mesh?.select();
  }

  public unselect() {
    this.selected = false;
    this.mesh?.select(false);
  }

  public updateSettings(settings: LoadDisplaySettings): boolean {
    if (this.displaySettingsChanged(settings)) {
      this.createMesh(settings);
      return true;
    }
    return false;
  }

  public isSameSizeAs(other: Load): boolean {
    const aHull = this.cuboidHull;
    const bHull = other.cuboidHull;
    return aHull.length === bHull.length && aHull.width === bHull.width && aHull.height == bHull.height;
  }

  public requireReloadWhenChangedTo(updated: Load): boolean {
    return (
      !this.isSameSizeAs(updated) ||
      this.floorableTop !== updated.floorableTop ||
      this.floorableBottom !== updated.floorableBottom ||
      this.protrusionLength !== updated.protrusionLength ||
      this.verticalRotationFrozen !== updated.verticalRotationFrozen ||
      this.horizontalRotationFrozen !== updated.horizontalRotationFrozen
    );
  }

  private displaySettingsChanged(newSettings: LoadDisplaySettings) {
    return (
      newSettings.loadBordersIntensity !== this.settings.loadBordersIntensity ||
      newSettings.loadTransparency !== this.settings.loadTransparency
    );
  }

  protected getDisplayCache(): DisplayCacheService {
    return this.#displayCache;
  }

  /**
   * bounding box bez używania setFromObject
   * pozycja ustawiana z mesh
   * nie trzeba używać na nim roundBox - ma to już zrobione
   *
   * @param load Load
   * @param into Box3
   * @returns Box3
   */
  public computeBoundingBox(): Box3 {
    const hull = this.cuboidHull;
    const pos = this.mesh.position;
    if (!this.mesh.boundingBox) {
      this.#mesh.boundingBox = new Box3();
    }
    const scaledHalfHull = {
      length: Config.scale(hull.length / 2),
      height: Config.scale(hull.height / 2),
      width: Config.scale(hull.width / 2)
    };
    this.#mesh.boundingBox.min.set(
      Config.roundToScale(pos.x - scaledHalfHull.length),
      Config.roundToScale(pos.y - scaledHalfHull.height),
      Config.roundToScale(pos.z - scaledHalfHull.width)
    );
    this.#mesh.boundingBox.max.set(
      Config.roundToScale(pos.x + scaledHalfHull.length),
      Config.roundToScale(pos.y + scaledHalfHull.height),
      Config.roundToScale(pos.z + scaledHalfHull.width)
    );
    return this.#mesh.boundingBox;
  }

  public get boundingBox(): Box3 {
    return this.#mesh.boundingBox;
  }
}
