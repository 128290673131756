import { Component } from '@angular/core';
import { OrbitControlsService } from './orbit-controls.service';

@Component({
  selector: 'app-orbit-controls',
  templateUrl: './orbit-controls.component.html',
  styleUrls: ['./orbit-controls.component.scss']
})
export class OrbitControlsComponent {
  constructor(private service: OrbitControlsService) {}

  public move(value: string) {
    this.service.move(value);
  }

  public rotate(value: string) {
    this.service.rotate(value);
  }

  public dolly(value: string) {
    this.service.dolly(value);
  }
}
