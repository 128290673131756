import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Vehicle } from '../lib/vehicle';
import { VehicleType } from '../lib/vehicle-type';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent {
  @Input() events: Observable<void>;
  @Input() type: VehicleType;
  @Output() addVehicleEvent = new EventEmitter<Vehicle>();

  eventsSubject: Subject<void> = new Subject<void>();

  addVehicle(vehicle: Vehicle) {
    // console.log('vehicle/form/form.component.ts: addVehicle called');
    this.addVehicleEvent.emit(vehicle);
  }
}
