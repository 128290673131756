import { Injectable } from '@angular/core';
import { LoadFactory } from '../load/lib/load-factory';
import { Vehicle } from '../vehicle/lib/vehicle';
import { Load } from '../load/lib/load';
import { Other } from '../vehicle/type/other/lib/other';
import { PalletLoad } from '../load/type';

@Injectable({
  providedIn: 'root'
})
export class VehicleToLoadTransformerService {
  constructor(private loadFactory: LoadFactory) {}

  public transform(vehicle: Vehicle, contextUuid: string): Load | null {
    if (!(vehicle instanceof Other)) {
      return null;
    }
    if (!vehicle.mesh) {
      vehicle.createMesh();
    }
    const loadData: Partial<PalletLoad> = {
      name: vehicle.name,
      length: vehicle.length || vehicle.spaces[0].length,
      height: vehicle.totalDisplayHeight,
      width: vehicle.width || vehicle.spaces[0].width,
      shape: vehicle.model,
      fromVehicleContext: contextUuid,
      weight: vehicle.loadedLoadsWeight || 0
    };

    const load = this.loadFactory.recreateLoad(loadData);
    console.debug(
      'VehicleToLoadTransformerService -> transform()',
      load,
      vehicle.maxHeight,
      vehicle.totalDisplayHeight
    );
    return load;
  }
}
