import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-block-rotation',
  templateUrl: './block-rotation.component.html',
  styleUrls: ['./block-rotation.component.scss']
})
export class BlockRotationComponent {
  constructor() {}
}
