import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from '../services/ui.service';
export interface DialogData {
  idx: string;
  cnt: number;
}
@Component({
  selector: 'app-copy-load',
  templateUrl: './copy-load.component.html',
  styleUrls: ['./copy-load.component.scss']
})
export class CopyLoadComponent {
  constructor(
    public dialogRef: MatDialogRef<CopyLoadComponent>,
    public ui: UiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
