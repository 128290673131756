import { SpaceFactory } from 'src/app/vehicle/space/lib/space-factory';
import { Vehicle } from '../../../lib/vehicle';
import { BusMesh } from './bus-mesh';

export class Bus extends Vehicle {
  type = 'bus';
  width: number;
  length: number;
  height: number;

  public cabinLength = 1000;
  public cabinHeight = 2000;
  public cabinRoofHeight = 500;
  public cabinWidth = 1500;

  constructor(obj: any, spaceFactory: SpaceFactory) {
    super(obj, spaceFactory);
  }

  createMesh(): void {
    this.mesh = new BusMesh(this);
  }

  get fullName(): string {
    return `${this.name}`;
  }

  get fullDescription(): string {
    return `${this.length}x${this.width}x${this.height}`;
  }

  get isEmpty(): boolean {
    return !(this.length + this.width + this.height > 0);
  }

  get maxLength(): number {
    return (
      this.cabinLength + (this.spaces.length > 0 ? this.spaces[0].length : 0)
    );
  }
}
