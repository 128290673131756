import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { animateText } from 'animations/animations';
import { MatDrawer } from '@angular/material/sidenav';
import { ProjectsService } from '../projects/projects.service';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { Project } from '../projects/lib/project';
import { ContextService } from '../vehicle/context/context.service';
import { MenuService } from './menu.service';
import { MenuState } from './lib/menu-state';
import { LoadService } from '../load/load.service';
import { VehicleService } from '../vehicle/vehicle.service';
import { LoadComponentService } from '../services/load-component.service';
import { ProjectCommentComponent } from '../project-comment/project-comment.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],

  animations: [animateText]
})
export class MenuComponent implements OnInit, OnDestroy {
  @ViewChild('loadingSpace')
  loadingSpace: MatDrawer;

  @ViewChild('loadingSpace', { read: ElementRef })
  loadingSpaceMenuRef: ElementRef;

  @ViewChild('loading')
  loading: MatDrawer;

  @ViewChild('loading', { read: ElementRef })
  loadingMenuRef: ElementRef;

  project$: Observable<Project>;

  loadingsOpened = false;
  vehiclesOpened = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private projectsService: ProjectsService,
    private contextService: ContextService,
    private menuService: MenuService,
    private loadService: LoadService,
    private vehicleService: VehicleService,
    private addComponent: LoadComponentService
  ) {
    this.project$ = projectsService.currentProject$;
  }

  ngOnInit(): void {
    this.menuService.menuState$.pipe(takeUntil(this.unsubscribe$)).subscribe((menuType) => {
      console.log('menu.component.ts switchMenu to', menuType);
      switch (menuType) {
        case MenuState.loadingsOpen:
          this.showLoadingsMenu();
          break;
        case MenuState.vehiclesOpen:
          this.showSpacesMenu();
          break;
        case MenuState.closed:
          this.loadingSpace?.close();
          this.loading?.close();
          break;
      }
    });
    this.menuService.menuState$.subscribe((x) => {
      switch (x) {
        case MenuState.loadingsOpen:
          this.loadingsOpened = true;
          this.vehiclesOpened = false;
          break;
        case MenuState.vehiclesOpen:
          this.vehiclesOpened = true;
          this.loadingsOpened = false;
          break;
        default:
          this.vehiclesOpened = false;
          this.loadingsOpened = false;
          break;
      }
    });
    this.loadService
      .getItemForScroll()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((x) => !!x)
      )
      .subscribe(() => {
        setTimeout(() => {
          this.loadingMenuRef.nativeElement.scrollTop = this.loadingMenuRef.nativeElement.scrollHeight;
        }, 50); // after form shows
      });
    this.vehicleService
      .getSelectedVehicle()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((x) => !!x)
      )
      .subscribe(() => {
        setTimeout(() => {
          this.loadingSpaceMenuRef.nativeElement.scrollTop = this.loadingSpaceMenuRef.nativeElement.scrollHeight;
        }, 50); // after form shows
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleVehicles() {
    if (this.vehiclesOpened) {
      this.menuService.close();
    } else {
      this.openVehicles();
    }
  }

  openVehicles() {
    this.menuService.openVehicles();
  }

  close() {
    this.menuService.close();
  }

  toggleLoadings() {
    if (this.loadingsOpened) {
      this.menuService.close();
    } else {
      this.openLoadings();
    }
  }

  openLoadings() {
    this.menuService.openLoadings();
  }

  backToProjects() {
    this.projectsService.currentProject = null;
    this.contextService.setContext(null);
  }

  protected openCommentModal() {
    this.addComponent.add(ProjectCommentComponent);
  }

  private showSpacesMenu() {
    this.loadingSpace?.open();
    this.loading?.close();
  }

  private showLoadingsMenu() {
    this.loadingSpace?.close();
    this.loading?.open();
  }
}
