<div class="select-list">
  <div *ngIf="listType === 'user' && items.length > 0"
    class="select-all-container">
    <mat-checkbox (change)="selectAll()" [checked]="areAllSelected()" i18n-title
      title="Zaznacz wszystkie ładunki"></mat-checkbox>
    <mat-form-field appearance="fill" class="cnt condensed">
      <input type="text" matInput placeholder="Wyszukaj" i18n-placeholder
        autocomplete="off" [(ngModel)]="searchText" (input)="searchChange()" />
    </mat-form-field>
    <button mat-flat-button (click)="deleteAll()" class="delete-btn" i18n-title
      title="Usuń wszystkie ładunki własne">
      ✕
    </button>
  </div>
  <div *ngIf="items.length === 0" class="help">
    <ng-container i18n>Zdefiniuj ładunki, aby dodać je do przestrzeni
      ładunkowej</ng-container>
  </div>
  <div *ngIf="items.length > 0 && filteredItems.length === 0" class="help">
    <ng-container i18n>Brak ładunków pasujących do kryteriów
      wyszukiwania</ng-container>
  </div>
  <div *ngIf="loading" class="text-center">
    <mat-spinner class="progress" color="accent" diameter="24"> </mat-spinner>
  </div>
  <mat-list class="condensed load-list" (scroll)="onScroll($event)">
    <mat-list-item *ngFor="let item of filteredItems; let i = index"
      [ngClass]="{ selected: item.selected }"
      [@flashBackground]="item.highlight ? 'show' : ''"
      (@flashBackground.done)="item.highlight = false">
      <div class="load-list-item">
        <span *ngIf="listType === 'user'">
          <mat-checkbox (click)="noop()" color="primary"
            (change)="updateSelection(item, $event)"
            [checked]="item.selected"></mat-checkbox>
        </span>
        <span *ngIf="listType === 'user'">
          <mat-form-field appearance="outline" class="cnt condensed">
            <input matInput appOnlyNumber="integer" [(ngModel)]="item.cnt"
              (input)="cntChanged(item)" (focus)="$event.target.select()"
              autocomplete="off" name="cnt" required max="10000"
              [tabindex]="i + 1" />
          </mat-form-field>
        </span>
        <span class="name-container" (click)="selectSingle(item)">
          <span class="name">{{ item.load.fullName }}</span>
          <span>
            {{ item.load | dimensions | async }}
            <span *ngIf="item.load.weight">
              |
              {{ item.load.weight | weightUnit | async | number : '0.0-2' }}
              {{ ui.getWeightUnit() | async }}
            </span>
          </span>
        </span>
        <div *ngIf="listType === 'user'" class="actions">
          <button mat-flat-button (click)="edit(item)" class="edit-btn">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-flat-button (click)="delete(item)"
            class="delete-btn">✕</button>
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</div>