import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ProfileService } from 'src/app/services/profile.service';
import { UiService } from 'src/app/services/ui.service';
import { CustomLoadService } from '../../lib/custom-load.service';
import { Load } from '../../lib/load';
import { LoadEventObject } from '../../lib/load-event-object';
import { LoadListItem } from '../../lib/load-list-item';
import { LoadService } from '../../load.service';
import { FlooringComponent } from '../flooring/flooring.component';
import { NameComponent } from '../name/name.component';
import { RotationComponent } from '../rotation/rotation.component';
import { WeightComponent } from '../weight/weight.component';
import { FormComponent as CuboidFormComponent } from '../../type/cuboid/form/form.component';
import { ColorComponent } from '../color/color.component';
import { ColorService } from 'src/app/services/color.service';

@Component({
  selector: 'app-load-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('name') name: NameComponent;
  @ViewChild('weight') weight: WeightComponent;
  @ViewChild('color') color: ColorComponent;
  @ViewChild('flooring') flooring: FlooringComponent;
  @ViewChild(RotationComponent) rotation: RotationComponent;
  @ViewChild(CuboidFormComponent) dimensions: CuboidFormComponent;

  protected eventsSubject: Subject<void> = new Subject<void>();
  protected formMode = 'plain-form';

  protected load: Load;

  private unsubscribe$ = new Subject<void>();

  protected defaultName: string = '';
  protected defaultColor: string;

  constructor(
    private service: LoadService,
    private customLoadService: CustomLoadService,
    protected ui: UiService,
    private profileService: ProfileService,
    private colorService: ColorService
  ) {
    this.defaultColor = colorService.getCssValue(colorService.DEFAULT_COLOR);
  }

  ngOnInit(): void {
    if (!this.load) {
      this.setDefaultName();
      this.defaultColor = this.colorService.getCssValue(this.colorService.randomColor());
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setDefaultName() {
    const prefix = $localize`Ładunek`;
    let nextFreeId = 1;
    this.customLoadService.loads$.pipe(takeUntil(this.unsubscribe$)).subscribe((loads) => {
      loads
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .forEach((l) => {
          while (l.name === `${prefix} ${nextFreeId}`) {
            nextFreeId++;
          }
        });
      this.defaultName = `${prefix} ${nextFreeId}`;
    });
  }

  close(): void {
    this.service.closeModal('load/form/create/create.component.ts');
    // this.service.close();
  }

  setEdit(load: Load) {
    this.load = load;
    this.defaultName = load.name;
  }

  onSubmit() {
    this.emitEventToChild();
  }

  emitEventToChild() {
    this.eventsSubject.next();
  }

  private getHorizontalRotationFrozen() {
    return !this.rotation.canRotate();
  }

  private getVerticalRotationFrozen() {
    return !this.rotation.canRotateVertical();
  }

  create(load: Load) {
    // console.log('create.component.ts: create called', load);
    if (!this.name.isValid()) {
      return;
    }
    const name = this.name.getValue();
    const weight = this.ui.getWeightInDefaultUnit(+this.weight.getValue());
    const color = this.color.getValue();
    const protrusionLength = this.flooring.getProtrusionLength();

    load.name = name;
    load.weight = weight;
    load.floorableTop = this.flooring.floorableTop;
    load.floorableBottom = this.flooring.floorableBottom;
    load.horizontalRotationFrozen = this.getHorizontalRotationFrozen();
    load.verticalRotationFrozen = this.getVerticalRotationFrozen();
    load.protrusionLength = protrusionLength;
    load.color = color;

    this.customLoadService
      .create(load)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.service.updateSelected([new LoadListItem(load, 1, true)]);
        // dodawanie na scenę tylko przy tworzeniu, nie przy edycji
        if (!this.load && this.profileService.currentSettings.autoAddCustomLoads) {
          this.service
            .addLoadAmount([new LoadEventObject({ load, cnt: 1 })])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
              this.close();
            });
        } else {
          this.close();
        }
      });
  }
}
