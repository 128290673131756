import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import {
  ConfirmationDialogAction,
  ConfirmationDialogComponent
} from '../confirmation-dialog/confirmation-dialog.component';
import { GaragePosition } from '../lib/model/garage-position';
import { ProfileInterface } from '../lib/model/profile';
import { MessageService } from '../messenger/message.service';
import { SceneService } from '../scene/scene.service';
import { GarageService } from './garage.service';
import { ContextFactory } from '../vehicle/context/lib/context-factory';
import { OrbitControlsState } from '../lib/model/orbit-controls-state';

@Component({
  selector: 'app-garage',
  templateUrl: './garage.component.html',
  styleUrls: ['./garage.component.scss']
})
export class GarageComponent implements OnDestroy {
  public profile: Observable<ProfileInterface>;
  public list$: Observable<GaragePosition[]>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: GarageService,
    private sceneService: SceneService,
    private router: Router,
    private messenger: MessageService,
    private dialog: MatDialog,
    private contextFactory: ContextFactory
  ) {
    this.list$ = service.list$;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close(): void {
    this.service.close();
  }

  openCalculation(calc: GaragePosition) {
    // console.log('open calculation', calc);
    const context = this.contextFactory.createForVehicle(calc.vehicle);
    context.setLoads(calc.loads);
    context.setOrbitControlsState(new OrbitControlsState(calc.orbitControlsState));
    this.sceneService
      .addVehicleContextAndSwitch(context)
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe(() => {
        this.router.navigate(['/']);
        this.close();
      });
  }

  removeCalculation(calc: GaragePosition) {
    // console.log('remove calculation');
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        action: ConfirmationDialogAction.deleteTemplate,
        message: calc.vehicle?.fullName
      }
    });

    confirmationDialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((confirmed) => {
        if (confirmed === true) {
          this.service.removeFromMyGarage(calc).subscribe((response) => {
            this.service.update();
            if (response.status === 'success') {
              this.messenger.snackSuccess({
                title: $localize`Układ został usunięty z Twojego garażu`,
                actionText: 'Ok'
              });
            } else {
              this.messenger.snackError({
                title: $localize`Coś poszło nie tak, układ nie został usunięty z Twojego garażu`,
                actionText: 'Ok'
              });
            }
          });
        } else {
          this.messenger.snackSuccess({
            title: $localize`Anulowano usuwanie układu`,
            actionText: 'Ok'
          });
        }
      });
  }
}
