import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LoadEventObject } from 'src/app/load/lib/load-event-object';
import { Space } from '../lib/space';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  @Input() events: Observable<void>;
  @Input() space: Space;
  @Output() addSpaceEvent = new EventEmitter<LoadEventObject>();
  private unsubscribe$ = new Subject<void>();

  eventsSubject: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    console.log('space/form.component.ts:ngOnInit space', this.space);
    this.events.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.onSubmit());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  onSubmit(): void {
    // console.log('space/form/form.component onSubmit()');
  }

  addSpace(eventObject: LoadEventObject) {
    // console.log('space/form/form.component.ts: addSpace called');
    this.addSpaceEvent.emit(eventObject);
  }
}
