<ng-template [ngIf]="(listType$ | async) === 'none'">
  <mat-card-title i18n>Przestrzenie ładunkowe:</mat-card-title>
  <button mat-raised-button class="btn-outlined btn-secondary btn-xl"
    (click)="changeList('system')" i18n>
    Typowe
  </button>
  <button mat-raised-button class="btn-outlined btn-secondary btn-xl"
    (click)="changeList('user')" i18n>
    Własne
  </button>
</ng-template>
<ng-template [ngIf]="(listType$ | async) === 'system'">
  <mat-card appearance="outlined" class="noborder">
    <mat-card-title>
      <ng-container i18n>Przestrzenie typowe</ng-container><span>&nbsp;</span>
      <a href="#" (click)="changeList('user')" class="nav-link text-primary"
        *ngIf="useCustomSpaces" i18n>Wróć do własne</a>
    </mat-card-title>
    <ng-container *ngTemplateOutlet="expertForm"></ng-container>
  </mat-card>
</ng-template>

<ng-template [ngIf]="(listType$ | async) === 'user'">
  <mat-card appearance="outlined" class="noborder">
    <mat-card-title>
      <ng-container i18n>Przestrzenie własne</ng-container><span>&nbsp;</span>
      <a href="#" (click)="changeList('system')" class="nav-link text-primary"
        *ngIf="useSystemSpaces" i18n>Wróć do typowe</a>
    </mat-card-title>
    <div class="buttons">
      <button mat-raised-button class="btn-secondary" (click)="createNew()"
        i18n>
        Zdefiniuj nową przestrzeń
      </button>

      <!--<button
          mat-raised-button
          class="btn-secondary"
          (click)="importFromFile()"
        >
          Import z pliku
        </button>-->
    </div>
    <ng-container *ngTemplateOutlet="expertForm"></ng-container>
  </mat-card>
</ng-template>

<ng-template #expertForm>
  <app-type *ngIf="(listType$ | async) === 'system'" #type
    [items]="vehicleTypes"></app-type>
  <app-select-list [listType]="listType$ | async" [vehicleType]="vehicleType"
    (itemSelectedEvent)="vehicleSelected($event)">
  </app-select-list>

  <button *ngIf="(listType$ | async) === 'system' && !useAllSystemSpaces"
    mat-flat-button class="mat-button mat-accent btn-secondary show-more"
    type="button" (click)="showFleetSettings()" i18n i18n-matTooltip
    matTooltip="Pokaż więcej dostępnych przestrzeni ładunkowych"
    [matTooltipPosition]="'above'" [matTooltipShowDelay]="500"
    #showMoreTooltip="matTooltip">
    Więcej
  </button>

  <ng-container *ngIf="vehicle">
    <ng-container *ngIf="vehicle.type != 'warehouse'">
      <app-space *ngFor="let space of vehicle.enabledSpaces | orderBy : order"
        [space]="space" [events]="eventsSubject.asObservable()"
        (addSpaceEvent)="confirmSpaceDimensions($event)"></app-space>
    </ng-container>

    <div style="clear: both" class="text-center">
      <br />
      <button mat-raised-button class="btn-primary" (click)="onSubmit()"
        type="submit" [disabled]="vehicle === null" i18n>
        ZATWIERDŹ PRZESTRZEŃ
      </button>
    </div>
    <div style="clear: both" class="text-center mt-1 mb-25">
      <button mat-raised-button
        class="mat-button mat-accent btn-secondary submit-btn" type="button"
        value="cargo" (click)="onSubmitAndGoToCargo(formDirective)" i18n>
        Ładunek >
      </button>
    </div>
  </ng-container>
</ng-template>