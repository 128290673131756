import { Injectable } from '@angular/core';

import { VehicleFactory } from 'src/app/vehicle/lib/vehicle-factory';
import { PositionerResponse } from '../../../lib/communication/positioner.response';
import { OrbitControlsState } from '../../../lib/model/orbit-controls-state';

import { VehicleContext } from '../../../lib/model/vehicle-context';
import { LoadFactory } from '../../../load/lib/load-factory';
import { Vehicle } from '../../lib/vehicle';

@Injectable({
  providedIn: 'root'
})
export class ContextFactory {
  public constructor(
    private vehicleFactory: VehicleFactory,
    private loadFactory: LoadFactory
  ) {}

  createForVehicle(vehicle: Vehicle) {
    return new VehicleContext(
      vehicle
        ? this.vehicleFactory.recreate(vehicle)
        : this.vehicleFactory.create('empty')
    );
  }

  fromPositionerResponse(
    response: PositionerResponse,
    intoContext?: VehicleContext
  ): VehicleContext {
    const context =
      intoContext ??
      new VehicleContext(
        response.vehicle
          ? this.vehicleFactory.recreate(response.vehicle)
          : this.vehicleFactory.create('empty')
      );
    context.setVehicle(this.vehicleFactory.recreate(response.vehicle));

    context.setLoads(
      (response.loads || []).map((l) => this.loadFactory.recreateLoad(l))
    );

    context.setAddedLoads([]);
    context.setLoadingSteps(response.steps || []);
    // context.setVehicleIdx(++idx);
    context.setStatistics(response.statistics);
    if (response.uuid) {
      context.setUuid(response.uuid);
    }
    context.setHistoryUuid(response.historyUuid);
    context.setInitialTimestamp(response.initialTimestamp);
    context.setCurrentTimestamp(response.timestamp);
    context.setOrbitControlsState(
      new OrbitControlsState(response.orbitControlsState)
    );
    if (!context.getProjectId()) {
      context.setProjectId(response.projectId);
    }
    if (response.settings) {
      context.setSettings(response.settings);
    }
    return context;
  }

  private copyValues(from: VehicleContext, to: VehicleContext, exact: boolean) {
    to.setVehicle(this.vehicleFactory.recreate(from.getVehicle(), true));

    to.setAddedLoads(
      from.getAddedLoads().map((load) => this.loadFactory.recreateLoad(load))
    );

    to.setLoads(from.getLoads().map((l) => this.loadFactory.recreateLoad(l)));

    to.setStatistics(from.getStatistics());
    to.setCurrentTimestamp(from.getCurrentTimestamp());
    to.setOrbitControlsState(
      new OrbitControlsState(from.getOrbitControlsState())
    );

    to.setInitialTimestamp(+new Date());
    to.setProjectId(from.getProjectId());
    to.setLoadingSteps(from.getLoadingSteps());

    if (from.getSettings()) {
      to.setSettings(from.getSettings());
    }
    if (exact) {
      to.setUuid(from.getUuid());
      to.setHistoryUuid(from.getHistoryUuid());
      to.setInitialTimestamp(from.getInitialTimestamp());
    }

    return to;
  }

  clone(other: VehicleContext, exact: boolean = false): VehicleContext {
    const context = new VehicleContext(this.vehicleFactory.create('empty'));
    return this.copyValues(other, context, exact);
  }

  cloneAsNewState(other: VehicleContext): VehicleContext {
    const context = new VehicleContext(this.vehicleFactory.create('empty'));
    const newContext = this.copyValues(other, context, true);
    newContext.generateHistoryUuid();
    return newContext;
  }

  copyInto(from: VehicleContext, to: VehicleContext): VehicleContext {
    return this.copyValues(from, to, true);
  }
}
