import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { LoadComponentService } from 'src/app/services/load-component.service';
import { Vehicle } from '../../lib/vehicle';
import { VehicleType } from '../../lib/vehicle-type';
import { Space } from '../../space/lib/space';
import { VehicleService as VehicleApi } from '../../lib/vehicle.service';
import { CreateComponent } from '../create/create.component';

import { TypeComponent } from '../type/type.component';
import { MenuService } from 'src/app/menu/menu.service';
import { VehicleService } from '../../vehicle.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Settings } from 'src/app/lib/model/settings';
import { SettingsModalComponent } from 'src/app/settings-modal/settings-modal.component';
import { MenuCategory } from 'src/app/settings-modal/lib/menu-category.enum';

@Component({
  selector: 'app-form-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss']
})
export class ExpertComponent implements OnInit, OnDestroy {
  @Output() addVehicleEvent = new EventEmitter<Vehicle>();
  @ViewChild('type') type: TypeComponent;
  public vehicleTypes: Array<VehicleType>;
  public vehicleType: VehicleType;

  eventsSubject: Subject<void> = new Subject<void>();

  listType$ = new BehaviorSubject<string>('none'); // system | user | none - lista typowe lub własne lub widok wyboru

  vehicle: Vehicle = null;
  advancedVisible = false;
  switchToLoadsAfterSubmit = false;

  spaceEventEmittedCnt = 0;

  protected settings: Settings;
  protected useSystemSpaces = true;
  protected useCustomSpaces = true;
  protected useAllSystemSpaces = true;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private vehicleApi: VehicleApi,
    private loadComponentService: LoadComponentService,
    private menuService: MenuService,
    private vehicleService: VehicleService,
    private profileService: ProfileService
  ) {
    this.vehicleTypes = this.vehicleApi.getVehicleTypes();

    this.vehicleService.selectVehicleType(this.vehicleTypes.length > 0 ? this.vehicleTypes[0] : null);
  }

  ngOnInit(): void {
    this.vehicleService.vehicleType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type) => {
      this.typeSelected(type);
    });

    this.profileService.settings$.pipe(takeUntil(this.unsubscribe$)).subscribe((settings) => {
      this.settings = settings;
      this.useSystemSpaces = settings.useSystemSpaces();
      this.useCustomSpaces = settings.useCustomSpaces();
      this.useAllSystemSpaces = settings.useAllSystemSpaces();
      if (!this.useSystemSpaces) {
        this.changeList('user');
      }
      if (!this.useCustomSpaces) {
        this.changeList('system');
      }
      const oldTypesCount = this.vehicleTypes.length;
      this.vehicleTypes = this.vehicleApi.getVehicleTypes();
      if (oldTypesCount === 0 && this.vehicleTypes.length > 0) {
        this.vehicleService.selectVehicleType(this.vehicleTypes[0]);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit() {
    this.switchToLoadsAfterSubmit = false;
    this.initFormSubmit();
  }

  onSubmitAndGoToCargo() {
    this.switchToLoadsAfterSubmit = true;
    this.initFormSubmit();
  }

  initFormSubmit() {
    if (this.vehicle.type === 'warehouse') {
      this.confirmFormSubmit();
    } else {
      this.eventsSubject.next();
    }
  }

  typeSelected(type: VehicleType) {
    this.vehicle = null;
    this.vehicleType = type;
  }

  vehicleSelected(vehicle: Vehicle) {
    this.vehicle = vehicle;
  }

  changeList(type: string) {
    this.listType$.next(type);
    this.vehicleService.selectVehicleType(this.vehicleType);
    if (type === 'system' && this.type) {
      this.type.onShow();
    }
  }

  confirmFormSubmit() {
    this.addVehicleEvent.emit(this.vehicle);
    if (this.switchToLoadsAfterSubmit) {
      this.menuService.openLoadings();
      // this.menuService.setState(MenuState.loadingOpen);
      // this.menuService.switchMenu('loading');
    } else {
      this.menuService.close();
      // this.menuService.setState(MenuState.closed);
      // this.menuService.switchMenu('loading');
    }
  }

  confirmSpaceDimensions(item: Space) {
    //we should check how many spaces should emit events in this vehicle and remember order of it
    const idx = this.vehicle.spaces.findIndex((x) => x.order === item.order);
    this.vehicle.spaces[idx] = item;

    //TODO bardzo duzy workaround
    //przerobic na service bo to nie jest raczej porządne :/
    //count addSpaceEvents
    //emit ony then if every space form already emited addSpaceEvent
    if (++this.spaceEventEmittedCnt === this.vehicle.enabledSpaces.length) {
      this.spaceEventEmittedCnt = 0;
      this.confirmFormSubmit();
    }
  }

  importFromFile() {
    this.loadComponentService.clear('expert.component.ts importFromFile');
    // this.loadComponentService.add(ImportFileComponent);
  }

  createNew() {
    console.log('create new vehicle clicked');
    this.loadComponentService.clear('expert.component.ts createNew');
    this.loadComponentService.add(CreateComponent);
  }

  protected showFleetSettings() {
    this.loadComponentService.clear('header.service.ts showSettings');
    const component = this.loadComponentService.add(SettingsModalComponent);
    component.instance.menuCategory = MenuCategory.Fleet;
  }
}
