<div id="context-menu" [class.active]="model?.opened"
  [style.top.px]="model?.event.clientY" [style.left.px]="model?.event.clientX">
  <div class="item" (click)="rotateHorizontal()"
    [class.disabled]="!model?.canRotateHorizontal">
    <mat-icon>swap_horiz</mat-icon>
    <ng-container i18n>Obróć w poziomie</ng-container>
  </div>
  <div class="item" (click)="rotateVertical()"
    [class.disabled]="!model?.canRotateVertical">
    <mat-icon>swap_vert</mat-icon>
    <ng-container i18n>Obróć w pionie</ng-container>
  </div>
  <div class="item" (click)="deleteLoad()">
    <mat-icon>delete</mat-icon> <ng-container i18n>Usuń</ng-container>
  </div>

  <mat-expansion-panel *ngIf="model?.canChangeFlooring" class="item condensed"
    #flooringPanel (opened)="flooringPanelOpenState = true"
    (closed)="flooringPanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title><mat-icon>category</mat-icon>
        <ng-container i18n>Piętrowanie</ng-container>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="floorable-container panel-content" *ngIf="model">
      <mat-checkbox (change)="floorableOptionChange('floorableAll', $event)"
        [(ngModel)]="model.isFloorableAll" i18n>Całkowita</mat-checkbox>
      <mat-checkbox (change)="floorableOptionChange('floorableTop', $event)"
        [(ngModel)]="model.isFloorableTop" i18n>Pasywna</mat-checkbox>
      <mat-checkbox (change)="floorableOptionChange('floorableBottom', $event)"
        [(ngModel)]="model.isFloorableBottom" i18n>Aktywna</mat-checkbox>
    </div>
  </mat-expansion-panel>
  <!--<div class="item" (click)="changeColor()">-->

  <mat-expansion-panel class="item condensed" #colorPanel
    (opened)="colorPanelOpenState = true"
    (closed)="colorPanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title><mat-icon>format_color_fill</mat-icon>
        <ng-container i18n>Zmień kolor</ng-container>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <color-block triangle="hide" [color]="color"
      [colors]="colors.colorsHexStrings" [style.background]="'transparent'"
      (onChangeComplete)="colorChangeCompleted($event)">
    </color-block>
  </mat-expansion-panel>

  <div class="item" (click)="changeSize()" *ngIf="model?.canChangesize">
    <mat-icon>photo_size_select_small</mat-icon>
    <ng-container i18n>Zmień rozmiar / wagę</ng-container>
  </div>
  <div class="item" (click)="copy()" *ngIf="!model?.isMultiMode">
    <mat-icon>plus_one</mat-icon> <ng-container i18n>Powiel</ng-container>
  </div>

  <div class="item" (click)="closeMenu()">
    <mat-icon>close</mat-icon> <ng-container i18n>Zamknij</ng-container>
  </div>
</div>