<div class="scene-container" id="scene-container">
  <div class="active-criteria-container">
    <app-scene-active-settings [context]="context"></app-scene-active-settings>
  </div>
  <div class="scene-header advanced">
    <app-scene-options [context]="context"></app-scene-options>
  </div>

  <div class="tools">
    <app-scene-tools [context]="context"></app-scene-tools>
  </div>

  <mat-nav-list class="scene-actions text-center">
    <a mat-list-item (click)="showRatingDialog()" i18n-title
      title="Oceń rozmieszczenie"
      *ngIf="loadsExist()"><mat-icon>star</mat-icon></a>
    <a mat-list-item (click)="loadPallets()"
      *ngIf="canLoadAnyContextOnThisVehicle()">
      <div mat-line i18n>Załaduj palety</div>
    </a>
    <a mat-list-item (click)="exportProject()" *ngIf="loadsExist()">
      <div mat-line i18n>Eksportuj projekt</div>
    </a>
    <a mat-list-item (click)="showMassDistribution()"
      *ngIf="massDistributionEnabled && loadsExist()">
      <div mat-line i18n>Rozkład mas</div>
    </a>
    <a mat-list-item (click)="clearLoad()" *ngIf="loadsExist()">
      <div mat-line i18n>Usuń ładunek</div>
    </a>
    <a mat-list-item (click)="clearVehicleAndLoad()"
      *ngIf="isNotEmptyContext()">
      <div mat-line>
        <ng-container i18n>Usuń przestrzeń</ng-container> ({{
          context?.getVehicle()?.fullName
        }})
        <span *ngIf="loadsExist()" i18n> i ładunek</span>
      </div>
    </a>
    <ng-template [ngIf]="loadsExist()">
      <a mat-list-item (click)="saveToMyGarage()">
        <div mat-line i18n>Zapisz układ jako szablon</div>
      </a>
    </ng-template>

    <a mat-list-item (click)="clearAll()" *ngIf="containsAnyContext()">
      <div mat-line i18n>Usuń wszystko</div>
    </a>
    <a mat-list-item (click)="reload()" *ngIf="loadsExist()">
      <div mat-line i18n>Przeładuj</div>
    </a>
  </mat-nav-list>

  <app-context-menu></app-context-menu>
  <canvas #canvas (window:resize)="onResize()" class="loadings-canvas"
    autofocus></canvas>
  <app-orbit-controls></app-orbit-controls>

  <app-vehicle-summary [context]="context"
    *ngIf="isNotEmptyContext()"></app-vehicle-summary>
  <div class="history-buttons">
    <app-history></app-history>
  </div>
</div>