import {
  Box2,
  Box3,
  BoxGeometry,
  Color,
  EdgesGeometry,
  FrontSide,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshPhongMaterial,
  Object3D,
  Raycaster,
  Vector2,
  Vector3
} from 'three';
import { Load } from '../load/lib/load';
import { Vehicle } from '../vehicle/lib/vehicle';
import { Injectable } from '@angular/core';
import { Constants as Config } from '../config/constants';
import { VehicleContext } from '../lib/model/vehicle-context';

enum Direction {
  front = 'X-',
  back = 'X+',
  right = 'Z-',
  left = 'Z+'
}

export enum CollisionDirection {
  lower = 'lower',
  greater = 'greater',
  unknown = 'unknown'
}

type Axis = 'x' | 'y' | 'z';

interface Collisions {
  x?: CollisionDirection;
  y?: CollisionDirection;
  z?: CollisionDirection;
}

interface Intersection {
  uuid?: string;
  size: { x: number; y: number; z: number };
  checkBox: Box3;
}

interface Jump {
  position: Vector3;
  distance: number;
}

@Injectable({
  providedIn: 'root'
})
export class ColliderDetector {
  private pullAccuracy = Config.DIMENSION_SCALING * -100;
  private vehicleBoundingBoxes: Array<Box3>;
  private loads: Load[] = [];
  private meshes: Object3D[] = [];
  private current: Load;
  private vehicle: Vehicle;
  private raycaster: Raycaster = new Raycaster();
  private distance: number;

  private occupiedSpaces: Box3[] = [];

  //reusable
  private _vecA = new Vector3();
  private _vecB = new Vector3();
  private _vecC = new Vector3();

  constructor() {
    this.raycaster.layers.set(Config.LAYER_COLLISIONS);
  }

  public detect(load: Load, context: VehicleContext, loads: Load[], meshes: Object3D[], distance: number) {
    const mesh = load.mesh?.obj;
    mesh.userData.locked = true;
    this.current = load;
    this.vehicle = context.getVehicle();
    this.loads = loads.filter((x) => x.uuid !== load.uuid);
    this.meshes = meshes;
    this.distance = distance;
    //console.log('zoom', this.distance);
    this.current.computeBoundingBox();

    this.occupiedSpaces = context
      .getLoads()
      .filter((l) => l.uuid != this.current.uuid)
      .map((l) => l.boundingBox);

    const currentOriginalBox = this.current.boundingBox.clone();
    currentOriginalBox.min.set(
      Config.scale(this.current.position.x),
      Config.scale(this.current.position.y),
      Config.scale(this.current.position.z)
    );
    currentOriginalBox.max.set(
      Config.scale(this.current.position.x + this.current.cuboidHull.length),
      Config.scale(this.current.position.y + this.current.cuboidHull.height),
      Config.scale(this.current.position.z + this.current.cuboidHull.width)
    );

    this.vehicleBoundingBoxes = new Array<Box3>();
    for (const space of this.vehicle.enabledSpaces) {
      const spacePos = space.mesh.meshObj.getWorldPosition(this._vecA);
      //console.log('collider space offset', spacePos.x);
      if (this.current.spaceUuid === space.uuid) {
        currentOriginalBox.min.set(
          spacePos.x + Config.scale(this.current.position.x),
          spacePos.y + Config.scale(this.current.position.y),
          spacePos.z + Config.scale(this.current.position.z)
        );
        currentOriginalBox.max.set(
          spacePos.x + Config.scale(this.current.position.x + this.current.cuboidHull.length),
          spacePos.y + Config.scale(this.current.position.y + this.current.cuboidHull.height),
          spacePos.z + Config.scale(this.current.position.z + this.current.cuboidHull.width)
        );
      }

      this.vehicleBoundingBoxes.push(
        this.roundBox(
          new Box3(
            spacePos.clone(),
            new Vector3(
              spacePos.x + Config.scale(space.length),
              spacePos.y + Config.scale(space.height),
              spacePos.z + Config.scale(space.width)
            )
          )
        )
      );

      space.occupiedMatrix.forEach((v) => {
        this.occupiedSpaces.push(
          this.roundBox(
            new Box3(
              new Vector3(
                spacePos.x + Config.scale(v.x),
                spacePos.y + Config.scale(v.y),
                spacePos.z + Config.scale(v.z)
              ),
              new Vector3(
                spacePos.x + Config.scale(v.x + v.xLength),
                spacePos.y + Config.scale(v.y + v.yLength),
                spacePos.z + Config.scale(v.z + v.zLength)
              )
            )
          )
        );
      });
    }
    this.vehicleBoundingBoxes = this.vehicleBoundingBoxes.sort((a, b) => a.min.x - b.min.x);

    this.roundBox(currentOriginalBox);
    this.cutOutCurrentFromOccupied(currentOriginalBox);
    /*console.log(
      'occupied',
      this.occupiedSpaces.map((s) => [s.min.x, s.min.y, s.min.z, s.max.x, s.max.y, s.max.z])
    );*/

    this.setSceneBoundary();

    //this.grounding(load);
    this.lowerUnsupportedLoad(this.loads);

    this.collisionDetection(this.loads);
    this.current.computeBoundingBox();
    mesh.userData.locked = false;
  }

  private cutOutCurrentFromOccupied(current: Box3) {
    //console.log('cut out', [current.min.x, current.min.y, current.min.z, current.max.x, current.max.y, current.max.z]);
    const newBoxes: Box3[] = [];
    for (let i = 0; i < this.occupiedSpaces.length; i++) {
      const space = this.occupiedSpaces[i];
      const intersection = this.getTwoBoxesIntersection(current, space);
      if (intersection) {
        newBoxes.push(...this.splitBox(space, current));
        this.occupiedSpaces[i] = undefined;
      }
    }
    //console.log('occupied len', this.occupiedSpaces.length);
    this.occupiedSpaces = this.occupiedSpaces.filter((x) => !!x);
    //console.log('occupied len after cutout', this.occupiedSpaces.length);
    this.occupiedSpaces.push(...newBoxes);
    /*console.log(
      'occupied len after add split',
      this.occupiedSpaces.map((s) => [s.min.x, s.min.y, s.min.z, s.max.x, s.max.y, s.max.z])
    );*/
  }

  private splitBox(toSplit: Box3, splitOn: Box3) {
    const newBoxes: Box3[] = [];
    const beforeX = toSplit.clone();
    const afterX = toSplit.clone();
    beforeX.max.x = splitOn.min.x;
    afterX.min.x = splitOn.max.x;
    const beforeY = toSplit.clone();
    const afterY = toSplit.clone();
    beforeY.max.y = splitOn.min.y;
    afterY.min.y = splitOn.max.y;
    const beforeZ = toSplit.clone();
    const afterZ = toSplit.clone();
    beforeZ.max.z = splitOn.min.z;
    afterZ.min.z = splitOn.max.z;
    if (beforeX.max.x > beforeX.min.x) {
      newBoxes.push(beforeX);
    }
    if (beforeY.max.y > beforeY.min.y) {
      newBoxes.push(beforeY);
    }
    if (beforeZ.max.z > beforeZ.min.z) {
      newBoxes.push(beforeZ);
    }
    if (afterX.max.x > afterX.min.x) {
      newBoxes.push(afterX);
    }
    if (afterY.max.y > afterY.min.y) {
      newBoxes.push(afterY);
    }
    if (afterZ.max.z > afterZ.min.z) {
      newBoxes.push(afterZ);
    }
    return newBoxes;
  }

  /**
   * Ustawia maksymalną możliwą pozycję dla ładunków na scenie
   */
  private setSceneBoundary() {
    const mesh = this.current.mesh?.obj;
    const box = this.current.boundingBox;
    const boxSize = box.getSize(this._vecA);
    for (const axis of ['x', 'z']) {
      if (box.min[axis] < -Config.GRID_SIZE / 2) {
        mesh.position[axis] = -Config.GRID_SIZE / 2 + boxSize[axis] / 2;
        mesh.userData.validPosition = this.roundVector(mesh.position.clone());
      }
      if (box.max[axis] > Config.GRID_SIZE / 2) {
        mesh.position[axis] = Config.GRID_SIZE / 2 - boxSize[axis] / 2;
        mesh.userData.validPosition = this.roundVector(mesh.position.clone());
      }
    }
  }

  /**
   * Rysuje prostopadłościany - do debugowania
   *
   * @param boxes Box3[]
   */
  private drawBoxes(boxes: Box3[]) {
    let b: Object3D;
    while ((b = this.vehicle.mesh.mesh.parent.getObjectByName('boundary'))) {
      this.vehicle.mesh.mesh.parent.remove(b);
    }
    for (const box of boxes) {
      const center = box.getCenter(new Vector3());
      const mesh = new Mesh();
      mesh.position.copy(center);
      mesh.name = 'boundary';
      mesh.geometry = new BoxGeometry(box.max.x - box.min.x, box.max.y - box.min.y, box.max.z - box.min.z);
      mesh.material = new MeshPhongMaterial({
        color: Color.NAMES.gray,
        side: FrontSide,
        transparent: true,
        opacity: 0.2,
        depthWrite: false
      });
      const wireframe = new EdgesGeometry(mesh.geometry);
      const mat = new LineBasicMaterial({
        color: Color.NAMES.steelblue,
        transparent: false,
        opacity: 1,
        linewidth: 1
      });
      const line = new LineSegments(wireframe, mat);
      mesh.add(line);
      this.vehicle.mesh.mesh.parent.add(mesh);
    }
  }

  /**
   * Tworzy boxy wokół przestrzeni.
   * Unikamy w ten sposób ładunków wystających w części z przestrzeni
   * i osiągamy efekt jak w GL - przeskakiwania ładunków nieco poza przestrzeń
   * w momencie ich wyciągania
   *
   * @returns Box3[]
   */
  private getFreeSpaceBoundaries(): Box3[] {
    let first = true;
    const spaceBoundary = Math.min(
      Config.roundToScale(500 * Config.DIMENSION_SCALING),
      Config.roundToScale(this.distance / 25),
      Config.roundToScale(
        (Math.min(this.current.cuboidHull.length, this.current.cuboidHull.height, this.current.cuboidHull.width) / 2) *
          Config.DIMENSION_SCALING
      )
    );
    //const spaceBoundary = 500 * Config.DIMENSION_SCALING;
    //console.log('space boundary', spaceBoundary);
    let spaceXMax: number;
    const boxes: Box3[] = [];
    for (const spaceBox of this.vehicleBoundingBoxes) {
      let xFront = spaceBoundary;
      if (first) {
        xFront = Math.max(this.vehicle.cabinLength * Config.DIMENSION_SCALING, xFront);
      } else if (spaceXMax !== undefined) {
        xFront = spaceBox.min.x - spaceXMax;
      }
      // front
      boxes.push(
        this.roundBox(
          new Box3(
            new Vector3(spaceBox.min.x - xFront, spaceBox.min.y, spaceBox.min.z - spaceBoundary),
            new Vector3(spaceBox.min.x, spaceBox.max.y, spaceBox.max.z + spaceBoundary)
          )
        )
      );
      // left
      boxes.push(
        this.roundBox(
          new Box3(
            new Vector3(spaceBox.min.x, spaceBox.min.y, spaceBox.max.z),
            new Vector3(spaceBox.max.x, spaceBox.max.y, spaceBox.max.z + spaceBoundary)
          )
        )
      );
      // right
      boxes.push(
        this.roundBox(
          new Box3(
            new Vector3(spaceBox.min.x, spaceBox.min.y, spaceBox.min.z - spaceBoundary),
            new Vector3(spaceBox.max.x, spaceBox.max.y, spaceBox.min.z)
          )
        )
      );

      spaceXMax = spaceBox.max.x;
      first = false;
    }
    const last = this.vehicleBoundingBoxes[this.vehicleBoundingBoxes.length - 1];
    boxes.push(
      this.roundBox(
        new Box3(
          new Vector3(last.max.x, last.min.y, last.min.z - spaceBoundary),
          new Vector3(last.max.x + spaceBoundary, last.max.y, last.max.z + spaceBoundary)
        )
      )
    );
    //this.drawBoxes(boxes);
    return boxes;
  }

  /**
   * Zwraca wszystkie poziomy (w osi Y) na jakich kończą się ładunki,
   * rozpoczynając od najniższego poziomu
   *
   * @param loads Load[]
   */
  private getLoadLevels(loads: Load[]): number[] {
    const levels = [
      ...new Set(
        loads.map((l) => {
          return l.boundingBox.max.y;
        })
      )
    ].sort((a, b) => a - b);
    return levels;
  }

  private invalidPositionsBoundaries(forBox: Box3, level: number): Box3[] {
    // const meshes = this.loads.map((l) => l.mesh.obj);

    const invalid: Box3[] = [];
    const rayCache = new Map<Direction, { x: number; z: number; dir: Direction }[]>();
    let intersectionCheckCnt = 0;

    for (const load of this.loads) {
      const box = load.boundingBox;
      if (box.max.y !== level || Math.abs(box.min.x - forBox.min.x) > 5 * (forBox.max.x - forBox.min.x)) {
        continue;
      }
      const space = this.findVehicleSpaceForBox(box);
      if (!space) {
        continue;
      }
      //console.log('level', level);

      const checkDirections = [
        {
          checks: [
            // górny prawy - w stronę tyłu
            {
              from: [box.max.x - Config.scale(1), box.min.z],
              to: [space.max.x, box.min.z]
            },
            // górny lewy - w stronę tyłu
            {
              from: [box.max.x - Config.scale(1), box.max.z],
              to: [space.max.x, box.max.z]
            }
          ],
          direction: Direction.back,
          spaceStart: [box.max.x, box.min.z],
          spaceEnd: [space.max.x, box.max.z]
        },
        {
          checks: [
            // górny przedni - w stronę lewej strony pojazdu
            {
              from: [box.min.x, box.max.z - Config.scale(1)],
              to: [box.min.x, space.max.z]
            },
            // górny tylny - w stronę lewej strony pojazdu
            {
              from: [box.max.x, box.max.z - Config.scale(1)],
              to: [box.max.x, space.max.z]
            }
          ],
          direction: Direction.left,
          spaceStart: [box.min.x, box.max.z],
          spaceEnd: [box.max.x, space.max.z]
        },
        {
          checks: [
            // górny przedni - w stronę prawej strony pojazdu
            {
              from: [box.min.x, box.min.z + Config.scale(1)],
              to: [box.min.x, space.min.z]
            },
            // górny tylny - w stronę prawej strony pojazdu
            {
              from: [box.max.x, box.min.z + Config.scale(1)],
              to: [box.max.x, space.min.z]
            }
          ],
          direction: Direction.right,
          spaceStart: [box.min.x, space.min.z],
          spaceEnd: [box.max.x, box.min.z]
        },
        {
          checks: [
            // górny prawy - w stronę przodu pojazdu
            {
              from: [box.min.x + Config.scale(1), box.min.z],
              to: [space.min.x, box.min.z]
            },
            // górny lewy - w stronę przodu pojazdu
            {
              from: [box.min.x + Config.scale(1), box.max.z],
              to: [space.min.x, box.max.z]
            }
          ],
          direction: Direction.front,
          spaceStart: [space.min.x, box.min.z],
          spaceEnd: [box.min.x, box.max.z]
        }
      ];
      //let intersects: THREE.Intersection[] = [];
      checkDirections.forEach((side) => {
        //intersects = [];
        let intersectionCnt = 0;
        for (const check of side.checks) {
          const cache = rayCache.get(side.direction) || [];
          for (const entry of cache) {
            if (
              side.direction == Direction.front &&
              side.direction == entry.dir &&
              entry.x <= check.from[0] &&
              entry.z == check.from[1]
            ) {
              return;
            }
            if (
              side.direction == Direction.right &&
              side.direction == entry.dir &&
              entry.z <= check.from[1] &&
              entry.x == check.from[0]
            ) {
              return;
            }
            if (
              side.direction == Direction.back &&
              side.direction == entry.dir &&
              entry.x >= check.from[0] &&
              entry.z == check.from[1]
            ) {
              return;
            }
            if (
              side.direction == Direction.left &&
              side.direction == entry.dir &&
              entry.z >= check.from[1] &&
              entry.x == check.from[0]
            ) {
              return;
            }
          }
          const startPoint = this.roundVector(
            this._vecA.set(check.from[0], box.max.y - Config.scale(1), check.from[1])
          );

          const target = this.roundVector(this._vecB.set(check.to[0], box.max.y - Config.scale(1), check.to[1]));
          const direction = this.roundVector(this._vecC.subVectors(target, startPoint));

          this.raycaster.far = direction.length();
          this.raycaster.set(startPoint, direction.normalize());
          let newIntersections = this.raycaster.intersectObjects(this.meshes, true);

          if (newIntersections.length == 1 && newIntersections[0].object.uuid === this.current.mesh.obj.uuid) {
            // no-op
          } else {
            intersectionCnt += newIntersections.length;
            if (newIntersections.length > 0) {
              let cache = rayCache.get(side.direction);
              if (!cache) {
                cache = [];
              }
              cache.push({ x: check.from[0], z: check.from[1], dir: side.direction });
              rayCache.set(side.direction, cache);
            }
          }
          //intersects = intersects.concat(this.raycaster.intersectObjects(meshes));
          intersectionCheckCnt++;
        }

        if (intersectionCnt === 0) {
          const invalidSpace = this.roundBox(
            new Box3(
              new Vector3(side.spaceStart[0], box.max.y, side.spaceStart[1]),
              new Vector3(side.spaceEnd[0], space.max.y, side.spaceEnd[1])
            )
          );

          if (side.spaceEnd[0] > side.spaceStart[0] && side.spaceEnd[1] > side.spaceStart[1]) {
            invalid.push(invalidSpace);
          }
        }
      });
    }
    //this.drawBoxes(invalid);
    return invalid;
  }

  /**
   * Obniża ładunki, które nie leżą na innych
   *
   * @param loads Load[]
   * @returns void
   */
  private lowerUnsupportedLoad(loads: Load[]) {
    let box = this.current.boundingBox;
    const vec2 = new Vector2();
    //console.log('box', this.current.idx, box.min, box.max);
    const boxSize = box.getSize(this._vecA);

    const loadLevelMap = new Map<number, { floorableTop: boolean; box: Box3 }[]>();
    loads.forEach((l) => {
      const checkBox = l.boundingBox;
      const onLevel = loadLevelMap.get(checkBox.max.y) || [];
      onLevel.push({ floorableTop: l.floorableTop, box: checkBox });
      loadLevelMap.set(checkBox.max.y, onLevel);
    });
    const levels = Array.from(loadLevelMap.keys())
      .sort((a, b) => b - a)
      .filter((l) => l <= box.min.y);

    //console.log('levels', levels);
    for (const level of levels) {
      //console.log('lower check level', level);
      let minY = 0;
      let canBeFloored = this.current.floorableBottom;
      let totalIntersection: Box2 = new Box2().makeEmpty();
      for (const other of loadLevelMap.get(level)) {
        const checkBox = other.box;
        minY = Math.max(minY, checkBox.min.y);
        const intersection = this.getTwoBoxesFloorContactSurface(box, checkBox);
        const intersectionSize = intersection?.getSize(vec2);
        if (intersection && intersectionSize.x !== 0 && intersectionSize.y !== 0) {
          //console.log('intersect', level, intersection);
          const area = intersectionSize.x * intersectionSize.y;
          if (area > 0) {
            totalIntersection.union(intersection);
            canBeFloored &&= other.floorableTop;
          }
        }
      }
      const intersectionSize = totalIntersection.getSize(vec2);

      if (intersectionSize.x < boxSize.x / 2 || intersectionSize.y < boxSize.z / 2) {
        //console.log('total intersection', intersectionSize, 'box', boxSize);
        this.updateLoadYPosition(Config.roundToScale(minY + boxSize.y / 2));
        box = this.current.boundingBox;
      } else {
        return;
      }
    }
  }

  /**
   * Zwraca nową współrzędną w osi Y jeśli ładunek można umieścić wyżej niż obecnie
   *
   * @param loads Load[]
   * @returns number|false
   */
  private stackSupportedLoad(loads: Load[]): number | false {
    let canBeStacked = this.current.floorableBottom;
    if (!canBeStacked) {
      return false;
    }
    const vec2 = new Vector2();
    const box = this.current.boundingBox.clone();
    //console.log('floor box', this.current.idx, box.min, box.max);
    const boxSize = box.getSize(this._vecA);

    const loadLevelMap = new Map<number, Box3[]>();
    loads.forEach((l) => {
      if (!l.floorableTop) {
        return;
      }
      const checkBox = l.boundingBox;
      const onLevel = loadLevelMap.get(checkBox.max.y) || [];
      onLevel.push(checkBox);
      loadLevelMap.set(checkBox.max.y, onLevel);
    });
    const levels = Array.from(loadLevelMap.keys())
      .sort((a, b) => b - a)
      .filter((l) => l > box.min.y);

    let totalIntersection = new Box2();
    for (const level of levels) {
      totalIntersection.makeEmpty();

      for (const other of loadLevelMap.get(level)) {
        const intersection = this.getTwoBoxesFloorContactSurface(box, other, true);
        const intersectionSize = intersection?.getSize(vec2);
        if (intersection && intersectionSize.x !== 0 && intersectionSize.y !== 0) {
          //console.log('f intersect', level, intersection);
          const area = intersectionSize.x * intersectionSize.y;
          if (area > 0) {
            totalIntersection.union(intersection);
          }
        }
      }
      const intersectionSize = totalIntersection.getSize(vec2);

      if (canBeStacked && intersectionSize.x >= boxSize.x / 2 && intersectionSize.y >= boxSize.z / 2) {
        const translation = this._vecB.set(0, level - box.min.y, 0);
        //console.log('floor', level, translation.y, Config.roundToScale(level + boxSize.y / 2));
        const movedBox = this.roundBox(box.translate(translation));
        if (this.fitsInCurrentSpace(movedBox)) {
          return Config.roundToScale(level + boxSize.y / 2);
        }
        return false;
      }
    }
    return false;
  }

  /**
   * Zwraca informacje o przecięciu dwóch obiektów Box3
   * @param box Box3
   * @param checkBox Box3
   * @returns Intersection|null
   */
  private getTwoBoxesIntersection(box: Box3, checkBox: Box3): Intersection {
    if (
      box.max.x < checkBox.min.x ||
      box.max.y < checkBox.min.y ||
      box.max.z < checkBox.min.z ||
      checkBox.max.x < box.min.x ||
      checkBox.max.y < box.min.y ||
      checkBox.max.z < box.min.z
    ) {
      return undefined;
    }
    const intersectXlength = Config.roundToScale(
      Math.min(checkBox.max.x, box.max.x) - Math.max(checkBox.min.x, box.min.x)
    );
    if (intersectXlength <= 0) {
      return undefined;
    }

    const intersectZlength = Config.roundToScale(
      Math.min(checkBox.max.z, box.max.z) - Math.max(checkBox.min.z, box.min.z)
    );
    if (intersectZlength <= 0) {
      return undefined;
    }

    const intersectYlength = Config.roundToScale(
      Math.min(checkBox.max.y, box.max.y) - Math.max(checkBox.min.y, box.min.y)
    );
    if (intersectYlength <= 0) {
      return undefined;
    }
    return {
      size: { x: intersectXlength, y: intersectYlength, z: intersectZlength },
      checkBox
    };
  }

  /**
   * Zwraca informacje o wspólnej częsci "podłogi" dwóch obiektów Box3 stojących bezpośrednio na sobie
   *
   * @param box Box3
   * @param checkBox Box3
   * @param ignoreY: boolean
   * @returns Box2
   */
  private getTwoBoxesFloorContactSurface(box: Box3, checkBox: Box3, ignoreY = false): Box2 {
    if (
      ignoreY ||
      Config.roundToScale(box.min.y - checkBox.max.y) === 0 ||
      Config.roundToScale(box.max.y - checkBox.min.y) === 0
    ) {
      const maxX = Math.min(checkBox.max.x, box.max.x);
      const minX = Math.max(checkBox.min.x, box.min.x);
      const intersectXlength = Config.roundToScale(maxX - minX);

      const maxZ = Math.min(checkBox.max.z, box.max.z);
      const minZ = Math.max(checkBox.min.z, box.min.z);
      const intersectZlength = Config.roundToScale(maxZ - minZ);
      if (intersectXlength > 0 && intersectZlength > 0) {
        return this.roundBox2(new Box2(new Vector2(minX, minZ), new Vector2(maxX, maxZ)));
        //return new Vector3(intersectXlength, 0, intersectZlength);
      }
    }
    return undefined;
  }

  /**
   * Ustawia nową pozycję ładunku w osi Y.
   * Nowa pozycja ustawiana jest również w dodatkowych atrybutach obiektu,
   * tak żeby TransformControls nie próbowało przywrócić pozycji startowej.
   * TransformControls zostało odpowiednio zmodyfikowane, aby odczytywać ten atrybut.
   *
   * @param load Load
   * @param newY number
   */
  private updateLoadYPosition(newY: number) {
    //console.error('move y', newY);
    this.current.mesh.obj.position.y = newY;
    this.current.mesh.obj.userData.changedY = newY;
    this.current.computeBoundingBox();
  }

  /**
   * Sprawdza czy box mieści się w całości w jednej z przestrzeni
   *
   * @param box Box3
   * @returns boolean
   */
  private fitsInCurrentSpace(box: Box3): boolean {
    const space = this.findVehicleSpaceForBox(box);
    return space ? true : false;
  }

  /**
   * Zwraca przestrzeń, w której mieści się box
   *
   * @param box Box3
   * @returns Box3 | null
   */
  private findVehicleSpaceForBox(box: Box3): Box3 | null {
    for (const checkBox of this.vehicleBoundingBoxes) {
      const maxX = Math.min(checkBox.max.x, box.max.x);
      const minX = Math.max(checkBox.min.x, box.min.x);
      const intersectXlength = Config.roundToScale(maxX - minX);

      const maxZ = Math.min(checkBox.max.z, box.max.z);
      const minZ = Math.max(checkBox.min.z, box.min.z);
      const intersectZlength = Config.roundToScale(maxZ - minZ);

      if (intersectXlength > 0 && intersectZlength > 0) {
        if (box.max.y > checkBox.max.y) {
          return null;
        }
        return checkBox;
      }
    }
    return null;
  }

  /**
   * Realizuje funkcję przyciągania do innych ładunków.
   * Zwraca box z pozycją po przyciągnięciu lub box z niezmienioną pozycją jeśli nie było przyciągania.
   *
   * @param boxes Box3[]
   * @returns Box3
   */
  private pullToLoads(boxes: Box3[]): Box3 {
    const box = this.current.boundingBox;
    const boxSize = box.getSize(new Vector3());
    const newPosition = this.roundVector(this.current.mesh.obj.position.clone());

    for (const checkBox of boxes) {
      let maxX = Math.min(checkBox.max.x, box.max.x);
      let minX = Math.max(checkBox.min.x, box.min.x);
      let intersectXlength = Config.roundToScale(maxX - minX);

      let maxZ = Math.min(checkBox.max.z, box.max.z);
      let minZ = Math.max(checkBox.min.z, box.min.z);
      let intersectZlength = Config.roundToScale(maxZ - minZ);

      if (intersectZlength > 0) {
        if (intersectXlength > this.pullAccuracy && intersectXlength < 0) {
          if (box.min.x > checkBox.max.x) {
            newPosition.x = checkBox.max.x + boxSize.x / 2;
          } else {
            newPosition.x = checkBox.min.x - boxSize.x / 2;
          }
        }
      }

      if (intersectXlength > 0) {
        if (intersectZlength > this.pullAccuracy && intersectZlength < 0) {
          if (box.min.z > checkBox.max.z) {
            newPosition.z = checkBox.max.z + boxSize.z / 2;
          } else {
            /*console.log(
              'pull ',
              intersectXlength,
              intersectZlength,
              box.min.z,
              checkBox.min.z
            );*/
            newPosition.z = checkBox.min.z - boxSize.z / 2;
          }
        }
      }
    }
    return this.roundBox(box.setFromCenterAndSize(newPosition, boxSize));
  }

  /**
   * Zwraca listę przecięć dwóch obiektów Box3,
   * posortowaną wg rozmiarów w osi X i Z.
   *
   * @param box Box3
   * @param others Box3[] already sorted by max.x desc
   * @returns Intersection[]
   */
  private getIntersections(box: Box3, others: Box3[]): Intersection[] {
    const intersections: Intersection[] = [];
    for (let i = 0; i < others.length; i++) {
      const checkBox = others[i];
      if (checkBox.max.x < box.min.x) {
        break;
      }
      const intersection = this.getTwoBoxesIntersection(box, checkBox);
      if (intersection) {
        intersections.push(intersection);
      }
    }

    intersections.sort((a, b) => {
      if (a.size.x === b.size.x) {
        return a.size.z - b.size.z;
      }
      return a.size.x - b.size.x;
    });
    return intersections;
  }

  /**
   * Sprawdza gdzie będzie najmniejsze przesunięcie ładunku wg ustalonego kierunku przesuwania.
   * Zwykłe sprawdzanie z boxSize / 2 nie sprawdza się gdy kierunek przeskoku jest już ustalony.
   *
   * @param position Vector3
   * @param boxSize Vector3
   * @param intersection Intersection
   * @param collisionDirection Collisions
   * @returns Axis
   */
  private getLeastJumpAxis(
    position: Vector3,
    boxSize: Vector3,
    intersection: Intersection,
    collisionDirection: Collisions
  ): Axis {
    let xJump = intersection.size.x;
    let zJump = intersection.size.z;
    let targetX: number;
    let targetZ: number;
    if (collisionDirection.x === CollisionDirection.lower) {
      targetX = Config.roundToScale(intersection.checkBox.min.x - boxSize.x / 2);
      xJump = Config.roundToScale(Math.abs(position.x - targetX));
    } else if (collisionDirection.x === CollisionDirection.greater) {
      targetX = Config.roundToScale(intersection.checkBox.max.x + boxSize.x / 2);
      xJump = Config.roundToScale(Math.abs(position.x - targetX));
    }
    if (collisionDirection.z === CollisionDirection.lower) {
      targetZ = Config.roundToScale(intersection.checkBox.min.z - boxSize.z / 2);
      zJump = Config.roundToScale(Math.abs(position.z - targetZ));
    } else if (collisionDirection.z === CollisionDirection.greater) {
      targetZ = Config.roundToScale(intersection.checkBox.max.z + boxSize.z / 2);
      zJump = Config.roundToScale(Math.abs(position.z - targetZ));
    }

    let axis: Axis = 'z';
    if (xJump < zJump) {
      axis = 'x';
    }
    return axis;
  }

  /**
   * Zwraca informację o najmniejszym możliwym przeskoku w celu wyeliminowania kolizji.
   * Wykonuje się rekurencyjnie aż do osiągnięcia pozycji bez kolizji.
   *
   * @param box Box3
   * @param startPosition Vector3
   * @param loads Box3[]
   * @param level number
   * @param collisionDirection CollisionDirection
   * @param jumpHistory Vector3[]
   * @returns Jump
   */
  private findNearestFreeSpace(
    box: Box3,
    startPosition: Vector3,
    loads: Box3[],
    level = 0,
    collisionDirection: Collisions = {},
    jumpHistory: Vector3[] = [],
    id = '1'
  ): Jump {
    const startPositionRound = new Vector3(
      Config.roundToScale(startPosition.x),
      Config.roundToScale(startPosition.y),
      Config.roundToScale(startPosition.z)
    );

    const boxSize = this.roundVector(box.getSize(this._vecA));
    const intersections = this.getIntersections(box, loads);
    //console.log('intersections', intersections, level);
    let minJumpPosition: Vector3;
    let minJumpDistance: number;
    /*console.log(
      'findNearestFreeSpace start',
      id,
      level,
      startPositionRound.x,
      startPositionRound.y,
      startPositionRound.z,
      boxSize.x,
      boxSize.y,
      boxSize.z
    );*/
    if (intersections.length === 0) {
      //console.error('findNearestFreeSpace return lvl', id, level);
      return {
        position: startPositionRound,
        distance: 0
      };
    }
    if (level > 5) {
      //console.log('findNearestFreeSpace max lvl', id);
      return undefined;
    }

    //console.log('findNearestFreeSpace collisions', id, intersections.length);

    let idx = 0;
    for (const intersection of intersections) {
      idx++;
      const pathId = `${id}-${idx}`;
      const localJumpHistory = [...jumpHistory];
      let position = startPositionRound.clone();

      const axis = this.getLeastJumpAxis(position, boxSize, intersection, collisionDirection);
      //console.log('collision', id, axis, collisionDirection[axis]);
      //for (const axis of ['x', 'z']) {
      if (collisionDirection[axis] === undefined) {
        const cmp = Config.roundToScale(startPositionRound[axis] - boxSize[axis] / 2 - intersection.checkBox.min[axis]);
        if (cmp < 0) {
          collisionDirection[axis] = CollisionDirection.lower;
        } else {
          collisionDirection[axis] = CollisionDirection.greater;
        }
      }
      if (collisionDirection[axis] === CollisionDirection.lower) {
        position[axis] = Config.roundToScale(intersection.checkBox.min[axis] - boxSize[axis] / 2);
      } else {
        position[axis] = Config.roundToScale(intersection.checkBox.max[axis] + boxSize[axis] / 2);
      }
      if (localJumpHistory.find((pos) => pos.equals(position))) {
        continue;
      }
      //console.log('jump to', id, position.x, position.y, position.z);
      localJumpHistory.push(position.clone());
      //collisionDirection = {};
      let jumpDistance = Config.roundToScale(startPositionRound.distanceTo(position));
      //console.log('jump distance', id, jumpDistance);

      let nextBox = this.roundBox(new Box3().setFromCenterAndSize(position, boxSize));
      let jumpData = this.findNearestFreeSpace(
        nextBox,
        position,
        loads /*.filter((x) => x.uuid !== intersection.uuid)*/,
        level + 1,
        collisionDirection,
        localJumpHistory,
        pathId
      );
      if (jumpData?.position) {
        jumpDistance = Config.roundToScale(startPositionRound.distanceTo(jumpData.position));
        if (minJumpDistance === undefined || jumpDistance < minJumpDistance) {
          minJumpDistance = jumpDistance;
          minJumpPosition = jumpData.position.clone();
        }
      } else {
        // TODO ? tu raczej zwracać undefined, bo nie doszło do wolnej przestrzeni
        /*if (minJumpDistance === undefined || jumpDistance < minJumpDistance) {
          minJumpDistance = jumpDistance;
          minJumpPosition = position.clone();
        }*/
      }

      collisionDirection = {};
      //}
    }

    /*if (minJumpPosition) {
      console.log(
        'jump',
        minJumpPosition.x,
        minJumpPosition.z,
        minJumpPosition.y,
        minJumpDistance,
        level
      );
    }*/
    return {
      position: minJumpPosition,
      distance: minJumpDistance
    };
  }

  private roundVector(vector: Vector3) {
    vector.x = Config.roundToScale(vector.x);
    vector.y = Config.roundToScale(vector.y);
    vector.z = Config.roundToScale(vector.z);
    return vector;
  }
  private roundVector2(vector: Vector2) {
    vector.x = Config.roundToScale(vector.x);
    vector.y = Config.roundToScale(vector.y);
    return vector;
  }

  private roundBox(box: Box3) {
    this.roundVector(box.min);
    this.roundVector(box.max);
    return box;
  }
  private roundBox2(box: Box2) {
    this.roundVector2(box.min);
    this.roundVector2(box.max);
    return box;
  }

  /**
   * Detekcja kolizji z innymi ładunkami.
   * Najpierw włącza przyciąganie do innych ładunków,
   * następnie sprawdza możliwość piętrowania ładunku,
   * po czym szuka wolnego miejsca na zadanym poziomie.
   * Ustawia docelową pozycję ładunku wg najmniejszego przeskoku.
   *
   * @param loads Load[]
   */
  private collisionDetection(loads: Load[]) {
    const mesh = this.current.mesh.obj;
    const box = this.current.boundingBox.clone();
    const boxSize = this.roundVector(box.getSize(new Vector3()));
    /*console.log(
      'start valid position',
      mesh.userData.validPosition
    );*/
    let boxes = this.occupiedSpaces; //loads.map((l) => l.boundingBox);

    const pulled = this.pullToLoads(boxes);
    if (pulled && Config.roundToScale(pulled.getCenter(new Vector3()).distanceToSquared(mesh.position)) === 0) {
      box.copy(pulled);
      const center = this.roundVector(pulled.getCenter(new Vector3()));
      //mesh.userData.changedX = center.x;
      //mesh.userData.changedZ = center.z;
      mesh.position.copy(center);
      this.current.computeBoundingBox();
    }

    let boundaries = this.getFreeSpaceBoundaries();

    boxes = boxes.concat(...boundaries);
    boxes.sort((a, b) => b.max.x - a.max.x);
    const changeFloor = this.stackSupportedLoad(loads);

    let floorJump: Jump;
    if (changeFloor !== false) {
      const jumpBox = box.clone();
      jumpBox.min.y = Config.roundToScale(changeFloor - boxSize.y / 2);
      jumpBox.max.y = Config.roundToScale(changeFloor + boxSize.y / 2);
      //console.error('change floor', jumpBox.min.y, box.min.y);
      //this.updateLoadYPosition(changeFloor);
      // add invalid boundaries for this level
      const extraInvalidBoundaries = this.invalidPositionsBoundaries(jumpBox, Config.roundToScale(jumpBox.min.y));
      boundaries = boundaries.concat(...extraInvalidBoundaries);
      boxes = boxes.concat(...extraInvalidBoundaries);
      boxes.sort((a, b) => b.max.x - a.max.x);
      //this.drawBoxes(extraInvalidBoundaries);
      floorJump = this.findNearestFreeSpace(jumpBox, this.roundVector(jumpBox.getCenter(new Vector3())), boxes);
      //console.log('after floor jump', floorJump);
    }

    const jump = this.findNearestFreeSpace(box, this.roundVector(mesh.position.clone()), boxes);
    //console.log('results after findNearestFreeSpace', jump, floorJump, changeFloor);
    if (changeFloor !== false && floorJump?.position && jump?.position && floorJump.distance <= jump.distance) {
      //console.log('change pos floor jump', floorJump.position);
      //floorJump.position.y = changeFloor;
      if (Config.roundToScale(mesh.position.distanceToSquared(floorJump.position)) !== 0) {
        //console.log('set changed X/Z');
        mesh.userData.changedX = floorJump.position.x;
        mesh.userData.changedZ = floorJump.position.z;
      }
      mesh.position.copy(floorJump.position);
      mesh.userData.validPosition = floorJump.position.clone();
      this.updateLoadYPosition(floorJump.position.y);
    } else if (jump?.position) {
      //console.log('change pos next jump', jump.position);
      if (Config.roundToScale(mesh.position.distanceToSquared(jump.position)) !== 0) {
        mesh.userData.changedX = jump.position.x;
        mesh.userData.changedZ = jump.position.z;
      }
      mesh.position.copy(jump.position);
      mesh.userData.validPosition = jump.position.clone();
      this.current.computeBoundingBox();
    } else {
      //console.log('back to valid position', mesh.userData.validPosition);

      if (Config.roundToScale(mesh.position.distanceToSquared(mesh.userData.validPosition)) !== 0) {
        const valid = mesh.userData.validPosition;
        mesh.userData.changedX = valid.x;
        mesh.userData.changedZ = valid.z;
      }
      mesh.position.copy(mesh.userData.validPosition);
      this.current.computeBoundingBox();
    }
  }
}
