import { FlooringLabel } from './FlooringLabel';
import { Constants as Config } from 'src/app/config/constants';
import { TextLabel } from './TextLabel';
import { Load } from 'src/app/load/lib/load';

export class FlooringTopLabel extends FlooringLabel {
  updatePosition(load: Load): void {
    const position = load.mesh.position.clone();
    position.y += (load.cuboidHull.height / 2 - 100) * Config.DIMENSION_SCALING;
    this.setPosition(position);
  }

  updateCssClass(load: Load): void {
    this.setCssClass(
      'material-label ' +
        (load.floorableTop ? 'color-enabled' : 'color-disabled') +
        ' ' +
        'scale-' +
        this.textScale.toString().replace('.', '_')
    );
  }

  makeCopy(): TextLabel {
    return new FlooringTopLabel(this.getText(), this.textScale);
  }
}
