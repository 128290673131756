import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { VehicleContext } from '../lib/model/vehicle-context';
import { SceneService } from '../scene/scene.service';

import { UiService } from '../services/ui.service';
import { Vehicle } from '../vehicle/lib/vehicle';
import { VehicleSuggestionType } from './lib/suggestion-type';
import { SuggestLargerVehicleService } from './suggest-larger-vehicle.service';

type Status = 'new' | 'found' | 'not-found';

@Component({
  selector: 'app-suggest-larger-vehicle',
  templateUrl: './suggest-larger-vehicle.component.html',
  styleUrls: ['./suggest-larger-vehicle.component.scss']
})
export class SuggestLargerVehicleComponent implements OnInit, OnDestroy {
  @Input() context: VehicleContext;
  @Input() type: VehicleSuggestionType = 'size';

  protected largerVehicles$: Observable<Vehicle[]>;
  protected status$: Observable<Status>;
  protected currentVehicle: Vehicle;

  protected loadedCnt: number;
  protected notLoadedCnt: number;
  protected loadedPercentage: number;
  protected notLoadedPercentage: number;

  protected loadsWeight: number;
  protected weightLimitExceededBy: number;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: SuggestLargerVehicleService,
    private sceneService: SceneService,
    protected ui: UiService
  ) {}

  ngOnInit(): void {
    this.currentVehicle = this.context.getVehicle();
    const currentVehicleUuid = this.currentVehicle.uuid;
    this.loadedCnt = this.context.getLoadedLoads().length;
    this.notLoadedCnt = this.context.getLoads().length;
    this.loadedPercentage = Math.round((this.loadedCnt / (this.loadedCnt + this.notLoadedCnt)) * 100);
    this.notLoadedPercentage = 100 - this.loadedPercentage;
    if (this.type === 'weight') {
      this.loadsWeight = this.context.getAllLoadsWeight();
      this.weightLimitExceededBy = Math.max(0, this.loadsWeight - this.context.getVehicle().maxLoadingWeight);
    }
    this.largerVehicles$ = this.service.getLargerVehicles(this.context, this.context.getAllLoads(), this.type).pipe(
      takeUntil(this.unsubscribe$),
      map((response) => response.vehicles.filter((v) => v.uuid !== currentVehicleUuid).slice(0, 10))
    );

    this.status$ = this.largerVehicles$.pipe(
      map((vehicles) => (vehicles.length === 0 ? 'not-found' : 'found')),
      startWith<Status>('new')
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close(): void {
    this.service.close();
  }

  vehicleChosen(vehicle: Vehicle): void {
    this.sceneService
      .reloadCurrentLoadIntoDifferentVehicle(vehicle)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.close());
  }

  multiply(): void {
    this.sceneService
      .multiplyVehicle(this.currentVehicle)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.close());
  }

  autoPlacement() {
    this.sceneService
      .autoPlaceLoads(this.currentVehicle)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.close());
  }
}
