import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { EmptyVehicle } from './empty-vehicle';
import { BufferGeometry } from 'three';

export class EmptyVehicleMesh extends VehicleMesh {
  private length: number;
  private height: number;
  private width: number;

  public constructor(empty: EmptyVehicle) {
    super('empty', empty);
    this.init();
  }

  public getLength(): number {
    return this.length * 1;
  }

  public getWidth(): number {
    return this.width * 1;
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public init() {
    this.mesh.geometry = new BufferGeometry();
  }
}
