<div class="modal-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Ustawienia</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-drawer-container class="settings-menu-container">
          <mat-drawer mode="side" opened>
            <mat-action-list class="categories">
              <button mat-list-item (click)="menuCategory = MenuCategory.Units"
                [class.active]="menuCategory === MenuCategory.Units" i18n>
                Jednostki i wygląd
              </button>
              <button mat-list-item
                (click)="menuCategory = MenuCategory.ProjectProperties"
                [class.active]="menuCategory === MenuCategory.ProjectProperties"
                i18n>
                Cechy projektu
              </button>
              <ng-container *ngIf="project$ | async">
                <button mat-list-item
                  (click)="menuCategory = MenuCategory.Reports"
                  [class.active]="menuCategory === MenuCategory.Reports" i18n>
                  Raporty XLS
                </button>
              </ng-container>
              <button mat-list-item (click)="menuCategory = MenuCategory.Fleet"
                [class.active]="menuCategory === MenuCategory.Fleet"
                [class.error]="fleetError$ | async">
                <ng-container i18n>Wybierz swoją flotę</ng-container>
              </button>
            </mat-action-list>
          </mat-drawer>
          <mat-drawer-content class="category-content">
            <div [class.hide]="menuCategory !== MenuCategory.Units">
              <div class="flex-columns">
                <mat-form-field appearance="fill">
                  <mat-label i18n>Jednostki długości: </mat-label>
                  <mat-select formControlName="lengthUnit" required>
                    <mat-option *ngFor="let unit of lengthUnits"
                      [value]="unit.value">
                      {{ unit.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label i18n>Jednostki wagi: </mat-label>
                  <mat-select formControlName="weightUnit" required>
                    <mat-option *ngFor="let unit of weightUnits"
                      [value]="unit.value">
                      {{ unit.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div>
                  <mat-label i18n>Widoczność obramowania ładunków: </mat-label>
                  <mat-slider discrete="true" step="1" min="0" max="10"
                    aria-label="units"><input matSliderThumb value="0"
                      formControlName="loadBordersIntensity" /></mat-slider>
                </div>
                <div>
                  <mat-label i18n>Widoczność ścian ładunków: </mat-label>
                  <mat-slider discrete="true" step="1" min="1" max="10"
                    aria-label="transparency"><input matSliderThumb value="4"
                      formControlName="loadTransparency" /></mat-slider>
                </div>
                <div>
                  <div class="row">
                    <app-image-upload formControlName="logo"
                      placeholder="Własne logo: "
                      i18n-placeholder></app-image-upload>


                    <button mat-raised-button
                      class="mat-button mat-accent btn-secondary" type="button"
                      (click)="removeLogo()" i18n>
                      Usuń logo
                    </button>
                  </div>

                  <div>
                    <img src="{{ settings.logo }}" *ngIf="settings?.logo" alt=""
                      class="logo-preview" />
                  </div>
                </div>
              </div>
            </div>
            <div [class.hide]="menuCategory !== MenuCategory.ProjectProperties">
              <div class="flex-columns">
                <mat-checkbox color="primary" formControlName="shareLoads"
                  i18n>Czy lista
                  ładunków własnych ma być współdzielona między
                  projektami?</mat-checkbox>
                <mat-checkbox color="primary" formControlName="shareVehicles"
                  i18n>Czy lista
                  przestrzeni własnych ma być współdzielona między
                  projektami?</mat-checkbox>
                <mat-checkbox color="primary"
                  formControlName="autoAddCustomLoads" i18n>Zdefiniowanie nowego
                  ładunku od razu umieszcza go na
                  przestrzeni ładunkowej</mat-checkbox>
                <mat-checkbox color="primary" formControlName="autoReload"
                  i18n>Czy
                  automatycznie dopełniać puste miejsca po usunięciu
                  ładunku?</mat-checkbox>
              </div>
            </div>
            <div [class.hide]="menuCategory !== MenuCategory.Reports">
              <div *ngIf="project$ | async as project">
                <app-settings-reports
                  [projectId]="project.uuid"></app-settings-reports>
              </div>
            </div>
            <div [class.hide]="menuCategory !== MenuCategory.Fleet">
              <app-fleet-select-form></app-fleet-select-form>
            </div>
          </mat-drawer-content>
        </mat-drawer-container>

        <div class="buttons mt-1">
          <button mat-raised-button type="submit" [disabled]="!form.valid"
            color="" class="btn-primary">
            <span i18n>Zapisz </span>
            <mat-icon *ngIf="isLoading">
              <mat-spinner class="progress" color="accent" diameter="24">
              </mat-spinner>
            </mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>