<div *ngIf="showPendingList$ | async" cdkDrag
  cdkDragRootElement="app-pending-loads-list">
  <div class="loads-list-header" cdkDragHandle>
    <span i18n>Lista załadunkowa:</span>
    <button class="close-btn" (click)="close()"
      title="Wyczyść listę załadunkową" i18n-title>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="loaded">
    <div *ngFor="let group of (pending$ | async)?.loads" class="loaded-element">
      <app-pending-load [group]="group" [cnt]="group.cnt"></app-pending-load>
    </div>
  </div>
  <div class="pending-add">
    <button mat-raised-button class="btn-primary" (click)="loadPending()"
      type="button" i18n>
      Załaduj
    </button>
  </div>
</div>