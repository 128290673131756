<div class="sharing-container" [class.expanded]="expanded"
    [class.enabled]="list.length > 0">
    <div class="header" (click)="expanded = !expanded">
        <div class="icon">
            <mat-icon class="icon">groups</mat-icon>
        </div>
        <span i18n>Współpraca</span>
    </div>
    <div class="content">
        <mat-list>
            <mat-list-item *ngFor="let item of list" (click)="pingUser(item)">
                <span class="status" [class.online]="item.online"></span>
                <span>{{ item.email }} <span
                        *ngIf="item.isOwner">*</span></span>
                <span class="remove" *ngIf="!item.isOwner"
                    (click)="removeShare(item)"><mat-icon>close</mat-icon></span>
            </mat-list-item>
        </mat-list>
        <div *ngIf="list.length == 0" i18n class="empty-list">Nie
            współdzielisz projektu</div>

        <form [formGroup]="form" (ngSubmit)="addShare()">
            <div>
                <mat-form-field class="email">
                    <mat-label i18n>Nazwa użytkownika</mat-label>
                    <input type="email" class="form-control" matInput
                        [class.is-invalid]="emailControl.invalid && emailControl.touched"
                        formControlName="email" />
                    <mat-error *ngIf="emailControl.errors?.required">
                        <ng-container i18n>Podaj adres e-mail
                            użytkownika</ng-container>
                    </mat-error>
                    <mat-error *ngIf="emailControl.errors?.pattern">
                        <ng-container i18n>To nie jest prawidłowy
                            e-mail</ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="buttons">
                <button mat-raised-button type="submit" class="btn-primary"
                    [disabled]="!form.valid">
                    <span i18n>Dodaj do projektu</span>
                    <mat-icon *ngIf="loading">
                        <mat-spinner class="progress" color="accent"
                            diameter="24">
                        </mat-spinner>
                    </mat-icon>
                </button>
            </div>
        </form>
    </div>
</div>