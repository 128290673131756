import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil, timer } from 'rxjs';
import { HttpCancelService } from '../lib/communication/http-cancel.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter();
  private readonly longWorkAfterMs = 5 * 1000;
  private readonly timeoutTime = 110 * 1000;

  protected showLongWorkInfo = false;
  protected timeoutCounter: number;

  private unsubscribe$ = new Subject<void>();
  constructor(private httpCancelService: HttpCancelService) {}

  ngOnInit(): void {
    this.showLongWorkInfo = false;
    this.timeoutCounter = this.timeoutTime;

    timer(this.longWorkAfterMs)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.showLongWorkInfo = true;
      });
    timer(1000, 1000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => {
        this.timeoutCounter = Math.max(0, this.timeoutCounter - 1000);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  cancel(): void {
    this.httpCancelService.cancelPendingRequests();
    this.close.emit();
  }
}
