const serverUrl = 'https://wr-server3d.evomedia.pl';
const siteUrl = 'https://wr-smartload.evomedia.pl';
const keycloakUrl = 'https://wr-accounts.evomedia.pl';

export const environment = {
  production: false,
  serverUrl: serverUrl,
  apiUrl: `${serverUrl}/api/v1`,
  authUrl: keycloakUrl,
  profileUrl: `${siteUrl}/moje-konto`,
  upgradePlanUrl: `${siteUrl}/pl/moje-konto/wybierz-plan`,
  subscriptionsUrl: `${siteUrl}/moje-konto/subskrypcje`,
  authRealm: 'smartload',
  authClient: '3d-app-wr',
  disableWarehouses: false,
  disablePalletsAsLoad: false,
  disableMassDistribution: true
};
