import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { Trailer } from '../lib/trailer';
import { UiService } from 'src/app/services/ui.service';

import { SpaceService } from '../../../space.service';

@Component({
  selector: 'app-trailer-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  @Output() addSpaceEvent = new EventEmitter<Trailer>();

  @Input() mode = 'mat-expansion-panel';
  @Input() events: Observable<void>;
  private item$ = new BehaviorSubject<Trailer>(this.item);
  @Input()
  set item(value: Trailer) {
    this.item$.next(value);
  }
  @ViewChild('search') searchElement: ElementRef;

  object: Trailer;

  public dataPanelOpenState = false;
  public form: UntypedFormGroup;
  public listOpened = false;

  private unsubscribe$ = new Subject<void>();

  constructor(private service: SpaceService, private fb: UntypedFormBuilder, public ui: UiService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.events.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.onSubmit();
    });

    this.item$.subscribe((item) => {
      this.object = item;
      this.fillForm(item);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  toggleList() {
    this.listOpened = !this.listOpened;
    if (this.listOpened) {
      setTimeout(() => {
        // this.searchElement.nativeElement.focus();
      }, 0);
    }
  }

  fillForm(space: Trailer): void {
    (this.form.controls.width as UntypedFormControl)?.setValue(this.ui.getLengthInCurrentUnit(space.width));
    (this.form.controls.length as UntypedFormControl)?.setValue(this.ui.getLengthInCurrentUnit(space.length));
    (this.form.controls.height as UntypedFormControl)?.setValue(this.ui.getLengthInCurrentUnit(space.height));
  }

  onSubmit() {
    if (!this.validateForm()) {
      const errors = this.getValidationErrors();
      // console.log('errors', errors);
      // console.log('form not valid');
    } else {
      const object = this.getObject();
      this.addSpaceEvent.emit(object);
    }
  }

  validateForm(): boolean {
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  getObject(): Trailer {
    const object = this.object;

    object.length = this.ui.getLengthInDefaultUnit(this.form.controls.length.value);
    object.length = this.ui.getLengthInDefaultUnit(this.form.controls.length.value);
    object.width = this.ui.getLengthInDefaultUnit(this.form.controls.width.value);
    object.height = this.ui.getLengthInDefaultUnit(this.form.controls.height.value);
    console.log('vehicle/space/type/trailer/form/form.component.ts: getObject', object);
    return object;
  }

  private getValidationErrors() {
    const errors: any = {};
    Object.keys(this.form.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        errors[key] = {};
        Object.keys(controlErrors).forEach((keyError) => {
          errors[key][keyError] = controlErrors[keyError];
        });
      }
    });
    return errors;
  }

  private createForm() {
    this.form = this.fb.group({
      length: [null, Validators.compose([Validators.required])],
      width: [null, Validators.compose([Validators.required])],
      height: [null, Validators.compose([Validators.required])]
    });
  }
}
