import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { Container } from './container';
import { BufferGeometry, Float32BufferAttribute } from 'three';

export class ContainerMesh extends VehicleMesh {
  private indices = [];
  private vertices = [];

  public constructor(container: Container) {
    super('container', container);
    this.init();
  }

  public init() {
    this.mesh.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    const x0 = 0;
    const y0 = 0;
    const z0 = 0;
    this.vertices.push(x0, y0, z0);

    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    this.buildWireframe();

    this.addSpaces();
  }
}
