import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { UiService } from '../../services/ui.service';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { SettingsComponent } from './settings/settings.component';
import { SettingsService as SummarySettingsService } from './settings/settings.service';
import { Settings } from 'src/app/lib/model/settings';
import { Statistics } from 'src/app/lib/model/statistics';
import { SceneService } from 'src/app/scene/scene.service';
import { ContextService } from '../context/context.service';

@Component({
  selector: 'app-vehicle-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {
  @Input() hideList = false;
  @Input() type = '';

  @Input() set context(value: VehicleContext) {
    this.currentVehicle = value;
    if (value) {
      this.updateCurrentVehicleValues(value);
    }
  }

  protected currentVehicle: VehicleContext;

  protected gradientWidth = 0;

  protected usedWeight = 0;
  protected usedTrailerWeight = 0;

  protected currentEP = 0;
  protected currentWeight = 0;
  protected freeFloor = 0;
  protected currentVolume = 0;
  protected freeVolume = 0;
  protected usedVolume = 0;

  protected mainStats = null;
  protected trailerStats = [];

  protected settings: Settings;

  protected expanded = true;

  private unsubscribe$ = new Subject<boolean>();

  constructor(
    protected ui: UiService,
    private loadComponentService: LoadComponentService,
    private settingsService: SummarySettingsService,
    private sceneService: SceneService,
    private contextService: ContextService
  ) {}
  ngOnInit(): void {
    if (this.currentVehicle) {
      this.updateCurrentVehicleValues(this.currentVehicle);
    }
    this.contextService
      .contextChanged()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((context: VehicleContext) => {
        if (this.type === 'pdf-export') {
          return;
        }
        this.currentVehicle = context;
        if (context && context?.getVehicle()) {
          this.updateCurrentVehicleValues(context);
        }
      });

    this.settingsService.profile$.pipe(takeUntil(this.unsubscribe$)).subscribe((profile) => {
      this.settings = profile.settings;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  openFloorSettings() {
    this.loadComponentService.clear('header.service.ts showSettings');
    this.loadComponentService.add(
      SettingsComponent
      // '#scene-container'
    );
  }

  highlightLDM(statistics: Statistics) {
    if (this.type === 'pdf-export') {
      return;
    }
    const space = this.currentVehicle.getVehicle().enabledSpaces.find((s) => s.uuid === statistics.spaceUuid);
    this.sceneService.addLDMHighlight(space);
  }

  removeLDMHighlight() {
    this.sceneService.addLDMHighlight(null);
  }

  private updateCurrentVehicleValues(context: VehicleContext) {
    const enabledSpacesStats = context.getEnabledSpacesStatistics();
    //console.log('summary context update', context, enabledSpacesStats);
    const [mainStats, ...trailerStats] = enabledSpacesStats;
    this.mainStats = mainStats;
    this.trailerStats = trailerStats;
    this.currentEP = enabledSpacesStats.reduce((total, next) => total + next.cnt, 0);
    this.freeFloor = this.roundTo(
      enabledSpacesStats.reduce((total, next) => total + (next.getFreeFloor() || 0), 0),
      2
    );
    this.freeVolume = this.roundTo(
      enabledSpacesStats.reduce((total, next) => total + (next.getFreeVolume() || 0), 0),
      2
    );
    this.usedVolume = this.roundTo(
      enabledSpacesStats.reduce((total, next) => total + next.getLoadsVolume(), 0),
      2
    );
    this.currentVolume = this.roundTo(this.freeVolume + this.usedVolume, 2) || 0;

    this.usedWeight = enabledSpacesStats.reduce((total, next) => total + next.getUsedWeight(), 0);
    this.currentWeight = context?.getVehicle()?.maxLoadingWeight || 0;
  }

  private roundTo(num: number, precision: number) {
    const multiplier = Math.pow(10, precision);
    return Math.round(num * multiplier + Number.EPSILON) / multiplier;
  }
}
