import { Component, ComponentRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { FreeSpaceService } from '../../vehicle/space/lib/free-space.service';
import { SceneService } from '../scene.service';
import { UiService } from 'src/app/services/ui.service';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { LoadingStepsComponent } from 'src/app/loading-steps/loading-steps.component';
import { ContextService } from 'src/app/vehicle/context/context.service';

type Tool = 'measurement' | 'loadingSteps';

type Tools = {
  [key in Tool]?: boolean;
};

@Component({
  selector: 'app-scene-tools',
  templateUrl: './scene-tools.component.html',
  styleUrls: ['./scene-tools.component.scss']
})
export class SceneToolsComponent implements OnInit, OnDestroy {
  @Input() context: VehicleContext;

  private loadingStepsComponentRef: ComponentRef<LoadingStepsComponent>;

  protected tools: Tools = {
    measurement: false,
    loadingSteps: false
  };

  private unsubscribe$ = new Subject<void>();
  constructor(
    private sceneService: SceneService,
    private freeSpaceService: FreeSpaceService,
    private ui: UiService,
    private componentLoader: LoadComponentService,
    private contextService: ContextService
  ) {}

  ngOnInit(): void {
    this.ui.enableTool$.pipe(takeUntil(this.unsubscribe$)).subscribe((tool: { name: Tool; state: boolean }) => {
      switch (tool.name) {
        case 'measurement':
          this.toggleMeasurementTool(tool.state);
        case 'loadingSteps':
          this.toggleLoadingSteps(tool.state);
          break;
      }
    });
    this.contextService
      .contextChanged()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((context) => {
        if (context?.getLoadingSteps()?.length <= 1) {
          this.toggleLoadingSteps(false);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected isNotEmptyContext() {
    return this.context && this.context.getVehicle() && this.context.getVehicle().type !== 'empty';
  }

  protected loadingStepsAvailable() {
    return this.isNotEmptyContext() && this.context.getLoadingSteps()?.length > 1;
  }

  protected toggleLoadingSteps(force?: boolean) {
    const enable = force !== undefined ? force : !this.tools.loadingSteps;
    if (enable) {
      if (!this.tools.loadingSteps) {
        this.enableOnly('loadingSteps');
        this.loadingStepsComponentRef = this.componentLoader.add(LoadingStepsComponent);
      }
    } else {
      this.tools.loadingSteps = false;
      this.loadingStepsComponentRef?.instance?.reset();
      this.componentLoader.remove(this.loadingStepsComponentRef);
    }
  }

  protected toggleMeasurementTool(force?: boolean) {
    const enable = force !== undefined ? force : !this.tools.measurement;
    if (enable) {
      if (!this.tools.measurement) {
        this.enableOnly('measurement');
        this.freeSpaceService.enableMeasurement();
      }
    } else {
      this.tools.measurement = false;
      this.freeSpaceService.disableMeasurement();
    }
    this.sceneService.redrawContextWithLabels();
  }

  private enableOnly(tool: keyof Tools) {
    for (const t in this.tools) {
      this.tools[t] = tool === t;
    }
  }
}
