import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';

import { Axle } from '../../lib/axle';
import { FormService } from '../form.service';
import { AxleForm } from '../lib/axle.form';

@Component({
  selector: 'app-axle',
  templateUrl: './axle.component.html',
  styleUrls: ['./axle.component.scss']
})
export class AxleComponent {
  @Input() form: AxleForm;

  @Output() deleteAxleEvent = new EventEmitter<AxleForm>();

  constructor(public ui: UiService) {}

  delete() {
    this.deleteAxleEvent.emit(this.form);
  }
}
