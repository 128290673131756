import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, shareReplay, Subject } from 'rxjs';
import { VehicleContext } from '../lib/model/vehicle-context';
import { LoadComponentService } from '../services/load-component.service';
import { FormService as AxlesFormService } from './axles/form/form.service';
import { SettingsComponent } from './form/settings/settings.component';
import { GridSettingsComponent } from './form/grid-settings/grid-settings.component';
import { Vehicle } from './lib/vehicle';
import { VehicleType } from './lib/vehicle-type';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  private vehicle = new Subject<Vehicle>();
  private selectedItem = new Subject<Vehicle>();
  private selectedType = new ReplaySubject<VehicleType>(1);

  get vehicleType$(): Observable<VehicleType> {
    return this.selectedType.asObservable().pipe(shareReplay(1));
  }

  constructor(private loadComponentService: LoadComponentService, private axlesService: AxlesFormService) {}

  public addToScene(vehicle: Vehicle) {
    this.vehicle.next(vehicle);
  }

  public vehicleChanged(): Observable<Vehicle> {
    return this.vehicle.asObservable();
  }

  public selectVehicleType(type: VehicleType) {
    this.selectedType.next(type);
  }

  public showSettings() {
    this.loadComponentService.clear('vehicle.service.ts showSettings');
    this.loadComponentService.add(
      SettingsComponent //,
      // '#scene-container'
    );
    //console.log('showSettings in vehicleService');
  }

  public showGridSettings() {
    this.loadComponentService.clear('vehicle.service.ts showGridSettings');
    this.loadComponentService.add(
      GridSettingsComponent //,
      // '#scene-container'
    );
    //console.log('showSettings in vehicleService');
  }

  public showAxlesSettings(context: VehicleContext) {
    this.axlesService.openForm(context);
  }

  public selectVehicle(item: Vehicle) {
    this.selectedItem.next(item);
  }
  public getSelectedVehicle(): Observable<Vehicle> {
    return this.selectedItem.asObservable();
  }
}
