<div class="scene-header__item" *ngIf="isNotEmptyContext()">
  <button mat-button (click)="showVehicleSettings()">
    <mat-icon>settings</mat-icon>
    <div class="scene-header__item__title" i18n>Ustawienia</div>
  </button>
</div>
<div class="scene-header__item" *ngIf="isContextAVehicle()">
  <button mat-button (click)="showAxlesSettings()">
    <mat-icon>settings_input_composite</mat-icon>
    <div class="scene-header__item__title" i18n>Osie</div>
  </button>
</div>
<div class="scene-header__item" *ngIf="isNotEmptyContext()">
  <button mat-button (click)="showGridSettings()">
    <mat-icon>grid_on</mat-icon>
    <div class="scene-header__item__title" i18n>Siatka</div>
  </button>
</div>
<div class="scene-header__item labels-view" *ngIf="showLoadLabelsOptions()">
  <button mat-button (click)="toggleLabelView()">
    <mat-icon>rule</mat-icon>
    <div class="scene-header__item__title" i18n>Widok ładunku</div>
  </button>
  <app-label *ngIf="labelViewVisible"></app-label>
</div>