import { Vector3 } from 'three';
import { CSS2DObject } from '../vendor/three/CSS2DRenderer';

export abstract class TextLabel extends CSS2DObject {
  #definedPosition: Vector3;

  constructor(
    text: string,
    position: Vector3,
    radius: number | string,
    cssClass?: string,
    protected textScale = 1.0
  ) {
    super();
    this.setCssClass(
      cssClass + ' ' + 'scale-' + textScale.toString().replace('.', '_')
    );
    this.setText(text);
    this.setRadius(radius);
    this.setPosition(position);
  }

  abstract makeCopy(): TextLabel;

  setText(text: string) {
    this.element.innerHTML = text.replace(/\n/g, '<br>\n');
  }

  setCssClass(cssClass: string) {
    this.element.className = 'scalable-label ' + (cssClass || '');
  }

  setRadius(radius: number | string) {
    this.element.setAttribute('data-radius', radius + '');
  }

  setPosition(position: Vector3) {
    this.#definedPosition = position;
    this.position.copy(position);
  }

  getText(): string {
    return this.element.innerHTML;
  }

  getCssClass(): string[] {
    return this.element.className.split(' ');
  }

  getPosition(): Vector3 {
    return this.position;
  }

  getRadius() {
    return parseFloat(this.element.getAttribute('data-radius'));
  }
}
