import { Load } from 'src/app/load/lib/load';
import { Vector3 } from 'three';
import { TextLabel } from './TextLabel';

export abstract class LoadDimensionLabel extends TextLabel {
  public constructor(txt: string, scale = 1.0) {
    super(txt, new Vector3(), 0, '', scale);
  }

  public abstract updatePosition(load: Load): void;
}
