<div class="left-menu-static">
  <div class="project-info" *ngIf="project$ | async; let project">
    <mat-icon class="comment-icon" i18n-matTooltip
      matTooltip="Komentarz do projektu" [matTooltipPosition]="'above'"
      [matTooltipShowDelay]="500" #addCommentTooltip="matTooltip"
      (click)="openCommentModal()"
      [class.has-content]="project.comment?.length > 0">comment</mat-icon>
    <span class="text-primary">{{ project.seqNumber }}</span>
    <span class="text-primary">{{ project.name ?? '(brak)' }}</span>
    <span class="back-link"><button class="back-to-projects" mat-flat-button
        color="accent" (click)="backToProjects()" i18n>
        Wróć do listy
      </button></span>
  </div>
  <div class="menu-container">
    <div class="load-nav">
      <a (click)="toggleVehicles()" class="nav-item"
        [class.active]="vehiclesOpened">
        <span class="menu-item-label">
          <ng-container i18n>PRZESTRZEŃ</ng-container><br />
          <ng-container i18n>ŁADUNKOWA</ng-container></span>
        <img class="menu-item-icon" src="assets/images/ic_truck2.png"
          alt="Truck" />
      </a>
      <mat-divider></mat-divider>
      <a (click)="toggleLoadings()" class="nav-item"
        [class.active]="loadingsOpened">
        <span class="menu-item-label" i18n>ŁADUNEK</span><img
          class="menu-item-icon" src="assets/images/ic_cargo2.png"
          alt="Cargo" />
      </a>
    </div>
    <mat-divider></mat-divider>

    <mat-drawer-container class="menu-extension-panel">
      <mat-drawer #loadingSpace>
        <mat-card appearance="outlined" class="show-card spaces-card noborder">
          <mat-card-header>
            <button mat-button (click)="close()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-card-header>
          <mat-card-content>
            <app-vehicle> </app-vehicle>
          </mat-card-content>
        </mat-card>
      </mat-drawer>
    </mat-drawer-container>
    <mat-drawer-container class="menu-extension-panel">
      <mat-drawer #loading>
        <mat-card appearance="outlined" class="show-card loading-card noborder">
          <mat-card-header>
            <button mat-button (click)="close()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-card-header>
          <mat-card-content>
            <div>
              <app-load-module> </app-load-module>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-drawer>
    </mat-drawer-container>
  </div>
</div>