<div>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'stacked'" [ngTemplateOutlet]="stacked">
    </ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="default"></ng-container>
  </ng-container>

  <ng-template #default>

    <div [formGroup]="form" class="form-control {{ type }}">
      <mat-form-field appearance="fill" class="cnt form-control-fill bordered">
        <mat-label i18n>Ile sztuk</mat-label>
        <input matInput appOnlyNumber="integer" formControlName="cnt"
          autocomplete="off" [placeholder]="label" name="cnt" required
          [readonly]="fillSpace" max="10000" />
      </mat-form-field>
      <mat-error *ngIf="form.controls['cnt'].hasError('required')">
        <ng-container i18n>Podaj</ng-container><span>&nbsp;</span>
        <strong><ng-container i18n>liczbę</ng-container></strong>
      </mat-error>
      <mat-error *ngIf="form.controls['cnt'].hasError('max')">
        <ng-container i18n>Max.</ng-container>&nbsp;<strong>10000</strong>&nbsp;
        <ng-container i18n>na raz</ng-container>
      </mat-error>
    </div>

    <div>
      <mat-checkbox [(ngModel)]="fillSpace" i18n i18n-title
        title="Wypełnij całą przestrzeń ładunkami wybranego typu"
        color="primary">Wypełnij
        przestrzeń</mat-checkbox>
    </div>
  </ng-template>

  <ng-template #stacked>
    <div [formGroup]="form" class="form-control {{ type }}">
      <mat-form-field appearance="fill" class="cnt form-control-fill">
        <mat-label i18n>Ile sztuk</mat-label>
        <input matInput appOnlyNumber="integer" formControlName="cnt"
          autocomplete="off" [placeholder]="label" name="cnt" required
          max="10000" />
      </mat-form-field>
      <mat-error *ngIf="form.controls['cnt'].hasError('required')">
        <ng-container i18n>Podaj</ng-container><span>&nbsp;</span>
        <strong><ng-container i18n>liczbę</ng-container></strong>
      </mat-error>
      <mat-error *ngIf="form.controls['cnt'].hasError('max')">
        <ng-container i18n>Max.</ng-container>&nbsp;<strong>10000</strong>&nbsp;
        <ng-container i18n>na raz</ng-container>
      </mat-error>
    </div>
  </ng-template>
</div>