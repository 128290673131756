<div class="vehicle-summary" [class]="type"
  [class.hide]="!currentVehicle?.getVehicle()" [class.expanded]="expanded">
  <div *ngIf="type != 'pdf-export'" class="expand"
    (click)="expanded = !expanded" i18n-matTooltip
    matTooltip="Pokaż / ukryj statystyki" [matTooltipPosition]="'left'"
    [matTooltipShowDelay]="400" #showBottomPanel="matTooltip">
    <span *ngIf="expanded">&laquo;</span>
    <span *ngIf="!expanded">&raquo;</span>
  </div>
  <div *ngIf="hideList === false">
    <app-context></app-context>
  </div>
  <div class="summary-row"
    *ngIf="currentVehicle?.getVehicle()?.type != 'warehouse'">
    <div class="text-right summary-label flex space-between">
      <mat-icon class="settings material-icons-outlined"
        (click)="openFloorSettings()" i18n-matTooltip
        matTooltip="Ustawienia zajętości przestrzeni"
        matTooltipPosition="right">settings</mat-icon>
      <span><ng-container i18n>Przestrzeń</ng-container> [{{
            (settingsService.profile$ | async).settings.summaryFloorUnit
          }}]</span>
    </div>
    <div class="summary-value">
      <ng-container *ngTemplateOutlet="
            loadProgressAdvanced;
            context: {
              usedPercentage: mainStats?.getUsedSpacePercentage(
                settings?.summaryFloorUnit
              ),
              freePercentage: mainStats?.getFreeSpacePercentage(
                settings?.summaryFloorUnit
              ),
              used: mainStats?.getUsedSpace(settings?.summaryFloorUnit),
              total: mainStats?.getTotalSpace(settings?.summaryFloorUnit),
              free: mainStats?.getFreeSpace(settings?.summaryFloorUnit),
              unit: settings?.summaryFloorUnit,
              showPercentage: settings?.summaryFloorPercentage,
              stats: mainStats
            }
          ">
      </ng-container>
      <div *ngIf="currentVehicle?.vehicle?.enabledSpaces.length > 1">
        <ng-container *ngFor="let stats of trailerStats; let idx = index">
          <ng-container *ngTemplateOutlet="
                loadProgressAdvanced;
                context: {
                  usedPercentage: stats.getUsedSpacePercentage(
                    settings?.summaryFloorUnit
                  ),
                  freePercentage: stats.getFreeSpacePercentage(
                    settings?.summaryFloorUnit
                  ),
                  used: stats.getUsedSpace(settings?.summaryFloorUnit),
                  total: stats.getTotalSpace(settings?.summaryFloorUnit),
                  free: stats.getFreeSpace(settings?.summaryFloorUnit),
                  unit: settings?.summaryFloorUnit,
                  showPercentage: settings?.summaryFloorPercentage,
                  stats: stats
                }
              ">
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="summary-row">
    <div class="text-right summary-label" i18n>Liczba ładunków</div>
    <div class="summary-value flex">
      <div class="col1">
        <strong>{{ currentEP }}</strong>
      </div>
      <div class="col2">
        <span class="summary-label" i18n>Wolna podłoga [m2]</span>
        <strong>{{ freeFloor }}</strong>
      </div>
    </div>
  </div>
  <div class="summary-row">
    <div class="text-right summary-label">
      <ng-container i18n>Waga ładunku</ng-container> [{{
          ui.getWeightUnit() | async
        }}]
    </div>
    <div class="summary-value flex">
      <div class="col1">
        <strong>
          {{ usedWeight | weightUnit | async | number : '0.0-2' }}
        </strong>
      </div>
      <div class="col2">
        <span class="summary-label" i18n>Kubatura ładunku [m3] zajęte /
          wolne</span>
        <strong>{{ usedVolume }} /
          <span class="primary">{{ freeVolume }}</span></strong>
      </div>
    </div>
  </div>
</div>

<ng-template #loadProgressAdvanced let-usedPercentage="usedPercentage"
  let-freePercentage="freePercentage" let-used="used" let-total="total"
  let-free="free" let-unit="unit" let-showPercentage="showPercentage"
  let-stats="stats">
  <div class="free-space">
    <span class="used" [style.width.%]="
        usedPercentage > 0 && usedPercentage < 1 ? 1 : usedPercentage
      "><ng-container i18n>Zajęte {{ used }} z {{ total }}
        {{ unit }}</ng-container>
      <ng-container *ngIf="showPercentage">
        ({{ usedPercentage }}%)</ng-container>
    </span>
    <div class="value" (mouseover)="highlightLDM(stats)"
      (mouseout)="removeLDMHighlight()" [style.width.%]="
        freePercentage > 0 && freePercentage < 1 ? 1 : freePercentage
      ">
      <span><ng-container i18n>Wolne {{ free }} z {{ total }}
          {{ unit }}</ng-container>
        <ng-container *ngIf="showPercentage">
          ({{ freePercentage }}%)</ng-container></span>
    </div>
  </div>
</ng-template>