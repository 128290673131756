import { Combination } from 'js-combinatorics';
import { Constants as Config } from 'src/app/config/constants';
import { GravityCenter } from 'src/app/lib/gravity-center/gravity-center';
import { GravityCenterSprite } from 'src/app/lib/gravity-center/gravity-center-sprite';
import { Settings } from 'src/app/lib/model/settings';
import { Statistics } from 'src/app/lib/model/statistics';
import { VehicleContext } from 'src/app/lib/model/vehicle-context';
import { Load } from 'src/app/load/lib/load';
import { Axle } from 'src/app/vehicle/axles/lib/axle';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';
import { FlooringLevelMesh } from 'src/app/vehicle/space/flooring-level/lib/flooring-level-mesh';
import { FreeFloorMesh } from 'src/app/vehicle/space/lib/free-floor-mesh';
import { Space } from 'src/app/vehicle/space/lib/space';
import { VectorMesh } from 'src/app/vehicle/space/lib/vector-mesh';
import { EmptyVehicle } from 'src/app/vehicle/type/empty-vehicle/lib/empty-vehicle';
import {
  AmbientLight,
  BoxGeometry,
  Color,
  DirectionalLight,
  DynamicDrawUsage,
  EdgesGeometry,
  GridHelper,
  Group,
  LineBasicMaterial,
  LineSegments,
  Material,
  Matrix4,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  Object3DEventMap,
  Scene,
  Vector3
} from 'three';
import { InstancedUniformsMesh } from 'three-instanced-uniforms-mesh';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ClickHandlerService } from '../click-handler.service';
import { DisplayCacheService } from 'src/app/services/display-cache.service';
import { VehicleMesh } from 'src/app/vehicle/lib/vehicle-mesh';
import { LoadGroupList } from 'src/app/lib/model/load-group/load-group-list';
import { LoadGroupInstanced } from 'src/app/lib/model/load-group/load-group-instanced';
import { LoadMaterial } from 'src/app/lib/helper/load-material';
import { PalletLoad } from 'src/app/load/type';
import { InstancedBorders } from './InstancedBorders';

export class MyScene {
  private scene: Scene;

  public isSecondaryCopy = false;
  private matrixMeshes: VectorMesh[] = [];
  //private currentLoadMeshes: LoadMesh[] = [];
  private currentVehicleMesh: VehicleMesh;

  private gridColor = new Color(Config.GRID_COLOR);
  private light = new AmbientLight(0xffffff, 2.5);
  private grid: GridHelper;
  private emptyVehiclePosition = new Vector3(0, 0, 0);
  private emptyVehicleMaterial = new MeshStandardMaterial({
    color: 0x838996,
    transparent: true,
    depthWrite: false,
    opacity: 0.5,
    metalness: 1,
    roughness: 0.5
  });
  private emptyVehicleScene: Group<Object3DEventMap>;
  private gltfLoader = new GLTFLoader();

  // reusable
  private _position: Vector3 = new Vector3();
  private _matrix: Matrix4 = new Matrix4();

  public instanceMeshes: InstancedUniformsMesh<Material>[] = [];
  public instanceBorders: InstancedBorders<LineSegments>[] = [];

  // map from group key ({InstancedMesh.id}-{instance_index}) to Load
  public instanceMap: Map<string, Load> = new Map();
  // map from Load uuid to instanceMeshes index for InstancedMesh
  public instanceReverseMap: Map<string, { groupIdx: number; instanceIdx: number }> = new Map();

  // map from mesh uuid to Load for single-instance loads (pallets etc)
  public loadMap: Map<string, Load> = new Map();

  private loadMaterials: LoadMaterial[] = [];
  private colorRed = new Color(Color.NAMES.firebrick);
  private colorBlack = new Color(Color.NAMES.black);
  private loadSettings: Settings;

  private stepsMode = false;
  private showInStepsMode: string[];
  private clickHandlerService: ClickHandlerService;
  private currentContextId: string;

  public constructor(private displayCache: DisplayCacheService) {
    this.scene = new Scene();
    this.scene.background = new Color(Config.MAIN_BG_DARK);
  }

  public initDefaultView() {
    this.addLight();
    this.addBackground();
  }

  public setBackground(color: number) {
    (this.scene.background as Color).set(color);
  }

  public setShowInSteps(context: VehicleContext, ids: string[]) {
    if (context?.getHistoryUuid() != this.currentContextId) {
      return Promise.resolve();
    }
    if (ids !== undefined && ids.length > 0) {
      this.stepsMode = true;
      this.showInStepsMode = ids;
    } else {
      this.stepsMode = false;
      this.showInStepsMode = [];
    }
    return this.redrawContext(context);
  }

  private cleanup(obj: any) {
    Object.keys(obj).forEach((k) => {
      if (k !== 'parent' && typeof obj[k] == 'object' && obj[k] != null && typeof obj[k]['dispose'] === 'function') {
        obj[k].dispose();
      } else if (Array.isArray(obj[k])) {
        obj[k].forEach((c) => this.cleanup(c));
      }
    });
  }

  removeObjectsByName(name: string) {
    for (let object: Object3D; (object = this.getObjectByName(name)); ) {
      object.parent.remove(object);
      this.cleanup(object);
    }
  }

  findSpaceMesh(spaceUuid: string): Mesh | null {
    const vehicleMesh = this.getObjectByName('vehicle-mesh') as Mesh;
    if (!vehicleMesh) {
      return null;
    }
    const spaceMesh = vehicleMesh.children
      .filter((x) => x.name === 'space-mesh')
      .find((x) => x.userData.uuid === spaceUuid);
    return spaceMesh as Mesh;
  }

  private treatAsSingleLoad(load: Load): boolean {
    return load instanceof PalletLoad;
  }

  groupLoadsBySize(loads: Load[]) {
    const loadList = new LoadGroupList(LoadGroupInstanced);
    loadList.clear();
    loads.forEach((load: Load) => {
      if (!this.treatAsSingleLoad(load) && (!this.stepsMode || this.showInStepsMode.includes(load.uuid))) {
        loadList.addLoad(load);
      }
    });
    return loadList;
  }

  getInstancedLoads(context: VehicleContext) {
    const meshes: InstancedUniformsMesh<Material>[] = [];
    const borders: InstancedBorders<LineSegments>[] = [];
    const matrix = new Matrix4();
    // ładunki które nie są nigdzie załadowane
    const offset = new Vector3(0, 0, 0);
    const groups = this.groupLoadsBySize(context.getLoads());
    //const singleLoads = [{ loads: single, offset }];
    const batches = [{ groups: groups, offset }];

    const edgesMaterial = new LineBasicMaterial({
      color: this.colorBlack,
      transparent: true,
      opacity: Math.min(0.99, this.loadSettings.loadBordersIntensity / 10),
      linewidth: 1
    });

    // ładunki na przestrzeniach
    for (const space of context.getVehicle().enabledSpaces) {
      const wPosition = space.mesh.meshObj.getWorldPosition(new Vector3());
      const spaceGroups = this.groupLoadsBySize(space.loads);
      batches.push({ groups: spaceGroups, offset: wPosition });
      //singleLoads.push({ loads: spaceSingle, offset: wPosition });

      for (const load of space.loads) {
        this._position.set(
          Config.scale(load.position.x + load.cuboidHull.length / 2),
          Config.scale(load.position.y + load.cuboidHull.height / 2),
          Config.scale(load.position.z + load.cuboidHull.width / 2)
        );
        this._position.add(wPosition);
      }
    }

    for (let g = 0; g < batches.length; g++) {
      const batch = batches[g];
      for (let i = 0; i < batch.groups.loads.length; i++) {
        const group = batch.groups.loads[i];
        const size = group.load.cuboidHull;
        const geometry = new BoxGeometry(
          Config.scale(size.length),
          Config.scale(size.height),
          Config.scale(size.width)
        );

        const material = this.displayCache.getLoadMaterial(0xffffff, this.loadSettings, false, false);
        const edges = new EdgesGeometry(geometry);
        const border = new InstancedBorders(LineSegments, edges, edgesMaterial, group.cnt);

        //console.log('init group', group.cnt);
        const mesh = new InstancedUniformsMesh(geometry, material, group.cnt);
        mesh.instanceMatrix.setUsage(DynamicDrawUsage);
        //mesh.instanceColor.setUsage(DynamicDrawUsage);

        for (let k = 0; k < group.list.length; k++) {
          const load = group.list[k];
          this._position.set(
            Config.scale(load.position.x + size.length / 2),
            Config.scale(load.position.y + size.height / 2),
            Config.scale(load.position.z + size.width / 2)
          );
          this._position.add(batch.offset);
          matrix.setPosition(this._position);
          mesh.setMatrixAt(k, matrix);
          mesh.setColorAt(k, new Color(load.color));
          border.setOffsetAt(k, this._position);
          border.setColorAt(k, load.selected ? this.colorRed : this.colorBlack);
          border.setOpacityAt(k, 1.0);

          //bordersColors.push(load.selected ? : )
          this.instanceMap.set(mesh.id + '-' + k, load);
          this.instanceReverseMap.set(load.uuid, {
            groupIdx: meshes.length,
            instanceIdx: k
          });
        }

        mesh.renderOrder = 0;
        mesh.instanceColor.needsUpdate = true;
        borders.push(border);
        meshes.push(mesh);
        this.loadMaterials.push(material);
      }
    }
    return { meshes, borders };
  }

  redrawContext(context: VehicleContext, vehicleClickHandler?: () => void): Promise<void> {
    console.log('redraw context', context?.getHistoryUuid());
    const vehicle = context?.getVehicle();
    return new Promise((resolve, reject) => {
      this.clearAll();
      this.addVehicle(vehicle, vehicleClickHandler, this.clickHandlerService).then(() => {
        if (vehicle instanceof EmptyVehicle === false) {
          this.addLoads(context);
          this.addFlooringLevels(vehicle);

          this.drawGravityCenters(vehicle, context.getAllLoads(), this.loadSettings.labelConfig?.showGravityCenter);
        }

        this.addSpaceMatrixMeshes(context);
        vehicle.updateCalculationSettings(context.getSettings());
        this.currentVehicleMesh = context?.getVehicle().mesh;
        resolve();
      });
    });
  }

  setContext(
    context: VehicleContext,
    settings: Settings,
    vehicleClickHandler: () => void,
    clickHandlerService: ClickHandlerService
  ): Promise<void> {
    this.currentContextId = context?.getHistoryUuid();
    this.clickHandlerService = clickHandlerService;
    this.loadSettings = settings;
    this.showInStepsMode = undefined;
    this.stepsMode = false;
    return this.redrawContext(context, vehicleClickHandler);
  }

  addFreeFloorHighlight(space: Space, statistics: Statistics) {
    this.removeFreeFloorHighlight();
    if (!space) {
      return;
    }
    const spaceMesh = this.findSpaceMesh(space.uuid);
    if (!spaceMesh) {
      console.log('drawFreeFloorHighlight: space mesh not found');
      return;
    }
    const freeFloorMesh = new FreeFloorMesh(space, statistics);
    spaceMesh.add(freeFloorMesh);
  }

  removeFreeFloorHighlight() {
    let highlight: Object3D;
    do {
      highlight = this.getObjectByName('free-floor-mesh');
      if (highlight) {
        highlight.parent.remove(highlight);
      }
    } while (highlight);
  }

  addEmptyVehicleImage(clickHandler: () => void, clickHandlerService: ClickHandlerService): Promise<void> {
    return this.addEmptyVehicleGLTF(
      this.emptyVehicleMaterial,
      this.emptyVehiclePosition,
      Config.scale(100),
      clickHandler,
      clickHandlerService
    );
  }

  addVehicle(vehicle: Vehicle, clickHandler?: () => void, clickHandlerService?: ClickHandlerService): Promise<void> {
    if (vehicle.type === 'empty') {
      return this.addEmptyVehicleImage(clickHandler, clickHandlerService);
    } else {
      return new Promise((resolve, reject) => {
        vehicle.createMesh();
        const mesh = vehicle.mesh.mesh;
        const clone = this.isSecondaryCopy ? mesh.clone(true) : mesh;
        clone.position.y = 0;
        clone.position.x = Config.scale(-vehicle.maxLength / 2);
        clone.position.z = Config.scale(-vehicle.maxWidth / 2);
        mesh.position.copy(clone.position);
        this.add(clone);
        resolve();
      });

      // this.showAxlesLabels();
    }
    // this.drawDebugPoints();
  }

  removeFlooringLevels() {
    this.removeObjectsByName('space-flooring-level-mesh');
  }

  addFlooringLevels(vehicle: Vehicle) {
    this.removeFlooringLevels();
    for (const space of vehicle.spaces) {
      if (space.settings && space.settings.maxHeightEnabled) {
        const flooringLevelMesh = new FlooringLevelMesh(space);
        const spaceMesh = this.findSpaceMesh(space.uuid);
        spaceMesh.add(flooringLevelMesh.obj);
      }
    }
  }

  addLoads(context: VehicleContext) {
    const { meshes: instanceGroups, borders } = this.getInstancedLoads(context);
    for (let i = 0; i < instanceGroups.length; i++) {
      const group = instanceGroups[i];
      const border = borders[i];
      if (!this.isSecondaryCopy) {
        group.layers.set(Config.LAYER_CAM_AND_MOUSEPICK);
        if (this.stepsMode) {
          group.layers.set(Config.LAYER_CAMERA);
        }
      }
      this.add(group);
      this.add(border.getObject());
    }
    this.instanceMeshes = instanceGroups;
    this.instanceBorders = borders;

    // normal one-by-one "adding" to determine position of each load mesh
    const offset = new Vector3(0, 0, 0);
    for (const load of context.getLoads()) {
      this.addLoad(load, offset);
    }
    // ładunki na przestrzeniach
    for (const space of context.getVehicle().enabledSpaces) {
      const wPosition = space.mesh.meshObj.getWorldPosition(new Vector3());

      for (const load of space.loads) {
        this.addLoad(load, wPosition);
      }
    }
  }

  drawGravityCenters(vehicle: Vehicle, loads: Array<Load>, showIcons: boolean) {
    this.removeGravityCenters();
    this.drawLoadsGravityCenters(vehicle, loads, showIcons);
  }

  getMatrixVectors(): VectorMesh[] {
    return this.matrixMeshes;
  }

  showMatrixVectors() {
    this.matrixMeshes.forEach((v) => {
      v.show();
    });
  }

  hideMatrixVectors() {
    this.matrixMeshes.forEach((v) => {
      v.hide();
    });
  }

  hoverLoad(hover: Load[]) {
    this.instanceMeshes.forEach((group) => {
      if (hover.length === 0) {
        group.unsetUniform('emissive');
        return;
      }
      for (let i = 0; i < group.count; i++) {
        const key = `${group.id}-${i}`;
        const load = this.instanceMap.get(key);
        group.setUniformAt('emissive', i, new Color(load.selected ? 0x111111 : 0x0));
      }
    });
    this.loadMap.forEach((load) => {
      load.mesh?.unhover();
    });
    hover.forEach((load) => {
      if (this.treatAsSingleLoad(load)) {
        load.mesh?.hover();
      } else {
        const data = this.instanceReverseMap.get(load.uuid);
        if (data && data.groupIdx !== undefined) {
          const color = new Color(load.selected ? 0x111111 : 0x666666);
          this.instanceMeshes[data.groupIdx].setUniformAt('emissive', data.instanceIdx, color);
        } else {
          console.error('hover instanced mesh not existing');
        }
      }
    });
  }

  // TODO optymalizacja - deselect poszczególnych load zamiast przekazywania które mają być zaznaczone
  selectLoads(loads: Load[]) {
    const selectedMap = new Map<string, boolean>();
    const colorArray = this.colorRed.toArray().map((c) => Math.floor(c * 255));
    const colorBlackArray = this.colorBlack.toArray().map((c) => Math.floor(c * 255));

    this.loadMap.forEach((load) => {
      load.unselect();
    });
    loads.forEach((selected) => {
      if (this.treatAsSingleLoad(selected)) {
        selected.select();
      } else {
        const { groupIdx, instanceIdx } = this.instanceReverseMap.get(selected.uuid);
        this.instanceMeshes[groupIdx].setColorAt(instanceIdx, this.colorRed);
        this.instanceBorders[groupIdx].setColorArrayAt(instanceIdx, colorArray);
      }
      selectedMap.set(selected.uuid, true);
    });

    for (let g = 0; g < this.instanceMeshes.length; g++) {
      const group = this.instanceMeshes[g];
      const borders = this.instanceBorders[g];
      for (let i = 0; i < group.count; i++) {
        const key = `${group.id}-${i}`;
        const load = this.instanceMap.get(key);
        const inSelected = selectedMap.get(load.uuid);
        if (inSelected) {
          continue;
        }
        borders.setColorArrayAt(i, colorBlackArray);
        group.setColorAt(i, new Color(load.color));
      }
      group.instanceColor.needsUpdate = true;
    }
  }

  disconnectFromInstance(load: Load) {
    const { groupIdx, instanceIdx } = this.instanceReverseMap.get(load.uuid);
    const group = this.instanceMeshes[groupIdx];
    group.getMatrixAt(instanceIdx, this._matrix);
    //console.log('disconnect instance', this._matrix);
    const mat = new Matrix4();
    this._position.set(0, 0, 0);
    mat.scale(this._position);
    group.setMatrixAt(instanceIdx, mat);
    this.instanceBorders[groupIdx].setOpacityAt(instanceIdx, 0.0);
    group.instanceMatrix.needsUpdate = true;
  }

  reconnectToInstance(load: Load) {
    const { groupIdx, instanceIdx } = this.instanceReverseMap.get(load.uuid);
    const group = this.instanceMeshes[groupIdx];
    this._matrix.identity().setPosition(load.mesh.position.x, load.mesh.position.y, load.mesh.position.z);
    group.setMatrixAt(instanceIdx, this._matrix);
    this.instanceBorders[groupIdx].setOpacityAt(instanceIdx, 1.0);
    group.instanceMatrix.needsUpdate = true;
  }

  private addLoad(load: Load, spaceOffset: Vector3): Load {
    load.updateSettings(this.loadSettings);
    if (!load.mesh) {
      load.createMesh(this.loadSettings);
    }

    // console.log('load.position', load.position);
    this._position.set(
      Config.scale(load.position.x + load.cuboidHull.length / 2),
      Config.scale(load.position.y + load.cuboidHull.height / 2),
      Config.scale(load.position.z + load.cuboidHull.width / 2)
    );
    this._position.add(spaceOffset);
    load.mesh.obj.position.copy(this._position);

    if (load.selected) {
      load.select();
    } else {
      load.unselect();
    }
    if (this.isSecondaryCopy) {
      if (this.treatAsSingleLoad(load)) {
        this.loadMap.set(load.mesh.obj.uuid, load);
        this.add(load.mesh.obj.clone(true));
      }
    } else {
      load.mesh.obj.layers.set(Config.LAYER_COLLISIONS);
      if (this.treatAsSingleLoad(load)) {
        this.loadMap.set(load.mesh.obj.uuid, load);
        load.mesh.obj.layers.enable(Config.LAYER_CAM_AND_MOUSEPICK);
        this.add(load.mesh.obj);
      }
      if (this.stepsMode) {
        load.mesh.obj.layers.set(Config.LAYER_CAMERA);
      }
    }
    return load;
  }

  private addEmptyVehicleGLTF(
    material: Material,
    position: Vector3,
    scale: number,
    clickHandler: () => void,
    clickHandlerService: ClickHandlerService
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.emptyVehicleScene) {
        this.add(this.emptyVehicleScene);
        resolve();
        return;
      }
      this.gltfLoader.load(
        'assets/glbs/tir_question_C.glb',
        (gltf) => {
          gltf.scene.name = 'vehicle';
          gltf.scene.scale.multiplyScalar(scale); // adjust scalar factor to match your scene scale

          gltf.scene.traverse((child) => {
            if (clickHandler && (child as Mesh).isMesh) {
              (child as Mesh).material = material;
              child.userData.onclick = clickHandler;
              clickHandlerService.addObjects([child]);
            }
          });
          gltf.scene.position.copy(position);
          //  gltf.scene.rotation.y = -0.72;
          gltf.scene.rotation.y = -1.12;
          const dirLight = new DirectionalLight(0xffffff, 0.5);
          dirLight.position.set(-100, 10, 10);
          gltf.scene.add(dirLight);
          const dirLight2 = new DirectionalLight(0xffffff, 0.5);
          dirLight2.position.set(100, 10, 10);
          gltf.scene.add(dirLight2);

          this.add(gltf.scene);
          this.emptyVehicleScene = gltf.scene;
          resolve();
        },
        (xhr) => {
          // console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  private addBackground() {
    this.gridColor.set(Config.GRID_COLOR);
    this.grid = new GridHelper(Config.GRID_SIZE, Config.GRID_DIVISIONS, this.gridColor, this.gridColor);
    this.grid.position.y = 0;
    this.add(this.grid);
  }

  private addLight() {
    this.add(this.light);
  }

  private clearAll(dispose = false) {
    this.currentVehicleMesh?.dispose();
    this.instanceMap.forEach((load, _) => {
      load.mesh?.dispose(this.displayCache);
    });
    this.loadMap.forEach((load, _) => {
      load.mesh?.dispose(this.displayCache);
    });
    this.loadMaterials.forEach((m) => {
      this.displayCache.disposeLoadMaterial(m.color.getHex(), this.loadSettings, false, false);
    });
    this.instanceBorders.forEach((border) => {
      border.dispose();
    });
    this.instanceMap = new Map();
    this.loadMap = new Map();
    this.instanceReverseMap = new Map();
    this.loadMaterials = [];
    this.instanceMeshes = [];
    this.instanceBorders = [];
    this.displayCache.disposeAll();
    if (this.grid) {
      console.log('remove grid');
      this.scene.remove(this.grid);
    }
    this.cleanup(this.scene);

    while (this.scene.children.length > 0) {
      this.scene.remove(this.scene.children[0]);
    }
    this.clickHandlerService?.clearObjects();

    if (!dispose) {
      this.initDefaultView();
    }
  }

  private removeGravityCenters() {
    this.removeObjectsByName('gravity-center');
  }

  private drawLoadsGravityCenters(vehicle: Vehicle, loads: Array<Load>, showIcons: boolean) {
    let weight = 0;
    let weightX = 0;
    let weightY = 0;
    let weightZ = 0;

    //vehicle.space.load :) tak powinno to wygladac jezeli chodzi o organizacje
    //srdoki ciezkosci powinny byc dla kazdej z przestrzeni z osobna

    for (const load of loads.filter((x) => !!x.spaceUuid)) {
      weightX += load.mesh.obj.position.x * load.weight;
      weightZ += load.mesh.obj.position.z * load.weight;
      weightY += load.mesh.obj.position.y * load.weight;
      weight += load.weight;
    }

    const center = new GravityCenter({
      position: new Vector3(weightX / weight, weightY / weight, weightZ / weight),
      weight
    });

    if (showIcons) {
      const centerSprite = new GravityCenterSprite(center);
      centerSprite.position.copy(center.position);
      this.add(centerSprite);
    }

    this.calculateAxlePressures(vehicle, center);
  }

  //zrobic liczenie dla kazdej z przestrzeni z osobna a nie dla calego pojazdu
  // to raczej przenieść do osobnej klasy
  private calculateAxlePressures(vehicle: Vehicle, center: GravityCenter) {
    const axles = new Array<Axle>();
    for (const axle of vehicle.axles) {
      axle.distanceFromGravityCenter = Math.abs(
        center.position.x - (vehicle.mesh.position.x + axle.mesh.obj.position.x)
        // center.position.x -
        // (vehicle.mesh.position.x - vehicle.cabinLength / 2 + axle.offset)
      ); //tylko w X

      axles.push(axle);
    }

    for (const space of vehicle.spaces) {
      for (const axle of space.axles) {
        /*console.log(
          'vehicle.mesh.position.x',
          vehicle.mesh.position.x,
          'space.mesh.position.x',
          space.mesh.position.x
        );*/
        axle.distanceFromGravityCenter = Math.abs(
          center.position.x - (vehicle.mesh.position.x + axle.mesh.obj.position.x + space.mesh.position.x)
        ); //tylko w X
        axles.push(axle);
      }
    }
    if (axles.length < 1) {
      return;
    }

    const comb = new Combination(
      Array(axles.length)
        .fill(0)
        .map((v, i) => i),
      axles.length - 1
    );
    let denominator = 0;
    for (const c of comb) {
      let mul = 1;
      for (const e of c) {
        mul *= axles[e].distanceFromGravityCenter;
      }
      denominator += mul;
    }

    for (let i = 0; i < axles.length; i++) {
      let numerator = center.weight;
      for (let j = 0; j < axles.length; j++) {
        if (i === j) {
          continue;
        }
        numerator *= axles[j].distanceFromGravityCenter;
      }
      axles[i].pressure = numerator / denominator;
    }
  }

  private removeMatrixMeshes() {
    this.matrixMeshes = [];
    this.removeObjectsByName('vector-mesh');
  }

  private addSpaceMatrixMeshes(context: VehicleContext) {
    this.removeMatrixMeshes();
    if (!context || !context.getVehicle()) {
      return;
    }
    context.getVehicle().spaces.forEach((space) => {
      const spaceMesh = this.findSpaceMesh(space.uuid);
      if (!spaceMesh) {
        return;
      }
      space.matrix
        .filter((v) => !v.occupied)
        .forEach((vector) => {
          //console.debug('DEBUG: draw matrix vector', vector, space.asVector());
          const vectorMesh = new VectorMesh(vector, { color: 0xfe9800 });
          spaceMesh.add(vectorMesh.mesh);
          this.matrixMeshes.push(vectorMesh);
        });
    });
  }

  public add(obj: Object3D): Scene {
    return this.scene.add(obj);
  }

  public remove(...obj: Object3D[]): Scene {
    return this.scene.remove(...obj);
  }

  public getObjectByName(name: string): Object3D<Object3DEventMap> {
    return this.scene.getObjectByName(name);
  }

  public getSceneObject(): Scene {
    return this.scene;
  }

  public dispose() {
    this.clearAll(true);
    this.clickHandlerService = undefined;
    this.scene.clear();
    this.scene = undefined;
  }
}
