import { Component, Input } from '@angular/core';
import { Axle } from '../../lib/axle';
import { AxleForm } from '../lib/axle.form';
import { SpaceAxlesForm } from '../lib/space-axles.form';

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.scss']
})
export class SpaceComponent {
  @Input() form: SpaceAxlesForm;
  @Input() type: 'vehicle' | 'space' = 'space';

  constructor() {}

  addAxle() {
    this.form.addAxle(new Axle());
  }

  deleteAxle(axleForm: AxleForm) {
    this.form.removeAxleForm(axleForm);
  }
}
