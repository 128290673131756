import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-protrusion',
  templateUrl: './protrusion.component.html',
  styleUrls: ['./protrusion.component.scss']
})
export class ProtrusionComponent {
  constructor() {}
}
