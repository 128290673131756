import { Load } from 'src/app/load/lib/load';
import { LoadDimensionLabel } from './LoadDimensionLabel';
import { Constants as Config } from 'src/app/config/constants';

export class LoadHeightLabel extends LoadDimensionLabel {
  public updatePosition(load: Load): void {
    const position = load.mesh.position.clone();
    position.x -= (load.cuboidHull.length / 2 - 20) * Config.DIMENSION_SCALING;
    position.z += (load.cuboidHull.width / 2 - 20) * Config.DIMENSION_SCALING;
    this.setPosition(position);
  }

  makeCopy(): LoadHeightLabel {
    return new LoadHeightLabel(this.getText(), this.textScale);
  }
}
