/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Type } from '@angular/core';
import { SpaceFactory } from '../space/lib/space-factory';
import { Bus } from '../type/bus/lib/bus';
import { Container } from '../type/container/lib/container';
import { EmptyVehicle } from '../type/empty-vehicle/lib/empty-vehicle';
import { Other } from '../type/other/lib/other';
import { Train } from '../type/train/lib/train';
import { Truck } from '../type/truck/lib/truck';
import { Warehouse } from '../type/warehouse/lib/warehouse';

import { Vehicle } from './vehicle';

@Injectable({
  providedIn: 'root'
})
export class VehicleFactory {
  readonly types = {
    Bus: $localize`Bus`,
    'Solówka 6,5': $localize`Solówka 6,5`,
    'Solówka 7,0': $localize`Solówka 7,0`,
    Solówka: $localize`Solówka 7,0`,
    'Standard 2.70': $localize`Standard 2,70`,
    'Standard 2.75': $localize`Standard 2,75`,
    Mega: $localize`Mega`,
    'Tandem [7,7+7,7]': $localize`Tandem [7,7 + 7,7]`,
    'Tandem [8,1+7,3]': $localize`Tandem [8,1 + 7,3]`,
    "Kontener Chłodniczy 20'": $localize`Kontener Chłodniczy 20'`,
    "Kontener Morski 20'": $localize`Kontener Morski 20'`,
    "Kontener chłodniczy 40'": $localize`Kontener chłodniczy 40'`,
    "Kontener chłodniczy 40' HC": $localize`Kontener chłodniczy 40' HC`,
    "Kontener Morski 40'": $localize`Kontener Morski 40'`,
    "Kontener Morski 40' HC": $localize`Kontener Morski 40\' HC`,
    "Kontener Morski 45' HC": $localize`Kontener Morski 45\' HC`,
    '1/2 palety Euro': $localize`1/2 palety Euro`,
    'Paleta Euro': $localize`Paleta Euro`,
    'Paleta ISO': $localize`Paleta ISO`,
    'Powiększona paleta Euro': $localize`Powiększona paleta Euro`,
    'Firanka / plandeka 2.70': $localize`Firanka / plandeka 2.70`,
    'Firanka / plandeka 2.75': $localize`Firanka / plandeka 2.75`,
    '1/2 palety euro': $localize`1/2 palety euro`,
    'paleta chemiczna': $localize`paleta chemiczna`,
    'paleta euro': $localize`paleta euro`
  };

  private typeMap: { [key: string]: Type<Vehicle> } = {
    car: Truck,
    truck: Truck,
    bus: Bus,
    train: Train,
    other: Other,
    container: Container,
    empty: EmptyVehicle,
    warehouse: Warehouse
  };

  public constructor(private spaceFactory: SpaceFactory) {}

  recreate(obj: any, forceCopy = false): Vehicle {
    if (!forceCopy && obj instanceof Vehicle) {
      return obj;
    }
    if (typeof this.typeMap[obj.type] === undefined) {
      console.error('unknown Vehicle type', obj.type);
      return null;
    }
    if (obj && this.types[obj.name]) {
      obj.name = this.types[obj.name];
    }
    return new this.typeMap[obj.type](obj, this.spaceFactory);
  }

  create(
    type:
      | 'truck'
      | 'train'
      | 'bus'
      | 'other'
      | 'container'
      | 'empty'
      | 'warehouse'
  ): Vehicle {
    return this.recreate({ type });
  }
}
