<div class="help-flooring">
  <div class="text-right floorable-active">
    <div>
      <span class="text-center"><ng-container
          i18n>Piętrowalny</ng-container><br /><ng-container
          i18n>aktywnie</ng-container></span>
    </div>
    <img src="assets/images/floorable-active.png" alt="Piętrowalny aktywnie" />
  </div>
  <div class="help-main">
    <svg class="help-bg" viewBox="0 0 112 129"
      xmlns="http://www.w3.org/2000/svg">
      <rect class="rect" width="32" height="48" />
      <rect class="rect" x="43" width="69" height="48" />
      <rect class="rect" y="58" width="32" height="71" />
      <rect class="rect" x="43" y="58" width="69" height="71" />
    </svg>
    <h2 class="primary" i18n>Piętrowalność ładunków aktywna i pasywna</h2>
    <p i18n>
      Ładunek piętrowalny aktywnie [PA] - to taki, który można postawić na innym
      piętrowalnym ładunku.
    </p>
    <p i18n>
      Ładunek piętrowalny pasywnie [PP] - to taka, na której można postawić inny
      ładunek.
    </p>
    <p i18n>
      Dany ładunek może mieć dowolną z tych cech, żadnej, lub obie. Mając obie
      cechy ładunek może być położony na innym oraz na nim może być położony
      inny ładunek. Nie mając żadnej z tych cech, musi leżeć na podłodze i nie
      być niczym przykryty.
    </p>
  </div>
  <div class="floorable-passive">
    <div class="text-center">
      <ng-container i18n>Piętrowalny</ng-container><br /><ng-container
        i18n>pasywnie</ng-container>
    </div>
    <img src="assets/images/floorable-passive.png" alt="Piętrowalny pasywnie" />
  </div>
</div>