import { Load } from 'src/app/load/lib/load';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';
import { OrbitControlsState } from '../model/orbit-controls-state';

import { LoadMovement } from './load-movement';
import { LoadEventObject } from 'src/app/load/lib/load-event-object';
import { LoadingStep } from '../model/loading-step';

export class PositionerRequest {
  uuid: string;
  historyUuid: string;
  message: string;
  addedLoads: Load[];
  addBundles?: LoadEventObject[];
  vehicle: Vehicle;
  rotateDirection: string;
  load: Load;
  action: string;
  color: number;
  initialTimestamp: number;
  orbitControlState: OrbitControlsState;
  projectId: string;
  movement?: LoadMovement;
  steps?: LoadingStep[];

  fillSpace: boolean = false;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
