import { Vector } from 'src/app/lib/communication/vector';
import { GravityCenter } from 'src/app/lib/gravity-center/gravity-center';
import { Load } from 'src/app/load/lib/load';
import { v4 as uuidv4 } from 'uuid';
import { Axle } from '../axles/lib/axle';
import { Settings } from '../form/settings/settings';

import { Space } from '../space/lib/space';
import { SpaceFactory } from '../space/lib/space-factory';
import { VehicleMesh } from './vehicle-mesh';

export abstract class Vehicle {
  uuid: string;
  name: string;
  abstract type: string;

  createdAt?: Date;
  updatedAt?: Date;
  public cabinLength = 1000;
  public cabinHeight = 2000;
  public cabinRoofHeight = 500;
  public cabinWidth = 1500;
  trailerSpacing = 500;

  spaces = new Array<Space>();
  axles = new Array<Axle>();
  gravityCenters = new Array<GravityCenter>();

  projectId?: string;
  #mesh: VehicleMesh;

  //20220122
  public height: number;
  public length: number;
  public width: number;
  public weight: number;
  public maxTransportLoadingWeight: number;
  public model: string;

  position: Vector;

  public defaultBaseSize: string;

  //  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];
  constructor(obj: any, spaceFactory: SpaceFactory) {
    Object.assign(this, obj);
    if (this.uuid === undefined) {
      this.uuid = uuidv4();
    }

    this.spaces = (this.spaces || []).map((s) => spaceFactory.recreate(s));

    this.axles = (this.axles || []).map((axle) => new Axle(axle));

    if (obj instanceof Vehicle) {
      this.defaultBaseSize = obj.defaultBaseSize;
    } else {
      this.defaultBaseSize = `${this.length}x${this.width}`;
    }
  }

  abstract createMesh(): void;
  disposeMesh(): void {
    this.mesh?.dispose();
    this.mesh = undefined;
  }

  abstract get fullName(): string;
  abstract get fullDescription(): string;
  get mesh() {
    return this.#mesh;
  }

  protected set mesh(mesh: VehicleMesh) {
    this.#mesh = mesh;
  }

  get enabledSpaces(): Space[] {
    return this.spaces.filter((space) => space.enabled);
  }

  get spacesForSizeDisplay(): Space[] {
    return this.enabledSpaces;
  }

  get dimensions(): number[] {
    const result: number[] = new Array<number>();
    for (const space of this.enabledSpaces) {
      result.push(...space.dimensions);
    }
    return result;
  }

  get maxLoadingWeight(): number {
    let weight = 0;
    for (const space of this.enabledSpaces) {
      weight += space.maxLoadingWeight;
    }
    return weight;
  }

  get volume(): number {
    let result = 0;
    for (const space of this.enabledSpaces) {
      result += space.volume;
    }
    return result;
  }
  get area(): number {
    let result = 0;
    for (const space of this.enabledSpaces) {
      result += space.area;
    }
    return result;
  }

  get isCar() {
    return ['bus', 'truck'].includes(this.type);
  }

  get canLoadPallets(): boolean {
    return ['bus', 'truck', 'container', 'warehouse'].includes(this.type);
  }

  get totalLength(): number {
    const trailerSpacing = (this.spaces.length - 1) * this.trailerSpacing;
    return (
      this.cabinLength +
      this.spaces.reduce((total, space) => total + space.length, 0) +
      trailerSpacing
    );
  }

  get maxLength() {
    return this.totalLength;
  }

  get maxHeight(): number {
    return Math.max(0, ...this.spaces.map((space) => space.height));
  }

  get maxWidth(): number {
    return Math.max(0, ...this.spaces.map((space) => space.width));
  }

  get totalDisplayHeight(): number {
    return this.maxHeight; // TODO + floorHeight?
  }

  get loadedLoadsWeight(): number {
    return this.loadedLoads.reduce(
      (total, load) => total + (load.weight || 0),
      0
    );
  }

  get loadedLoads(): Load[] {
    return this.spaces.flatMap((space) => space.loads);
  }

  public generateUuid() {
    this.uuid = uuidv4();
  }

  public addSpace(space: Space) {
    space.order = this.spaces.length;
    this.spaces.push(space);
  }

  public updateCalculationSettings(settings: Settings) {
    this.spaces.forEach((space) => space.updateCalculationSettings(settings));
  }

  public baseSizeChanged(): boolean {
    return `${this.length}x${this.width}` !== this.defaultBaseSize;
  }
}
