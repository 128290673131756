import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterType } from './lib/filter-type';
import { DateRange } from './lib/date-range';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { DatesFilterService } from './lib/dates-filter.service';
import { FilterChangeEvent } from './lib/filter-change.event';

@Component({
  selector: 'app-dates-filter',
  templateUrl: './dates-filter.component.html',
  styleUrls: ['./dates-filter.component.scss']
})
export class DatesFilterComponent implements OnInit, OnDestroy {
  @Input()
  get currentType() {
    return this.selectedFilter;
  }
  set currentType(type: FilterType) {
    // console.log('dates.filter.component.ts: set filter type', type);
    this.selectedFilter = type;
    //this.changeFilterType(type);
  }
  @Output() datesChangeEvent = new EventEmitter<FilterChangeEvent>();
  filterType = FilterType; // for template reference

  currentDate: Date; // bieżący miesiąc / rok / dzień do filtrów w widoku
  unsubscribe$ = new Subject<void>();

  rangeFrom: Date = null;
  rangeTo: Date = null;

  now: Date = new Date();

  selectedFilter: FilterType = FilterType.month;

  constructor(private service: DatesFilterService) {
    // przywracanie stanu z usługi
    this.currentDate = service.currentDate;
    this.selectedFilter = service.currentType;
    this.rangeFrom = service.rangeFrom;
    this.rangeTo = service.rangeTo;
  }

  ngOnInit(): void {
    // console.log('dates-filter.component.ts: init');
    this.service.range$
      .pipe(debounceTime(100), distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((val: DateRange) => {
        let to = val.to;
        if (val.to) {
          to = new Date(val.to.getFullYear(), val.to.getMonth(), val.to.getDate() + 1);
        }
        this.changeDates(val.from, to);
      });
    this.changeFilterType(this.selectedFilter);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changeFilterType(type: FilterType) {
    this.selectedFilter = type;
    switch (type) {
      case FilterType.month:
        this.changeMonthFilter();
        break;
      case FilterType.year:
        this.changeYearFilter();
        break;
      case FilterType.day:
        this.changeDayFilter();
        break;
      case FilterType.none:
        this.changeDates(null, null);
        break;
    }
  }

  changeDates(from?: Date, to?: Date) {
    this.datesChangeEvent.emit(new FilterChangeEvent(this.currentType, new DateRange(from, to)));
    this.storeState();
  }

  changeMonth(month: number, year: number) {
    // console.log('change month', month, year);
    this.currentDate = new Date(year, month, 1);
    // console.log(this.currentDate);
    this.changeMonthFilter();
  }

  changeMonthYear(change: number) {
    this.currentDate = new Date(change, this.currentDate.getMonth(), 1);
    this.changeMonthFilter();
  }

  changeMonthFilter() {
    const from = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
    const to = new Date(from.getFullYear(), from.getMonth() + 1, 1);
    this.changeDates(from, to);
  }

  changeDay(event: any) {
    if (event.value) {
      this.currentDate = new Date(event.value);
      this.changeDayFilter();
    }
  }

  changeDayFilter() {
    const from = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());
    const to = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() + 1);
    this.changeDates(from, to);
  }

  changeRangeFrom(event: any) {
    this.rangeFrom = this.getDateFromRangeChangeEvent(event);
    this.service.updateRange(new DateRange(this.rangeFrom, this.rangeTo));
  }

  changeRangeTo(event: any) {
    this.rangeTo = this.getDateFromRangeChangeEvent(event);
    this.service.updateRange(new DateRange(this.rangeFrom, this.rangeTo));
  }

  changeYear(change: number) {
    this.currentDate = new Date(change, this.currentDate.getMonth(), 1);
    this.changeYearFilter();
  }

  private getDateFromRangeChangeEvent(event: any): Date {
    let d: Date = null;
    if (event.value) {
      d = new Date(event.value);
    }
    return d;
  }

  private changeYearFilter() {
    const from = new Date(this.currentDate.getFullYear(), 0, 1);
    const to = new Date(from.getFullYear() + 1, 0, 1);
    this.changeDates(from, to);
  }

  private storeState() {
    this.service.currentDate = this.currentDate;
    this.service.currentType = this.selectedFilter;
    this.service.rangeFrom = this.rangeFrom;
    this.service.rangeTo = this.rangeTo;
  }
}
