import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { Vehicle } from '../../lib/vehicle';
import { CreateComponent as CreateSpaceComponent } from 'src/app/vehicle/space/form/create/create.component';
import { Space } from '../../space/lib/space';
import { SpaceFactory } from '../../space/lib/space-factory';
import { VehicleType } from '../../lib/vehicle-type';
import { VehicleService } from '../../lib/vehicle.service';
import { VehicleService as VehicleUiService } from '../../vehicle.service';
import { NameComponent } from '../name/name.component';
import { VehicleFactory } from '../../lib/vehicle-factory';
import { CustomVehicleService } from '../../lib/custom-vehicle.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, OnDestroy {
  @ViewChild('spaces', { read: ViewContainerRef, static: true })
  spacesFormsContainer: ViewContainerRef;
  @ViewChild('name') nameComponent: NameComponent;

  private componentList: CreateSpaceComponent[] = [];
  public spaces: Space[] = [];
  public vehicleTypes: VehicleType[] = [];
  public vehicleType: VehicleType;

  protected vehicle: Vehicle;

  @Output() createLoadEvent = new EventEmitter<Vehicle>();
  // @ViewChild('name') name: NameComponent;
  // @ViewChild('weight') weight: WeightComponent;
  // @ViewChild('color') color: ColorComponent;
  // @ViewChild('flooring') flooring: FlooringComponent;
  eventsSubject: Subject<void> = new Subject<void>();
  private $unsubscribe = new Subject<void>();

  constructor(
    vehicleService: VehicleService,
    private vehicleUiService: VehicleUiService,
    private customVehicleService: CustomVehicleService,
    private loadComponentService: LoadComponentService,
    private vehicleFactory: VehicleFactory,
    private spaceFactory: SpaceFactory
  ) {
    this.vehicleTypes = vehicleService.getVehicleTypes(false).filter((type) => type.canCreate);
    this.vehicleType = this.vehicleTypes.find((x) => x.type === 'car');
  }

  ngOnInit(): void {
    if (this.vehicle) {
      this.vehicle.spaces.forEach((space) => {
        this.addSpaceForm(space);
      });
    } else {
      this.addSpaceForm();
    }
    this.vehicleUiService.vehicleType$
      .pipe(
        takeUntil(this.$unsubscribe),
        filter((type) => !!type)
      )
      .subscribe((type) => this.typeSelected(type));
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  close(): void {
    this.loadComponentService.clear('vehicle/form/create/create.component.ts');
  }

  setEdit(item: Vehicle) {
    this.vehicle = item;
    this.vehicleUiService.selectVehicleType(this.vehicleTypes.find((t) => t.type === this.vehicle.type));
  }

  onSubmit() {
    const nameValid = this.nameComponent.validateForm();
    let spacesValid = this.spaces.length > 0;
    this.componentList.forEach((space, idx) => {
      const spaceValid = this.componentList[idx].validateForm();
      spacesValid = spacesValid && spaceValid;
    });
    const valid = nameValid && spacesValid;
    if (valid) {
      const vehicle = this.getObject();
      this.create(vehicle);
    }
  }

  getObject(): Vehicle {
    const vehicle = this.vehicleFactory.recreate(
      this.vehicle || {
        type: this.vehicleType.type
      },
      true
    );
    vehicle.name = this.nameComponent.getValue();
    vehicle.type = this.vehicleType.type;
    this.componentList.forEach((spaceComponent) => {
      const space = spaceComponent.getObject();
      if (this.vehicleType.type === 'container') {
        space.type = 'container';
      } else if (this.vehicleType.type === 'other') {
        space.type = 'box';
      } else {
        space.type = 'trailer';
      }
      const existingIndex = vehicle.spaces.findIndex((s) => s.uuid === space.uuid);
      if (existingIndex < 0) {
        vehicle.addSpace(space);
      } else {
        vehicle.spaces[existingIndex] = space;
      }
    });
    return vehicle;
  }

  emitEventToChild() {
    this.eventsSubject.next();
  }

  typeSelected(type: VehicleType) {
    console.log('type selected', type);
    this.vehicleType = type;
  }

  create(vehicle: Vehicle) {
    this.customVehicleService
      .create(vehicle)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe(() => {
        this.vehicleUiService.selectVehicle(vehicle);
        this.close();
      });
  }

  public canAddSpace() {
    return this.spaces.length < 2;
  }

  public addSpaceForm(obj?: Space) {
    const space = this.spaceFactory.recreate(obj || { type: 'trailer' });
    this.spaces.push(space);

    const newComponent = this.spacesFormsContainer.createComponent(CreateSpaceComponent);
    newComponent.instance.space = space;
    newComponent.instance.order = this.componentList.length;
    this.componentList.push(newComponent.instance);
  }
}
