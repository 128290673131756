import { SpaceFactory } from 'src/app/vehicle/space/lib/space-factory';
import { Vehicle } from '../../../lib/vehicle';
import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { TruckMesh } from './truck-mesh';

export class Truck extends Vehicle {
  type = 'truck';
  width: number;
  length: number;
  height: number;

  constructor(obj: any, spaceFactory: SpaceFactory) {
    super(obj, spaceFactory);
  }

  createMesh(): void {
    this.mesh = new TruckMesh(this);
  }

  get fullName(): string {
    return `${this.name}`;
  }

  get fullDescription(): string {
    return `${this.length}x${this.width}x${this.height}`;
  }
  get isEmpty(): boolean {
    return !(this.length + this.width + this.height > 0);
  }
}
