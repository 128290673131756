import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { Warehouse } from './warehouse';
import { BufferGeometry, Float32BufferAttribute } from 'three';
import { Constants as Config } from 'src/app/config/constants';

export class WarehouseMesh extends VehicleMesh {
  private indices = [];
  private vertices = [];

  public constructor(warehouse: Warehouse) {
    super('warehouse', warehouse);
    this.init();
  }

  public init() {
    this.mesh.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    const x0 = 0;
    const y0 = 0;
    const z0 = 0;
    this.vertices.push(x0, y0, z0);

    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    this.buildWireframe();
    this.addSpaces();
  }

  protected addSpaces(startX?: number, startY?: number) {
    this.mesh.geometry.computeBoundingBox();

    let spaceOffsetX = 0;
    startX ?? this.mesh.geometry.boundingBox.max.x;

    console.log('this.mesh.position', this.mesh.position);
    for (const space of this.vehicle.spaces) {
      const mesh = space.mesh;
      this.mesh.geometry.computeBoundingBox();

      //mesh.position.copy(this.mesh.position);
      mesh.position.x = space.offset.x * Config.DIMENSION_SCALING;
      mesh.position.y = space.offset.y * Config.DIMENSION_SCALING;
      mesh.position.z = space.offset.z * Config.DIMENSION_SCALING;
      // mesh.position.x = space.offset.x + spaceOffsetX;

      // console.log('spaceOffsetX', spaceOffsetX);

      mesh.meshObj.geometry.computeBoundingBox();
      this.boundingBoxes.push(mesh.meshObj.geometry.boundingBox);

      this.mesh.add(mesh.meshObj);
      spaceOffsetX +=
        space.length * Config.DIMENSION_SCALING +
        this.vehicle.trailerSpacing * Config.DIMENSION_SCALING;
    }
  }
}
