import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LoadGroup } from 'src/app/lib/model/load-group/load-group';
import { LoadGroupList } from 'src/app/lib/model/load-group/load-group-list';
import { SceneService } from 'src/app/scene/scene.service';

@Component({
  selector: 'app-loadings-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  loads: LoadGroupList;

  protected reorderMode = false;

  constructor(private sceneService: SceneService) {
    this.loads = new LoadGroupList(LoadGroup);
  }

  ngOnInit(): void {
    this.sceneService
      .getVehicleContext()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.loads = this.sceneService.getGroupedLoads();
      });
  }
  ngOnDestroy() {
    this.unsubscribe();
  }

  protected toggleReorderMode() {
    this.reorderMode = !this.reorderMode;
    if (this.reorderMode) {
      this.loads.loads.forEach((group) => (group.expanded = false));
    } else {
      this.sceneService
        .saveContextChanges(this.sceneService.context)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {});
    }
  }

  protected orderChanged(event: CdkDragDrop<LoadGroup[]>) {
    moveItemInArray(this.loads.loads, event.previousIndex, event.currentIndex);
    this.loads.loads.forEach((group, index) => {
      group.list.forEach((load) => (load.displayOrder = index * 10));
    });
  }

  private unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
