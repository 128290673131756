<ng-template [ngIf]="listType === 'none'">
  <div class="types-container">
    <mat-card-title i18n>Ładunki:</mat-card-title>
    <button mat-raised-button class="btn-outlined btn-secondary btn-xl"
      (click)="changeListType('system')" i18n>
      Typowe
    </button>
    <button mat-raised-button class="btn-outlined btn-secondary btn-xl"
      (click)="changeListType('user')" i18n>
      Własne
    </button>
  </div>
</ng-template>
<ng-template [ngIf]="listType === 'system'">
  <mat-card appearance="outlined" class="noborder">
    <mat-card-title>
      <ng-container i18n>Ładunki typowe</ng-container><span>&nbsp;</span>
      <a href="#" (click)="changeListType('user')" class="nav-link text-primary"
        i18n>Wróć do własne</a>
    </mat-card-title>
    <ng-container *ngTemplateOutlet="expertForm"></ng-container>
  </mat-card>
</ng-template>

<ng-template [ngIf]="listType === 'user'">
  <mat-card appearance="outlined" class="noborder">
    <mat-card-title>
      <ng-container i18n>Ładunki własne</ng-container><span>&nbsp;</span>
      <a href="#" (click)="changeListType('system')"
        class="nav-link text-primary" i18n>Wróć do typowe</a>
    </mat-card-title>
    <div class="buttons">
      <button mat-raised-button class="btn-secondary" (click)="createNew()"
        i18n>
        Zdefiniuj nowy
      </button>
      <button mat-raised-button class="btn-secondary" (click)="importFromFile()"
        i18n>
        Import z pliku
      </button>
    </div>
    <ng-container *ngTemplateOutlet="expertForm"></ng-container>
  </mat-card>
</ng-template>

<ng-template #expertForm>
  <app-cuboid-select-list [listType]="listType"></app-cuboid-select-list>
  <div [@flashBackground]="showForm === true ? 'show' : ''">
    <div [class.hidden]="showForm === false">
      <div *ngIf="multiLoadForm">
        <mat-slide-toggle [(ngModel)]="customLoadSettings"
          labelPosition="before" i18n i18n-matTooltip
          matTooltip="Domyślnie brane są pod uwagę parametry podane przy dodawaniu ładunków. Zaznacz to pole, aby zmienić domyślne parametry."
          #customSettingsTooltip="matTooltip">Zmień parametry
          ładunków</mat-slide-toggle>
      </div>
      <div [class.hidden]="multiLoadForm && !customLoadSettings" class="flow">
        <div class="amount-row">
          <app-amount #amount [class.hidden]="multiLoadForm"
            [multimode]="multiLoadForm"></app-amount>
          <div>
            <app-weight #weight [class.hidden]="multiLoadForm"></app-weight>
          </div>
        </div>

        <app-cuboid-form [class.hidden]="multiLoadForm"
          [events]="eventsSubject.asObservable()"
          (addLoadEvent)="addLoad($event)"></app-cuboid-form>

        <app-color *ngIf="!randomizeColors" #color></app-color>
        <app-flooring #flooring></app-flooring>
        <app-load-rotation #rotation></app-load-rotation>
      </div>
    </div>
  </div>

  <div style="clear: both" class="text-center">
    <br />
    <div class="keep-open">
      <mat-checkbox [(ngModel)]="keepOpenAfterSubmit" i18n color="primary">Nie
        zamykaj okna po
        dodaniu ładunku</mat-checkbox>
    </div>
    <div class="keep-open">
      <mat-checkbox [ngModel]="addLoadsToPendingList$ | async"
        (ngModelChange)="usePendingList($event)" i18n color="primary">Dodawaj
        ładunki do listy
        załadunkowej</mat-checkbox>
    </div>

    <button mat-raised-button class="btn-primary" (click)="onSubmit()"
      type="submit" [disabled]="showForm === false" i18n>
      Dodaj
    </button>
  </div>
</ng-template>