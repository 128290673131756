import { Load } from './load';

export class LoadEventObject {
  load: Load;
  cnt: number = 1;

  constructor(obj: Partial<LoadEventObject>) {
    Object.assign(this, obj);
    if (typeof this.cnt === 'string') {
      this.cnt = parseInt(this.cnt, 10);
    }
  }
}
