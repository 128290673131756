import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { LoadGroupList } from 'src/app/lib/model/load-group/load-group-list';
import { LoadEventObject } from 'src/app/load/lib/load-event-object';
import { SceneService } from 'src/app/scene/scene.service';
import { ProfileService } from 'src/app/services/profile.service';
import { PendingLoadsService } from '../lib/pending-loads.service';
@Component({
  selector: 'app-pending-loads-list',
  templateUrl: './pending-list.component.html',
  styleUrls: ['./pending-list.component.scss']
})
export class PendingListComponent implements OnInit, OnDestroy {
  pending$: Observable<LoadGroupList>;
  showPendingList$: Observable<boolean>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private sceneService: SceneService,
    private profileService: ProfileService,
    private pendingLoads: PendingLoadsService
  ) {
    this.pending$ = pendingLoads.loads$();
    this.showPendingList$ = pendingLoads.showList$();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected loadPending() {
    const loadAmounts = this.pendingLoads.getCurrentLoads().loads.map((group) => {
      return new LoadEventObject({
        load: group.load,
        cnt: group.cnt
      });
    });
    this.sceneService
      .addLoadAmount(loadAmounts)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() => this.profileService.fetchProfile())
      )
      .subscribe((profile) => {
        this.profileService.updateProfile(profile);
        this.pendingLoads.clear();
      });
  }

  protected close() {
    this.pendingLoads.clear();
  }
}
