import {
  EdgesGeometry,
  Float32BufferAttribute,
  LineBasicMaterial,
  LineSegments,
  Vector3
} from 'three';
import { Constants } from 'src/app/vehicle/lib/constants';
import { OtherMesh } from '../other-mesh';
import { Other } from '../other';

const darkWood = 0;
const lightWood = 1;

export class EPAL2Mesh extends OtherMesh {
  public constructor(other: Other) {
    super(other);
    console.log('EuroPaletteMesh 2 constructor called', other);
    this.mesh.material = [
      Constants.DARK_BROWN_WOOD_MATERIAL,
      Constants.LIGHT_BROWN_WOOD_MATERIAL
    ];
    this.init();
  }

  override getBufferGeometry() {
    const x0 = 0;
    const z0 = 0;
    const x1 = x0 + this.length;
    const z1 = z0 + this.width;
    const xc = (x1 - x0) / 2;
    const zc = (z1 - z0) / 2;
    const plankW = this.scale(100);
    const plankH = this.scale(22);
    const plankL = this.width;
    const boxH = this.scale(96);

    let currentY = 0;
    const r = this.scale(17);

    //bottom planks
    const allRounded = [r, r, r, r] as const;
    const leftExternalRadiuses = [r, 0, 0, r] as const;
    const rightExternalRadiuses = [0, r, r, 0] as const;
    const backExternalRadiuses = [r, r, 0, 0] as const;
    const frontExternalRadiuses = [0, 0, r, r] as const;

    //bottom planks
    this.plank(
      new Vector3(x0, currentY, z0),
      plankW,
      plankL,
      plankH,
      darkWood,
      ...leftExternalRadiuses
    );
    this.plank(
      new Vector3(x1 - plankW, currentY, z0),
      plankW,
      plankL,
      plankH,
      darkWood,
      ...rightExternalRadiuses
    );
    const bottomCenterPlanks = [
      new Vector3(x0 + plankW, currentY, z0),
      new Vector3(x0 + plankW, currentY, zc - plankW / 2),
      new Vector3(x0 + plankW, currentY, z1 - plankW)
    ];
    bottomCenterPlanks.map((p) =>
      this.plank(p, plankL, plankW, plankH, darkWood)
    );

    //boxes
    currentY += plankH;
    const cornerBoxes = [
      new Vector3(x0, currentY, z0),
      new Vector3(x0, currentY, z1 - this.scale(90)),
      new Vector3(x1 - this.scale(160), currentY, z0),
      new Vector3(x1 - this.scale(160), currentY, z1 - this.scale(90))
    ];
    cornerBoxes.forEach((p) => {
      this.plank(
        p,
        this.scale(160),
        this.scale(90),
        boxH,
        lightWood,
        ...allRounded
      );
    });
    this.plank(
      new Vector3(x0, currentY, zc - this.scale(90) / 2),
      this.scale(160),
      this.scale(90),
      boxH,
      lightWood
    );
    this.plank(
      new Vector3(x1 - this.scale(160), currentY, zc - this.scale(90) / 2),
      this.scale(160),
      this.scale(90),
      boxH,
      lightWood
    );
    const centerBoxes = [
      new Vector3(xc - this.scale(110) / 2, currentY, z0),
      new Vector3(xc - this.scale(110) / 2, currentY, zc - this.scale(90) / 2),
      new Vector3(xc - this.scale(110) / 2, currentY, z1 - this.scale(90))
    ];
    centerBoxes.forEach((p) => {
      this.plank(p, this.scale(110), this.scale(90), boxH, lightWood);
    });

    //beforeTopPlanks
    currentY += boxH;
    this.plank(
      new Vector3(x0, currentY, z0),
      this.length,
      plankW,
      plankH,
      darkWood,
      ...backExternalRadiuses
    );
    this.plank(
      new Vector3(x0, currentY, zc - plankW / 2),
      this.length,
      plankW,
      plankH,
      darkWood
    );

    this.plank(
      new Vector3(x0, currentY, z1 - plankW),
      this.length,
      plankW,
      plankH,
      darkWood,
      ...frontExternalRadiuses
    );

    //topPlanks
    currentY += plankH;
    this.plank(
      new Vector3(x0, currentY, -z0),
      this.scale(120),
      plankL,
      plankH,
      lightWood,
      ...leftExternalRadiuses
    ); // left
    this.plank(
      new Vector3(x1 - this.scale(120), currentY, -z0),
      this.scale(120),
      plankL,
      plankH,
      lightWood,
      ...rightExternalRadiuses
    ); // right
    this.plank(
      new Vector3(x0 + this.scale(120), currentY, z0),
      this.scale(127),
      plankL,
      plankH,
      lightWood
    ); // after left
    this.plank(
      new Vector3(x1 - this.scale(120) - this.scale(127), currentY, z0),
      this.scale(127),
      plankL,
      plankH,
      lightWood
    ); /// before right
    const centerPlanks = [
      new Vector3(
        x0 + this.scale(120) + this.scale(127) + this.scale(16),
        currentY,
        z0
      ),
      new Vector3(
        x0 +
          this.scale(120) +
          this.scale(127) +
          this.scale(16) +
          this.scale(120) +
          this.scale(18),
        currentY,
        z0
      ),
      new Vector3(xc - this.scale(120) / 2, currentY, z0),
      new Vector3(
        x1 -
          this.scale(120) -
          this.scale(127) -
          this.scale(16) -
          this.scale(120) -
          this.scale(18) -
          this.scale(120),
        currentY,
        z0
      ),
      new Vector3(
        x1 -
          this.scale(120) -
          this.scale(127) -
          this.scale(16) -
          this.scale(120),
        currentY,
        z0
      )
    ];
    centerPlanks.forEach((p) => {
      this.plank(p, this.scale(120), plankL, plankH, lightWood);
    });

    currentY += plankH;
    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    const geo = new EdgesGeometry(this.mesh.geometry, 1);
    const mat = new LineBasicMaterial({ color: 0x333333 });
    const wireframe = new LineSegments(geo, mat);
    this.mesh.add(wireframe);

    this.addSpaces(0, currentY);
  }
}
