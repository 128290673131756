import { Injectable } from '@angular/core';
import { BoxGeometry, BufferGeometry, Color, EdgesGeometry, LineBasicMaterial, Material } from 'three';
import { LoadDisplaySettings } from '../load/lib/load-display-settings';
import { LoadMaterial, loadMaterial } from '../lib/helper/load-material';

@Injectable({
  providedIn: 'root'
})
export class DisplayCacheService {
  private geometryCache: { [key: string]: BufferGeometry } = {};
  private geometryUseCounters: { [key: string]: number } = {};
  private edgesGeometryCache: { [key: string]: BufferGeometry } = {};
  private edgesGeometryUseCounters: { [key: string]: number } = {};

  private loadMaterialCache: { [key: string]: LoadMaterial } = {};
  private loadMaterialUseCounters: { [key: string]: number } = {};

  private loadBordersMaterialCache: { [key: string]: LineBasicMaterial } = {};
  private loadBordersMaterialUseCounters: { [key: string]: number } = {};

  constructor() {}

  public clear() {
    this.geometryCache = {};
    this.geometryUseCounters = {};
    this.edgesGeometryCache = {};
    this.edgesGeometryUseCounters = {};
    this.loadMaterialCache = {};
    this.loadMaterialUseCounters = {};
    this.loadBordersMaterialCache = {};
    this.loadBordersMaterialUseCounters = {};
  }

  public disposeAll() {
    Object.values(this.geometryCache).forEach((g) => g.dispose());
    Object.values(this.edgesGeometryCache).forEach((g) => g.dispose());
    Object.values(this.loadMaterialCache).forEach((m) => m.dispose());
    Object.values(this.loadBordersMaterialCache).forEach((m) => m.dispose());
    this.clear();
  }

  public getBoxGeometry(length: number, height: number, width: number): BufferGeometry {
    const cacheKey = this.getGeometryCacheKey(length, height, width);
    if (typeof this.geometryCache[cacheKey] === 'undefined') {
      this.geometryCache[cacheKey] = new BoxGeometry(length, height, width);
    }
    if (typeof this.geometryUseCounters[cacheKey] === 'undefined') {
      this.geometryUseCounters[cacheKey] = 0;
    }
    this.geometryUseCounters[cacheKey]++;
    return this.geometryCache[cacheKey];
  }

  public disposeBoxGeometry(length: number, height: number, width: number) {
    const cacheKey = this.getGeometryCacheKey(length, height, width);
    this.geometryUseCounters[cacheKey] = (this.geometryUseCounters[cacheKey] || 0) - 1;
    if (this.geometryUseCounters[cacheKey] < 0) {
      this.geometryUseCounters[cacheKey] = 0;
      if (typeof this.geometryCache[cacheKey] !== 'undefined') {
        this.geometryCache[cacheKey].dispose();
      }
      delete this.geometryCache[cacheKey];
    }
  }

  public getEdgesBoxGeometry(length: number, height: number, width: number): BufferGeometry {
    const cacheKey = this.getGeometryCacheKey(length, height, width);
    if (typeof this.edgesGeometryCache[cacheKey] === 'undefined') {
      const geo = this.getBoxGeometry(length, height, width);
      this.edgesGeometryCache[cacheKey] = new EdgesGeometry(geo);
    }
    if (typeof this.edgesGeometryUseCounters[cacheKey] === 'undefined') {
      this.edgesGeometryUseCounters[cacheKey] = 0;
    }
    this.edgesGeometryUseCounters[cacheKey]++;
    return this.edgesGeometryCache[cacheKey];
  }

  public disposeEdgesBoxGeometry(length: number, height: number, width: number) {
    const cacheKey = this.getGeometryCacheKey(length, height, width);
    this.edgesGeometryUseCounters[cacheKey] = (this.edgesGeometryUseCounters[cacheKey] || 0) - 1;
    if (this.edgesGeometryUseCounters[cacheKey] < 0) {
      this.edgesGeometryUseCounters[cacheKey] = 0;
      if (typeof this.edgesGeometryCache[cacheKey] !== 'undefined') {
        this.edgesGeometryCache[cacheKey].dispose();
      }
      delete this.edgesGeometryCache[cacheKey];
    }
  }

  public getLoadMaterial(
    color: number,
    settings: LoadDisplaySettings,
    selected: boolean,
    hover: boolean
  ): LoadMaterial {
    if (selected) {
      color = 0xff0000;
    }
    const cacheKey = `${color}-${selected ? 1 : 0}-${hover ? 1 : 0}-${settings.loadTransparency}`;
    if (typeof this.loadMaterialCache[cacheKey] === 'undefined') {
      this.loadMaterialCache[cacheKey] = loadMaterial(color, settings);
      if (selected) {
        (this.loadMaterialCache[cacheKey] as any).emissive.set(0x111111);
      }
      if (hover) {
        // hover overrides selected - OK
        (this.loadMaterialCache[cacheKey] as any).emissive.set(0x333333);
      }
    }
    if (typeof this.loadMaterialUseCounters[cacheKey] === 'undefined') {
      this.loadMaterialUseCounters[cacheKey] = 0;
    }
    this.loadMaterialUseCounters[cacheKey]++;
    return this.loadMaterialCache[cacheKey];
  }

  public disposeLoadMaterial(color: number, settings: LoadDisplaySettings, selected: boolean, hover: boolean) {
    if (selected) {
      color = 0xff0000;
    }
    const cacheKey = `${color}-${selected ? 1 : 0}-${hover ? 1 : 0}-${settings.loadTransparency}`;
    this.loadMaterialUseCounters[cacheKey] = (this.loadMaterialUseCounters[cacheKey] || 0) - 1;
    if (this.loadMaterialUseCounters[cacheKey] < 0) {
      this.loadMaterialUseCounters[cacheKey] = 0;
      if (typeof this.loadMaterialCache[cacheKey] !== 'undefined') {
        this.loadMaterialCache[cacheKey].dispose();
      }
      delete this.loadMaterialCache[cacheKey];
    }
  }

  public getLoadBorderMaterial(settings: LoadDisplaySettings, selected: boolean, shader?: any): Material {
    const color = selected ? Color.NAMES.firebrick : Color.NAMES.black;
    const cacheKey = `${color}-${selected ? 1 : 0}-${settings.loadBordersIntensity}`;
    if (typeof this.loadBordersMaterialCache[cacheKey] === 'undefined') {
      const material = new LineBasicMaterial({
        color: color,
        transparent: settings.loadBordersIntensity < 10,
        opacity: settings.loadBordersIntensity / 10,
        linewidth: 2 // i tak w webgl linie są rysowane w grubości 1
      });
      if (shader) {
        material.onBeforeCompile = shader;
      }
      this.loadBordersMaterialCache[cacheKey] = material;
    }
    if (typeof this.loadBordersMaterialUseCounters[cacheKey] === 'undefined') {
      this.loadBordersMaterialUseCounters[cacheKey] = 0;
    }
    this.loadBordersMaterialUseCounters[cacheKey]++;
    return this.loadBordersMaterialCache[cacheKey];
  }

  public disposeLoadBorderMaterial(settings: LoadDisplaySettings, selected: boolean) {
    const color = selected ? Color.NAMES.firebrick : Color.NAMES.black;
    const cacheKey = `${color}-${selected ? 1 : 0}-${settings.loadBordersIntensity}`;

    this.loadBordersMaterialUseCounters[cacheKey] = (this.loadBordersMaterialUseCounters[cacheKey] || 0) - 1;
    if (this.loadBordersMaterialUseCounters[cacheKey] < 0) {
      this.loadBordersMaterialUseCounters[cacheKey] = 0;
      if (typeof this.loadBordersMaterialCache[cacheKey] !== 'undefined') {
        this.loadBordersMaterialCache[cacheKey].dispose();
      }
      delete this.loadBordersMaterialCache[cacheKey];
    }
  }

  private getGeometryCacheKey(length: number, height: number, width: number): string {
    return `${length}-${height}-${width}`;
  }
}
