import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, switchMap, filter, concat } from 'rxjs';
import { Project } from '../projects/lib/project';
import { ProjectsService } from '../projects/projects.service';
import { distinctUntilChanged, first, takeUntil } from 'rxjs/operators';
import { CalculationService } from '../api/calculation.service';
import { ContextService } from '../vehicle/context/context.service';
import { CustomLoadService } from '../load/lib/custom-load.service';
import { CustomVehicleService } from '../vehicle/lib/custom-vehicle.service';
import { SceneService } from '../scene/scene.service';
import { ContextExport } from '../vehicle/context/lib/context-export';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PdfExportComponent } from '../pdf-export/pdf-export.component';
import { UiService } from '../services/ui.service';
import { ContextFactory } from '../vehicle/context/lib/context-factory';

@Component({
  selector: 'app-loadings',
  templateUrl: './loadings.component.html',
  styleUrls: ['./loadings.component.scss']
})
export class LoadingsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  project$: Observable<Project>;
  labelFontModifier$: Observable<string>;

  constructor(
    projectsService: ProjectsService,
    private contextService: ContextService,
    private calculationService: CalculationService,
    private customLoadsService: CustomLoadService,
    private customVehicleService: CustomVehicleService,
    private sceneService: SceneService,
    public exportDialog: MatDialog,
    private uiService: UiService,
    private contextFactory: ContextFactory
  ) {
    this.project$ = projectsService.currentProject$;
    this.labelFontModifier$ = uiService.getLabelFontModifier();
  }

  ngOnInit(): void {
    // console.log('loadings.component.ts: init');
    this.project$.pipe(takeUntil(this.unsubscribe$)).subscribe((project) => {
      this.contextService.updateProject(project);
      this.customLoadsService.updateCurrentProject(project?.uuid);
      this.customVehicleService.updateCurrentProject(project?.uuid);
    });
    if (!this.sceneService.isInLockedMode) {
      //console.log('load calculations list');
      this.project$
        .pipe(
          takeUntil(this.unsubscribe$),
          distinctUntilChanged(),
          filter((project) => project != null),
          switchMap((project) => this.calculationService.fetchCurrentCalculations(project.uuid))
        )
        .subscribe((response) => {
          this.contextService.init(response);
        });
    }
    this.exportProcess();

    this.calculationService.requestedContextUpdate$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((uuids) => {
          const requests = uuids.map((uuid) => this.calculationService.getCalculation(uuid));
          return concat(...requests);
        })
      )
      .subscribe((updated) => {
        this.contextService.replaceContext(this.contextFactory.fromPositionerResponse(updated), false);
      });
  }

  private exportProcess() {
    this.contextService
      .getExportProcess()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((contexts) => {
        const selectedContext = this.contextService.getCurrentContext();
        let currentIdx = 0;
        let currentContext = contexts[currentIdx];
        const exportData: ContextExport[] = [];
        this.uiService.setLoading(true);
        const nextContext = () => {
          this.contextService
            .getAfterContextDrawn()
            .pipe(first(), takeUntil(this.unsubscribe$))
            .subscribe((context) => {
              //console.log('after context drawn', context.getUuid());
              this.uiService.setLoading(true);
              this.sceneService
                .onSceneImgResponse()
                .pipe(first(), takeUntil(this.unsubscribe$))
                .subscribe((img) => {
                  exportData.push(new ContextExport(currentContext, img));
                  if (currentIdx >= contexts.length - 1) {
                    this.contextService.setContext(selectedContext);
                    this.contextService.endExport(exportData);
                  } else {
                    currentIdx++;
                    currentContext = contexts[currentIdx];
                    nextContext();
                  }
                });

              this.sceneService.requestSceneImg();
            });
          //console.log('request context: ', currentContext.getUuid());
          this.contextService.setContext(currentContext);
        };

        if (contexts.length === 0) {
          this.contextService.endExport([]);
        }
        nextContext();
      });
    this.contextService
      .onExportEnd()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.uiService.setLoading(false);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.id = 'modal-component';
        dialogConfig.height = '90%';
        dialogConfig.width = '90%';
        dialogConfig.maxWidth = '90vw';
        dialogConfig.data = {
          hideList: true,
          data
        };
        this.exportDialog.open(PdfExportComponent, dialogConfig);
      });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
