import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-axles',
  templateUrl: './axles.component.html',
  styleUrls: ['./axles.component.scss']
})
export class AxlesComponent {
  constructor() {}
}
