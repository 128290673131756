import { LoadEventObject } from '../../load/lib/load-event-object';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { PositionerRequest } from '../../lib/communication/positioner.request';

export class AddLoadsRequest extends PositionerRequest {
  constructor(loadBundle: LoadEventObject[], context: VehicleContext) {
    super();

    Object.assign(this, {
      historyUuid: context.getHistoryUuid(),
      uuid: context.getUuid(),
      initialTimestamp: context.getInitialTimestamp(),
      message: 'add',
      loads: context.getLoads(),
      addBundles: loadBundle,
      vehicle: context.getVehicle(),
      orbitControlsState: context.getOrbitControlsState(),
      projectId: context.getProjectId(),
      settings: context.getSettings(),
      steps: context.getLoadingSteps()
    });
    if (loadBundle.length === 1 && loadBundle[0].cnt === -1) {
      this.fillSpace = true;
    }
  }
}
