import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Project } from 'src/app/projects/lib/project';
import { ProjectsService } from 'src/app/projects/projects.service';

interface CommentFormType {
  content: FormControl<string | null>;
}

@Component({
  selector: 'app-project-comment-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  @Input() rows: number = 8;
  @Input() showFormElements = true;
  @Output() saved = new EventEmitter<void>();

  protected project: Project;

  protected form: FormGroup<CommentFormType>;
  protected isLoading = false;
  protected maxLength = 500;
  private unsubscribe$ = new Subject<void>();

  constructor(private projectsService: ProjectsService) {
    this.project = projectsService.currentProject;

    this.form = new FormGroup({
      content: new FormControl(this.project.comment || '', [Validators.maxLength(this.maxLength)])
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public save() {
    this.project.comment = this.form.controls.content.value;
    this.isLoading = true;
    this.projectsService
      .updateProject(this.project, false)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.isLoading = false;
        this.saved.emit();
      });
  }

  protected onSubmit() {
    if (this.form.valid) {
      this.save();
    }
  }
}
