import { Injectable } from '@angular/core';
import { BehaviorSubject, concatAll, EMPTY, Observable, of, Subject, tap } from 'rxjs';
import { CalculationService } from '../api/calculation.service';
import { HistoryService } from '../history/lib/history.service';

import { HistoryResponse } from '../lib/communication/history.response';

import {
  ChangeFlooring,
  ChangeLoadSize,
  ContextMenuActionType,
  ContextMenuService,
  ContextMenuServiceCommunicationModel
} from '../context-menu';
import { LoadGroupList } from '../lib/model/load-group/load-group-list';
import { Profile } from '../lib/model/profile';
import { VehicleContext } from '../lib/model/vehicle-context';
import { MessageService } from '../messenger/message.service';
import { OrbitControlsService } from '../orbit-controls/orbit-controls.service';
import {
  TransformControlsService,
  TransformControlsServiceCommunicationModel
} from '../services/transform-controls.service';
import { LabelService } from '../label/label.service';
import { LoadComponentService } from '../services/load-component.service';
import { SuggestLargerVehicleComponent } from '../suggest-larger-vehicle/suggest-larger-vehicle.component';
import { ContextService } from '../vehicle/context/context.service';

import { MeshPositionerValidator } from './mesh.positioner.validator';

import { Load } from '../load/lib/load';
import { LoadEventObject } from '../load/lib/load-event-object';
import { LoadFactory } from '../load/lib/load-factory';

import { CalculationSummaryComponent } from '../calculation-summary/calculation-summary.component';
import { LabelComponentModel } from '../label/label.component.model';
import { PositionerResponse } from '../lib/communication/positioner.response';
import { StatusResponse } from '../lib/communication/status.response';
import { LoadGroup } from '../lib/model/load-group/load-group';
import { ScaledImage } from '../lib/model/scaled-image';
import { SceneImage } from '../lib/model/scene-image';
import { OrbitControls } from '../lib/vendor/three/OrbitControls';
import { Cuboid } from '../load/type/cuboid/lib/cuboid';
import { ProfileService } from '../services/profile.service';

import { Camera, Spherical, Vector3, WebGLRenderer } from 'three';
import { Vehicle } from '../vehicle/lib/vehicle';
import { VehicleFactory } from '../vehicle/lib/vehicle-factory';
import { VehicleService } from '../vehicle/vehicle.service';

import { GarageService } from '../garage/garage.service';
import { ReportsService } from '../reports/reports.service';
import { Space } from '../vehicle/space/lib/space';
import { MyScene } from './lib/MyScene';
import { FormService as AxlesFormService } from '../vehicle/axles/form/form.service';
import { ContextFactory } from '../vehicle/context/lib/context-factory';
import { ColliderDetector } from './collider-detector';
import { FlooringLevelSettingsService } from '../settings-modal/flooring-level/flooring-level-settings.service';
import { UiService } from '../services/ui.service';
import { VehicleSuggestionType } from '../suggest-larger-vehicle/lib/suggestion-type';
import { VehicleToLoadTransformerService } from '../services/vehicle-to-load-transformer.service';
import { Constants as Config } from 'src/app/config/constants';
import { LoadMovement } from '../lib/communication/load-movement';
import { Position } from '../lib/positioner/position';
import { SceneDirector } from './lib/SceneDirector';

type ContextMenuAction = (loads: Load[], value: any) => Observable<PositionerResponse>;
type ContextMenuActionDispatch = {
  [key in ContextMenuActionType]?: ContextMenuAction;
};

@Injectable({
  providedIn: 'root'
})
export class SceneService {
  public loads: Load[];
  public profile$: Observable<Profile>;
  public updateAxles$: Observable<void>;

  private shouldRefresh = new BehaviorSubject<boolean>(null);
  public updateFlooringLevelInView$: Subject<void>;
  public isInLockedMode = false;
  private contextMenuActionHandlers: ContextMenuActionDispatch = {};
  private highlightLDM = new Subject<Space>();

  private sceneImgRequest$ = new Subject<void>();
  private sceneImgResponse$ = new Subject<SceneImage>();

  private showInStep = new Subject<string[]>();

  public get context(): VehicleContext {
    return this.contextService.getCurrentContext();
  }

  public readonly selectedLoads$: Observable<Load[]>;
  public readonly hoveredLoads$: Observable<Load[]>;

  constructor(
    private calculationService: CalculationService,
    private profileService: ProfileService,
    private messenger: MessageService,
    private historyService: HistoryService,
    private orbitControlsService: OrbitControlsService,
    private contextMenuService: ContextMenuService,
    private vehicleService: VehicleService,
    // private spaceService: SpaceService,

    private labelService: LabelService,
    private transformControlsService: TransformControlsService,
    private loadComponentService: LoadComponentService,
    private contextService: ContextService,
    private reportsService: ReportsService,
    private garageService: GarageService,
    axlesService: AxlesFormService,
    private flooringLevelSettingsService: FlooringLevelSettingsService,
    private vehicleFactory: VehicleFactory,
    private contextFactory: ContextFactory,
    private loadFactory: LoadFactory,
    private uiService: UiService,
    private colliderDetector: ColliderDetector,
    private vehicleToLoadTransformer: VehicleToLoadTransformerService
  ) {
    this.profile$ = profileService.getProfile();
    this.selectedLoads$ = transformControlsService.getSelectedLoads();
    this.hoveredLoads$ = transformControlsService.getHoveredLoads();

    this.updateAxles$ = axlesService.updateAxles$;
    this.updateFlooringLevelInView$ = flooringLevelSettingsService.updateFlooringLevelInView$;
  }

  public setShowInStep(ids: string[]) {
    this.showInStep.next(ids);
  }

  public get showInStep$() {
    return this.showInStep.asObservable();
  }

  public updateHoveredLoads(loads: Load[]) {
    this.transformControlsService.updateHoveredLoads(loads);
  }

  public addSelectedLoad(load: Load) {
    this.transformControlsService.toggleSelectedLoad(load, true);
  }

  public removeSelectedLoad(load: Load) {
    this.transformControlsService.toggleSelectedLoad(load, false);
  }

  public isLoadSelected(load: Load) {
    this.transformControlsService.isSelected(load);
  }

  public showFlooringLevelSettings() {
    this.flooringLevelSettingsService.show();
  }

  showVehicleSettings() {
    this.vehicleService.showSettings();
  }

  showGridSettings() {
    this.vehicleService.showGridSettings();
  }
  updateProfile(profile: Profile) {
    this.profileService.updateProfile(profile);
  }

  containsAnyContext(): boolean {
    return this.contextService.contexts.value.length !== 0;
  }

  hasMoreThanOneContext(): boolean {
    return this.contextService.contexts.value.length > 1;
  }

  setLabelsConfiguration(config: LabelComponentModel) {
    this.labelService.initModel(config);
  }

  public clearLoads(): Observable<PositionerResponse> {
    this.setLoadingButtonEnabled(false);
    return this.calculationService
      .clearLoads(this.context)
      .pipe(tap((response) => this.handlePositionerResponse(response)));
  }

  public saveContextChanges(context: VehicleContext): Observable<PositionerResponse> {
    this.setLoadingButtonEnabled(false);
    return this.calculationService
      .saveContext(context)
      .pipe(tap((response) => this.handlePositionerResponse(response)));
  }

  public updateContext(context: VehicleContext) {
    if (!context) {
      return;
    }
    if (context.getMessage() === 'space_not_found') {
      this.suggestLargerVehicles(context);
    } else if (context.getMessage() === 'weight_limit') {
      this.suggestLargerVehicles(context, 'weight');
    } else {
      this.loadComponentService.remove(SuggestLargerVehicleComponent);
    }

    this.setLoadingButtonEnabled(true);

    //this.historyService.setUndoButtonActive(true);
    //this.historyService.setRedoButtonActive(true);

    // if (this.context.getUuid() !== context.getUuid()) {
    this.orbitControlsService.restore(context);
    // }
  }

  public showAxlesSettings() {
    this.vehicleService.showAxlesSettings(this.context);
  }

  public vehicleContextDrawn(context: VehicleContext, director: SceneDirector) {
    //this.initHistory(context);

    this.bindDragControlsLoads(context.getAllLoads(), director);
    this.orbitControlsService.enable();
    this.labelService.setData(
      context,
      context.getAllLoads(),
      this.orbitControlsService.getControls().getSpherical().radius
    );
    // this.addTroikaLabelService();
    // this.setLabelsData();

    // this.initFlooringLevel(ctx);

    //this.orbitControlsService.dolly('in');
    //this.orbitControlsService.dolly('in');
    // this.orbitControlsService.dolly('in');

    this.contextService.contextDrawn(context);
  }

  public refreshSummary() {
    // console.log('refreshSummary called');
  }

  public refreshView() {
    this.shouldRefresh.next(true);
  }

  public multiplyVehicle(vehicle: Vehicle): Observable<PositionerResponse[]> {
    this.setLoadingButtonEnabled(false);

    const currentContext = this.context;
    const loads = currentContext.getAllLoads();

    const newContext = this.contextFactory.createForVehicle(vehicle);
    newContext.setUuid(currentContext.getUuid());
    newContext.setProjectId(currentContext.getProjectId());
    return this.calculationService
      .multiplyVehicle(loads, newContext)
      .pipe(tap((responses) => this.handleMultiplyResponse(responses)));
  }

  public autoPlaceLoads(vehicle: Vehicle): Observable<PositionerResponse[]> {
    this.setLoadingButtonEnabled(false);
    const currentContext = this.context;
    const loads = currentContext.getAllLoads();
    const newContext = this.contextFactory.createForVehicle(vehicle);
    newContext.setUuid(currentContext.getUuid());
    newContext.setProjectId(currentContext.getProjectId());
    return this.calculationService
      .autoPlaceLoads(loads, newContext)
      .pipe(tap((responses) => this.handleMultiplyResponse(responses)));
  }

  public handleMultiplyResponse(response: PositionerResponse[]) {
    let context: VehicleContext;
    for (const positionerResponse of response) {
      context = this.contextService.handleResponse(positionerResponse, false, true);
    }
    if (context) {
      this.contextService.setContext(context);
    }
    if (response.length === 0 || (response[0].vehicle && !response[0].vehicle.isCar)) {
      this.showCalculationSummary(response);
      this.reportsService.refresh(this.context.getProjectId());
    }
  }

  public reloadCurrentLoadIntoDifferentVehicle(vehicle: Vehicle): Observable<PositionerResponse> {
    this.setLoadingButtonEnabled(false);
    const currentContext = this.context;
    const loads = currentContext.getAllLoads();

    const newContext = this.contextFactory.createForVehicle(vehicle);
    newContext.setUuid(currentContext.getUuid());
    newContext.setProjectId(currentContext.getProjectId());
    return this.calculationService
      .addAllLoads(loads, newContext)
      .pipe(tap((response) => this.handleMultiplyResponse([response])));
  }

  public shouldRefreshInvoked(): Observable<boolean> {
    return this.shouldRefresh.asObservable();
  }

  public suggestLargerVehicles(context: VehicleContext, type: VehicleSuggestionType = 'size') {
    this.loadComponentService.clear('scene.service.ts - suggestLargerVehicls');
    const cr = this.loadComponentService.add(SuggestLargerVehicleComponent, '#scene-container');
    cr.instance.context = context;
    cr.instance.type = type;
  }

  public setLoadingButtonEnabled(value: boolean) {
    this.uiService.setLoading(!value);
  }

  public cloneContext(): Observable<VehicleContext> {
    return this.contextService.cloneContext(this.context);
  }

  public addVehicleContextAndSwitch(context?: VehicleContext): Observable<VehicleContext> {
    if (context === null || context === undefined) {
      this.orbitControlsService.reset();
    }

    return this.contextService.createContext(context);
  }

  public getVehicleContext(): Observable<VehicleContext> {
    return this.contextService.contextChanged();
  }

  // public getFlooringLevel(): Observable<FlooringLevel> {
  // return this.loadingSpaceService.flooringLevel$;
  // }

  public getGroupedLoads(): LoadGroupList {
    if (this.context) {
      return this.context.groupLoads();
    } else {
      return new LoadGroupList(LoadGroup);
    }
  }

  public removeAllContexts(): Observable<StatusResponse<void>> {
    this.setLoadingButtonEnabled(false);
    return this.contextService.removeAllContexts();
  }
  public removeCurrentContext(): Observable<VehicleContext> {
    this.setLoadingButtonEnabled(false);
    return this.contextService.removeCurrentContext();
  }

  public removeContextFromView(context: VehicleContext) {
    this.contextService.removeContextFromView(context);
    this.setLoadingButtonEnabled(true);
  }

  public removeAllContextsFromView() {
    this.contextService.removeAllContextsFromView();
  }

  public rotateHorizontal(loads: Load[]): Observable<PositionerResponse> {
    this.setLoadingButtonEnabled(false);
    return this.calculationService.rotateHorizontal(loads, this.context).pipe(
      tap((response) => {
        this.handlePositionerResponse(response);
      })
    );
  }

  public rotateVertical(loads: Load[]): Observable<PositionerResponse> {
    this.setLoadingButtonEnabled(false);
    return this.calculationService.rotateVertical(loads, this.context).pipe(
      tap((response) => {
        this.handlePositionerResponse(response);
      })
    );
  }

  /**
   * Wszystko co nie wymaga zmiany rozmieszczenia ładunków,
   * może być aktualizowane tą metodą
   *
   * @param load Load
   */
  public updateLoads(loads: Load[]): Observable<PositionerResponse> {
    this.setLoadingButtonEnabled(false);

    return this.calculationService.updateLoads(loads, this.context).pipe(
      tap((response) => {
        this.handlePositionerResponse(response);
      })
    );
  }

  public changeLoadCount(group: LoadGroup, cnt: number): Observable<PositionerResponse> {
    // console.log('scene.service.ts: changeLoadCount', group, cnt);
    const currentCount = group.cnt;
    if (cnt >= currentCount) {
      const toAdd = cnt - currentCount;
      const cuboidCnt = new LoadEventObject({ load: group.load, cnt: toAdd });
      return this.addLoadAmount([cuboidCnt]);
    } else if (cnt < currentCount) {
      const toRemove = cnt - currentCount;
      const loads = group.list.sort((a, b) => (a.idx < b.idx ? -1 : 1)).slice(toRemove);
      return this.removeLoads(loads);
    }
  }

  public changeSize(loads: Load[]): Observable<PositionerResponse> {
    this.setLoadingButtonEnabled(false);
    return this.calculationService.changeSize(loads, this.context).pipe(
      tap((response) => {
        this.handlePositionerResponse(response);
      })
    );
  }

  public copyLoad(
    load: Load,
    data: {
      cnt: number;
    }
  ): Observable<PositionerResponse> {
    this.setLoadingButtonEnabled(false);
    return this.addLoadAmount([
      new LoadEventObject({
        load,
        cnt: data.cnt
      })
    ]);
  }

  /*public initHistory(context: VehicleContext) {
    this.historyService.setContext(context);
  }*/

  // public historyBack(currentTimestamp: number) {

  // }
  // public historyForward(currentTimestamp: number) {

  // }

  public removeLoads(loads: Array<Load>): Observable<PositionerResponse> {
    this.setLoadingButtonEnabled(false);
    return this.calculationService.removeLoads(loads, this.context).pipe(
      tap((response) => {
        this.handlePositionerResponse(response);
      })
    );
  }

  public moveLoad(movement: LoadMovement): Observable<PositionerResponse> {
    this.setLoadingButtonEnabled(false);
    return this.calculationService
      .moveLoad(movement, this.context)
      .pipe(tap((response) => this.handlePositionerResponse(response)));
  }

  public addLoadAmount(loadAmount: LoadEventObject[]): Observable<PositionerResponse> {
    const context = this.contextService.getCurrentContext();
    this.setLoadingButtonEnabled(false);
    const results: Observable<PositionerResponse>[] = [];
    results.push(this.calculationService.addLoads(loadAmount, context));
    console.log('calculationService.addLoads context', context);
    return of(...results)
      .pipe(concatAll())
      .pipe(tap((response) => this.handlePositionerResponse(response)));
  }

  public reloadLoads(): Observable<PositionerResponse> {
    const context = this.contextService.getCurrentContext();
    this.setLoadingButtonEnabled(false);
    const results: Observable<PositionerResponse>[] = [];
    results.push(this.calculationService.reloadLoads(context));
    console.log('calculationService.reloadLoads context', context);
    return of(...results)
      .pipe(concatAll())
      .pipe(tap((response) => this.handlePositionerResponse(response)));
  }

  /**
   * // FIXME dodać typ
   *
   * @param response
   */
  public handleAddToMyGarageResponse(response: StatusResponse<void>) {
    this.garageService.update();

    if (response.status === 'success') {
      this.messenger.snackSuccess({
        title: 'Układ został zapisany w Twoim garażu',
        actionText: 'Ok'
      });
    } else {
      this.messenger.snackError({
        title: 'Coś poszło nie tak - układ nie został dodany do Twojego garażu',
        actionText: 'Ok'
      });
    }
  }

  public lockContext(lock: boolean) {
    this.isInLockedMode = lock;
  }

  public init(camera: Camera, scene: MyScene, canvas: HTMLCanvasElement) {
    this.registerContextMenuActionHandlers();
    this.initLabels(canvas, scene, camera);
  }

  public bindDragControlsLoads(loads: Load[], director: SceneDirector) {
    this.transformControlsService.bindLoads(loads, director);
  }

  public getCurrentSphericalRadius(): number {
    return this.orbitControlsService.getControls().getSpherical().radius;
  }
  public getCurrentSpherical(): Spherical {
    return this.orbitControlsService.getControls().getSpherical();
  }

  public saveToMyGarage(img: ScaledImage, context: VehicleContext): Observable<StatusResponse<void>> {
    if (img.error) {
      // console.log('Failed to scale Scene image. ', img.error);
    }
    return this.garageService.saveToMyGarage(context, img.data);
  }

  // public initFlooringLevel(context: VehicleContext) {
  // this.flooringLevelService.setLevel(
  // context,
  // context?.getVehicle()?.flooringLevel || 100
  // );/
  // this.flooringLevelService.drawLabel();
  // }

  public setLabelRendererSize(width: number, height: number) {
    this.labelService.setSize(width, height);
  }

  public renderLabels() {
    this.labelService.render();
  }

  public handlePositionerResponse(response: PositionerResponse) {
    if (!this.historyService.hasHistory(this.context.getUuid())) {
      this.historyService.initIfEmpty(this.contextFactory.clone(this.context, true));
    }
    const newContext = this.contextService.handleResponse(response);
    this.historyService.pushState(this.contextFactory.clone(newContext, true));
  }

  public dragEnable(value: boolean) {
    if (this.context) {
      if (value === true) {
        this.orbitControlsService.disableAllButZoom();
      } else {
        this.refreshView();
        this.orbitControlsService.enable();
      }
    }
  }

  public dragModelChange(model: TransformControlsServiceCommunicationModel) {
    const load = model.load;
    const controls = this.orbitControlsService.getControls();
    const distance = controls.object.position.distanceTo(load.boundingBox.max);
    this.colliderDetector.detect(load, this.context, model.allLoads, model.meshes, distance);
    this.labelService.updateLabels();
    this.refreshView();
  }

  public dragEnd(model: TransformControlsServiceCommunicationModel): Observable<PositionerResponse> {
    //console.log('dragEnd called', model);

    const load = model.load;

    const validator = new MeshPositionerValidator(this.context.getVehicle(), model.allLoads, load, model.movement);
    validator.validate();
    this.labelService.updateLabels();

    const position = load.mesh.position.clone();
    position.x -= (load.cuboidHull.length * Config.DIMENSION_SCALING) / 2;
    position.y -= (load.cuboidHull.height * Config.DIMENSION_SCALING) / 2;
    position.z -= (load.cuboidHull.width * Config.DIMENSION_SCALING) / 2;
    const inSpace = validator.inVehicle();
    let spaceId = '';
    if (inSpace) {
      spaceId = inSpace.uuid;
      const spaceWorldPosition = inSpace.mesh.meshObj.getWorldPosition(new Vector3());
      position.sub(spaceWorldPosition);
    }
    position.x = Math.round(position.x / Config.DIMENSION_SCALING);
    position.y = Math.round(position.y / Config.DIMENSION_SCALING);
    position.z = Math.round(position.z / Config.DIMENSION_SCALING);

    const movement = new LoadMovement(load, new Position(position), spaceId);

    //return EMPTY;
    return this.moveLoad(movement);
  }

  public handleHistoryResponse(history: HistoryResponse) {
    const response = history.calculation;

    const context = this.context;
    if (!context?.getVehicle() && response.vehicle) {
      context?.setVehicle(this.vehicleFactory.recreate(response.vehicle));
    }
    context?.setAddedLoads([]);
    context?.setCurrentTimestamp(response.timestamp);

    context?.setStatistics(response.statistics);
    context?.setHistoryUuid(response.historyUuid);
    context?.setInitialTimestamp(response.initialTimestamp);

    context?.setSuggestedVehicles(response.suggestedVehicles?.map((v) => this.vehicleFactory.recreate(v)));

    this.setLoadingButtonEnabled(true);
  }

  /**
   * Wykonanie akcji z menu kontekstowego wg zarejestrowanych funkcji do obsługi
   *
   * @param model ContextMenuServiceCommunicationModel
   */
  public executeContextAction(model: ContextMenuServiceCommunicationModel): Observable<PositionerResponse> {
    if (model && typeof this.contextMenuActionHandlers[model.action] !== 'undefined') {
      const loads = model.selectedLoads;
      const value = model.value;

      this.refreshView();
      return this.contextMenuActionHandlers[model.action](loads, value);
    } else {
      // console.log(
      // 'scene.service.ts: executeContextAction, uundefined action or model',
      // model
      // );
    }
    return EMPTY;
  }

  public initOrbitControls(camera: Camera, renderer: WebGLRenderer): Observable<OrbitControls> {
    this.orbitControlsService.init(camera, renderer.domElement);

    return this.orbitControlsService.modelChanged();
  }

  public redrawContextWithLabels() {
    this.contextMenuService.close();

    if (this.context) {
      this.labelService.setData(
        this.context,
        this.context.getAllLoads(),
        this.orbitControlsService.getControls().getSpherical().radius
      );
      this.context.setOrbitControlsState(this.orbitControlsService.getCurrentControlsState());
    }
    this.refreshView();
  }

  public addLDMHighlight(space?: Space) {
    this.highlightLDM.next(space);
  }

  get freeFloorHighlight$() {
    return this.highlightLDM.asObservable();
  }

  public requestSceneImg() {
    this.sceneImgRequest$.next();
  }

  public onSceneImgRequest(): Observable<void> {
    return this.sceneImgRequest$.asObservable();
  }

  public returnSceneImg(imgData: SceneImage) {
    this.sceneImgResponse$.next(imgData);
  }

  public onSceneImgResponse(): Observable<SceneImage> {
    return this.sceneImgResponse$.asObservable();
  }

  public exportCurrentContext() {
    this.contextService.startExport([this.context]);
  }

  public loadPallets(pallets: VehicleContext[]): Observable<PositionerResponse> {
    console.debug('scene.service.ts -> loadPallets()', pallets);
    const context = this.contextService.getCurrentContext();
    this.setLoadingButtonEnabled(false);

    const loads = pallets
      .map((pallet) => this.vehicleToLoadTransformer.transform(pallet.getVehicle(), pallet.getUuid()))
      .filter((x) => !!x);
    const addEvents = loads.map((load) => new LoadEventObject({ cnt: 1, load }));

    return this.calculationService
      .addLoads(addEvents, context)
      .pipe(tap((response) => this.handlePositionerResponse(response)));
  }

  private initLabels(canvas: HTMLCanvasElement, scene: MyScene, camera: Camera) {
    this.labelService.init(canvas, scene, camera);
  }

  private showCalculationSummary(summary: PositionerResponse[]) {
    this.loadComponentService.clear('scene.service.ts - showCalculationSummary');
    const cr = this.loadComponentService.add(CalculationSummaryComponent, '#scene-container');
    cr.instance.items = summary;
  }

  /**
   * Definicja funkcji podpiętych pod akcje menu kontekstowego ładunku.
   */
  private registerContextMenuActionHandlers() {
    this.contextMenuActionHandlers.open = () => EMPTY;
    this.contextMenuActionHandlers.rotateHorizontal = (loads: Load[]) => this.rotateHorizontal(loads);

    this.contextMenuActionHandlers.rotateVertical = (loads: Load[]) => this.rotateVertical(loads);

    this.contextMenuActionHandlers.changeColor = (loads: Load[], value: number) => {
      loads.forEach((load) => {
        load.color = value;
      });
      return this.updateLoads(loads);
    };

    this.contextMenuActionHandlers.changeSize = (loads: Load[], value: ChangeLoadSize) => {
      const load = loads[0];
      if (!(load instanceof Cuboid)) {
        return EMPTY;
      }
      let needsReload = false;
      const changedLoads = loads.map((l) => {
        const updated = this.loadFactory.recreateLoad({ ...l, ...value });
        if (l.requireReloadWhenChangedTo(updated)) {
          needsReload = true;
        }
        return updated;
      });
      if (!needsReload) {
        /*// rozmiar bez zmian, aktualizacja tylko wagi
        loads.forEach((load) => {
          load.weight = value.weight;
        });*/
        return this.updateLoads(changedLoads);
      } else {
        return this.changeSize(changedLoads);
      }
    };

    this.contextMenuActionHandlers.changeFlooring = (loads: Load[], value: ChangeFlooring) => {
      const changedLoads = loads.map((l) => {
        const updated = this.loadFactory.recreateLoad(l);
        updated.floorableTop = value.floorableTop;
        updated.floorableBottom = value.floorableBottom;
        return updated;
      });
      return this.changeSize(changedLoads);
    };

    this.contextMenuActionHandlers.copy = (loads: Load[], value: any) =>
      loads.length === 1 ? this.copyLoad(loads[0], value) : EMPTY;

    this.contextMenuActionHandlers.delete = (loads: Load[]) => this.removeLoads(loads);

    this.contextMenuActionHandlers.close = () => EMPTY;
  }
}
