import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { SceneService } from './../scene.service';

@Component({
  selector: 'app-scene-options',
  templateUrl: './scene-options.component.html',
  styleUrls: ['./scene-options.component.scss']
})
export class SceneOptionsComponent implements OnInit, OnDestroy {
  @Input() context: VehicleContext;
  private unsubscribe$ = new Subject<void>();

  protected labelViewVisible = false;

  constructor(private sceneService: SceneService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected newContext() {
    this.sceneService.addVehicleContextAndSwitch(undefined);
  }

  protected contextExists(): boolean {
    if (this.context !== undefined) {
      return this.context.getVehicle() !== null;
    }
    return false;
  }

  protected copyContext() {
    this.sceneService
      .cloneContext()
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((context) => this.sceneService.addVehicleContextAndSwitch(context))
      )
      .subscribe();
  }

  protected isNotEmptyContext() {
    return this.context && this.context.getVehicle() && this.context.getVehicle().type !== 'empty';
  }

  protected showLoadLabelsOptions() {
    return this.isNotEmptyContext() && this.context.getAllLoads().length <= 1000;
  }

  protected isContextAVehicle(): boolean {
    return this.isNotEmptyContext() && this.context.getVehicle().isCar;
  }

  protected showGridSettings(): void {
    this.sceneService.showGridSettings();
  }

  protected toggleLabelView(): void {
    this.labelViewVisible = !this.labelViewVisible;
  }

  protected showVehicleSettings(): void {
    this.sceneService.showVehicleSettings();
  }

  protected showAxlesSettings(): void {
    this.sceneService.showAxlesSettings();
  }
}
