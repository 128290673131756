import { Load } from 'src/app/load/lib/load';
import { FlooringLabel } from './FlooringLabel';
import { Constants as Config } from 'src/app/config/constants';

export class ProtrusionBottomLabel extends FlooringLabel {
  updatePosition(load: Load): void {
    const position = load.mesh.position.clone();
    position.y -= (load.cuboidHull.height / 2 - 65) * Config.DIMENSION_SCALING;
    this.setPosition(position);
  }

  updateCssClass(): void {}

  makeCopy(): ProtrusionBottomLabel {
    return new ProtrusionBottomLabel(this.getText(), this.textScale);
  }
}
