<div class="modal-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Ustawienia zajętości</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="flex-columns">
          <mat-form-field appearance="fill">
            <mat-label i18n>Jednostki zajętości: </mat-label>
            <mat-select formControlName="floorUnit" required>
              <mat-option *ngFor="let unit of floorUnits" [value]="unit.value">
                {{ unit.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-slide-toggle formControlName="showPercentage" i18n>Pokazuj
            zajętość również procentowo</mat-slide-toggle>
        </div>

        <div class="buttons mt-1">
          <button mat-raised-button type="submit" [disabled]="!form.valid"
            class="btn-primary">
            <span i18n>Zapisz </span>
            <mat-icon *ngIf="isLoading">
              <mat-spinner class="progress" color="accent" diameter="24">
              </mat-spinner>
            </mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>