import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { LoadEventObject } from './lib/load-event-object';
import { LoadService } from './load.service';

@Component({
  selector: 'app-load-module',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss']
})
export class LoadComponent implements OnDestroy {
  eventsSubject: Subject<void> = new Subject<void>();
  public saveForm: UntypedFormGroup;
  public fb: UntypedFormBuilder = new UntypedFormBuilder();

  public listType = 'none'; // system | user | none - lista typowe lub własne lub widok wyboru

  private unsubscribe$ = new Subject<void>();

  constructor(private service: LoadService) {
    this.saveForm = this.fb.group({
      name: [null, Validators.required]
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  //addLoad to calculation
  protected addLoad(eventObject: LoadEventObject[]) {
    this.service.addLoadAmount(eventObject).pipe(takeUntil(this.unsubscribe$)).subscribe();
  }
}
