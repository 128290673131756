export class Constants {
  //static readonly SCENE_FLOOR_Y = -1250;
  static readonly DIMENSION_SCALING = 0.01;

  static readonly MAX_CONTEXTS = 100;
  static readonly GRID_SIZE = 50000 * this.DIMENSION_SCALING;
  static readonly GRID_DIVISIONS = 25;

  static readonly MAIN_BG = 0xffffff;
  static readonly MAIN_BG_DARK = 0x31383f;
  static GRID_COLOR = 0xcccccc;
  static VEHICLE_BORDERS_COLOR = 0x333333;

  static readonly LAYER_CAMERA = 0;
  static readonly LAYER_CAM_AND_MOUSEPICK = 1;
  static readonly LAYER_COLLISIONS = 2;

  // zaokrągla nie zmieniając skali, ale uwzględniając jej dokładność
  public static roundToScale(num: number) {
    return Math.round((num + Number.EPSILON) * (1 / Constants.DIMENSION_SCALING)) / (1 / Constants.DIMENSION_SCALING);
  }

  // skaluje rozmiar z używanego na serwerze do rysowanego
  public static scale(num: number) {
    return num * Constants.DIMENSION_SCALING;
  }

  public static getBit(bits: number, mask: number): boolean {
    return (bits & mask) !== 0;
  }

  public static setBit(bits: number, mask: number, value: boolean): number {
    if (value) {
      return bits | mask;
    }
    return bits & ~mask;
  }
}
