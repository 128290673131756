import { Injectable } from '@angular/core';
import { Camera, Vector3, Group } from 'three';
import { UiService } from '../services/ui.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LabelComponentModel } from './label.component.model';
import { LabelRenderer } from '../lib/text-label/LabelRenderer';
import {
  TextLabel,
  LoadCenterLabel,
  LoadLengthLabel,
  LoadWidthLabel,
  LoadHeightLabel,
  FlooringTopLabel,
  FlooringBottomLabel,
  ProtrusionBottomLabel,
  FlooringLevelLabel,
  GridAxisLabel,
  AxleLabel
} from '../lib/text-label';
import { MyScene } from '../scene/lib/MyScene';
import { VehicleContext } from '../lib/model/vehicle-context';
import { Grid3DLines } from '../lib/helper/grid-3d-lines';
import { Load } from '../load/lib/load';
import { VectorMesh } from '../vehicle/space/lib/vector-mesh';
import { VectorLengthLabel, VectorHeightLabel, VectorWidthLabel } from '../lib/text-label/vector';
import { Constants as Config } from '../config/constants';
import { Space } from '../vehicle/space/lib/space';
import { Axle } from '../vehicle/axles/lib/axle';

@Injectable({
  providedIn: 'root'
})
export class LabelService {
  private canvas: HTMLCanvasElement;

  private shouldRefresh = new BehaviorSubject<boolean>(null);
  private labelRenderer: LabelRenderer;
  private scene: MyScene;
  private camera: Camera;
  private loadLabels: Group;
  private spaceLabels: Group;
  private gridAxisLabels: Group;
  private matrixLabels: Group;

  private loads: Load[];
  private context: VehicleContext;
  private radius: number;
  private vector: VectorMesh;

  private scale = 1.0;

  private cache: LabelCachePool = new LabelCachePool();

  private model = new BehaviorSubject<LabelComponentModel>(new LabelComponentModel());

  constructor(private uiService: UiService) {}

  public init(canvas: HTMLCanvasElement, scene: MyScene, camera: Camera, scale = 1.0) {
    this.canvas = canvas;
    this.scene = scene;
    this.camera = camera;
    this.scale = scale;

    this.labelRenderer = new LabelRenderer();
    this.adjustRendererDimensions();
    this.labelRenderer.attach();

    this.reinitializeLoadRelatedLabels();
    this.reinitializeSpaceRelatedLabels();
    this.reinitializeMatrixLabels();

    this.render();
  }

  public getModel(): Observable<LabelComponentModel> {
    return this.model.asObservable();
  }

  public setModel(model: LabelComponentModel) {
    this.model.next(model);
    this.cache.clearUsageByTypes([
      LoadCenterLabel.name,
      LoadLengthLabel.name,
      LoadHeightLabel.name,
      LoadWidthLabel.name,
      FlooringTopLabel.name,
      FlooringBottomLabel.name,
      ProtrusionBottomLabel.name
    ]);
    this.drawLoadRelatedLabels();
    this.shouldRefresh.next(true);
  }

  public initModel(model: LabelComponentModel) {
    this.model.next(model);
  }

  public setData(context: VehicleContext, loads: Load[], radius: number) {
    //console.log('set Data called', loads.length);
    this.context = context;
    this.loads = loads;
    this.radius = radius;
    this.cache.clearUsage();
    this.updateLabels();
  }

  public showMatrixLabels(vector: VectorMesh) {
    this.vector = vector;
    this.drawMatrixLabels();
    this.shouldRefresh.next(true);
  }

  public getAllLabels(): TextLabel[] {
    return []
      .concat(this.loadLabels.children)
      .concat(this.spaceLabels.children)
      .concat(this.gridAxisLabels.children)
      .concat(this.matrixLabels.children);
  }

  public shouldRefreshInvoked(): Observable<boolean> {
    return this.shouldRefresh.asObservable();
  }

  public setSize(width, height) {
    this.labelRenderer.setSize(width, height);
  }

  public updateLabels() {
    if (!this.scene) {
      return;
    }
    //console.log('update Labels Called');
    this.adjustRendererDimensions();
    this.drawLoadRelatedLabels();
    this.drawSpaceRelatedLabels();
    this.drawMatrixLabels();
  }

  public render() {
    //console.log('render labels');
    if (this.labelRenderer) {
      this.labelRenderer.render(this.scene.getSceneObject(), this.camera);
    }
  }

  private drawMatrixLabels() {
    this.reinitializeMatrixLabels();
    if (!this.vector) {
      return;
    }
    const lengthLabel = new VectorLengthLabel(
      '' + this.uiService.getLengthInCurrentUnit(this.vector.length),
      this.scale
    );
    const heightLabel = new VectorHeightLabel(
      '' + this.uiService.getLengthInCurrentUnit(this.vector.height),
      this.scale
    );
    const widthLabel = new VectorWidthLabel('' + this.uiService.getLengthInCurrentUnit(this.vector.width), this.scale);
    lengthLabel.updatePosition(this.vector);
    heightLabel.updatePosition(this.vector);
    widthLabel.updatePosition(this.vector);
    this.addLabel(this.matrixLabels, lengthLabel);
    this.addLabel(this.matrixLabels, heightLabel);
    this.addLabel(this.matrixLabels, widthLabel);
  }

  private drawLoadRelatedLabels() {
    const settings = this.model.value;
    this.reinitializeLoadRelatedLabels();
    if (!settings.displayLoadRelatedLabels() || this.loads.length > 1000) {
      return;
    }
    for (const load of this.loads) {
      if (!load.mesh || !load.mesh?.obj?.visible) {
        continue;
      }
      this.drawLoadCenterLabels(load);
      if (settings.showDimensions) {
        this.drawLoadDimensions(load);
      }
      if (settings.showFlooring) {
        this.drawLoadFlooring(load);
      }
    }
  }

  private drawSpaceRelatedLabels() {
    this.reinitializeSpaceRelatedLabels();
    this.drawAxleLabels();

    for (const space of this.context.getVehicle().enabledSpaces) {
      if (space.mesh && this.context.getSettings().grid.show && this.context.getSettings().grid.showLabels) {
        const grids = space.mesh.getGridHelpers();
        if (grids.length > 0) {
          this.drawGridAxisLabels(grids[0]);
        }
      }

      if (!space.settings || !space.settings.maxHeightEnabled) {
        continue;
      }
      const label = new FlooringLevelLabel(this.getFlooringLevelLabelText(space), this.scale);
      label.updatePosition(space);
      this.addLabel(this.spaceLabels, label);
    }
  }

  private drawGridAxisLabels(grid: Grid3DLines) {
    const absoluteGridPosition = new Vector3();
    grid.obj.getWorldPosition(absoluteGridPosition);
    grid.xLabelsPositions.forEach((pos) => {
      const absPos = new Vector3(
        absoluteGridPosition.x + pos.x,
        absoluteGridPosition.y + pos.y,
        absoluteGridPosition.z + pos.z
      );
      const txt =
        this.uiService.convertLengthToUnit(pos.x / Config.DIMENSION_SCALING, this.context.getSettings().grid.unit) +
        '\n' +
        this.context.getSettings().grid.unit;
      const label = new GridAxisLabel(txt, this.scale);
      label.setPosition(absPos);
      this.addLabel(this.gridAxisLabels, label);
    });
    grid.yLabelsPositions.forEach((pos) => {
      const absPos = new Vector3(
        absoluteGridPosition.x + pos.x,
        absoluteGridPosition.y + pos.y,
        absoluteGridPosition.z + pos.z
      );
      const txt =
        this.uiService.convertLengthToUnit(pos.y / Config.DIMENSION_SCALING, this.context.getSettings().grid.unit) +
        '\n' +
        this.context.getSettings().grid.unit;
      const label = new GridAxisLabel(txt, this.scale);
      label.setPosition(absPos);
      this.addLabel(this.gridAxisLabels, label);
    });
    grid.zLabelsPositions.forEach((pos) => {
      const absPos = new Vector3(
        absoluteGridPosition.x + pos.x,
        absoluteGridPosition.y + pos.y,
        absoluteGridPosition.z + pos.z
      );
      const txt =
        this.uiService.convertLengthToUnit(pos.z / Config.DIMENSION_SCALING, this.context.getSettings().grid.unit) +
        '\n' +
        this.context.getSettings().grid.unit;
      const label = new GridAxisLabel(txt, this.scale);
      label.setPosition(absPos);
      this.addLabel(this.gridAxisLabels, label);
    });
  }

  private drawAxleLabels() {
    const vehicle = this.context.getVehicle();
    if (!vehicle.mesh) {
      return;
    }
    for (const axle of vehicle.axles) {
      const label = new AxleLabel(this.getAxleLabelText(axle, $localize`oś pojazdu`), this.scale);
      label.updatePosition(axle, vehicle.mesh.position.x);
      this.addLabel(this.spaceLabels, label);
    }

    for (const space of vehicle.spaces) {
      for (const axle of space.axles) {
        const label = new AxleLabel(this.getAxleLabelText(axle, $localize`oś naczepy`), this.scale);
        label.updatePosition(axle, vehicle.mesh.position.x + space.mesh.position.x);
        this.addLabel(this.spaceLabels, label);
      }
    }
  }

  private drawLoadCenterLabels(load: Load) {
    const txt = this.getLoadCenterLabelsText(load).join('\n');
    let label: LoadCenterLabel = this.cache.get(txt, LoadCenterLabel.name);
    if (!label) {
      label = this.cache.add(new LoadCenterLabel(txt, this.scale));
    }
    label.updatePosition(load);
    this.addLabel(this.loadLabels, label);
  }

  private drawLoadDimensions(load: Load) {
    const loadLLabel = new LoadLengthLabel(
      '' + this.uiService.getLengthInCurrentUnit(load.cuboidHull.length),
      this.scale
    );
    const loadHLabel = new LoadHeightLabel(
      '' + this.uiService.getLengthInCurrentUnit(load.cuboidHull.height),
      this.scale
    );
    const loadWLabel = new LoadWidthLabel(
      '' + this.uiService.getLengthInCurrentUnit(load.cuboidHull.width),
      this.scale
    );
    loadLLabel.updatePosition(load);
    loadHLabel.updatePosition(load);
    loadWLabel.updatePosition(load);
    this.addLabel(this.loadLabels, loadLLabel);
    this.addLabel(this.loadLabels, loadHLabel);
    this.addLabel(this.loadLabels, loadWLabel);
  }

  private drawLoadFlooring(load: Load) {
    const fTopLabel = new FlooringTopLabel(this.getFlooringTopLabelText(load), this.scale);
    const fBottomLabel = new FlooringBottomLabel(this.getFlooringBottomLabelText(load), this.scale);
    fTopLabel.update(load);
    fBottomLabel.update(load);
    this.addLabel(this.loadLabels, fTopLabel);
    this.addLabel(this.loadLabels, fBottomLabel);
    /*
     // wyłączone pokazywanie nawisu
    if (load.floorableBottom) {
      const fProtrusionBottomLabel = new ProtrusionBottomLabel(
        this.getProtrusionBottomLabelText(load),
        this.scale
      );
      fProtrusionBottomLabel.update(load);
      this.addLabel(this.loadLabels, fProtrusionBottomLabel);
    }
      */
  }

  private addLabel(group: Group, label: TextLabel) {
    label.setRadius(this.radius);
    group.add(label);
  }

  private reinitializeLoadRelatedLabels() {
    this.removeGroup(this.loadLabels);
    this.loadLabels = this.initGroup();
  }

  private reinitializeMatrixLabels() {
    this.removeGroup(this.matrixLabels);
    this.matrixLabels = this.initGroup();
  }

  private reinitializeSpaceRelatedLabels() {
    this.removeGroup(this.spaceLabels);
    this.spaceLabels = this.initGroup();
    this.removeGroup(this.gridAxisLabels);
    this.gridAxisLabels = this.initGroup();
  }

  /**
   * Rozmiar musi być aktualizowany w momencie dodawania ładunków.
   * Jeśli dodanie ładunku spowoduje pojawienie się pionowego paska przewijania (z powodu listy ładunków z lewej),
   * to rozmiar canvas się zmieni, więc trzeba zmienić i kontener etykiet, żeby nie pojawiał się dodatkowo poziomy pasek przewijania.
   */
  private adjustRendererDimensions() {
    if (!this.labelRenderer) {
      return;
    }
    this.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
  }

  private removeGroup(group: Group) {
    while (group && group.children.length > 0) {
      const object = group.children[0];
      object.parent.remove(object);
    }
    this.scene.remove(group);
  }

  private initGroup() {
    const group = new Group();
    this.scene.add(group);
    return group;
  }

  private get settings() {
    return this.model.value;
  }

  private getLoadCenterLabelsText(load: Load): string[] {
    const labels = [];
    if (this.settings.showNumber) {
      labels.push(load.idx);
    }
    if (this.settings.showName) {
      labels.push(load.name);
    }
    if (this.settings.showWeight) {
      labels.push(
        '(' + this.uiService.getWeightInCurrentUnit(load.weight) + ' ' + this.uiService.getCurrentWeightUnit() + ')'
      );
    }
    return labels;
  }

  private getFlooringLevelLabelText(space: Space): string {
    let txt = $localize`Poziom piętrowania` + '\n';
    txt += this.uiService.getLengthInCurrentUnit(space.settings.height) + ' ' + this.uiService.getCurrentLengthUnit();
    return txt;
  }

  private getAxleLabelText(axle: Axle, type: string): string {
    const text =
      type +
      '\n' +
      'offset: ' +
      this.uiService.getLengthInCurrentUnit(axle.offset) +
      this.uiService.getCurrentLengthUnit() +
      '\n' +
      this.uiService.getWeightInCurrentUnit(axle.pressure) +
      this.uiService.getCurrentWeightUnit();
    return text;
  }

  private getProtrusionBottomLabelText(load: Load): string {
    const text = load.protrusionLength + '%';
    return text;
  }

  private getFlooringBottomLabelText(load: Load): string {
    return load.floorableBottom ? 'get_app' : 'block';
  }
  private getFlooringTopLabelText(load: Load): string {
    return load.floorableBottom ? 'publish' : 'block';
  }
}

class LabelCachePool {
  private cache: { [key: string]: TextLabel[] } = {};
  private usage: { [key: string]: number } = {};

  public clearUsage() {
    this.usage = {};
  }

  public clearUsageByTypes(types: string[]) {
    Object.keys(this.usage).forEach((k) => {
      const parts = k.split(':');
      if (types.includes(parts[1])) {
        this.usage[k] = 0;
      }
    });
  }

  public clearCache() {
    this.clearUsage();
    this.cache = {};
  }

  public get<T extends TextLabel>(text: string, type: string): T | undefined {
    const key = this.getCacheKey(text, type);
    const entries = this.cache[key];
    const usage = this.usage[key] || 0;
    if (entries === undefined || entries.length == 0) {
      //console.log('label cache miss', type);
      return undefined;
    }
    if (entries.length <= usage) {
      //console.log('label cache make copy', type);
      return this.add(entries[0].makeCopy()) as T;
    }
    const label = entries[usage];
    this.incUsage(key);
    //console.log('label cache hit', type);
    return label as T;
  }

  public add<T extends TextLabel>(label: T): T {
    const key = this.getCacheKey(label.getText(), label.constructor.name);
    if (this.cache[key] === undefined) {
      this.cache[key] = [];
    }
    this.cache[key].push(label);
    this.incUsage(key);
    return label;
  }

  private incUsage(key: string) {
    if (this.usage[key] === undefined) {
      this.usage[key] = 0;
    }
    this.usage[key]++;
  }

  private getCacheKey(text: string, type: string): string {
    return `${type}:${text}`;
  }
}
