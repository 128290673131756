<div class="main-content-container"
  *ngIf="project$ | async as project; else projectList">
  <div class="main-menu-container">
    <app-menu></app-menu>
    <app-loadings-list></app-loadings-list>
  </div>

  <div class="app-main-scene">
    <app-scene class="app-scene" [project]="project"
      style="--label-fs-add: {{ labelFontModifier$ | async }}"></app-scene>
    <app-pending-loads-list class="pending-loads"></app-pending-loads-list>
  </div>
</div>
<ng-template #projectList>
  <app-projects></app-projects>
</ng-template>