import { Component } from '@angular/core';

@Component({
  selector: 'app-help-vertical-rotation',
  templateUrl: './vertical-rotation.component.html',
  styleUrls: ['./vertical-rotation.component.scss']
})
export class VerticalRotationComponent {
  constructor() {}
}
