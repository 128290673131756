import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, share, take, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { PositionerResponse } from '../lib/communication/positioner.response';
import { VehicleContext } from '../lib/model/vehicle-context';
import { environment } from '../../environments/environment';

import { ShareProject } from '../lib/model/share-project';
import { ShareProjectResponse } from '../lib/communication/share-project.response';
import { HistoryResponse } from '../lib/communication/history.response';
import { LoadEventObject } from '../load/lib/load-event-object';
import { Load } from '../load/lib/load';
import { HttpService } from '../services/http.service';
import { StatusResponse } from '../lib/communication/status.response';
import {
  RotateHorizontalRequest,
  RotateVerticalRequest,
  MoveLoadRequest,
  RemoveLoadsRequest,
  UpdateLoadRequest,
  HistoryRequest,
  ChangeSizeRequest,
  AddLoadsRequest,
  DuplicateVehicleRequest,
  ReloadLoadsRequest
} from './requests';
import { VehicleFactory } from '../vehicle/lib/vehicle-factory';
import { DebugPointsService } from '../lib/debug/debug-points.service';
import { LoadFactory } from '../load/lib/load-factory';
import { LoadMovement } from '../lib/communication/load-movement';

type SaveContextResponse = {
  calculation: PositionerResponse;
  updatedContexts: string[];
};

@Injectable({
  providedIn: 'root'
})
export class CalculationService extends HttpService {
  private positionerUrl = environment.apiUrl + '/positioner';
  private calculationsUrl = environment.apiUrl + '/positioner/calculations';
  private requestedContextUpdate = new Subject<string[]>();

  constructor(
    protected http: HttpClient,
    private vehicleFactory: VehicleFactory,
    private loadFactory: LoadFactory,
    private debugPointsService: DebugPointsService
  ) {
    super(http);
  }

  get requestedContextUpdate$(): Observable<string[]> {
    return this.requestedContextUpdate.asObservable();
  }

  public fetchCurrentCalculations(projectUuid: string): Observable<PositionerResponse[]> {
    const httpCall = this.http.get<any>(this.calculationsUrl + '/by-project/' + projectUuid).pipe(
      take(1),
      map((response) =>
        response.list.map((calc: any) => new PositionerResponse(calc, this.vehicleFactory, this.loadFactory))
      )
    );
    return httpCall;
  }

  public removeContext(uuid: string): Observable<StatusResponse<void>> {
    return this.http
      .delete(this.calculationsUrl + '/' + uuid)
      .pipe(map((response: any) => new StatusResponse<void>(response.status, null)));
  }

  public removeAllContextsInProject(projectId: string): Observable<StatusResponse<void>> {
    return this.http
      .delete(this.calculationsUrl + '/by-project/' + projectId)
      .pipe(map((response: any) => new StatusResponse<void>(response.status, null)));
  }

  public saveContext(context: VehicleContext): Observable<PositionerResponse> {
    return this.http.post(this.calculationsUrl, context).pipe(
      take(1),
      tap((response: SaveContextResponse) => {
        if ((response.updatedContexts || []).length > 0) {
          this.requestedContextUpdate.next(response.updatedContexts);
        }
      }),
      map(
        (response: SaveContextResponse) =>
          new PositionerResponse(response.calculation, this.vehicleFactory, this.loadFactory)
      )
    );
  }

  public rotateHorizontal(rotateLoads: Load[], context: VehicleContext): Observable<PositionerResponse> {
    const request = new RotateHorizontalRequest(rotateLoads, context);
    const httpCall = this.http
      .post<any>(this.positionerUrl + '/change-load-rotation', request)
      .pipe(map((response) => new PositionerResponse(response, this.vehicleFactory, this.loadFactory)));
    return httpCall;
  }

  public rotateVertical(rotateLoads: Load[], context: VehicleContext): Observable<PositionerResponse> {
    const request = new RotateVerticalRequest(rotateLoads, context);
    const httpCall = this.http
      .post<any>(this.positionerUrl + '/change-load-rotation', request)
      .pipe(map((response) => new PositionerResponse(response, this.vehicleFactory, this.loadFactory)));
    return httpCall;
  }

  public moveLoad(movement: LoadMovement, context: VehicleContext): Observable<PositionerResponse> {
    const request = new MoveLoadRequest(movement, context);

    const httpCall = this.http
      .post<any>(this.positionerUrl + '/move-load', request)
      .pipe(map((response) => new PositionerResponse(response, this.vehicleFactory, this.loadFactory)));
    return httpCall;
  }

  public removeLoads(removeLoads: Load[], context: VehicleContext): Observable<PositionerResponse> {
    const request = new RemoveLoadsRequest(removeLoads, context);
    const httpCall = this.http
      .post<any>(this.positionerUrl + '/remove-loads', request)
      .pipe(map((response) => new PositionerResponse(response, this.vehicleFactory, this.loadFactory)));

    return httpCall;
  }

  /**
   * Aktualizacja pojedycznego ładunku bez zmiany rozmieszczenia
   *
   * @param changeLoad Load
   * @param context VehicleContext
   */
  public updateLoads(changeLoads: Load[], context: VehicleContext): Observable<PositionerResponse> {
    const request = new UpdateLoadRequest(changeLoads, context);
    const httpCall = this.http
      .post<any>(this.positionerUrl + '/update-load', request)
      .pipe(map((response) => new PositionerResponse(response, this.vehicleFactory, this.loadFactory)));
    return httpCall;
  }

  public history(
    currentContext: VehicleContext,
    currentTiemstamp: number,
    action: string
  ): Observable<HistoryResponse> {
    const request = new HistoryRequest({
      uuid: currentContext.getUuid(),
      current: currentTiemstamp,
      action
    });
    const httpCall = this.http
      .post<any>(environment.apiUrl + '/database/calculation/history/', request)
      .pipe(map((response) => new HistoryResponse(response)));
    return httpCall;
  }

  public setHistoryHead(context: VehicleContext): Observable<PositionerResponse> {
    const httpCall = this.http
      .post<any>(environment.apiUrl + '/database/calculation/history/set-head/' + context.getHistoryUuid(), {})
      .pipe(map((response) => new PositionerResponse(response, this.vehicleFactory, this.loadFactory)));
    return httpCall;
  }

  public changeSize(changeLoads: Load[], context: VehicleContext): Observable<PositionerResponse> {
    const request = new ChangeSizeRequest(changeLoads, context);
    const httpCall = this.http
      .post<any>(this.positionerUrl + '/change-size', request)
      .pipe(map((response) => new PositionerResponse(response, this.vehicleFactory, this.loadFactory)));
    return httpCall;
  }

  public addLoads(loadBundle: LoadEventObject[], context: VehicleContext): Observable<PositionerResponse> {
    const request = new AddLoadsRequest(loadBundle, context);
    const httpCall = this.http.post<any>(this.positionerUrl + '/add-load', request).pipe(
      take(1),
      map((response) => new PositionerResponse(response, this.vehicleFactory, this.loadFactory))
    );
    return httpCall;
  }

  public reloadLoads(context: VehicleContext): Observable<PositionerResponse> {
    const request = new ReloadLoadsRequest(context);
    const httpCall = this.http.post<any>(this.positionerUrl + '/add-load', request).pipe(
      take(1),
      map((response) => new PositionerResponse(response, this.vehicleFactory, this.loadFactory))
    );
    return httpCall;
  }

  public multiplyVehicle(loads: Load[], context: VehicleContext): Observable<PositionerResponse[]> {
    const request = new DuplicateVehicleRequest(loads, context);
    const httpCall = this.http
      .post<PositionerResponse[]>(this.positionerUrl + '/add-all-loads-multiply-vehicle', request)
      .pipe(
        map((response) => response.map((calc) => new PositionerResponse(calc, this.vehicleFactory, this.loadFactory)))
      );
    return httpCall;
  }

  public autoPlaceLoads(loads: Load[], context: VehicleContext): Observable<PositionerResponse[]> {
    const request = new DuplicateVehicleRequest(loads, context);
    const httpCall = this.http
      .post<PositionerResponse[]>(this.positionerUrl + '/add-loads-auto', request)
      .pipe(
        map((response) => response.map((calc) => new PositionerResponse(calc, this.vehicleFactory, this.loadFactory)))
      );
    return httpCall;
  }

  public addAllLoads(loads: Load[], context: VehicleContext): Observable<PositionerResponse> {
    const request = new DuplicateVehicleRequest(loads, context);
    const httpCall = this.http
      .post<PositionerResponse>(this.positionerUrl + '/add-all-loads', request)
      .pipe(map((response) => new PositionerResponse(response, this.vehicleFactory, this.loadFactory)));
    return httpCall;
  }

  public shareProject(shareProject: ShareProject): Observable<ShareProjectResponse> {
    const httpCall = this.http
      .post<any>(this.positionerUrl + '/share-project', shareProject)
      .pipe(map((response) => new ShareProjectResponse(response)));
    return httpCall;
  }

  public clearLoads(context: VehicleContext): Observable<PositionerResponse> {
    const httpCall = this.http.post<any>(`${this.positionerUrl}/clear-loads/${context.getHistoryUuid()}`, {}).pipe(
      map((response) => {
        const calculation = new PositionerResponse(response, this.vehicleFactory, this.loadFactory);
        return calculation;
      })
    );
    return httpCall;
  }

  public getCalculation(historyUuid: string) {
    const httpCall = this.http
      .get<any>(this.calculationsUrl + '/' + historyUuid)
      .pipe(catchError(this.handleError('getCalculation', [])))
      .pipe(
        map((response) => new PositionerResponse(response, this.vehicleFactory, this.loadFactory)),
        share()
      );
    return httpCall;
  }
}
