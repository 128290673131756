import { Injectable } from '@angular/core';
import { TransformControls } from 'src/app/lib/vendor/three/TransformControls';
import { Constants as Config } from 'src/app/config/constants';

import { Box3, Mesh, Object3D, PerspectiveCamera, Raycaster, Vector2, Vector3, Event as THREEEvent } from 'three';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ContextMenuService } from '../context-menu/context-menu.service';
import { Vector } from '../lib/communication/vector';
import { Load } from '../load/lib/load';
import { UiService } from './ui.service';
import { SceneDirector } from '../scene/lib/SceneDirector';

interface TransformEventListeners {
  [key: string]: (event: THREEEvent) => void;
}

interface DocumentEventListeners {
  [kay: string]: (event: Event | MouseEvent | KeyboardEvent) => void;
}

export class TransformControlsServiceCommunicationModel {
  load: Load;
  allLoads: Load[];
  meshes: Object3D[];
  movement?: Vector;
}

@Injectable({
  providedIn: 'root'
})
export class TransformControlsService {
  private controls: TransformControls;
  private loads: Load[];
  // instance meshes + wydzielone
  private meshes: Mesh[];
  // meshes z każdego z ładunków pojedynczo dla collidera
  private singleMeshes: Mesh[];
  private instanceMap: Map<string, Load>;
  private loadMap: Map<string, Load>;
  private camera: PerspectiveCamera;
  private canvas: HTMLCanvasElement;

  private mouse: Vector2;

  private model = new Subject<TransformControlsServiceCommunicationModel>();
  private movement = new Subject<TransformControlsServiceCommunicationModel>();

  private enabled = new BehaviorSubject<boolean>(false);
  private hoveredLoads = new BehaviorSubject<Load[]>([]);
  private selectedLoads = new BehaviorSubject<Load[]>([]);

  private raycaster = new Raycaster();
  private director: SceneDirector;

  private dragPid: number = 0;

  private eventListeners: TransformEventListeners = {};
  private documentListeners: DocumentEventListeners = {};

  // reuse for current load
  private curBox = new Box3();
  // reuse for other load
  private othBox = new Box3();

  constructor(
    // private orbitControlsService: OrbitControlsService,
    private contextMenuService: ContextMenuService,
    private ui: UiService
  ) {
    //this.selectedLoads = [];
    this.mouse = new Vector2();
    this.raycaster.layers.set(Config.LAYER_CAM_AND_MOUSEPICK);
  }

  public getEnabled(): Observable<boolean> {
    return this.enabled.asObservable();
  }

  public getHoveredLoads(): Observable<Load[]> {
    return this.hoveredLoads.asObservable();
  }

  public updateHoveredLoads(loads: Load[]) {
    this.hoveredLoads.next(loads);
  }

  public getSelectedLoads(): Observable<Load[]> {
    return this.selectedLoads.asObservable();
  }

  public init(camera: PerspectiveCamera, canvas: HTMLCanvasElement) {
    this.camera = camera;
    this.canvas = canvas;
    this.controls = new TransformControls(this.camera, this.canvas);
    this.controls.enabled = true;
    this.controls.axis = 'XZ';
    this.controls.showY = false;
    this.controls.showX = false;
    this.controls.showZ = false;

    //this.controls.setMode('translate');
  }

  public bindLoads(loads: Load[], director: SceneDirector) {
    this.unbindEvents();
    this.loads = loads;
    this.director = director;
    this.meshes = [
      ...director.scene.instanceMeshes,
      ...Array.from(director.scene.loadMap).map(([key, load]) => load.mesh.obj)
    ];
    this.instanceMap = director.scene.instanceMap;
    this.loadMap = director.scene.loadMap;
    this.bindEvents();
    this.loads.forEach((l) => {
      l.mesh.obj.updateMatrixWorld();
      l.computeBoundingBox();
    });
    this.loads.sort((a, b) => {
      const worldPosA = a.boundingBox.min;
      const worldPosB = b.boundingBox.min;
      if (worldPosA.x === worldPosB.x) {
        if (worldPosA.z === worldPosB.z) {
          return worldPosA.y - worldPosB.y;
        }
        return worldPosA.z - worldPosB.z;
      }
      return worldPosA.x - worldPosB.x;
    });
    this.singleMeshes = this.loads.map((l) => l.mesh.obj);

    //this.controls.enableMultiMode(this.ui.loadMultiSelectEnabled);
  }

  public modelChanged(): Observable<TransformControlsServiceCommunicationModel> {
    return this.model.asObservable();
  }

  public dragEnd(): Observable<TransformControlsServiceCommunicationModel> {
    return this.movement.asObservable();
  }

  /**
   * Wykrywanie najechania myszką na ładunek / zjechania myszką z ładunku
   *
   * @param event
   */
  private onPointerMove(event: PointerEvent) {
    if (this.controls.dragging) {
      return;
    }
    var rect = this.canvas.getBoundingClientRect();

    this.mouse.x = ((event.clientX - rect.left) / this.canvas.clientWidth) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / this.canvas.clientHeight) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);

    const intersects = this.raycaster.intersectObjects(this.meshes, false);

    if (intersects.length > 0) {
      const instanceId = intersects[0].instanceId;
      let object = intersects[0].object;
      let selectedLoad: Load;
      if (instanceId < 0 || instanceId === undefined) {
        if (object.name !== 'load') {
          return;
        }
        selectedLoad = this.loadMap.get(object.uuid);
      } else {
        object.userData.currentInstance = instanceId;
        const key = `${object.id}-${instanceId}`;
        selectedLoad = this.instanceMap.get(key);
      }
      if (event.shiftKey) {
        console.log(
          'hover pos',
          instanceId,
          selectedLoad.mesh.obj.name,
          object.name,
          selectedLoad.mesh.obj.position.x,
          selectedLoad.mesh.obj.position.y,
          selectedLoad.mesh.obj.position.z
        );
      }

      if (!this.canDrag(selectedLoad)) {
        this.hoveredLoads.next([]);
        this.setCursor('auto');
        this.controls.detach();
        return;
      }
      this.controls.enabled = true;

      if (object !== this.controls.object) {
        this.controls.attach(object);
      }

      this.hoveredLoads.next([selectedLoad]);
      if (!event.ctrlKey) {
        this.setCursor('pointer');
      }
    } else {
      if (this.controls.object !== undefined) {
        this.hoveredLoads.next([]);
        this.setCursor('auto');

        this.enable(false);
        this.controls.detach();
      }
    }
  }

  /**
   * bounding box bez używania setFromObject
   * pozycja ustawiana z mesh
   * nie trzeba używać na nim roundBox - ma to już zrobione
   *
   * @param load Load
   * @param into Box3
   * @returns Box3
   */
  private boxFromLoad(load: Load, into: Box3): Box3 {
    const hull = load.cuboidHull;
    const pos = load.mesh.position;
    into.min.set(
      Config.roundToScale(pos.x - Config.scale(hull.length / 2)),
      Config.roundToScale(pos.y - Config.scale(hull.height / 2)),
      Config.roundToScale(pos.z - Config.scale(hull.width / 2))
    );
    into.max.set(
      Config.roundToScale(pos.x + Config.scale(hull.length / 2)),
      Config.roundToScale(pos.y + Config.scale(hull.height / 2)),
      Config.roundToScale(pos.z + Config.scale(hull.width / 2))
    );
    return into;
  }

  private canDrag(load: Load) {
    const box = this.boxFromLoad(load, this.curBox);
    const otherLoads = this.loads.filter((l) => l.uuid !== load.uuid);
    const epsilon = Config.DIMENSION_SCALING / 1000;

    for (const other of otherLoads) {
      const checkBox = this.boxFromLoad(other, this.othBox);
      const maxX = Math.min(checkBox.max.x, box.max.x);
      const minX = Math.max(checkBox.min.x, box.min.x);
      const intersectXlength = maxX - minX;

      const maxZ = Math.min(checkBox.max.z, box.max.z);
      const minZ = Math.max(checkBox.min.z, box.min.z);
      const intersectZlength = maxZ - minZ;
      if (
        intersectXlength > epsilon &&
        intersectZlength > epsilon &&
        checkBox.min.y + 1 * Config.DIMENSION_SCALING - box.max.y >= epsilon // obejście błędów zaokrąglenia
      ) {
        return false;
      }
    }
    return true;
  }

  private setCursor(type: 'pointer' | 'auto' | 'move' | 'magnet' | 'magnet-minus') {
    ['pointer', 'move', 'magnet', 'magnet-minus'].forEach((t) => {
      type !== t && this.canvas.classList.remove('cursor-' + t);
    });
    this.canvas.classList.add('cursor-' + type);
  }

  public getControls() {
    return this.controls;
  }

  private enable(val: boolean) {
    this.enabled.next(val);
    //this.controls.enabled = true;
  }

  // tutaj przy naciśniętym przycisku myszy object to pojedynczy Mesh
  private onDragMove(force = false) {
    if (!this.controls.object || !this.controls.dragging || this.controls.object.userData.instanceId !== undefined) {
      return;
    }
    const object = this.controls.object;
    //console.log('onDragMove pos', object.position.x, object.position.y, object.position.z);
    let load: Load;
    if (object.userData.groupKey) {
      load = this.instanceMap.get(object.userData.groupKey);
    } else {
      load = this.loadMap.get(object.uuid);
    }

    let movementVector: Vector = null;
    let moveVec: Vector3 = null;
    let distance: number = 0;
    if (object.userData.moveStartPoint) {
      const moveEnd: Vector3 = object.position.clone();
      moveVec = moveEnd.clone().sub(object.userData.moveStartPoint);
      movementVector = new Vector(moveVec.normalize());
      /*console.log(
        'move start',
        object.userData.moveStartPoint.x,
        object.userData.moveStartPoint.y,
        object.userData.moveStartPoint.z,
        'move end',
        moveEnd.x,
        moveEnd.y,
        moveEnd.z
      );*/
      distance = object.userData.moveStartPoint.distanceTo(moveEnd);
      if (distance !== 0) {
        object.userData.moveStartPoint = moveEnd;
      }
    }
    if (force || distance !== 0) {
      //console.log('onDragMove end', distance, object.position);

      if (object.userData.groupKey) {
        if (distance == 0) {
          this.director.scene.reconnectToInstance(load);
        } else {
          this.director.scene.disconnectFromInstance(load);
        }
        this.director.render();
      }
      this.model.next({
        load: load,
        allLoads: this.loads,
        meshes: this.singleMeshes,
        movement: movementVector
      });
    }
  }

  private bindEvents() {
    this.eventListeners.change = (event) => {
      //console.log('TransformControls change', event, this.controls.enabled);
      this.onDragMove();
    };
    this.eventListeners['dragging-changed'] = (event) => {
      /*console.log(
        'TransformControls dragging-changed',
        event.value,
        this.controls.enabled
      );*/
    };
    this.eventListeners.mouseDown = (event: any) => {
      //console.log('TransformControls mouseDown', event);
      const ctrl = event.pointer?.mouseEvent?.ctrlKey || false;
      let object = this.controls.object;

      let key: string;
      let selectedLoad: Load;
      if (object.userData.currentInstance !== undefined) {
        key = `${object.id}-${object.userData.currentInstance}`;
        selectedLoad = this.instanceMap.get(key);
      } else {
        selectedLoad = this.loadMap.get(object.uuid);
      }

      if (event.pointer.button === 0) {
        // left
        if (key) {
          this.director.scene.add(selectedLoad.mesh.obj);
          this.controls.dragging = false;
          this.controls.changeAttachmentOnPointerDown(selectedLoad.mesh.obj, event.pointer);
          this.controls.dragging = true;
          object = selectedLoad.mesh.obj;
          object.layers.enable(Config.LAYER_CAM_AND_MOUSEPICK);
          object.userData.groupKey = key;
        }

        const position = object.position.clone();
        object.userData.moveStartPoint = position.clone();
        object.userData.start = position.clone();
        object.userData.validPosition = position.clone();
        this.enable(true);
        if (!ctrl) {
          this.setCursor('move');
        }

        this.dragPid++;
        if (!this.ui.loadMultiSelectEnabled) {
          // no-op
        } else if (ctrl && !selectedLoad?.selected) {
          // TODO
          object.userData['drag'] = this.dragPid;
          this.toggleSelectedLoad(selectedLoad!!);
        } else if (!ctrl) {
          if (!this.isSelected(selectedLoad!!)) {
            this.deselectAll();
            // this.selectOneLoad(selectedLoad);
          }
        }
      } else if (event.pointer.button === 2) {
        // right
        let selectedLoads: Load[] = [];
        //console.log('right mouse button', ctrl);
        if (this.ui.loadMultiSelectEnabled && (ctrl || selectedLoad?.selected)) {
          //console.log('right selected', ctrl, selectedLoad.selected);
          this.toggleSelectedLoad(selectedLoad!!, true);
          selectedLoads = this.selectedLoads.value;
        } else {
          //this.selectOneLoad(selectedLoad);
          selectedLoads = selectedLoad ? [selectedLoad] : [];
        }
        this.contextMenuService.open(selectedLoads, event.pointer.mouseEvent);
      }
    };
    this.eventListeners.mouseUp = (event: any) => {
      //console.log('TransformControls mouseup', event);
      this.enable(false);
      this.onDragMove(true);
      const object = this.controls.object;

      this.setCursor(this.hoveredLoads.value.length ? 'pointer' : 'auto');
      if (object !== undefined && object.userData.start) {
        const ctrl = event.pointer.mouseEvent.ctrlKey;
        //TODO
        const start = object.userData.start;
        object.userData.start = null;
        const end = object.position.clone();
        const moveVec = new Vector3(end.x - start.x, end.y - start.y, end.z - start.z);
        const movement = new Vector({
          x: moveVec.x / Config.DIMENSION_SCALING,
          y: moveVec.y / Config.DIMENSION_SCALING,
          z: moveVec.z / Config.DIMENSION_SCALING
        });
        let load: Load;
        if (object.userData.groupKey) {
          const key = object.userData.groupKey; // `${object.id}-${object.userData.currentInstance}`;
          load = this.instanceMap.get(key);
        } else {
          load = this.loadMap.get(object.uuid);
        }

        if (!load) {
          return;
        }

        //console.log('movement', movement);
        if (Math.abs(movement.x) < 10 && Math.abs(movement.y) < 10 && Math.abs(movement.z) < 10) {
          if (object.userData.groupKey) {
            this.director.scene.reconnectToInstance(load);
            load.mesh.obj.layers.disable(Config.LAYER_CAM_AND_MOUSEPICK);
            this.director.scene.remove(load.mesh.obj); // usuwamy jeśli nie było ruchu. Jeśli był - kopia zostanie usunięta po redraw sceny
            this.director.render();
          }
          // odznaczamy tylko jeśli nie było ruchu i zaznaczenie nastąpiło w wyniku wcześniejszego kliknięcia niż bieżące
          // lub jeśli zaznaczony był pojedynczy ładunek
          if (ctrl && object.userData['drag'] !== this.dragPid && this.isSelected(load)) {
            this.toggleSelectedLoad(load, false);
          }
        } else {
          if (this.selectedLoads.value.length > 1) {
            return;
          }
          //load.mesh.position.add(moveVec);
          this.movement.next({
            load,
            allLoads: this.loads,
            meshes: this.singleMeshes,
            movement
          });
          //this.deselectAll();
        }
      }
      this.controls.detach();
    };
    this.eventListeners.objectChange = (event) => {};
    for (const event in this.eventListeners) {
      this.controls.addEventListener(event as any, this.eventListeners[event]);
    }

    this.documentListeners['pointermove'] = (event) => this.onPointerMove(event as PointerEvent);

    this.documentListeners['keydown'] = (event: KeyboardEvent) => {
      if (this.ui.loadMultiSelectEnabled && event.ctrlKey) {
        //if (event.shiftKey) {
        //  setCursor('magnet-minus');
        //} else {
        this.setCursor('magnet');
        //}
      } else {
        this.setCursor('auto');
      }
    };
    this.documentListeners['keyup'] = (event) => {
      this.setCursor('auto');
    };
    for (const event in this.documentListeners) {
      document.addEventListener(event, this.documentListeners[event]);
    }
  }

  public isSelected(load: Load) {
    return this.selectedLoads.value.findIndex((l) => l.uuid === load.uuid) >= 0;
  }

  public toggleSelectedLoad(load: Load, state?: boolean) {
    const idx = this.selectedLoads.value.findIndex((l) => l.uuid === load.uuid);
    //console.log('select load', load.idx, state);
    if (idx < 0 || state !== false) {
      //console.log('add selection');
      if (!this.isSelected(load)) {
        this.selectedLoads.next([...this.selectedLoads.value, load]);
      }
      load.select();
    } else if (!state && idx >= 0) {
      //console.log('remove selection');
      const loads = this.selectedLoads.value;
      loads.splice(idx, 1);
      load.unselect();
      this.selectedLoads.next(loads);
    }
    //this.controls.enableMove(this.selectedLoads.value.length <= 1);
    this.contextMenuService.close();
  }

  public selectOneLoad(load: Load) {
    this.selectedLoads.value.forEach((l) => {
      l.unselect();
    });
    load.select();
    this.selectedLoads.next([load]);
    //this.controls.enableMove(true);
    this.contextMenuService.close();
  }

  public deselectAll() {
    this.selectedLoads.value.forEach((load) => {
      load.unselect();
    });
    this.selectedLoads.next([]);
    this.contextMenuService.close();
  }

  private unbindEvents() {
    if (!this.controls) {
      return;
    }
    for (const event in this.eventListeners) {
      this.controls.removeEventListener(event as any, this.eventListeners[event]);
    }
    for (const event in this.documentListeners) {
      document.removeEventListener(event, this.documentListeners[event]);
    }
    this.eventListeners = {};
    this.documentListeners = {};
    //this.controls.dispose();
  }
}
