import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadComponentService } from '../services/load-component.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { VehicleContext } from '../lib/model/vehicle-context';
import { ContextService } from '../vehicle/context/context.service';

@Component({
  selector: 'app-mass-distribution-chart',
  templateUrl: './mass-distribution-chart.component.html',
  styleUrls: ['./mass-distribution-chart.component.scss']
})
export class MassDistributionChartComponent implements OnInit, OnDestroy {
  protected context$: Observable<VehicleContext>;
  protected massDistributionData: Number[];
  private unsubscribe$ = new Subject<void>();
  constructor(private componentService: LoadComponentService, private contextService: ContextService) {
    this.context$ = contextService.currentContext$;
    this.massDistributionData = [];
  }

  ngOnInit(): void {
    this.context$.pipe(takeUntil(this.unsubscribe$)).subscribe((context) => {});
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    this.componentService.clear('MassDistributionChartComponent');
  }
}
