import { Component, OnInit } from '@angular/core';
import { enterLeaveAnimation } from 'animations/animations';
import { InfoService } from './info.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  animations: [enterLeaveAnimation]
})
export class InfoComponent implements OnInit {
  constructor(public service: InfoService) {}

  ngOnInit(): void {
    // console.log('help.component.ts: init');
  }

  close() {
    this.service.close();
  }
}
