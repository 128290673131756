import { Load } from 'src/app/load/lib/load';
import { LoadDimensionLabel } from './LoadDimensionLabel';
import { Constants as Config } from 'src/app/config/constants';

export class LoadWidthLabel extends LoadDimensionLabel {
  public updatePosition(load: Load): void {
    const position = load.mesh.position.clone();
    position.x -= (load.cuboidHull.length / 2 - 20) * Config.DIMENSION_SCALING;
    position.y -= (load.cuboidHull.height / 2) * Config.DIMENSION_SCALING;
    this.setPosition(position);
  }

  makeCopy(): LoadWidthLabel {
    return new LoadWidthLabel(this.getText(), this.textScale);
  }
}
