import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { Box } from '../lib/box';
import { UiService } from 'src/app/services/ui.service';

import { Vector } from 'src/app/lib/communication/vector';

@Component({
  selector: 'app-box-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  @Output() addSpaceEvent = new EventEmitter<Box>();

  @Input() mode = 'mat-expansion-panel';
  @Input() events: Observable<void>;
  private item$ = new BehaviorSubject<Box>(this.item);
  @Input()
  set item(value: Box) {
    this.item$.next(value);
  }
  @Input() minSize: Vector;
  @ViewChild('search') searchElement: ElementRef;

  object: Box;

  public dataPanelOpenState = false;
  public form: UntypedFormGroup;
  public listOpened = false;

  private unsubscribe$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder, protected ui: UiService) {}

  ngOnInit(): void {
    this.createForm();
    this.events.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      // console.log('box/form.component.ts emit event to child received');

      this.onSubmit();
    });

    this.item$.subscribe((item) => {
      console.log('space/type/box/form.component.ts:ngOnInit subscribe to item', item);
      if (item) {
        this.object = item;
        this.fillForm(item);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  toggleList() {
    // console.log('toggleList called');
    this.listOpened = !this.listOpened;
    if (this.listOpened) {
      setTimeout(() => {
        // this.searchElement.nativeElement.focus();
      }, 0);
    }
  }

  fillForm(space: Box): void {
    (this.form.controls.width as UntypedFormControl)?.setValue(
      space.width ? this.ui.getLengthInCurrentUnit(space.width) : ''
    );
    (this.form.controls.length as UntypedFormControl)?.setValue(
      space.length ? this.ui.getLengthInCurrentUnit(space.length) : ''
    );
    (this.form.controls.height as UntypedFormControl)?.setValue(
      space.height ? this.ui.getLengthInCurrentUnit(space.height) : ''
    );
  }

  onSubmit() {
    if (!this.validateForm()) {
      const errors = this.getValidationErrors();
      // console.log('errors', errors);
      // console.log('form not valid');
    } else {
      const object = this.getObject();
      this.addSpaceEvent.emit(object);
    }
  }

  validateForm(): boolean {
    this.form.markAllAsTouched();
    const valid = this.form.valid;
    return valid;
  }

  getObject(): Box {
    const object = this.object;

    object.length = this.ui.getLengthInDefaultUnit(this.form.controls.length.value);
    object.length = this.ui.getLengthInDefaultUnit(this.form.controls.length.value);
    object.width = this.ui.getLengthInDefaultUnit(this.form.controls.width.value);
    object.height = this.ui.getLengthInDefaultUnit(this.form.controls.height.value);
    return object;
  }

  private getValidationErrors() {
    const errors: any = {};
    Object.keys(this.form.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        errors[key] = {};
        Object.keys(controlErrors).forEach((keyError) => {
          errors[key][keyError] = controlErrors[keyError];
        });
      }
    });
    return errors;
  }

  private createForm() {
    const lengthValidators = [Validators.required];
    if (this.minSize?.x) {
      lengthValidators.push(Validators.min(this.ui.getLengthInCurrentUnit(this.minSize.x)));
    }
    const heightValidators = [Validators.required];
    if (this.minSize?.y) {
      heightValidators.push(Validators.min(this.ui.getLengthInCurrentUnit(this.minSize.y)));
    }
    const widthValidators = [Validators.required];
    if (this.minSize?.z) {
      widthValidators.push(Validators.min(this.ui.getLengthInCurrentUnit(this.minSize.z)));
    }
    this.form = this.fb.group({
      length: [null, Validators.compose(lengthValidators)],
      width: [null, Validators.compose(widthValidators)],
      height: [null, Validators.compose(heightValidators)]
    });
  }
}
