import { Vector } from '../communication/vector';
import { Statistics } from './statistics';
import { v4 as uuidv4 } from 'uuid';
import { Load } from 'src/app/load/lib/load';
import { OrbitControlsState } from './orbit-controls-state';
import { Space } from 'src/app/vehicle/space/lib/space';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';
import { Settings } from 'src/app/vehicle/form/settings/settings';
import { Settings as GlobalSettings } from './settings';
import { LoadGroupList } from './load-group/load-group-list';
import { LoadGroup } from './load-group/load-group';
import { EmptyVehicle } from 'src/app/vehicle/type/empty-vehicle/lib/empty-vehicle';
import { LoadFactory } from 'src/app/load/lib/load-factory';
import { LoadingStep } from './loading-step';
// import { LoadingSpace } from 'src/app/loading-space/lib/loading-space';

export class VehicleContext {
  private uuid: string;
  private historyUuid: string;
  private vehicle: Vehicle;

  private loads: Load[];

  private addedLoads: Load[];
  private statistics: Statistics[];
  private suggestedVehicles: Vehicle[];

  private currentTimestamp: number;
  private initialTimestamp: number;

  private message: string;

  private orbitControlsState: OrbitControlsState;
  private projectId: string;
  private space: Space;
  private settings: Settings;

  private loadingSteps: LoadingStep[] = [];

  constructor(vehicle: Vehicle) {
    this.uuid = uuidv4();
    this.historyUuid = this.uuid;
    this.statistics = [];

    this.vehicle = vehicle;

    this.addedLoads = [];
    this.loads = [];
    // console.log('TODO!!!: Statistics');
    if (vehicle !== null && typeof vehicle !== 'undefined') {
      vehicle.spaces.forEach((space) => {
        this.statistics.push(
          new Statistics({
            fxLDM: space.length,
            fF: space.area,
            fV: space.volume,
            spaceUuid: space.uuid
          })
        );
      });
    }
    this.initialTimestamp = +new Date();
    this.settings = new Settings();
  }

  public generateHistoryUuid() {
    this.historyUuid = uuidv4();
  }

  public setSettings(settings: Settings) {
    this.settings = settings;
    this.vehicle?.updateCalculationSettings(this.settings);
  }

  public getSettings(): Settings {
    return this.settings;
  }

  public setSpace(space: Space) {
    this.space = space;
  }

  public getSpace(): Space {
    return this.space;
  }

  public getUsedLoadingLengthPercentage(): number {
    const [stats] = this.statistics.values(); // FIXME
    return stats.getUsedLoadingLengthPercentage();
  }
  public hasTrailer(): boolean {
    // console.log('TODO vehicle has trailer? ');
    return false;
    //return this.vehicle && this.vehicle.trailer;
  }

  public setOrbitControlsState(value: OrbitControlsState) {
    this.orbitControlsState = value;
  }

  public getOrbitControlsState(): OrbitControlsState {
    return this.orbitControlsState;
  }

  public setVehicle(vehicle: Vehicle) {
    this.vehicle = vehicle;
  }

  public setCurrentTimestamp(value: number) {
    this.currentTimestamp = value;
  }

  public setInitialTimestamp(value: number) {
    this.initialTimestamp = value;
  }

  public getInitialTimestamp(): number {
    return this.initialTimestamp;
  }

  public setAddedLoads(loads: Load[]) {
    this.addedLoads = loads;
  }

  public setLoadingSteps(steps: LoadingStep[]) {
    this.loadingSteps = steps;
  }

  public getLoadingSteps(): LoadingStep[] {
    return this.loadingSteps;
  }

  public getVehicle(): Vehicle {
    return this.vehicle;
  }

  public getCurrentTimestamp(): number {
    return this.currentTimestamp;
  }

  public getAddedLoads(): Load[] {
    return this.addedLoads;
  }

  public getLoads(): Load[] {
    return this.loads;
  }

  public setLoads(obj: Load[]) {
    this.loads = obj;
  }

  public getAllLoads(): Load[] {
    return this.getLoadedLoads().concat(this.loads);
  }

  public getLoadedLoads(): Load[] {
    return this.vehicle.spaces.map((space) => space.loads).flat();
  }

  public isEmpty(): boolean {
    return this.getAllLoads().length === 0;
  }

  public isVehicleChosen(): boolean {
    return this.vehicle && !(this.vehicle instanceof EmptyVehicle);
  }

  public getMessage(): string {
    return this.message;
  }

  public setMessage(value: string) {
    this.message = value;
  }

  public setStatistics(statistics: Statistics[]) {
    this.statistics = statistics;
  }
  public getStatistics(): Statistics[] {
    return this.statistics;
  }

  public getSpaceStatistics(spaceUuid: string): Statistics {
    return this.statistics.find((stats) => stats.spaceUuid === spaceUuid);
  }

  public getEnabledSpacesStatistics(): Statistics[] {
    const stats = this.vehicle.spaces
      .filter((space) => space.enabled)
      .map((space) => this.getSpaceStatistics(space.uuid));
    return stats;
  }

  public getUuid() {
    return this.uuid;
  }

  public setUuid(uuid: string) {
    this.uuid = uuid;
  }

  public getHistoryUuid() {
    return this.historyUuid;
  }

  public setHistoryUuid(uuid: string) {
    this.historyUuid = uuid;
  }

  public setSuggestedVehicles(list: Vehicle[]) {
    this.suggestedVehicles = list;
  }
  public getSuggestedVehicles() {
    return this.suggestedVehicles;
  }

  public getProjectId(): string {
    return this.projectId;
  }

  public setProjectId(id: string) {
    this.projectId = id;
  }

  public getAllLoadsWeight(): number {
    return this.getAllLoads().reduce((total, load) => total + (load.weight || 0), 0);
  }
  public groupLoads() {
    const loadList = new LoadGroupList(LoadGroup);
    loadList.clear();
    this.getAllLoads()
      .sort((a, b) =>
        a.displayOrder === b.displayOrder ? (a.idx < b.idx ? -1 : 1) : a.displayOrder < b.displayOrder ? -1 : 1
      )
      .forEach((load: Load) => {
        loadList.addLoad(load);
      });
    return loadList;
  }

  public canBeLoadedOnVehicle(): boolean {
    return this.vehicle?.type === 'other';
  }

  public canLoadOtherVehiclesOnThis(): boolean {
    return this.vehicle?.canLoadPallets;
  }
}
