import { Vector3 } from 'three';
import { VectorMesh } from '../../../vehicle/space/lib/vector-mesh';
import { TextLabel } from '../TextLabel';

export abstract class VectorDimensionLabel extends TextLabel {
  public constructor(txt: string, scale = 1.0) {
    super(
      txt,
      new Vector3(),
      0,
      'cuboid-label fs14' + ' ' + 'scale-' + scale.toString().replace('.', '_')
    );
  }

  protected abstract updatePosition(vector: VectorMesh): void;
}
