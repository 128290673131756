export class LiveCollaboration {
  shareID: string;
  projectID: string;
  email: string;
  online = false;

  constructor(obj?: Partial<LiveCollaboration>) {
    Object.assign(this, obj);
  }
}
