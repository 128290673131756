import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { User } from '../lib/model/user';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HeaderService } from './header.service';
import { ProjectsService } from '../projects/projects.service';
import { Project } from '../projects/lib/project';
import { UiService } from '../services/ui.service';

type ColorScheme = 'light' | 'dark' | '';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: Observable<User>;
  currentDate: Date;
  project$: Observable<Project>;
  protected currentLanguage = 'PL';
  protected theme: ColorScheme = '';
  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(LOCALE_ID) protected locale: string,
    private service: HeaderService,
    private auth: AuthService,
    private ui: UiService,
    projectsService: ProjectsService
  ) {
    this.project$ = projectsService.currentProject$;
    this.currentLanguage = (locale || 'pl').toUpperCase();
    let userThemePref: ColorScheme = 'light';
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      userThemePref = 'dark';
    }
    this.theme = (localStorage.getItem('theme') as ColorScheme) || userThemePref;
  }

  ngOnInit(): void {
    this.user = this.auth.getUser();
    this.currentDate = new Date();
    setInterval(() => {
      this.currentDate = new Date();
    }, 60000);
    this.updateColorSchemeAttribute();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout() {
    this.auth.logout();
  }

  myAccount() {
    window.location.assign(environment.profileUrl);
  }

  myGarage() {
    // console.log('mygarage clicked');
    this.service.showMyGarage();
  }

  settings() {
    // console.log('settings clicked');
    this.service.showSettings();
  }

  changeLanguage(lang: string) {
    const newUrl = window.location.protocol + '//' + window.location.host + '/' + lang + '/';
    window.location.replace(newUrl);
  }

  private updateColorSchemeAttribute() {
    document.body.setAttribute('color-scheme', this.theme);
    this.ui.updateTheme(this.theme);
  }

  protected toggleTheme() {
    this.theme = this.theme == 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', this.theme);
    this.updateColorSchemeAttribute();
  }
}
