import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { map, merge, Subject, takeUntil } from 'rxjs';
import { HistoryService } from './lib/history.service';
import { ContextService } from '../vehicle/context/context.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
  protected undoButtonActive: boolean;
  protected redoButtonActive: boolean;

  private unsubscribe$ = new Subject<void>();

  constructor(private historyService: HistoryService, private contextService: ContextService) {}

  ngOnInit(): void {
    merge(this.contextService.context$, this.historyService.state$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.undoButtonActive = this.historyService.canUndo(this.contextService.getCurrentContext());
        this.redoButtonActive = this.historyService.canRedo(this.contextService.getCurrentContext());
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public historyBack() {
    this.historyService
      .back(this.contextService.getCurrentContext())
      .pipe(
        takeUntil(this.unsubscribe$),
        map((response) => this.contextService.handleResponse(response))
      )
      .subscribe((response) => {
        this.undoButtonActive = this.historyService.canUndo(response);
        this.redoButtonActive = this.historyService.canRedo(response);
      });
  }

  public historyForward() {
    this.historyService
      .forward(this.contextService.getCurrentContext())
      .pipe(
        takeUntil(this.unsubscribe$),
        map((context) => this.contextService.replaceContext(context))
      )
      .subscribe((response) => {
        this.undoButtonActive = this.historyService.canUndo(response);
        this.redoButtonActive = this.historyService.canRedo(response);
      });
  }

  @HostListener('window:keydown.control.z', ['$event'])
  onControlZ($event: KeyboardEvent) {
    if (this.undoButtonActive) {
      console.log('ctrl + z ');
      this.historyBack();
    }
  }

  @HostListener('window:keydown.control.y', ['$event'])
  onControlY($event: KeyboardEvent) {
    if (this.redoButtonActive) {
      console.log('ctrl + y ');

      this.historyForward();
    }
  }
}
