import { Mesh } from 'three';
import { Constants } from '../../lib/constants';

import { Axle } from './axle';

export abstract class AxleMesh {
  protected description: string;
  protected aType: string;
  public axle: Axle;
  public obj: Mesh;

  constructor(type: string, obj: Axle) {
    this.obj = new Mesh();
    this.axle = obj;
    this.aType = type;
    this.obj.name = 'axle-mesh';
    this.obj.material = [
      Constants.NEUTRAL_AXLE_MATERIAL,
      Constants.SUCCESS_AXLE_MATERIAL,
      Constants.WRONG_AXLE_MATERIAL
    ];
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(value: string) {
    this.description = value;
  }

  public getType() {
    return this.aType;
  }

  public dispose() {
    if (Array.isArray(this.obj?.material)) {
      this.obj?.material.forEach((m) => m.dispose());
    } else {
      this.obj?.material.dispose();
    }
  }
}
