import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileInterface } from '../lib/model/profile';
import { ProfileService } from '../services/profile.service';

export interface DialogData {
  title: string;
  body: string;
  style: 'default' | 'success' | 'error' | 'info';
  type?: string;
  profile?: ProfileInterface;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnDestroy {
  title: string;
  body: string;
  style: string;
  type: string;
  env: any;
  profile$: Observable<ProfileInterface>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ModalComponent>,
    profileService: ProfileService
  ) {
    this.title = data.title;
    this.body = data.body;
    this.style = data.style || data.type || 'default';
    this.type = data.type;
    this.env = environment;
    this.profile$ = profileService.getProfile();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    this.dialogRef.close();
  }
}
