import { Component, OnDestroy, OnInit } from '@angular/core';
import { LiveCollaborationService } from './lib/live-collaboration.service';
import { ProjectsService } from '../projects/projects.service';
import { shareReplay, Subject, takeUntil, tap } from 'rxjs';
import { LiveCollaboration } from './lib/live-collaboration';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../services/profile.service';
import { MessageService } from '../messenger/message.service';
import { AuthService } from '../services/auth.service';
import { PositionerResponse } from '../lib/communication/positioner.response';
import { ContextService } from '../vehicle/context/context.service';
import { VehicleFactory } from '../vehicle/lib/vehicle-factory';
import { LoadFactory } from '../load/lib/load-factory';

@Component({
  selector: 'app-project-sharing',
  templateUrl: './sharing.component.html',
  styleUrls: ['./sharing.component.scss']
})
export class SharingComponent implements OnInit, OnDestroy {
  protected expanded = false;
  protected loading = false;

  protected list: LiveCollaboration[] = [];

  protected form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  protected get emailControl() {
    return this.form.get('email');
  }

  private unsubscribe$ = new Subject<void>();

  get currentShare() {
    return this.liveCollaboration.currentShare;
  }

  set currentShare(val: LiveCollaboration | undefined) {
    this.liveCollaboration.currentShare = val;
  }

  constructor(
    private liveCollaboration: LiveCollaborationService,
    private projectService: ProjectsService,
    private profileService: ProfileService,
    private messageService: MessageService,
    private authService: AuthService,
    private contextService: ContextService,
    private vehicleFactory: VehicleFactory,
    private loadFactory: LoadFactory
  ) {}

  ngOnInit(): void {
    this.liveCollaboration.setAuthToken(this.authService.getToken());
    this.liveCollaboration
      .getSharedUsers(this.projectService.currentProject?.uuid)
      .pipe(shareReplay(1))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        this.currentShare = list.find((s) => s.email == this.profileService.currentProfile.email);
        this.list = this.sortList(list);
        if (!this.currentShare) {
          this.leaveCurrentProjectRoom();
        } else {
          this.joinCurrentProjectRoom();
        }
        this.setupEventListeners();
      });

    this.authService.tokenRefreshed$.pipe(takeUntil(this.unsubscribe$)).subscribe((t) => {
      this.liveCollaboration.setAuthToken(this.authService.getToken());
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.liveCollaboration.disconnect();
  }

  private setupEventListeners() {
    this.liveCollaboration.on('message', (msg, ...other) => {
      console.log('[WS] message', msg, other);
    });

    this.liveCollaboration.on('leave-project', ({ projectID, shareID }) => {
      console.log('[WS] force leave project', projectID, shareID);
      if (projectID == this.projectService.currentProject?.uuid) {
        this.leaveCurrentProjectRoom();
      }
    });

    this.liveCollaboration.on('update', (calc: PositionerResponse) =>
      this.onContextUpdate(new PositionerResponse(calc, this.vehicleFactory, this.loadFactory))
    );
    this.liveCollaboration.on('remove', (uuid: string) => this.onContextRemove(uuid));
    this.liveCollaboration.on('clear', (projectID: string) => this.onProjectClear(projectID));

    this.liveCollaboration.on('online', ({ shareID }) => {
      const share = this.list.find((s) => s.shareID == shareID);
      if (share) {
        share.online = true;
      }
    });
    this.liveCollaboration.on('offline', ({ shareID }) => {
      const share = this.list.find((s) => s.shareID == shareID);
      if (share) {
        share.online = false;
      }
    });
  }

  private joinCurrentProjectRoom() {
    if (this.currentShare) {
      this.liveCollaboration.join(this.currentShare);
      this.setupEventListeners();
    }
  }

  private leaveCurrentProjectRoom() {
    this.liveCollaboration.disconnect();
    if (this.projectService.currentProject.userId != this.profileService.currentProfile.userId) {
      this.projectService.currentProject = undefined;
    }
  }

  private onContextUpdate(resp: PositionerResponse) {
    if (resp.projectId == this.projectService.currentProject.uuid) {
      const currentContext = this.contextService.getCurrentContext();
      const context = this.contextService.handleResponse(
        resp,
        true,
        currentContext && !currentContext.isEmpty() && currentContext.getUuid() != resp.uuid
      );
      console.log('[WS] Context updated');
    }
  }

  private onContextRemove(uuid: string) {
    const context = this.contextService.findContext(uuid);
    if (context) {
      this.contextService.removeContextFromView(context);
    }
  }

  private onProjectClear(projectID: string) {
    if (projectID == this.projectService.currentProject.uuid) {
      this.contextService.removeAllContextsFromView();
    }
  }

  private sortList(list: LiveCollaboration[]) {
    return list
      .filter((l) => l.email != this.profileService.currentProfile.email)
      .sort((a, b) => {
        return a.email.localeCompare(b.email);
      });
  }

  protected isShared() {
    return this.list.filter((s) => s.email != this.profileService.currentProfile.email).length > 0;
  }

  protected addShare() {
    const email = this.emailControl.value;
    this.loading = true;
    this.liveCollaboration
      .shareWithUser(this.projectService.currentProject?.uuid, email)
      .pipe(tap(() => (this.loading = false)))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        this.leaveCurrentProjectRoom();
        this.currentShare = list.find((s) => s.email == this.profileService.currentProfile.email);
        this.list = this.sortList(list);
        this.emailControl.reset();
        this.emailControl.markAsUntouched();
        this.emailControl.markAsPristine();
        this.emailControl.setErrors(null);
        this.messageService.snackSuccess({ title: $localize`Użytkownik został dodany do projektu` });
        this.joinCurrentProjectRoom();
      });
  }

  protected removeShare(share: LiveCollaboration) {
    this.loading = true;
    this.liveCollaboration
      .removeShare(share)
      .pipe(tap(() => (this.loading = false)))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        this.currentShare = list.find((s) => s.email == this.profileService.currentProfile.email);
        if (!this.currentShare) {
          this.leaveCurrentProjectRoom();
        }
        this.list = list;
        if (share.email == this.profileService.currentProfile?.email) {
          this.messageService.snackSuccess({ title: $localize`Współdzielenie projektu zakończone` });
        } else {
          this.messageService.snackSuccess({ title: $localize`Użytkownik został usunięty z projektu` });
        }
      });
  }

  protected pingUser(share: LiveCollaboration) {
    this.liveCollaboration.emit('message', { ...share, message: `ping ${share.email}` });
  }
}
