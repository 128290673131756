<div class="list-container">
  <div class="arrow-left" [class.hide]="!showScrollLeft" (click)="scrollLeft()">
    <mat-icon> arrow_back_ios_new </mat-icon>
  </div>
  <div class="arrow-right" [class.hide]="!showScrollRight"
    (click)="scrollRight()">
    <mat-icon> arrow_forward_ios </mat-icon>
  </div>
  <mat-action-list #list class="context-list row" (scroll)="onScroll($event)">
    <div mat-list-item *ngFor="let context of contexts; index as i">
      <button (click)="changeContext(context)" class="context-list-item"
        [class.active]="currentContext === context">
        <span>[{{ i + 1 }}]
          {{ context.getVehicle().fullName }}
        </span>
        <div *ngIf="currentContext === context" class="size">
          {{ context?.getVehicle() | vehicleReadableSize | async }}
        </div>
        <span *ngIf="
              currentContext === context &&
              context.getVehicle().type != 'warehouse'
            " class="edit-context" (click)="editContext(context)"
          i18n-matTooltip matTooltip="Edytuj wymiary przestrzeni"
          [matTooltipPosition]="'above'" [matTooltipShowDelay]="500"
          #editContextTooltip="matTooltip"><mat-icon>edit</mat-icon></span>
      </button>
    </div>
  </mat-action-list>
</div>