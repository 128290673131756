<mat-expansion-panel (opened)="opened = true" (closed)="opened = false">
  <mat-expansion-panel-header>
    <mat-panel-title i18n>Dostosuj piętrowalność</mat-panel-title>
  </mat-expansion-panel-header>
  <div class="floorable-container">
    <div [formGroup]="form" class="floorable-options">
      <mat-checkbox color="primary" formControlName="all"
        (change)="change('all', $event)" i18n>Całkowita</mat-checkbox>
      <mat-checkbox color="primary" formControlName="top"
        (change)="change('top', $event)" i18n>Pasywna</mat-checkbox>
      <mat-checkbox color="primary" formControlName="bottom"
        (change)="change('bottom', $event)" i18n>Aktywna</mat-checkbox>
    </div>

    <button mat-icon-button (click)="toggleHelp()" class="style-success"
      type="button">
      <mat-icon aria-hidden="false" i18n-aria-label
        aria-label="Pomoc dotycząca piętrowalności"
        color="primary">help_outline</mat-icon>
    </button>
  </div>
</mat-expansion-panel>

<app-flooring-levels *ngIf="flooringLevelActive" #levels></app-flooring-levels>
<app-protrusion *ngIf="protrusionActive" #protrusion></app-protrusion>