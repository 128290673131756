import {
  BufferGeometry,
  Color,
  DynamicDrawUsage,
  InstancedBufferAttribute,
  InstancedBufferGeometry,
  Material,
  Object3D
} from 'three';

export class InstancedBorders<T extends Object3D> {
  //line basic material
  private material: Material;
  // edges geometry
  private geometry: BufferGeometry;
  private instancedGeometry: InstancedBufferGeometry;
  private offsets: Float32Array;
  private offsetAttribute: InstancedBufferAttribute;
  private colors: Uint8Array;
  private colorAttribute: InstancedBufferAttribute;
  private opacities: Float32Array;
  private opacityAttribute: InstancedBufferAttribute;

  private mesh: T;

  public constructor(
    private type: { new (g: BufferGeometry, m: Material): T },
    geometry: BufferGeometry,
    material: Material,
    private cnt: number,
    private renderOrder = 1
  ) {
    this.material = material.clone();
    this.geometry = geometry.clone();
    this.init();
  }

  private init() {
    this.setupMaterial();
    this.setupGeometry();

    this.mesh = new this.type(this.instancedGeometry, this.material);
    this.mesh.renderOrder = this.renderOrder;
    // fix dla problemu ze znikaniem ramek przy zoomie
    this.mesh.frustumCulled = false;
  }

  private setupMaterial() {
    this.material.onBeforeCompile = (shader) => {
      shader.vertexShader = `
        attribute vec3 offset;
        attribute vec3 aInstanceColor;
        attribute float aInstanceOpacity;

        ${shader.vertexShader.replace(
          '#include <begin_vertex>',
          `#include <begin_vertex>
            transformed += offset;`
        )}`;
      shader.vertexShader = `
        varying vec3 vInstanceColor;
        varying float vInstanceOpacity;
        ${shader.vertexShader.replace(
          `#include <color_vertex>`,
          `#include <color_vertex>
            vInstanceColor = aInstanceColor;
            vInstanceOpacity = aInstanceOpacity;`
        )}`;

      shader.fragmentShader = `
        varying vec3 vInstanceColor;
        varying float vInstanceOpacity;
        ${shader.fragmentShader.replace(
          'vec4 diffuseColor = vec4( diffuse, opacity );',
          'vec4 diffuseColor = vec4( vInstanceColor, vInstanceOpacity );'
        )}`;
    };
  }

  private setupGeometry() {
    this.instancedGeometry = new InstancedBufferGeometry().copy(this.geometry as any as InstancedBufferGeometry);
    this.instancedGeometry.instanceCount = this.cnt;
    this.offsets = new Float32Array(this.cnt * 3);
    this.offsetAttribute = new InstancedBufferAttribute(this.offsets, 3);
    this.instancedGeometry.setAttribute('offset', this.offsetAttribute);

    this.colors = new Uint8Array(this.cnt * 3);
    this.colorAttribute = new InstancedBufferAttribute(this.colors, 3, true);
    this.colorAttribute.setUsage(DynamicDrawUsage);
    this.instancedGeometry.setAttribute('aInstanceColor', this.colorAttribute);
    this.opacities = new Float32Array(this.cnt);
    this.opacityAttribute = new InstancedBufferAttribute(this.opacities, 1, true);
    this.opacityAttribute.setUsage(DynamicDrawUsage);
    this.instancedGeometry.setAttribute('aInstanceOpacity', this.opacityAttribute);
  }

  public setColorAt(idx: number, color: Color) {
    const colorArray = color.toArray().map((c) => Math.floor(c * 255));
    return this.setColorArrayAt(idx, colorArray);
  }

  public setColorArrayAt(idx: number, colorArray: number[]) {
    this.colorAttribute.set(colorArray, idx * 3);
    this.colorAttribute.needsUpdate = true;
    return this;
  }

  public getColorAt(idx: number): Color {
    const colorArray = new Array(3);
    for (let i = 0; i < 3; i++) {
      colorArray[i] = this.colors[idx * 3 + i];
    }
    return new Color().fromArray(colorArray);
  }

  public setOffsetAt(idx: number, offset: { x: number; y: number; z: number }) {
    this.offsetAttribute.set([offset.x, offset.y, offset.z], idx * 3);
    this.offsetAttribute.needsUpdate = true;
    return this;
  }

  public getOffsetAt(idx: number): { x: number; y: number; z: number } {
    const offsetArray = new Array(3);
    for (let i = 0; i < 3; i++) {
      offsetArray[i] = this.offsets[idx * 3 + i];
    }
    return { x: offsetArray[0], y: offsetArray[1], z: offsetArray[2] };
  }

  public setOpacityAt(idx: number, opacity: number) {
    if (this.getOpacityAt(idx) !== opacity) {
      this.opacityAttribute.set([opacity], idx);
      this.opacityAttribute.needsUpdate = true;
    }
  }

  public getOpacityAt(idx: number): number {
    return this.opacities[idx];
  }

  public getObject(): Object3D {
    return this.mesh;
  }

  public dispose() {
    this.instancedGeometry.dispose();
    this.geometry.dispose();
    this.material.dispose();
    this.material = undefined;
    this.geometry = undefined;
    this.mesh = undefined;
  }
}
