<div class="modal-box settings-modal">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Ustawienia siatki</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <form [formGroup]="form" class="form text-center" (ngSubmit)="onSubmit()">
        <div>
          <mat-checkbox formControlName="show" i18n color="primary">Pokaż
            siatkę</mat-checkbox>
          <div class="help" i18n>
            Wyświetla siatkę z wybraną podziałką w przestrzeniach ładowania
          </div>
        </div>

        <div>
          <mat-label i18n>Podziałka: </mat-label>
          <div class="cell-row">
            <mat-form-field appearance="fill">
              <mat-label i18n>Rozmiar:</mat-label>
              <input matInput formControlName="cellSize" required />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label i18n>Jednostka:</mat-label>
              <mat-select formControlName="unit" required>
                <mat-option *ngFor="let unit of lengthUnits"
                  [value]="unit.value">
                  {{ unit.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-error *ngIf="form.controls['cellSize'].hasError('required')">
            <ng-container i18n>Podaj</ng-container><span>&nbsp;</span>
            <strong><ng-container i18n>rozmiar podziałki</ng-container></strong>
          </mat-error>
        </div>

        <div>
          <mat-label i18n>Widoczność: </mat-label>
          <mat-slider thumbLabel step="1" min="0" max="10" i18n-aria-label
            discrete="true" aria-label="Intensywność"><input
              formControlName="intensity" matSliderThumb
              value="0" /></mat-slider>
        </div>
        <div>
          <mat-checkbox formControlName="showLabels" i18n color="primary">Pokaż
            miary</mat-checkbox>
        </div>

        <div class="buttons">
          <button mat-raised-button type="submit" [disabled]="!form.valid"
            class="btn-primary mt-2">
            <span i18n>Zapisz </span>
            <mat-icon *ngIf="isLoading">
              <mat-spinner class="progress" color="accent" diameter="24">
              </mat-spinner>
            </mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>