<div class="logo">
  <img src="assets/images/logo.png?v=1.01" alt="SmartLoad" />
</div>
<div class="session-info">
  <ng-container i18n>Dzisiaj jest</ng-container>
  {{ currentDate | date : 'dd.MM.YYYYr.' }} |
  <ng-container i18n>godz.</ng-container>
  {{ currentDate | date : 'HH:mm' }}
</div>

<div class="subscription-info">
  <app-subscription-info></app-subscription-info>
</div>

<div>
  <button (click)="toggleTheme()" mat-raised-button class="switch-theme">
    <mat-icon><ng-container
        *ngIf="theme == 'light'">dark_mode</ng-container><ng-container
        *ngIf="theme != 'light'">light_mode</ng-container></mat-icon>
  </button>
</div>

<div>
  <button mat-raised-button [matMenuTriggerFor]="languageMenu"
    class="user-panel">
    {{ currentLanguage }}
  </button>
  <mat-menu #languageMenu="matMenu">
    <a href="#" mat-menu-item (click)="changeLanguage('pl')">PL</a>
    <a href="#" mat-menu-item (click)="changeLanguage('de')">DE</a>
    <a href="#" mat-menu-item (click)="changeLanguage('en')">EN</a>
    <a href="#" mat-menu-item (click)="changeLanguage('es')">ES</a>
  </mat-menu>
</div>

<div *ngIf="user | async; let user">
  <button mat-raised-button [matMenuTriggerFor]="userMenu" class="user-panel">
    <mat-icon>account_circle</mat-icon>
    {{ user ? user.name : 'Użytkownik niezalogowany' }}
  </button>
  <mat-menu #userMenu="matMenu">
    <a href="#" mat-menu-item (click)="myGarage()"
      *ngIf="project$ | async"><mat-icon>garage</mat-icon><ng-container i18n>Mój
        garaż</ng-container></a>
    <a href="#" mat-menu-item (click)="settings()"><mat-icon>
        settings</mat-icon><ng-container i18n>Ustawienia</ng-container></a>
    <a href="#" mat-menu-item
      (click)="myAccount()"><mat-icon>manage_accounts</mat-icon><ng-container
        i18n>Moje konto</ng-container></a>
    <a href="#" mat-menu-item
      (click)="logout()"><mat-icon>logout</mat-icon><ng-container i18n>Wyloguj
        się</ng-container></a>
  </mat-menu>
</div>