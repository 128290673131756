import { PalletLoad } from 'src/app/load/type';
import { Load } from '../../../load/lib/load';
import { LoadGroup } from './load-group';
export class LoadGroupInstanced extends LoadGroup {
  public has(aLoad: Load) {
    if (aLoad instanceof PalletLoad) {
      return false;
    }
    const sameBoxes = aLoad.isSameSizeAs(this.load);

    return sameBoxes;
  }
}
