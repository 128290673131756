import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { ContextService } from 'src/app/vehicle/context/context.service';
import { FlooringLevelSettings } from 'src/app/vehicle/space/flooring-level/lib/flooring-level-settings';
import { Space } from 'src/app/vehicle/space/lib/space';
import { FlooringLevelsForm } from './lib/flooring-levels.form';

@Component({
  selector: 'app-flooring-levels',
  templateUrl: './flooring-level.component.html',
  styleUrls: ['./flooring-level.component.scss']
})
export class FlooringLevelComponent implements OnInit, OnDestroy {
  @Input() protected type: 'load-form' | 'modal' = 'load-form';
  protected opened: boolean;
  protected form: FlooringLevelsForm;
  private unsubscribe$ = new Subject<void>();
  constructor(private contextService: ContextService, protected uiService: UiService) {}

  ngOnInit(): void {
    console.log('init florring level component');
    this.contextService.context$.pipe(takeUntil(this.unsubscribe$)).subscribe((context) => {
      if (context) {
        this.form = new FlooringLevelsForm(context.getVehicle(), this.uiService);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get valid(): boolean {
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  getSpaceSettings(space: Space): FlooringLevelSettings {
    return this.form.findForm(space).data;
  }
}
