<div class="modal-box file-import-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <ng-container *ngIf="load">
        <h1 i18n>Edytuj {{ load.name }}</h1>
      </ng-container>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <div class="flex-row">
        <div class="flex-grow">
          <div class="flex-row even-columns">
            <app-name #name [events]="eventsSubject.asObservable()"
              [default]="load.name"></app-name>
            <app-amount #amount [cnt]="group.cnt"
              [type]="'stacked'"></app-amount>
            <app-weight #weight [mode]="formMode"
              [default]="ui.getWeightInCurrentUnit(load.weight)"></app-weight>
          </div>

          <app-cuboid-form #dimensions [events]="eventsSubject.asObservable()"
            (addLoadEvent)="update($event)" mode="inline" [type]="'edit'"
            [item]="load"></app-cuboid-form>

          <app-flooring #flooring [mode]="'edit'" [item]="load"></app-flooring>
          <app-load-rotation #rotation [mode]="'edit'"
            [item]="load"></app-load-rotation>
        </div>
        <div class="flex-vert-end flex-column">
          <app-color #color [color]="colorCss" (emitColor)="colorChange($event)"
            mode="popup" i18n-matTooltip matTooltip="Dostosuj kolor"
            [matTooltipPosition]="'left'"
            #editColorTooltip="matTooltip"></app-color>
          <button mat-raised-button (click)="rotateHorizontal()"
            [disabled]="!allowHorizontalRotation" i18n-matTooltip
            matTooltip="Obróć w poziomie" [matTooltipPosition]="'left'"
            #rotateHorizontalTooltip="matTooltip">
            <mat-icon>360</mat-icon>
          </button>
          <button mat-raised-button (click)="rotateVertical()"
            [disabled]="!allowVerticalRotation" i18n-matTooltip
            matTooltip="Obróć w pionie" [matTooltipPosition]="'left'"
            #rotateVerticalTooltip="matTooltip">
            <mat-icon>screen_rotation</mat-icon>
          </button>
          <button mat-raised-button (click)="delete()" i18n-matTooltip
            matTooltip="Usuń" [matTooltipPosition]="'left'"
            #removeTooltip="matTooltip">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>

      <div class="buttons">
        <button mat-raised-button (click)="onSubmit()" cdkFocusInitial>
          <mat-icon>save</mat-icon>
          <ng-container i18n>Zapisz</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>