import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VehicleContext } from '../lib/model/vehicle-context';
import { UiService } from '../services/ui.service';
import { SceneService } from '../scene/scene.service';
import { LabelService } from '../label/label.service';
import { ContextService } from '../vehicle/context/context.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-loading-steps',
  templateUrl: './loading-steps.component.html',
  styleUrls: ['./loading-steps.component.scss']
})
export class LoadingStepsComponent implements OnInit, OnDestroy {
  private _context: VehicleContext;
  set context(val: VehicleContext) {
    if (this._context && val && val.getHistoryUuid() != this._context.getHistoryUuid()) {
      this.close();
    }
    this._context = val;
    this.steps = this.getStepsWithLoads();
  }

  get context(): VehicleContext {
    return this._context;
  }

  protected steps: any[] = [];

  protected currentStepNumber: number = 0;

  private unsubscribe$ = new Subject<void>();
  private layerMask = new Map<string, number>();

  constructor(
    private ui: UiService,
    private sceneService: SceneService,
    private labelService: LabelService,
    private contextService: ContextService
  ) {}

  ngOnInit(): void {
    this.contextService
      .contextChanged()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((context) => {
        if (this.context && (!context || this.context.getUuid() != context.getUuid())) {
          this.close();
          return;
        }
        if (context?.getLoadingSteps()?.length <= 1) {
          this.close();
        } else {
          this.context = context;
          this.currentStepNumber = 0;
          context?.getAllLoads().forEach((l) => {
            if (l.mesh?.obj) {
              this.layerMask.set(l.uuid, l.mesh.obj.layers.mask);
            }
          });
          this.selectStep(1);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected close() {
    this.ui.enableTool('loadingSteps', false);
  }

  public reset() {
    this.currentStepNumber = 0;
    this.steps = [];
    this.context?.getAllLoads().forEach((load) => {
      if (load.mesh?.obj) {
        load.mesh.obj.visible = true;
        load.mesh.obj.layers.mask = this.layerMask.get(load.uuid);
      }
    });
    this.sceneService.setShowInStep(undefined);
    this.layerMask.clear();
    this._context = undefined;
    this.labelService.updateLabels();
    this.sceneService.refreshView();
  }

  protected selectStep(stepNumber: number) {
    this.currentStepNumber = stepNumber;
    const loadsToShow: string[] = [];
    this.steps.forEach((step) => {
      if (step.step <= stepNumber) {
        loadsToShow.push(...step.ids);
      }
    });
    this.sceneService.setShowInStep(loadsToShow);
    this.context.getAllLoads().forEach((load) => {
      if (load.mesh?.obj) {
        load.mesh.obj.visible = loadsToShow.includes(load.uuid);
      }
    });
    this.labelService.updateLabels();
    this.sceneService.refreshView();
  }

  private getStepsWithLoads() {
    const steps = [];
    const loads = this.context?.getAllLoads();
    this.context?.getLoadingSteps().forEach((step) => {
      const stepLoads = loads.filter((l) => step.loadIds.includes(l.uuid));
      if (stepLoads.length == 0) {
        console.error('Loads from loading step not present in calculation');
        return;
      }
      const info = {
        step: step.step,
        load: stepLoads[0],
        ids: step.loadIds,
        cnt: step.loadIds.length
      };
      steps.push(info);
    });
    return steps;
  }
}
