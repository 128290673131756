import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalculationService } from '../api/calculation.service';
import { PositionerResponse } from '../lib/communication/positioner.response';
import { SceneService } from '../scene/scene.service';
import { LoadFactory } from '../load/lib/load-factory';
import { Subject, takeUntil } from 'rxjs';
import { VehicleFactory } from '../vehicle/lib/vehicle-factory';
import { ContextFactory } from '../vehicle/context/lib/context-factory';
import { ProjectsService } from '../projects/projects.service';
import { Project } from '../projects/lib/project';
import { ContextService } from '../vehicle/context/context.service';

@Component({
  selector: 'app-single-context',
  templateUrl: './single-context.component.html',
  styleUrls: ['./single-context.component.scss']
})
export class SingleContextComponent implements OnInit, OnDestroy {
  contextId: string;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private sceneService: SceneService,
    private calculationService: CalculationService,
    private contextFactory: ContextFactory,
    private vehicleFactory: VehicleFactory,
    private loadFactory: LoadFactory,
    private projectsService: ProjectsService,
    private contextService: ContextService
  ) {
    this.sceneService.lockContext(true);
  }

  ngOnInit(): void {
    this.contextId = this.route.snapshot.paramMap.get('id');
    console.debug('SingleContextComponent: ', this.contextId);
    this.calculationService
      .getCalculation(this.contextId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((calculation: PositionerResponse) => {
        if (calculation.projectId) {
          console.debug('Setting project: ', calculation.projectId);
          const project = new Project({
            uuid: calculation.projectId,
            name: '?'
          });
          this.projectsService.currentProject = project;
        }
        const context = this.contextFactory.createForVehicle(calculation.vehicle);
        context?.setAddedLoads([]);
        context?.setCurrentTimestamp(calculation.timestamp);
        context?.setStatistics(calculation.statistics);
        context?.setSuggestedVehicles(calculation.suggestedVehicles?.map((v) => this.vehicleFactory.recreate(v)));
        context?.setProjectId(calculation.projectId);
        this.contextService.addAndActivate(context);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
