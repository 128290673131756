import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { Load } from '../../lib/load';
import { LoadService } from '../../load.service';
import { FlooringComponent } from '../flooring/flooring.component';
import { ColorComponent } from '../color/color.component';
import { NameComponent } from '../name/name.component';
import { RotationComponent } from '../rotation/rotation.component';
import { WeightComponent } from '../weight/weight.component';
import { FormComponent as CuboidFormComponent } from '../../type/cuboid/form/form.component';
import { LoadGroup } from '../../../lib/model/load-group/load-group';
import { AmountComponent } from '../amount/amount.component';
import { SceneService } from 'src/app/scene/scene.service';
import { Cuboid } from '../../type/cuboid/lib/cuboid';
import { LoadFactory } from '../../lib/load-factory';
import { ColorService } from 'src/app/services/color.service';

@Component({
  selector: 'app-loaded-load-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit, OnDestroy {
  @ViewChild('name') name: NameComponent;
  @ViewChild('amount') amount: AmountComponent;
  @ViewChild('weight') weight: WeightComponent;
  @ViewChild('color') color: ColorComponent;
  @ViewChild('flooring') flooring: FlooringComponent;
  @ViewChild(RotationComponent) rotation: RotationComponent;
  @ViewChild(CuboidFormComponent) dimensions: CuboidFormComponent;

  private _group: LoadGroup;
  protected allowHorizontalRotation: boolean;
  protected allowVerticalRotation: boolean;
  protected colorCss: string;

  @Input() public set group(group: LoadGroup) {
    this._group = group;
    this.allowHorizontalRotation = group.list.findIndex((l) => l.horizontalRotationFrozen) < 0;
    this.allowVerticalRotation = group.list.findIndex((l) => l.verticalRotationFrozen) < 0;
    this.colorCss = this.colors.getCssValue(this._group.load.color);
  }

  public get group(): LoadGroup {
    return this._group;
  }

  protected get load(): Load {
    return this.group.load;
  }

  protected eventsSubject: Subject<void> = new Subject<void>();
  protected formMode = 'edit-form';

  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: LoadService,
    protected ui: UiService,
    private sceneService: SceneService,
    private loadFactory: LoadFactory,
    protected colors: ColorService
  ) {}

  close(): void {
    this.service.closeModal('load/form/edit/edit.component.ts');
  }

  onSubmit() {
    this.emitEventToChild();
  }

  emitEventToChild() {
    this.eventsSubject.next();
  }

  private getHorizontalRotationFrozen() {
    return !this.rotation.canRotate();
  }

  private getVerticalRotationFrozen() {
    return !this.rotation.canRotateVertical();
  }

  protected colorChange(color: string) {
    this.colorCss = color;
  }

  protected update(updated: Load) {
    if (!this.name.isValid() || !this.amount.isValid()) {
      return;
    }

    const name = this.name.getValue();
    const weight = this.ui.getWeightInDefaultUnit(+this.weight.getValue());
    const color = this.color.getValue();
    const protrusionLength = this.flooring.getProtrusionLength();
    const floorableTop = this.flooring.floorableTop;
    const floorableBottom = this.flooring.floorableBottom;
    const horizontalRotationFrozen = this.getHorizontalRotationFrozen();
    const verticalRotationFrozen = this.getVerticalRotationFrozen();
    const newAmount = this.amount.getValue();

    updated = this.loadFactory.recreateLoad({
      ...updated,
      name,
      weight,
      floorableTop,
      floorableBottom,
      protrusionLength,
      color,
      horizontalRotationFrozen,
      verticalRotationFrozen
    });

    let requireReload = false;
    const sizeChanged = this.dimensions.loadSizeChanged(updated);
    this.group.list.forEach((load) => {
      if (updated instanceof Cuboid && !sizeChanged) {
        updated.length = load.cuboidHull.length;
        updated.width = load.cuboidHull.width;
        updated.height = load.cuboidHull.height;
      }
      requireReload = requireReload || load.requireReloadWhenChangedTo(updated);
      this.setLoadValues(load, updated);
    });

    if (newAmount !== this.group.cnt) {
      this.sceneService
        .changeLoadCount(this.group, newAmount)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          this.close();
        });
    } else if (requireReload) {
      this.sceneService
        .changeSize(this.group.list)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          this.close();
        });
    } else {
      this.sceneService
        .updateLoads(this.group.list)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          this.close();
        });
    }
  }

  protected rotateHorizontal() {
    this.sceneService
      .rotateHorizontal(this.group.list)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this.close();
      });
  }

  protected rotateVertical() {
    this.sceneService
      .rotateVertical(this.group.list)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this.close();
      });
  }

  protected delete() {
    this.sceneService
      .removeLoads(this.group.list)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this.close();
      });
  }

  private setLoadValues(load: Load, data: Partial<Load>) {
    load.name = data.name;
    load.weight = data.weight;
    load.floorableTop = data.floorableTop;
    load.floorableBottom = data.floorableBottom;
    load.horizontalRotationFrozen = data.horizontalRotationFrozen;
    load.verticalRotationFrozen = data.verticalRotationFrozen;
    load.protrusionLength = data.protrusionLength;
    load.color = data.color;
    if (load instanceof Cuboid && data instanceof Cuboid) {
      load.length = data.length;
      load.height = data.height;
      load.width = data.width;
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
