import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ImageUploadComponent,
      multi: true
    }
  ]
})
export class ImageUploadComponent implements ControlValueAccessor {
  @Input() placeholder: string = 'Upload Image';
  @Input() formControlName!: string;
  @Input() maxFileSize: number = 1 * 1024 * 1024; // Default: 1 MB

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  fileName: string | null = null;
  onChange: any = () => {};
  onTouched: any = () => {};
  errorMessage: string | null = null;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle disable state
  }

  writeValue(value: any): void {
    this.fileName = value;
  }

  handleFileInput(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      if (file.size > this.maxFileSize) {
        this.errorMessage = $localize`Maksymalny rozmiar pliku to ${this.maxFileSize / (1024 * 1024)} MB.`;
        this.fileName = null;
        this.onChange(null);
      } else {
        this.errorMessage = null;
        this.fileName = file.name;
        this.onChange(file);
      }
    } else {
      this.fileName = null;
    }
  }

  triggerFileDialog(): void {
    this.fileInput.nativeElement.click();
  }
}
