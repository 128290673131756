<div [formGroup]="form">
  <mat-form-field>
    <mat-label i18n>Nazwa</mat-label>
    <input matInput required i18n-placeholder placeholder="Nazwa"
      formControlName="name" autocomplete="off" />
    <mat-error *ngIf="form.controls['name'].hasError('required')">
      <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
      <strong><ng-container i18n>wymagane</ng-container></strong>
    </mat-error>
  </mat-form-field>
</div>