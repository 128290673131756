import { AfterViewInit, Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ImportFileComponent } from 'src/app/import-file/import-file.component';
import { MenuService } from 'src/app/menu/menu.service';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { UiService } from 'src/app/services/ui.service';
import { ContextService } from 'src/app/vehicle/context/context.service';
import { Load } from '../lib/load';
import { LoadEventObject } from '../lib/load-event-object';
import { AmountComponent } from './amount/amount.component';
import { ColorComponent } from './color/color.component';
import { CreateComponent } from './create/create.component';
import { FlooringComponent } from './flooring/flooring.component';
import { WeightComponent } from './weight/weight.component';
import { LoadService as LoadUiService } from '../load.service';
import { LoadFactory } from '../lib/load-factory';
import { LoadListItem } from '../lib/load-list-item';
import { RotationComponent } from './rotation/rotation.component';
import { Space } from 'src/app/vehicle/space/lib/space';
import { ColorService } from 'src/app/services/color.service';
import { flashBackground } from 'animations/animations';
import { PendingLoadsService } from 'src/app/loadings/lib/pending-loads.service';

@Component({
  selector: 'app-load-form',
  templateUrl: './form.component.html',
  styleUrls: ['./../load.component.scss', './form.component.scss'],
  animations: [flashBackground]
})
export class FormComponent implements OnDestroy, AfterViewInit {
  @Output() addLoadEvent = new EventEmitter<LoadEventObject[]>();

  @ViewChild('amount') amount: AmountComponent;
  @ViewChild('weight') weight: WeightComponent;

  @ViewChild('color') color: ColorComponent;
  @ViewChild('flooring') flooring: FlooringComponent;
  @ViewChild(RotationComponent) rotation: RotationComponent;

  protected listType = 'none'; // system | user | none - lista typowe lub własne lub widok wyboru
  protected keepOpenAfterSubmit = false;
  protected addLoadsToPendingList$: Observable<boolean>;

  protected eventsSubject: Subject<void> = new Subject<void>();

  protected showForm = false;
  protected multiLoadForm = false;
  protected customLoadSettings = false;

  private selected: LoadListItem[] = [];
  private unsubscribe$ = new Subject<void>();

  get randomizeColors(): boolean {
    return this.contextService.getCurrentContext().getSettings().randomizeColors;
  }

  get spaces(): Space[] {
    return this.contextService.getCurrentContext().getVehicle().enabledSpaces;
  }

  constructor(
    private loadComponentService: LoadComponentService,
    private loadUiService: LoadUiService,
    private uiService: UiService,
    private menuService: MenuService,
    private contextService: ContextService,
    private loadFactory: LoadFactory,
    private colors: ColorService,
    private pendingLoadsService: PendingLoadsService
  ) {
    this.addLoadsToPendingList$ = pendingLoadsService.isActive$();
  }

  ngAfterViewInit(): void {
    this.loadUiService.selected$.pipe(takeUntil(this.unsubscribe$)).subscribe((items) => {
      this.selected = items;
      this.showForm = items.length > 0;
      this.multiLoadForm = items.length > 1;
      if (items.length === 1) {
        this.singleItemSelected(items[0]);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected onSubmit() {
    if (this.multiLoadForm) {
      this.addAllSelected();
    } else {
      this.emitEventToChild();
    }
  }

  protected changeListType(type: string) {
    this.loadUiService.updateSelected([]);
    this.listType = type;
  }

  private emitEventToChild() {
    // console.log('expert.component.ts: emit event to child');
    this.eventsSubject.next();
  }

  protected singleItemSelected(item: LoadListItem) {
    if (item) {
      this.weight.setValue(this.uiService.getWeightInCurrentUnit(item.load.weight));
      this.flooring.setValues(item.load.floorableTop, item.load.floorableBottom, item.load.protrusionLength);

      this.amount.setValue(item.cnt);
      this.rotation.setValues(item.load);
      if (item.load.color && this.color) {
        this.color.color = this.colors.getCssValue(item.load.color);
      }
    }
  }

  private getCnt(): number {
    const cnt = this.amount.getValue();
    if (cnt > 10000) {
      // console.log('FIXME: dodać reagowanie na walidację podformularzy');
      return 0;
    }
    return cnt;
  }

  private getHorizontalRotationFrozen() {
    return !this.rotation.canRotate();
  }

  private getVerticalRotationFrozen() {
    return !this.rotation.canRotateVertical();
  }

  protected addLoad(item: Load) {
    // console.log('expert.component.ts: addLoad called', item);
    item.generateUuid();
    const cnt = this.getCnt();
    if (cnt === 0) {
      return;
    }
    if (!this.flooring.valid) {
      console.error('flooring component not valid');
      return;
    }
    const weight = this.uiService.getWeightInDefaultUnit(+this.weight.getValue());

    let color = this.colors.randomColor();
    if (!this.randomizeColors) {
      color = this.color.getValue();
    }
    const protrusionLength = this.flooring.getProtrusionLength();

    item.weight = weight;
    item.floorableTop = this.flooring.floorableTop;
    item.floorableBottom = this.flooring.floorableBottom;
    item.protrusionLength = protrusionLength;
    item.horizontalRotationFrozen = this.getHorizontalRotationFrozen();
    item.verticalRotationFrozen = this.getVerticalRotationFrozen();
    item.color = color;

    this.spaces.forEach((space) => {
      const settings = this.flooring.getSpaceSettings(space);
      space.settings = settings;
    });

    if (this.pendingLoadsService.getIsActive() && cnt > 0) {
      this.pendingLoadsService.addLoad(item, cnt);
    } else {
      this.addLoadEvent.emit([
        new LoadEventObject({
          load: item,
          cnt
        })
      ]);
    }
    if (!this.keepOpenAfterSubmit) {
      this.menuService.close();
      // this.switchMenu('none');
    }
  }

  protected addAllSelected() {
    const payload: LoadEventObject[] = [];
    if (this.multiLoadForm && this.customLoadSettings) {
      if (!this.flooring.valid) {
        console.error('flooring component not valid');
        return;
      }
      this.selected.forEach((item) => {
        const load = this.loadFactory.recreateLoad(item.load);
        load.generateUuid();
        let color = this.colors.randomColor();
        if (!this.randomizeColors) {
          color = this.color.getValue();
        }
        const protrusionLength = this.flooring.getProtrusionLength();
        load.floorableTop = this.flooring.floorableTop;
        load.floorableBottom = this.flooring.floorableBottom;
        load.protrusionLength = protrusionLength;
        load.horizontalRotationFrozen = this.getHorizontalRotationFrozen();
        load.verticalRotationFrozen = this.getVerticalRotationFrozen();
        load.color = color;

        payload.push(
          new LoadEventObject({
            load,
            cnt: Math.max(item.cnt, 1)
          })
        );
      });
    } else {
      this.selected.forEach((item) => {
        const load = this.loadFactory.recreateLoad(item.load);
        load.generateUuid();

        payload.push(
          new LoadEventObject({
            load,
            cnt: Math.max(item.cnt, 1)
          })
        );
      });
    }

    this.spaces.forEach((space) => {
      const settings = this.flooring.getSpaceSettings(space);
      space.settings = settings;
    });
    if (this.pendingLoadsService.getIsActive()) {
      payload.forEach((bundle) => {
        this.pendingLoadsService.addLoad(bundle.load, bundle.cnt);
      });
    } else {
      this.addLoadEvent.emit(payload);
    }
    if (!this.keepOpenAfterSubmit) {
      this.menuService.close();
      // this.switchMenu('none');
    }
  }

  protected usePendingList(val: boolean) {
    this.pendingLoadsService.setIsActive(val);
  }

  protected importFromFile() {
    this.loadComponentService.clear('expert.component.ts importFromFile');
    this.loadComponentService.add(ImportFileComponent);
  }

  protected createNew() {
    this.loadComponentService.clear('expert.component.ts createNew');
    this.loadComponentService.add(CreateComponent);
  }
}
