import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { InfoService } from 'src/app/info/info.service';
import { InfoType } from 'src/app/info/lib/info-type';
import { FlooringService } from '../flooring/flooring.service';

@Component({
  selector: 'app-protrusion',
  templateUrl: './protrusion.component.html',
  styleUrls: ['./protrusion.component.scss']
})
export class ProtrusionComponent {
  public opened: boolean;
  public form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private infoService: InfoService,
    private flooringService: FlooringService
  ) {
    this.createForm();
  }

  formatSliderLabel(value: number) {
    return value + '%';
  }

  public get length(): number {
    return this.form.get('protrusion').value;
  }

  private createForm() {
    this.form = this.formBuilder.group({
      protrusion: [this.flooringService.protrusionLength, Validators.compose([Validators.required])]
    });
  }

  public setValue(val: number) {
    this.form.patchValue({ protrusion: val });
  }

  public toggleHelp() {
    this.infoService.toggle(InfoType.Protrusion);
  }
}
