import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { GridSettings } from './grid-settings';
import { SettingsService } from '../settings/settings.service';
import { Settings } from '../settings/settings';
import { UiService } from 'src/app/services/ui.service';

interface LengthUnit {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-grid-settings',
  templateUrl: './grid-settings.component.html',
  styleUrls: ['./grid-settings.component.scss']
})
export class GridSettingsComponent implements OnInit, OnDestroy {
  settings: GridSettings;
  isLoading = false;

  lengthUnits: LengthUnit[] = [
    { value: 'mm', viewValue: 'mm' },
    { value: 'cm', viewValue: 'cm' },
    { value: 'm', viewValue: 'm' },
    { value: 'in', viewValue: 'in' },
    { value: 'ft', viewValue: 'ft' }
  ];

  public form = this.fb.group({
    show: new UntypedFormControl(this.service.context.getSettings().grid.show, Validators.compose([])),
    showLabels: new UntypedFormControl(this.service.context.getSettings().grid.showLabels, Validators.compose([])),
    unit: new UntypedFormControl(this.service.context.getSettings().grid.unit, [Validators.required]),
    cellSize: new UntypedFormControl(
      this.uiService.convertLengthToUnit(
        this.service.context.getSettings().grid.cellSize,
        this.service.context.getSettings().grid.unit
      ),
      Validators.compose([])
    ),
    intensity: new UntypedFormControl(this.service.context.getSettings().grid.intensity, [Validators.required])
  });

  private unsubscribe$ = new Subject<void>();

  constructor(private service: SettingsService, private fb: UntypedFormBuilder, private uiService: UiService) {}

  ngOnInit(): void {
    console.log('grid-settings onInit');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit() {
    this.isLoading = true;

    const settings = this.service.context.getSettings() ?? new Settings();
    settings.grid.show = this.form.controls.show.value;
    settings.grid.showLabels = this.form.controls.showLabels.value;
    settings.grid.unit = this.form.controls.unit.value;
    settings.grid.cellSize = this.uiService.convertLengthToDefaultUnit(
      this.form.controls.cellSize.value,
      settings.grid.unit
    );
    settings.grid.intensity = this.form.controls.intensity.value;

    this.service
      .saveSettings(settings)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        console.log('settings to store in vehicle calculation', x);
        this.service.context.setSettings(settings);
        this.service.requestRedraw();
        this.close();
      });
  }

  close(): void {
    this.service.close();
  }
}
