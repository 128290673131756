import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { SceneService } from 'src/app/scene/scene.service';
import { Settings } from './settings';
import { SettingsService } from './settings.service';

//TODO: to jest settings bardziej kalkulacji niż pojazdu
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  settings: Settings;
  // flooringLevelActive = false;
  // flooringLevel: number;
  isLoading = false;

  public form = this.fb.group({
    preserveOrder: [this.service.context.getSettings().preserveOrder, Validators.compose([])],
    randomizeColors: [this.service.context.getSettings().randomizeColors, Validators.compose([])],
    fullLevels: [this.service.context.getSettings().fullLevels, Validators.compose([])]
  });

  private unsubscribe$ = new Subject<void>();

  constructor(private service: SettingsService, private sceneService: SceneService, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    console.log('onInit');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit() {
    this.isLoading = true;

    const settings = this.service.context.getSettings() ?? new Settings();
    settings.preserveOrder = this.form.controls.preserveOrder.value;
    settings.randomizeColors = this.form.controls.randomizeColors.value;
    settings.fullLevels = this.form.controls.fullLevels.value;

    this.service
      .saveSettings(settings)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        console.log('settings to store in vehicle calculation', x);
        this.service.context.setSettings(settings);
        this.sceneService.reloadLoads().subscribe(() => {
          this.close();
        });
      });
  }

  close(): void {
    this.service.close();
  }
}
