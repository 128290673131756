import { Vector3 } from 'three';
import { UiService } from '../../services/ui.service';
import { TextLabel } from './TextLabel';

export class GridAxisLabel extends TextLabel {
  public constructor(txt: string, protected textScale = 1.0) {
    super(txt, new Vector3(), 0, 'grid-axis-label', textScale);
  }

  makeCopy(): GridAxisLabel {
    return new GridAxisLabel(this.getText(), this.textScale);
  }
}
