import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Settings } from '../lib/model/settings';
import { LoadComponentService } from '../services/load-component.service';
import { ProfileService } from '../services/profile.service';
import { FleetSelectFormComponent } from './form/fleet-select-form.component';

@Component({
  selector: 'app-fleet-select',
  templateUrl: './fleet-select.component.html',
  styleUrls: ['./fleet-select.component.scss']
})
export class FleetSelectComponent implements OnDestroy, AfterViewInit {
  @ViewChild(FleetSelectFormComponent)
  fleetSelectComponent: FleetSelectFormComponent;

  isLoading = false;
  fleetError$ = new Subject<boolean>();
  private unsubscribe$ = new Subject<void>();

  constructor(private loadComponentService: LoadComponentService, private profileService: ProfileService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.fleetSelectComponent.selectAll();
    }, 0);
  }

  close(): void {
    this.loadComponentService.clear('fleet-select.component.ts');
  }

  onSubmit() {
    if (!this.fleetSelectComponent.isValid()) {
      this.fleetError$.next(true);
      return;
    }
    this.fleetError$.next(false);
    this.isLoading = true;
    this.profileService
      .saveSettings(
        new Settings({
          ...this.profileService.currentSettings,
          usedVehicles: this.fleetSelectComponent.getValues()
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newSettings) => {
        this.profileService.updateSettings(newSettings);
        this.close();
      });
  }
}
