import { CuboidHull } from 'src/app/load/lib/cuboid-hull';
import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { Load } from '../../../lib/load';
import { CuboidMesh } from './cuboid-mesh';

export class Cuboid extends Load {
  shape = 'cuboid';
  width: number;
  length: number;
  height: number;

  get cuboidHull(): CuboidHull {
    return {
      width: this.width,
      length: this.length,
      height: this.height
    };
  }

  get fullName(): string {
    return `${this.name}`;
  }

  get volume(): number {
    return this.area * this.height;
  }

  get area(): number {
    return this.width * this.length;
  }

  get fullDescription(): string {
    return `${this.length}x${this.width}x${this.height}`;
  }

  get descriptiveDimensions(): number[] {
    return [this.length, this.width, this.height];
  }

  createMesh(settings: LoadDisplaySettings): LoadMesh {
    this.disposeMesh();
    this.mesh = new CuboidMesh(this, settings, this.getDisplayCache());
    return this.mesh;
  }

  get isEmpty(): boolean {
    return !(this.length + this.width + this.height > 0);
  }
}
