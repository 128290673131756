import { Component, OnDestroy, OnInit } from '@angular/core';
import { enterLeaveAnimation, onMainContentChange } from 'animations/animations';
import { MessageService } from './messenger/message.service';
import { Message, SystemMessage } from './messenger/message';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from './modal/modal.component';
import { AuthService } from './services/auth.service';
import { EMPTY, Observable, Subject } from 'rxjs';
import { User } from './lib/model/user';
import { UiService } from './services/ui.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { ProfileService } from './services/profile.service';
import { environment } from 'src/environments/environment';
import { LoadComponentService } from './services/load-component.service';
import { FleetSelectComponent } from './fleet-select/fleet-select.component';
import { ProjectsService } from './projects/projects.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [onMainContentChange, enterLeaveAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  public user: Observable<User>;
  public isMobile: boolean;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private profileService: ProfileService,
    private projectService: ProjectsService,
    private messenger: MessageService,
    public dialog: MatDialog,
    protected uiService: UiService,
    private auth: AuthService,
    private loadComponentService: LoadComponentService
  ) {
    this.user = auth.getUser();
  }
  ngOnInit(): void {
    this.isMobile = window.innerWidth < 1000;
    this.user.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      // console.log('app.component.ts: user loaded');
      if (user) {
        this.profileService
          .fetchProfile()
          .pipe(
            takeUntil(this.unsubscribe$),
            catchError((err, obj) => {
              window.app.hideLoader();
              this.messenger.error(
                new Message(
                  $localize`Wystąpił problem`,
                  $localize`Brak połączenia z serwerem. Odśwież stronę lub spróbuj ponownie później.`
                )
              );
              return EMPTY;
            })
          )
          .subscribe((profile) => {
            if (profile.isExpired) {
              window.location.replace(environment.profileUrl);
              return;
            }
            this.profileService.updateProfile(profile);
            if ((this.profileService.currentSettings.usedVehicles || []).length === 0) {
              this.loadComponentService.add(FleetSelectComponent);
            }
            if (profile.latestProject) {
              this.projectService.currentProject = profile.latestProject;
            }
            window.app.hideLoader(); // main loader before app starts
          });
      }
    });

    this.profileService
      .getProfile()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile) => {
        this.uiService.setLengthUnit(profile.settings.lengthUnit);
        this.uiService.setWeightUnit(profile.settings.weightUnit);
        this.uiService.setLabelFontModifier(profile.settings.labelConfig.fontSize);
      });

    this.handleAuthTokens();
    this.handleMessages();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private handleAuthTokens() {
    this.auth.tokenExpired$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.auth.updateToken());
    this.auth.tokenRefreshed$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.auth.setTokenFromKeycloakInstance());
  }

  private handleMessages() {
    this.messenger
      .getCritical()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((message: Message) => {
        window.app.hideLoader();
        this.uiService.setLoading(false);
        const dialog = this.dialog.open(ModalComponent, {
          disableClose: true,
          data: { title: message.title, body: message.body, style: 'error' }
        });

        dialog
          .afterClosed()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {
            window.location.reload();
          });
      });

    this.messenger
      .getSystemMessage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((message: SystemMessage) => {
        window.app.hideLoader();
        this.uiService.setLoading(false);
        const dialog = this.dialog.open(ModalComponent, {
          disableClose: true,
          data: { type: message.type, ...message.context }
        });
        dialog
          .afterClosed()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {});
      });
  }
}
