import { Constants } from '../../lib/constants';
import { Space } from './space';
import { Axle } from '../../axles/lib/axle';
import { Grid3DLines } from '../../../lib/helper/grid-3d-lines';
import { Box3, Mesh, Object3D, Vector3 } from 'three';
import { Constants as Config } from 'src/app/config/constants';

export abstract class SpaceMesh {
  public type: string;
  protected description: string;
  public space: Space;
  public meshObj: Mesh;
  public grids: Grid3DLines[];

  constructor(type: string, obj: Space) {
    this.meshObj = new Mesh();
    this.meshObj.userData.uuid = obj.uuid;
    this.space = obj;
    this.type = type;
    this.meshObj.name = 'space-mesh';
    this.grids = [];

    this.meshObj.material = [
      Constants.TRANSPARENT_MATERIAL,
      Constants.FLOOR_MATERIAL,
      Constants.DISABLED_SPACE_MATERIAL
    ];
  }

  get position(): Vector3 {
    return this.meshObj.position;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(value: string) {
    this.description = value;
  }

  public getType() {
    return this.type;
  }

  public updateAxles() {
    //console.log('updateAxles in space Mesh', this.space.axles);
    this.removeAxles();
    this.addAxles();
  }

  private removeAxles() {
    for (const mesh of this.meshObj.children.filter((x) => x.name === 'axle-mesh')) {
      this.meshObj.remove(mesh);
    }
  }

  public showAxles() {
    for (const mesh of this.meshObj.children.filter((x) => x.name === 'axle-mesh')) {
      mesh.visible = true;
    }
  }

  public hideAxles() {
    for (const mesh of this.meshObj.children.filter((x) => x.name === 'axle-mesh')) {
      mesh.visible = false;
    }
  }

  protected addAxles() {
    this.meshObj.geometry.computeBoundingBox();
    const offsetY = this.meshObj.geometry.boundingBox.min.y;
    const offsetX = this.meshObj.geometry.boundingBox.min.x;

    this.space.axles = this.space.axles.map((axle) => new Axle(axle));

    //console.log('space axles', this.space.axles);
    for (const axle of this.space.axles) {
      const mesh = axle.mesh;
      //console.log('axle', axle, 'mesh', mesh);
      const bb = new Box3().setFromObject(mesh.obj);
      const height = bb.max.y - bb.min.y;

      if (mesh !== undefined) {
        mesh.obj.position.y = offsetY - height;
        mesh.obj.position.x = offsetX + axle.offset * Config.DIMENSION_SCALING;
        this.meshObj.add(mesh.obj);
      }
    }
  }

  public getGrids(): Object3D[] {
    return this.meshObj.children.filter((x) => x.name === 'space-grid').map((grid) => grid as Object3D);
  }

  public getGridHelpers(): Grid3DLines[] {
    return this.grids;
  }

  public redrawGrid(show = false) {
    while (this.getGrids().length > 0) {
      this.meshObj.remove(this.getGrids()[0]);
    }
    this.grids.forEach((grid) => grid.dispose());
    this.grids = [];
    if (show) {
      this.addGrid(true);
    }
  }

  protected addGrid(visible = false) {
    const box = new Box3().setFromObject(this.meshObj);
    const size = new Vector3();
    box.getSize(size);

    const grid = new Grid3DLines(
      size,
      (this.space.calculationSettings.grid?.cellSize || 500) * Config.DIMENSION_SCALING,
      {
        color: Config.VEHICLE_BORDERS_COLOR,
        transparent: true,
        opacity: (this.space.calculationSettings.grid?.intensity || 5) / 10
      }
    );
    grid.obj.name = 'space-grid';
    grid.obj.visible = visible;
    this.meshObj.add(grid.obj);
    this.grids.push(grid);
  }

  public dispose() {
    if (!this.meshObj) {
      return;
    }
    if (Array.isArray(this.meshObj.material)) {
      this.meshObj.material.forEach((m) => m.dispose());
    } else {
      this.meshObj.material.dispose();
    }

    this.meshObj.geometry?.dispose();

    this.grids.forEach((g) => {
      g.dispose();
    });
    this.space.axles.forEach((axle) => {
      axle.disposeMesh();
    });
  }
}
