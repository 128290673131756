import { Load } from '../../../load/lib/load';
import { LoadGroup } from './load-group';
export class LoadGroupCondensed extends LoadGroup {
  public has(aLoad: Load) {
    const sameBoxes =
      aLoad.name === this.load.name &&
      aLoad.color === this.load.color &&
      aLoad.weight === this.load.weight &&
      aLoad.shape === this.load.shape;

    return sameBoxes;
  }
}
