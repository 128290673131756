import {
  EdgesGeometry,
  Float32BufferAttribute,
  LineBasicMaterial,
  LineSegments,
  Vector3
} from 'three';
import { Constants } from 'src/app/vehicle/lib/constants';
import { OtherMesh } from '../other-mesh';
import { Other } from '../other';

const darkWood = 0;
const lightWood = 1;

export class EPALCP7Mesh extends OtherMesh {
  public constructor(other: Other) {
    super(other);
    this.mesh.material = [
      Constants.DARK_BROWN_WOOD_MATERIAL,
      Constants.LIGHT_BROWN_WOOD_MATERIAL
    ];
    this.init();
  }

  override getBufferGeometry() {
    const x0 = 0;
    const z0 = 0;
    const x1 = x0 + this.length;
    const z1 = z0 + this.width;
    const xc = (x1 - x0) / 2;
    const zc = (z1 - z0) / 2;
    const plankW = this.scale(100);
    const plankW2 = this.scale(80);
    const plankW3 = this.scale(125);
    const plankH = this.scale(18);
    const plankH2 = this.scale(22);
    const plankL = this.length;

    let currentY = 0;
    const r = this.scale(14);

    const allRounded = [r, r, r, r] as const;
    const leftExternalRadiuses = [r, 0, 0, r] as const;
    const rightExternalRadiuses = [0, r, r, 0] as const;
    const backExternalRadiuses = [r, r, 0, 0] as const;
    const frontExternalRadiuses = [0, 0, r, r] as const;

    //bottom planks
    const bottomPlankCutoff = this.scale(100);
    const bottomPlanks = [
      new Vector3(
        x0 + bottomPlankCutoff / 2,
        currentY,
        z0 + bottomPlankCutoff / 2
      ),
      new Vector3(
        x0 + bottomPlankCutoff / 2,
        currentY,
        z0 + bottomPlankCutoff / 2 + plankW2 + bottomPlankCutoff / 2
      ),
      new Vector3(x0 + bottomPlankCutoff / 2, currentY, zc - plankW / 2),
      new Vector3(
        x0 + bottomPlankCutoff / 2,
        currentY,
        z1 - plankW2 * 2 - bottomPlankCutoff
      ),
      new Vector3(
        x0 + bottomPlankCutoff / 2,
        currentY,
        z1 - plankW2 - bottomPlankCutoff / 2
      )
    ];
    bottomPlanks.forEach((p, index) =>
      this.plank(
        p,
        this.length - bottomPlankCutoff,
        index === 2 ? plankW : plankW2,
        plankH,
        darkWood
      )
    );
    //after bottom planks
    currentY += plankH;
    const afterBottomPlanks = [
      new Vector3(
        x0 + bottomPlankCutoff / 2,
        currentY,
        z0 + bottomPlankCutoff / 2
      ),
      new Vector3(xc - plankW / 2, currentY, z0 + bottomPlankCutoff / 2),
      new Vector3(
        x1 - bottomPlankCutoff / 2 - plankW,
        currentY,
        z0 + bottomPlankCutoff / 2
      )
    ];
    afterBottomPlanks.forEach((p, index) =>
      this.plank(p, plankW, this.width - bottomPlankCutoff, plankH, darkWood)
    );

    //boxes
    currentY += plankH;
    const boxH = this.scale(80);
    const boxOffset = this.scale(50);
    const boxSize = [plankW, plankW2, boxH] as const;
    const leftBoxes = [
      new Vector3(x0 + boxOffset, currentY, z0 + boxOffset),
      new Vector3(x0 + boxOffset, currentY, zc - plankW2 / 2),
      new Vector3(x0 + boxOffset, currentY, z1 - plankW2 - boxOffset)
    ];
    leftBoxes.map((p) => {
      this.plank(p, ...boxSize, lightWood, ...leftExternalRadiuses);
    });
    const centerBoxes = [
      new Vector3(xc - plankW / 2, currentY, z0 + boxOffset),
      new Vector3(xc - plankW / 2, currentY, zc - plankW2 / 2),
      new Vector3(xc - plankW / 2, currentY, z1 - plankW2 - boxOffset)
    ];
    centerBoxes.map((p) => {
      this.plank(p, ...boxSize, lightWood);
    });
    const rightBoxes = [
      new Vector3(x1 - plankW - boxOffset, currentY, z0 + boxOffset),
      new Vector3(x1 - plankW - boxOffset, currentY, zc - plankW2 / 2),
      new Vector3(x1 - plankW - boxOffset, currentY, z1 - plankW2 - boxOffset)
    ];
    rightBoxes.map((p) => {
      this.plank(p, ...boxSize, lightWood, ...rightExternalRadiuses);
    });

    //before top planks
    currentY += boxH;
    this.plank(
      new Vector3(x0, currentY, z0),
      plankL,
      plankW3,
      plankH2,
      darkWood,
      ...backExternalRadiuses
    );
    this.plank(
      new Vector3(x0, currentY, z1 - plankW3),
      plankL,
      plankW3,
      plankH2,
      darkWood,
      ...frontExternalRadiuses
    );
    this.plank(
      new Vector3(x0, currentY, zc - plankW3 / 2),
      plankL,
      plankW3,
      plankH2,
      darkWood
    );

    //top planks
    currentY += plankH2;
    this.plank(
      new Vector3(x0, currentY, z0),
      plankW,
      this.width,
      plankH,
      lightWood,
      ...leftExternalRadiuses
    );
    this.plank(
      new Vector3(xc - plankW / 2, currentY, z0),
      plankW,
      this.width,
      plankH,
      lightWood
    );
    this.plank(
      new Vector3(x1 - plankW, currentY, z0),
      plankW,
      this.width,
      plankH,
      lightWood,
      ...rightExternalRadiuses
    );
    const spacing = this.scale(40);
    const middlePlanks = [
      new Vector3(x0 + plankW, currentY, z0),
      new Vector3(x0 + plankW * 2 + spacing, currentY, z0),
      new Vector3(x0 + plankW * 2 + plankW2 + spacing * 2, currentY, z0),
      new Vector3(x0 + plankW * 2 + plankW2 * 2 + spacing * 3, currentY, z0),

      new Vector3(x1 - plankW * 2, currentY, z0),
      new Vector3(x1 - plankW * 2 - plankW2 - spacing, currentY, z0),
      new Vector3(x1 - plankW * 2 - plankW2 * 2 - spacing * 2, currentY, z0),
      new Vector3(x1 - plankW * 2 - plankW2 * 3 - spacing * 3, currentY, z0)
    ];
    middlePlanks.forEach((p, index) => {
      this.plank(
        p,
        [0, 4].includes(index) ? plankW : plankW2,
        this.width,
        plankH,
        lightWood
      );
    });

    currentY += plankH;
    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    const geo = new EdgesGeometry(this.mesh.geometry, 1);
    const mat = new LineBasicMaterial({ color: 0x333333 });
    const wireframe = new LineSegments(geo, mat);
    this.mesh.add(wireframe);

    this.addSpaces(0, currentY);
  }
}
