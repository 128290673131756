import { TextLabel } from './TextLabel';
import { Vector3 } from 'three';
import { Load } from 'src/app/load/lib/load';

export class LoadCenterLabel extends TextLabel {
  public constructor(txt: string, scale = 1.0) {
    super(txt, new Vector3(), 0, 'cuboid-label fs14', scale);
  }

  public updatePosition(load: Load) {
    const position = new Vector3();
    load.mesh.getWorldPosition(position);
    //const position = load.mesh.position.clone();
    this.setPosition(position);
  }

  public makeCopy() {
    return new LoadCenterLabel(this.getText(), this.textScale);
  }
}
