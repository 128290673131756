import { VehicleContext } from '../../lib/model/vehicle-context';
import { PositionerRequest } from '../../lib/communication/positioner.request';

export class ReloadLoadsRequest extends PositionerRequest {
  constructor(context: VehicleContext) {
    super();
    Object.assign(this, {
      historyUuid: context.getHistoryUuid(),
      uuid: context.getUuid(),
      initialTimestamp: context.getInitialTimestamp(),
      orbitControlsState: context.getOrbitControlsState(),
      projectId: context.getProjectId(),
      settings: context.getSettings(),
      action: 'reload'
    });
  }
}
