import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { Vehicle } from '../../lib/vehicle';
import { VehicleType } from '../../lib/vehicle-type';
import { VehicleService } from '../../lib/vehicle.service';
import { CustomVehicleService } from '../../lib/custom-vehicle.service';
import { VehicleService as VehicleUiService } from '../../vehicle.service';
import { UiService } from 'src/app/services/ui.service';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { CreateComponent } from '../create/create.component';

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListComponent implements OnInit, OnDestroy {
  @Input() listType: string;
  //@Input()
  /*set vehicleType(value: VehicleType) {
    this.vehicleType$.next(value);
  }*/
  private vehicleType$: Observable<VehicleType>; // = new BehaviorSubject<VehicleType>(this.vehicleType);

  @Output() itemSelectedEvent = new EventEmitter<Vehicle>();

  public selectedItem: Vehicle;

  public items: Vehicle[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: VehicleService,
    private customVehicleService: CustomVehicleService,
    private vehicleUiService: VehicleUiService,
    protected ui: UiService,
    private loadComponentService: LoadComponentService
  ) {
    this.vehicleType$ = vehicleUiService.vehicleType$;
  }

  ngOnInit(): void {
    this.select(null);
    this.vehicleUiService
      .getSelectedVehicle()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicle) => {
        this.selectedItem = vehicle;
        this.itemSelectedEvent.emit(vehicle);
      });

    if (this.listType === 'system') {
      this.vehicleType$
        .pipe(
          takeUntil(this.unsubscribe$),
          switchMap((type) => this.service.getByType(type))
        )
        .subscribe((vehicles) => {
          this.items = vehicles;
          this.select(null);
        });
    } else if (this.listType === 'user') {
      this.customVehicleService.vehicles$.pipe(takeUntil(this.unsubscribe$)).subscribe((list) => {
        this.items = list || [];
        this.selectIfFound(this.selectedItem);
      });
    }
  }

  private selectIfFound(item?: Vehicle) {
    if (item) {
      const found = this.items.find((x) => x.uuid === item.uuid);
      if (found) {
        this.select(found);
        return;
      }
    }
    this.select(null);
  }

  protected delete(item: Vehicle) {
    this.customVehicleService
      .delete(item)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.select(null);
        this.itemSelectedEvent.emit(null);
      });
  }

  protected edit(item: Vehicle) {
    this.loadComponentService.clear('select-list-component.ts edit');
    const component = this.loadComponentService.add(CreateComponent);
    component.instance.setEdit(item);
  }

  protected select(item: Vehicle): void {
    this.vehicleUiService.selectVehicle(item);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
