import { Statistics } from '../model/statistics';

import { Profile, ProfileInterface } from '../model/profile';
import { Load } from 'src/app/load/lib/load';
import { OrbitControlsState } from '../model/orbit-controls-state';
import { LoadGroupList } from '../model/load-group/load-group-list';
import { LoadGroupCondensed } from '../model/load-group/load-group-condended';
import { LoadFactory } from 'src/app/load/lib/load-factory';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';
import { VehicleFactory } from 'src/app/vehicle/lib/vehicle-factory';
import { Settings } from 'src/app/vehicle/form/settings/settings';
import { LoadingStep } from '../model/loading-step';

export class PositionerResponse {
  uuid: string;
  historyUuid: string;
  message: string;

  loads: Load[]; //nigdzie nie załadowane ładunki

  addedLoads: Load[];
  vehicle: Vehicle;
  statistics: Statistics[];
  messages: string[];
  suggestedVehicles: Vehicle[];

  timestamp: number;
  createdAt: number;
  initialTimestamp: number;
  orbitControlsState: OrbitControlsState;
  projectId: string;
  settings?: Settings;

  steps: LoadingStep[];

  get allLoads(): Load[] {
    return this.vehicle.enabledSpaces
      .map((space) => space.loads)
      .flat()
      .concat(this.loads);
  }

  get loadsCondensed(): LoadGroupList {
    const list = new LoadGroupList(LoadGroupCondensed);
    this.allLoads.forEach((load) => {
      list.addLoad(load);
    });
    return list;
  }
  constructor(obj: any, vehicleFactory: VehicleFactory, loadFactory: LoadFactory) {
    Object.assign(this, obj);

    if (this.vehicle != null && !(this.vehicle instanceof Vehicle)) {
      this.vehicle = vehicleFactory.recreate(this.vehicle);
    }
    this.statistics = (this.statistics ?? []).map((s) => new Statistics(s));
    this.addedLoads = (this.addedLoads ?? []).map((l) => loadFactory.recreateLoad(l));

    this.steps = (obj?.steps || []).map((s: LoadingStep, i: number) => new LoadingStep(s.loadIds, i + 1));

    this.settings = new Settings(this.settings ?? {});
  }
}
