import { Load } from 'src/app/load/lib/load';
import { Vector3 } from 'three';
import { TextLabel } from './TextLabel';

export abstract class FlooringLabel extends TextLabel {
  public constructor(txt: string, protected textScale = 1.0) {
    super(txt, new Vector3(), 0, '', textScale);
  }

  protected abstract updateCssClass(load: Load): void;
  protected abstract updatePosition(load: Load): void;

  update(load: Load) {
    this.updatePosition(load);
    this.updateCssClass(load);
  }
}
