<mat-expansion-panel (opened)="opened = true" (closed)="opened = false">
  <mat-expansion-panel-header>
    <mat-panel-title i18n>Obrót ładunku</mat-panel-title>
  </mat-expansion-panel-header>
  <div class="floorable-container" [formGroup]="form">
    <div class="row">
      <mat-checkbox color="primary" formControlName="blockAll"
        (change)="change('blockAll', $event)" i18n>Zablokuj
        ułożenie</mat-checkbox>
      <button mat-icon-button (click)="toggleBlockAllHelp()"
        class="style-success" type="button">
        <mat-icon color="primary" aria-hidden="false" i18n-aria-label
          aria-label="Wyświetl opis ustawienia">help_outline</mat-icon>
      </button>
    </div>

    <div class="row">
      <mat-checkbox color="primary" formControlName="allowAll"
        (change)="change('allowAll', $event)" i18n>Obracaj
        dowolnie</mat-checkbox>
      <button mat-icon-button (click)="toggleAllowAllHelp()"
        class="style-success" type="button">
        <mat-icon color="primary" aria-hidden="false" i18n-aria-label
          aria-label="Wyświetl opis ustawienia">help_outline</mat-icon>
      </button>
    </div>
  </div>
</mat-expansion-panel>