import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss']
})
export class NameComponent {
  public form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.createForm();
  }

  public getValue(): string {
    return this.form.get('name').value;
  }

  private createForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required])]
    });
  }
}
