import { Box3, EdgesGeometry, LineSegments, Material, Mesh, Vector3 } from 'three';
import { LoadDisplaySettings } from './load-display-settings';
import { DisplayCacheService } from 'src/app/services/display-cache.service';

export abstract class LoadMesh {
  protected selected = false;
  public obj: Mesh;
  public boundingBox: Box3;

  constructor(
    uuid: string,
    protected color: number,
    public readonly settings: LoadDisplaySettings,
    protected displayCache: DisplayCacheService
  ) {
    this.obj = new Mesh();
    this.obj.userData.uuid = uuid;

    this.obj.material = displayCache.getLoadMaterial(color, settings, false, false);
    this.obj.userData['originalColor'] = (this.obj.material as any).color.getHex();
    this.obj.name = 'load';
  }

  get position(): Vector3 {
    return this.obj.position;
  }

  public getWorldPosition(target: Vector3) {
    return this.obj.getWorldPosition(target);
  }

  public abstract getName(): string;

  public getMaterial() {
    return this.obj.material;
  }

  protected prepareMesh() {
    const wireframe = new EdgesGeometry(this.obj.geometry);
    const mat = this.displayCache.getLoadBorderMaterial(this.settings, this.selected);
    const line = new LineSegments(wireframe, mat);
    line.name = 'border';
    this.obj.add(line);
  }

  protected getBorders() {
    return this.obj.children.find((x) => x.name === 'border') as LineSegments;
  }

  public select(value = true) {
    this.selected = value;
    const border = this.getBorders();
    if (border) {
      border.material = this.displayCache.getLoadBorderMaterial(this.settings, value);
    }
    this.obj.userData['selected'] = value;

    this.obj.material = this.displayCache.getLoadMaterial(
      this.obj.userData['originalColor'],
      this.settings,
      value,
      false
    );
  }

  public hover() {}

  public unhover() {}

  public dispose(displayCache: DisplayCacheService) {
    this.obj.traverse((child) => {
      if (child instanceof Mesh) {
        child.geometry.dispose();
        if (typeof child.material.dispose !== 'function') {
          child.material.forEach((m) => m.dispose());
        } else {
          child.material.dispose();
        }
      }
    });
    this.obj.geometry.dispose();
    if (this.obj.material instanceof Material) {
      this.obj.material.dispose();
    } else {
      this.obj.material.forEach((m) => m.dispose());
    }
  }
}
