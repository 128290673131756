import { Load } from 'src/app/load/lib/load';
import { Vector } from './vector';
import { Position } from '../positioner/position';

export class LoadMovement {
  public constructor(
    public load: Load,
    public relativePosition: Position,
    public targetSpaceId: string
  ) {}
}
