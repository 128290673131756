import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Vector } from 'src/app/lib/communication/vector';
import { UiService } from 'src/app/services/ui.service';
import { Space } from '../../lib/space';
import { SpaceService } from '../../space.service';
import { FormComponent } from '../../type/box/form/form.component';
import { WeightComponent } from '../weight/weight.component';
// import { NameComponent } from '../name/name.component';
// import { WeightComponent } from '../weight/weight.component';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent {
  @Output() createSpaceEvent = new EventEmitter<Space>();
  @ViewChild('box') box: FormComponent;
  @ViewChild('weight') weight: WeightComponent;
  // @ViewChild('name') name: NameComponent;
  // @ViewChild('color') color: ColorComponent;
  // @ViewChild('flooring') flooring: FlooringComponent;
  eventsSubject: Subject<void> = new Subject<void>();
  formMode = 'plain-form';
  space: Space;
  minSize: Vector;
  order = 0;

  constructor(private service: SpaceService, protected ui: UiService) {}

  close(): void {
    this.service.closeModal('space/form/create/create.component.ts');
    // this.service.close();
  }

  onSubmit() {
    this.emitEventToChild();
  }

  emitEventToChild() {
    this.eventsSubject.next();
  }

  validateForm(): boolean {
    const boxValid = this.box.validateForm();
    const weightValid = this.weight.validateForm();
    return boxValid && weightValid;
  }

  getObject() {
    const object = this.box.getObject();
    object.maxLoadingWeight = this.weight.getValue();
    console.log('vehicle/space/form/create/create.component.ts getObject', object, this.space);
    return object;
  }
}
