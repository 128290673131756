import { Vector3 } from 'three';
import { Space } from '../../vehicle/space/lib/space';
import { TextLabel } from './TextLabel';
import { UiService } from '../../services/ui.service';
import { Constants as Config } from 'src/app/config/constants';

export class FlooringLevelLabel extends TextLabel {
  public constructor(txt: string, protected textScale = 1.0) {
    super(txt, new Vector3(), 0, 'cuboid-label blue fs14', textScale);
  }

  public updatePosition(space: Space) {
    const spaceWorldPosition = space.mesh.meshObj.getWorldPosition(
      new Vector3()
    );
    const labelPosition = new Vector3().copy(spaceWorldPosition);
    labelPosition.x += space.length * Config.DIMENSION_SCALING;
    labelPosition.z += space.width * Config.DIMENSION_SCALING;
    labelPosition.y += space.settings.height * Config.DIMENSION_SCALING;
    this.setPosition(labelPosition);
  }

  makeCopy(): TextLabel {
    return new FlooringLevelLabel(this.getText(), this.textScale);
  }
}
