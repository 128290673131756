import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Settings } from 'src/app/lib/model/settings';
import { SettingsService } from './settings.service';

interface FloorUnit {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  settings: Settings;
  isLoading = false;

  floorUnits: FloorUnit[] = [
    { value: 'mm', viewValue: $localize`milimetry` },
    { value: 'cm', viewValue: $localize`centrymetry` },
    { value: 'm', viewValue: $localize`metry` },
    { value: 'LDM', viewValue: $localize`LDM` }
  ];

  public form = this.fb.group({
    floorUnit: new UntypedFormControl(null, [Validators.required]),
    showPercentage: new UntypedFormControl(null, [Validators.required])
  });

  private unsubscribe$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder, private service: SettingsService) {}

  ngOnInit(): void {
    this.service.profile$.pipe(takeUntil(this.unsubscribe$)).subscribe((profile) => {
      this.isLoading = false;
      this.settings = profile.settings;
      (this.form.controls.floorUnit as UntypedFormControl).setValue(this.settings.summaryFloorUnit);
      (this.form.controls.showPercentage as UntypedFormControl).setValue(this.settings.summaryFloorPercentage);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit() {
    this.isLoading = true;
    this.service
      .saveSettings(
        new Settings({
          ...this.settings,
          summaryFloorUnit: this.form.controls.floorUnit.value,
          summaryFloorPercentage: this.form.controls.showPercentage.value
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newSettings) => {
        this.service.updateSettingsInView(newSettings);
        this.close();
      });
  }

  close(): void {
    this.service.close();
  }
}
