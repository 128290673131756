import { Vector3 } from 'three';
import { UiService } from '../../services/ui.service';
import { Axle } from '../../vehicle/axles/lib/axle';
import { TextLabel } from './TextLabel';
import { Constants as Config } from 'src/app/config/constants';

export class AxleLabel extends TextLabel {
  public constructor(txt: string, scale = 1.0) {
    super(txt, new Vector3(), 0, 'axle-box-content fs12', scale);
  }

  public updatePosition(axle: Axle, xSpaceOffset: number): void {
    const labelPosition = new Vector3(
      xSpaceOffset + axle.mesh.obj.position.x,
      -400 * Config.DIMENSION_SCALING,
      axle.mesh.obj.position.z
    );
    this.setPosition(labelPosition);
  }

  makeCopy(): TextLabel {
    return new AxleLabel(this.getText(), this.textScale);
  }
}
