<div class="help-flooring">
  <div class="text-right with-img">
    <img src="assets/images/block-rotation.png" alt="Zablokuj ułożenie"
      i18n-alt />
  </div>
  <div class="help-main">
    <svg class="help-bg" viewBox="0 0 112 129"
      xmlns="http://www.w3.org/2000/svg">
      <rect class="rect" width="32" height="48" />
      <rect class="rect" x="43" width="69" height="48" />
      <rect class="rect" y="58" width="32" height="71" />
      <rect class="rect" x="43" y="58" width="69" height="71" />
    </svg>
    <h2 class="primary" i18n>Zablokuj ułożenie</h2>
    <p i18n>
      Ładunek nie będzie obracany w żadnym kierunku, nawet jeśli pozwalałoby to
      na lepsze rozmieszczenie.
    </p>
    <p i18n>
      Możesz użyć tej opcji, jeśli ładunek musi zostać załadowany w konkretny
      sposób, na przykład przy załadunku bocznym długich ładunków.
    </p>
  </div>
</div>