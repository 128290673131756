<div class="tool" *ngIf="isNotEmptyContext()"
  [class.enabled]="tools.measurement === true" i18n-matTooltip
  matTooltip="Zmierz wolną przestrzeń" [matTooltipPosition]="'right'"
  [matTooltipShowDelay]="250" #measurementTooltip="matTooltip">
  <button mat-button (click)="toggleMeasurementTool()">
    <mat-icon>straighten</mat-icon>
  </button>
</div>

<div class="tool" *ngIf="loadingStepsAvailable()"
  [class.enabled]="tools.loadingSteps === true" i18n-matTooltip
  matTooltip="Etapy załadunku" [matTooltipPosition]="'right'"
  [matTooltipShowDelay]="250" #measurementTooltip="matTooltip">

  <button mat-button (click)="toggleLoadingSteps()">
    <mat-icon>view_timeline</mat-icon>
  </button>
</div>

<div class="share-project">
  <app-project-sharing></app-project-sharing>
</div>