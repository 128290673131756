<div cdkDrag cdkDragRootElement="app-loading-steps">
    <div class="header" cdkDragHandle>
        <span>
            <span i18n>Etapy załadunku</span>:
        </span>
        <button class="close-btn" (click)="close()" title="Zamknij" i18n-title>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="steps-container">
        <div *ngFor="let step of steps" class="step"
            [class.current]="currentStepNumber == step.step"
            (click)="selectStep(step.step)">
            <div class="step-number">{{step.step}}.</div>
            <div>
                <div>
                    {{ step.cnt }} x {{ step.load.fullName }}
                </div>
                <div class="size">{{ step.load | dimensions | async }}</div>
            </div>
        </div>

    </div>
</div>