<ng-container [ngSwitch]="mode">
  <mat-expansion-panel *ngSwitchCase="'mat-expansion-panel'" #dataPanel
    (opened)="dataPanelOpenState = true" (closed)="dataPanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong i18n>Dostosuj wymiary kontenera</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container [ngTemplateOutlet]="formTemplate"></ng-container>
  </mat-expansion-panel>
  <ng-container *ngSwitchCase="'plain-form'" [ngTemplateOutlet]="formTemplate">
  </ng-container>
</ng-container>

<ng-template #formTemplate>
  <div [formGroup]="form" novalidate (ngSubmit)="onSubmit()"
    class="load-form full-width">
    <div class="row">
      <mat-form-field>
        <mat-label i18n>Długość ({{ ui.getLengthUnit() | async }})</mat-label>

        <input matInput appOnlyNumber required formControlName="length"
          autocomplete="off" />
        <mat-error *ngIf="form.get('length').hasError('required')">
          Pole <strong>wymagane</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Szerokość ({{ ui.getLengthUnit() | async }})</mat-label>

        <input matInput appOnlyNumber required formControlName="width"
          autocomplete="off" />
        <mat-error *ngIf="form.get('width').hasError('required')">
          <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
          <strong><ng-container i18n>wymagane</ng-container></strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label i18n>Wysokość ({{ ui.getLengthUnit() | async }})</mat-label>

        <input matInput appOnlyNumber required formControlName="height"
          autocomplete="off" />
        <mat-error *ngIf="form.get('height').hasError('required')">
          <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
          <strong><ng-container i18n>wymagane</ng-container></strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</ng-template>