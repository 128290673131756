import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectsService } from './projects.service';
import { debounceTime, distinctUntilChanged, filter, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { Project } from './lib/project';
import { LoadComponentService } from '../services/load-component.service';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { DateRange } from '../dates-filter/lib/date-range';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogAction,
  ConfirmationDialogComponent
} from '../confirmation-dialog/confirmation-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { FilterType } from '../dates-filter/lib/filter-type';
import { FilterChangeEvent } from '../dates-filter/lib/filter-change.event';
import { ProjectList } from './lib/project-list';
import { DirectoryCreateComponent } from './directory-create/directory-create.component';
import { DirectoryListItem } from './lib/directory-list-item';
import { ProfileService } from '../services/profile.service';
import { ProfileInterface } from '../lib/model/profile';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  projects$: Observable<Project[]>;
  list$: Observable<ProjectList>;
  showHelp = true;
  search: UntypedFormControl;
  projectNames$: Observable<string[]>;
  selectedDatesFilter = FilterType.month;
  protected isLoading = true;
  private readonly helpCookieName = 'prl-help';
  protected readonly profile$: Observable<ProfileInterface>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: ProjectsService,
    private loadComponentService: LoadComponentService,
    private dialog: MatDialog,
    profileService: ProfileService
  ) {
    this.profile$ = profileService.getProfile();
    this.projects$ = service.projects$;
    this.list$ = service.list$;
    this.search = new UntypedFormControl(service.lastSearchQuery, []);
    this.projectNames$ = this.search.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((value) =>
        this.service
          .fetchProjectNames()
          .pipe(map((response) => response.filter((name) => name.toLowerCase().includes((value ?? '').toLowerCase()))))
      )
    );
  }

  ngOnInit(): void {
    // console.log('ProjectsComponent onInit');

    if (localStorage.getItem(this.helpCookieName)) {
      this.showHelp = false;
    }

    // update project list
    this.search.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((searchQuery) => {
        this.selectedDatesFilter = FilterType.none;
        if (searchQuery) {
          this.service
            .fetchProjects(new DateRange(), searchQuery)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((list) => this.service.updateProjectList(list));
        } else {
          this.service
            .fetchProjects(undefined, '')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((list) => this.service.updateProjectList(list));
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected openProject(project: Project) {
    // console.log('projects.component.ts: project selected', project);
    this.service.currentProject = project;
  }

  protected editProject(project: Project) {
    const component = this.loadComponentService.add(ProjectCreateComponent);
    component.instance.project = project;
  }

  protected deleteProject(project: Project) {
    // console.log('projects.component.ts: project delete clicked', project);
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { action: ConfirmationDialogAction.deleteProject }
    });

    confirmationDialogRef
      .afterClosed()
      .pipe(
        filter((confirmed) => confirmed),
        switchMap(() => this.service.deleteProject(project)),
        switchMap(() => this.service.fetchProjects()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((response) => {
        this.service.updateProjectList(response);
        this.service.clearNamesCache();
      });
  }

  hideHelp() {
    localStorage.setItem(this.helpCookieName, new Date().getTime() + '');
    this.showHelp = false;
  }

  newProject() {
    // console.log('projects.component.ts: new project clicked');
    this.loadComponentService.clear('projects.component.ts');
    this.loadComponentService.add(ProjectCreateComponent);
  }

  newDirectory() {
    this.loadComponentService.clear('projects.component.ts');
    this.loadComponentService.add(DirectoryCreateComponent);
  }

  protected editDirectory(listItem: DirectoryListItem) {
    const component = this.loadComponentService.add(DirectoryCreateComponent);
    component.instance.directory = listItem.directory;
  }

  protected deleteDirectory(listItem: DirectoryListItem) {
    // console.log('projects.component.ts: project delete clicked', project);
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { action: ConfirmationDialogAction.deleteDirectory }
    });

    confirmationDialogRef
      .afterClosed()
      .pipe(
        filter((confirmed) => confirmed),
        switchMap(() => this.service.deleteDirectory(listItem.directory)),
        switchMap(() => this.service.fetchProjects()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((response) => {
        this.service.updateProjectList(response);
        this.service.clearNamesCache();
      });
  }

  changeFilters(event: FilterChangeEvent) {
    //console.log('projects.component.ts: changed filters', event);
    this.selectedDatesFilter = event.filterType;
    this.updateList(event.range, this.search.value);
  }

  private updateList(range?: DateRange, search?: string) {
    this.service
      .fetchProjects(range, search)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        //console.debug('projects.component.ts: update list', list);
        this.isLoading = false;
        this.service.updateProjectList(list);
      });
  }
}
