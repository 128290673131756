import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { LoadGroup } from '../lib/model/load-group/load-group';
import { SceneService } from '../scene/scene.service';
import { UiService } from '../services/ui.service';
import { Subject, takeUntil, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Color } from 'three';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Load } from '../load/lib/load';
import { LoadComponentService } from '../services/load-component.service';
import { EditComponent as EditLoadedGroupComponent } from '../load/form/edit/edit.component';

@Component({
  selector: 'app-loaded-element',
  templateUrl: './loaded-element.component.html',
  styleUrls: ['./loaded-element.component.scss']
})
export class LoadedElementComponent implements OnInit, OnDestroy {
  @Input() group: LoadGroup;
  @Input() protected drag: boolean = false;

  fillColor = 'rgb(0, 0, 255)';
  cnt: number;
  cnt$: Subject<number>;
  listShowStart = 0;
  listShowSize = 50;

  protected readonly maxCount = 10000;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private sceneService: SceneService,
    protected ui: UiService,
    private componentLoader: LoadComponentService
  ) {}

  ngOnInit(): void {
    // this.fillColor = new THREE.Color(this.load.load.color).getStyle();

    this.fillColor = new Color(this.group.load.color).getStyle();
    this.cnt = this.group.cnt;
    this.cnt$ = new Subject<number>();
    this.cnt$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((val) => this.sceneService.changeLoadCount(this.group, val)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.sceneService.selectedLoads$.pipe(takeUntil(this.unsubscribe$)).subscribe((loads) => {});
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected selectedLoadChange(event: MatCheckboxChange, load: Load) {
    if (event.checked) {
      this.sceneService.addSelectedLoad(load);
    } else {
      this.sceneService.removeSelectedLoad(load);
    }
  }

  protected isSelected(load: Load) {
    return this.sceneService.isLoadSelected(load);
  }

  protected changeColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    this.fillColor = `rgb(${r}, ${g}, ${b})`;

    this.group.list.forEach((load) => {
      load.color = new Color(this.fillColor).getHex();
    });
    // FIXME - obejście żeby zaktualizować N ładunków jednym żądaniem
    this.sceneService.changeLoadCount(this.group, this.group.cnt).pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  protected cntChanged(event: any) {
    this.cnt = Math.min(this.cnt, this.maxCount);
    this.cnt$.next(this.cnt);
  }

  protected increaseCount() {
    const oldCnt = this.cnt;
    this.cnt += 1;
    this.cnt = Math.min(this.cnt, this.maxCount);
    if (oldCnt != this.cnt) {
      this.cnt$.next(this.cnt);
    }
  }
  protected decreaseCount() {
    const oldCnt = this.cnt;
    this.cnt -= 1;
    this.cnt = Math.min(this.cnt, this.maxCount);
    if (oldCnt != this.cnt) {
      this.cnt$.next(this.cnt);
    }
  }

  protected onHoverGroup(group: LoadGroup, state: boolean) {
    this.sceneService.updateHoveredLoads(state ? group.list : []);
  }

  protected onHoverLoad(load: Load, state: boolean) {
    this.sceneService.updateHoveredLoads(state ? [load] : []);
  }

  protected showMenu() {
    const component = this.componentLoader.add(EditLoadedGroupComponent);
    component.setInput('group', this.group);
  }

  protected filteredItems() {
    return this.group.list
      .sort((a, b) => a.idx - b.idx)
      .slice(this.listShowStart, this.listShowStart + this.listShowSize);
  }

  protected nextItems() {
    this.listShowStart += this.listShowSize;
  }

  protected prevItems() {
    this.listShowStart = Math.max(0, this.listShowStart - this.listShowSize);
  }

  protected showLessItemsVisible() {
    return this.listShowStart > 0;
  }
  protected showMoreItemsVisible() {
    return this.listShowStart + this.listShowSize < this.group.list.length;
  }
}
