import { SpaceFactory } from 'src/app/vehicle/space/lib/space-factory';
import { Vehicle } from '../../../lib/vehicle';
import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { ContainerMesh } from './container-mesh';

export class Container extends Vehicle {
  type = 'container';
  width: number;
  length: number;
  height: number;
  cabinLength = 0;

  constructor(obj: any, spaceFactory: SpaceFactory) {
    super(obj, spaceFactory);
  }

  createMesh(): void {
    this.mesh = new ContainerMesh(this);
  }

  get fullName(): string {
    return `${this.name}`;
  }

  get fullDescription(): string {
    return `${this.length}x${this.width}x${this.height}`;
  }

  get isEmpty(): boolean {
    return !(this.length + this.width + this.height > 0);
  }

  get maxLength(): number {
    return this.spaces.length > 0 ? this.spaces[0].length : 0;
  }
}
