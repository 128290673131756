import { Component, Input, OnDestroy } from '@angular/core';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Directory } from '../lib/directory';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { ProjectsService } from '../projects.service';

@Component({
  selector: 'app-directory-create',
  templateUrl: './directory-create.component.html',
  styleUrls: ['./directory-create.component.scss']
})
export class DirectoryCreateComponent implements OnDestroy {
  private _directory: Directory;
  @Input() set directory(directory: Directory) {
    this._directory = directory;
    console.log('DirectoryCreateComponent', directory);
    this.form.get('name').setValue(directory?.name || '');
  }
  get directory() {
    return this._directory;
  }
  public form: UntypedFormGroup;

  private unsubscribe$ = new Subject<void>();
  constructor(
    private componentService: LoadComponentService,
    private projectsService: ProjectsService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close(): void {
    this.componentService.clear('DirectoryCreateComponent');
  }

  onSubmit() {
    const name = this.form.controls.name.value;
    const directory =
      this.directory ||
      new Directory({
        name
      });
    if (this.directory) {
      this.directory.name = name;
      this.updateDirectory(directory);
    } else {
      this.createDirectory(directory);
    }
  }

  private updateDirectory(directory: Directory) {
    this.projectsService
      .updateDirectory(directory)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((directory) => {
        //this.projectsService.clearNamesCache();
        this.close();
      });
  }

  private createDirectory(directory: Directory) {
    this.projectsService
      .addDirectory(directory)
      .pipe(
        switchMap(() => this.projectsService.fetchProjects()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((list) => {
        this.projectsService.updateProjectList(list);
        this.projectsService.clearNamesCache();
        this.close();
      });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.compose([])]
    });
  }
}
