import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { LineSegments } from 'three';
import { Cuboid } from './cuboid';
import { Constants as Config } from 'src/app/config/constants';
import { DisplayCacheService } from 'src/app/services/display-cache.service';

export class CuboidMesh extends LoadMesh {
  private length: number;
  private height: number;
  private width: number;

  public constructor(cuboid: Cuboid, settings: LoadDisplaySettings, displayCache: DisplayCacheService) {
    super(cuboid.uuid, cuboid.color, settings, displayCache);
    this.length = Config.scale(cuboid.length);
    this.width = Config.scale(cuboid.width);
    this.height = Config.scale(cuboid.height);

    this.init();
  }

  public getLength(): number {
    return this.length * 1;
  }

  public getWidth(): number {
    return this.width * 1;
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getName(): string {
    return '(' + this.length + 'x' + this.width + 'x' + this.height + ')';
  }

  private init() {
    this.obj.geometry = this.displayCache.getBoxGeometry(this.length, this.height, this.width);
    this.prepareMesh();
  }

  protected prepareMesh() {
    const wireframe = this.displayCache.getEdgesBoxGeometry(this.length, this.height, this.width);
    const mat = this.displayCache.getLoadBorderMaterial(this.settings, this.selected);
    const line = new LineSegments(wireframe, mat);
    line.name = 'border';
    this.obj.add(line);
  }

  public dispose() {
    this.displayCache.disposeBoxGeometry(this.length, this.height, this.width);
    this.displayCache.disposeEdgesBoxGeometry(this.length, this.height, this.width);
    this.displayCache.disposeLoadMaterial(this.obj.userData['originalColor'], this.settings, false, false);
    this.displayCache.disposeLoadBorderMaterial(this.settings, false);
  }
}
