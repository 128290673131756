import { Vector3 } from 'three';
import { VectorDimensionLabel } from './VectorDimensionLabel';
import { Constants as Config } from 'src/app/config/constants';
import { VectorMesh } from 'src/app/vehicle/space/lib/vector-mesh';

export class VectorHeightLabel extends VectorDimensionLabel {
  public updatePosition(vector: VectorMesh): void {
    const position = new Vector3();
    vector.mesh.getWorldPosition(position);
    position.x -= (vector.length / 2 - 100) * Config.DIMENSION_SCALING;
    position.z += (vector.width / 2 - 20) * Config.DIMENSION_SCALING;
    this.setPosition(position);
  }

  makeCopy(): VectorHeightLabel {
    return new VectorHeightLabel(this.getText(), this.textScale);
  }
}
