import { LoadDisplaySettings } from '../../../lib/load-display-settings';
import { LoadMesh } from '../../../lib/load-mesh';
import { PalletLoadMesh } from './pallet-load-mesh';
import { Cuboid } from '../../cuboid/lib/cuboid';
import { DisplayCacheService } from 'src/app/services/display-cache.service';

export class PalletLoad extends Cuboid {
  shape = 'pallet-load';
  fromVehicleContext: string;

  constructor(
    obj: any,
    settings: LoadDisplaySettings,
    displayCache: DisplayCacheService
  ) {
    super(obj, settings, displayCache);
    this.shape = obj.shape;
  }

  createMesh(settings: LoadDisplaySettings): LoadMesh {
    //console.error('create mesh');
    this.mesh = new PalletLoadMesh(this, settings, this.getDisplayCache());
    return this.mesh;
  }
}
