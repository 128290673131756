import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { distinctUntilChanged, filter, merge, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Mesh, Vector3 } from 'three';
import {
  ConfirmationDialogAction,
  ConfirmationDialogComponent
} from '../confirmation-dialog/confirmation-dialog.component';
import { ContextMenuService } from '../context-menu';
import { LabelService } from '../label/label.service';

import { User } from '../lib/model/user';
import { VehicleContext } from '../lib/model/vehicle-context';

import { RatingComponent } from '../rating/rating.component';
import { AuthService } from '../services/auth.service';
import { TransformControlsService } from '../services/transform-controls.service';
import { ClickHandlerService } from './click-handler.service';
import { UiService } from '../services/ui.service';
import { Vehicle } from '../vehicle/lib/vehicle';
import { VehicleService } from '../vehicle/vehicle.service';
import { SceneService } from './scene.service';
import { MenuService } from '../menu/menu.service';
import { LoadComponentService } from '../services/load-component.service';
import { ExportListComponent } from '../pdf-export/export-list/export-list.component';
import { SceneImage } from '../lib/model/scene-image';
import { GravityCenterMesh } from '../lib/gravity-center/gravity-center-mesh';
import { GravityCenter } from '../lib/gravity-center/gravity-center';
import { EmptyVehicle } from '../vehicle/type/empty-vehicle/lib/empty-vehicle';
import { CSS2DToCanvasConverterService } from '../label/css2d-to-canvas-converter.service';
import { ContextFactory } from '../vehicle/context/lib/context-factory';
import { DebugPointsService } from '../lib/debug/debug-points.service';
import { ProfileService } from '../services/profile.service';
import { SceneDirector } from './lib/SceneDirector';
import { FreeSpaceService } from '../vehicle/space/lib/free-space.service';
import { ContextService } from '../vehicle/context/context.service';
import { PalletsLoadingModalComponent } from '../pallets-loading-modal/pallets-loading-modal.component';
import { MassDistributionChartComponent } from '../mass-distribution-chart/mass-distribution-chart.component';
import { Constants } from 'src/app/config/constants';
import { DisplayCacheService } from '../services/display-cache.service';

@Component({
  selector: 'app-scene',
  templateUrl: './scene.component.html',
  styleUrls: ['./scene.component.scss']
})
export class SceneComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('canvas')
  private canvasRef: ElementRef;

  protected user$: Observable<User>;
  protected upgradePlanUrl: string;
  protected massDistributionEnabled: boolean;
  protected palletsAsLoadEnabled: boolean;

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  private director: SceneDirector;

  context: VehicleContext;

  private unsubscribe$ = new Subject<boolean>();

  constructor(
    authService: AuthService,
    private sceneService: SceneService,
    private contextService: ContextService,
    private contextMenuService: ContextMenuService,
    private transformControlsService: TransformControlsService,
    private clickHandlerService: ClickHandlerService,
    private labelService: LabelService,
    private uiService: UiService,
    private vehicleService: VehicleService,
    public exportDialog: MatDialog,
    private dialog: MatDialog,
    private menuService: MenuService,
    private loadComponentService: LoadComponentService,
    private labelConverter: CSS2DToCanvasConverterService,
    private contextFactory: ContextFactory,
    private debugPointsService: DebugPointsService,
    private profileService: ProfileService,
    private freeSpaceService: FreeSpaceService,
    displayCache: DisplayCacheService
  ) {
    this.user$ = authService.getUser();
    this.director = new SceneDirector(displayCache);

    this.upgradePlanUrl = environment.upgradePlanUrl;
    this.massDistributionEnabled = uiService.massDistributionEnabled;
    this.palletsAsLoadEnabled = uiService.palletsAsLoadEnabled;
  }

  public ngAfterViewInit() {
    this.director.setupMainScene(new Vector3(0, 50, 16000 * Constants.DIMENSION_SCALING), this.canvas);
    this.director.scene.setBackground(
      this.uiService.getCurrentTheme() == 'light' ? Constants.MAIN_BG : Constants.MAIN_BG_DARK
    );

    this.sceneService.init(this.director.camera, this.director.scene, this.canvas);

    this.sceneService
      .initOrbitControls(this.director.camera, this.director.renderer)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.sceneService.redrawContextWithLabels();
      });
    this.initDragControls();
    this.initClickHandler();
  }

  public ngOnInit(): void {
    this.subscribe();
  }

  ngOnDestroy(): void {
    // console.log('scene.component.ts: destroy, unsubscribe');
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.clickHandlerService.deactivate();
    this.freeSpaceService.stop();
  }

  //#region  ----------- UI ACTIONS ---------------
  protected containsAnyContext(): boolean {
    return this.sceneService.containsAnyContext();
  }

  protected isNotEmptyContext() {
    return this.context && this.context.getVehicle() && this.context.getVehicle().type !== 'empty';
  }

  //usuń ładunek z pojazdu
  protected clearLoad() {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { action: ConfirmationDialogAction.deleteAllLoads }
    });

    confirmationDialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((confirmed) => confirmed),
        switchMap(() => this.sceneService.clearLoads())
      )
      .subscribe();
  }

  protected clearVehicleAndLoad() {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { action: ConfirmationDialogAction.deleteVehicle }
    });

    confirmationDialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((confirmed) => confirmed),
        switchMap(() => this.sceneService.removeCurrentContext())
      )
      .subscribe((removedContext) => {
        this.sceneService.removeContextFromView(removedContext);
      });
  }

  protected clearAll() {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { action: ConfirmationDialogAction.deleteAll }
    });

    confirmationDialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((confirmed) => confirmed),
        switchMap(() => this.sceneService.removeAllContexts())
      )
      .subscribe(() => {
        this.sceneService.removeAllContextsFromView();
      });
  }

  protected exportProject() {
    if (this.sceneService.hasMoreThanOneContext()) {
      this.loadComponentService.add(ExportListComponent);
    } else {
      this.sceneService.exportCurrentContext();
    }
  }

  protected loadPallets() {
    console.debug('scene.component.ts -> loadPallets()');
    this.loadComponentService.add(PalletsLoadingModalComponent);
  }

  protected showMassDistribution() {
    this.loadComponentService.add(MassDistributionChartComponent);
  }

  protected canLoadAnyContextOnThisVehicle(): boolean {
    return (
      this.palletsAsLoadEnabled &&
      this.context?.canLoadOtherVehiclesOnThis() &&
      this.contextService.canLoadAnyContextOnVehicle()
    );
  }

  protected showRatingDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component';
    //dialogConfig.height = '50%';
    dialogConfig.width = '30%';
    dialogConfig.data = {
      calculationId: this.context.getUuid(),
      historyId: this.context.getHistoryUuid()
    };
    this.exportDialog.open(RatingComponent, dialogConfig);
    return;
  }

  protected saveToMyGarage() {
    const imgData = this.canvas.toDataURL('image/jpeg', 1.0);
    this.uiService
      .scaleImage(imgData, 300, 96)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((img) => this.sceneService.saveToMyGarage(img, this.context))
      )
      .subscribe((response) => {
        this.sceneService.handleAddToMyGarageResponse(response);
      });
  }

  protected loadsExist(): boolean {
    if (this.context) {
      return !this.context.isEmpty();
    }
    return false;
  }

  protected reload() {
    this.sceneService.reloadLoads().subscribe();
  }

  //#endregion ----------- UI ACTIONS ---------------

  protected onResize() {
    this.resizeCanvasToDisplaySize();
    this.renderOnRequest();
  }

  private getCanvasAsImg(canvas: HTMLCanvasElement) {
    return canvas.toDataURL('image/jpeg', 1.0);
  }

  private subscribe() {
    this.vehicleService
      .vehicleChanged()
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((vehicle) => {
          const context = this.contextFactory.createForVehicle(vehicle);
          return this.sceneService.addVehicleContextAndSwitch(context);
        })
      )
      .subscribe((context) => {
        // console.log('addvehicleContextandswitch', context);
        // this.sceneService.zoomIn();
      });

    this.sceneService
      .getVehicleContext()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((context) => {
        if (!context) {
          return;
        }
        this.sceneService.updateContext(context);
        this.updateContext(context).then(() => {
          if (this.profileService.currentProfile.hasAttribute('debug')) {
            console.log('DEBUG: draw matrix debug vectors');
            this.drawMatrixDebugVectors();
          }
        });
      });

    this.sceneService
      .shouldRefreshInvoked()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.renderOnRequest();
      });

    this.sceneService.updateAxles$.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
      const vehicle = this.context.getVehicle();
      this.updateAxles(vehicle);
      this.sceneService.redrawContextWithLabels();
      //console.log('updateAxles in scene component.ts');
    });

    this.sceneService.updateFlooringLevelInView$.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
      const vehicle = this.context.getVehicle();
      this.drawFlooringLevels(vehicle);
      this.sceneService.redrawContextWithLabels(); // update labels
      //console.log('updateFlooring level in scene component.ts');
    });

    this.contextMenuService
      .modelChanged()
      .pipe(
        switchMap((model) => this.sceneService.executeContextAction(model)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.sceneService.profile$.pipe(takeUntil(this.unsubscribe$)).subscribe((profile) => {
      this.sceneService.setLabelsConfiguration(profile.settings.labelConfig);
      if (this.context) {
        //console.log('should redraw loads');

        this.updateContext(this.context);
      }
    });

    this.labelService
      .shouldRefreshInvoked()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        //console.log('should refresh invoked');
        this.sceneService.refreshView();
      });

    merge(this.uiService.getLengthUnit(), this.uiService.getWeightUnit(), this.uiService.getLabelFontModifier())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.labelService.updateLabels();
        this.sceneService.refreshView();
      });

    this.labelService.getModel().subscribe((model) => {
      // console.log('show Gravity center PAWEŁ', model.showGravityCenter);
      if (this.context && !(this.context.getVehicle() instanceof EmptyVehicle)) {
        this.director.scene.drawGravityCenters(
          this.context.getVehicle(),
          this.context.getAllLoads(),
          model.showGravityCenter
        );
      }
    });

    this.sceneService.freeFloorHighlight$.pipe(takeUntil(this.unsubscribe$)).subscribe((space) => {
      this.director.scene.addFreeFloorHighlight(
        space,
        space ? this.context.getStatistics().find((s) => s.spaceUuid === space.uuid) : null
      );
      this.renderOnRequest();
    });

    this.sceneService
      .onSceneImgRequest()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async () => {
        //console.log('scene.component.ts: requested img');
        await new Promise((f) => setTimeout(f, 10));
        const labelCanvas = this.labelConverter.addLabelsToCanvas(
          this.labelService.getAllLabels(),
          this.canvas,
          this.director.camera,
          this.uiService.getCurrentLabelFontModifierAsNumber()
        );

        const img = this.getCanvasAsImg(labelCanvas);
        this.cleanupExport();
        this.sceneService.returnSceneImg(new SceneImage(img, this.director.getAspectRatio()));
      });

    this.freeSpaceService.hovered$.pipe(takeUntil(this.unsubscribe$)).subscribe((vectorMesh) => {
      //console.debug('scene.component.ts: hovered space change', vectorMesh);
      this.labelService.showMatrixLabels(vectorMesh);
      this.sceneService.redrawContextWithLabels();
    });

    this.freeSpaceService.measurementEnabled$.pipe(takeUntil(this.unsubscribe$)).subscribe((enabled) => {
      if (enabled) {
        this.director.scene?.showMatrixVectors();
      } else {
        this.director.scene?.hideMatrixVectors();
      }
    });

    this.sceneService.showInStep$.pipe(takeUntil(this.unsubscribe$)).subscribe((ids) => {
      this.director.scene.setShowInSteps(this.context, ids).then(() => {
        this.sceneService.vehicleContextDrawn(this.context, this.director);
        this.renderOnRequest();
      });
    });

    this.uiService.themeChange$.pipe(takeUntil(this.unsubscribe$)).subscribe((theme) => {
      if (theme == 'dark') {
        this.director.scene?.setBackground(Constants.MAIN_BG_DARK);
        Constants.VEHICLE_BORDERS_COLOR = 0xe0e0e0;
        Constants.GRID_COLOR = 0x666666;
      } else {
        this.director.scene?.setBackground(Constants.MAIN_BG);
        Constants.VEHICLE_BORDERS_COLOR = 0x333333;
        Constants.GRID_COLOR = 0xcccccc;
      }
      this.director.scene?.redrawContext(this.context);
      this.renderOnRequest();
    });
  }

  private cleanupExport() {
    document.querySelectorAll('.label-canvas').forEach((canvas) => canvas.remove());
  }

  private initDragControls() {
    // transform controls
    this.transformControlsService.init(this.director.camera, this.director.renderer.domElement);

    this.transformControlsService
      .modelChanged()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((model) => {
        this.sceneService.dragModelChange(model);
      });

    this.transformControlsService
      .dragEnd()
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((model) => this.sceneService.dragEnd(model))
      )
      .subscribe();

    this.transformControlsService
      .getEnabled()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        this.director.scene.add(this.transformControlsService.getControls());
        if (!val) {
          this.director.scene.hoverLoad([]);
          this.sceneService.refreshView();
        }
        this.sceneService.dragEnable(val);
      });

    this.transformControlsService
      .getHoveredLoads()
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((loads) => {
        this.director.scene.hoverLoad(loads);
        this.sceneService.refreshView();
      });

    this.transformControlsService
      .getSelectedLoads()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loads) => {
        this.director.scene.selectLoads(loads);
        this.renderOnRequest();
      });
  }

  private async updateContext(context: VehicleContext) {
    if (!this.director.hasScene()) {
      return;
    }
    await this.director.scene.setContext(
      context,
      this.profileService.currentSettings,
      () => {
        this.menuService.openVehicles();
      },
      this.clickHandlerService
    );

    this.context = context;
    this.sceneService.vehicleContextDrawn(context, this.director);
    this.freeSpaceService.listen(this.director.scene.getMatrixVectors(), this.canvas, this.director.camera);
    if (this.freeSpaceService.currentMeasurementEnabledValue) {
      this.director.scene.showMatrixVectors();
    }
    /*
      const geometry = new SphereGeometry(50, 32, 16);
      const redMaterial = new MeshBasicMaterial({ color: 0xff0000 });
      const mesh = new Mesh(geometry, redMaterial);
      this.director.scene.add(mesh);
      */
    this.renderOnRequest();
  }

  // ?
  private drawVehicleGravityCenters(vehicle: Vehicle) {
    const vehicleMesh = this.director.scene.getObjectByName('vehicle-mesh') as Mesh;
    if (!vehicleMesh) {
      console.log('no vehicle mesh');
      return;
    }

    const vehicleFront = new GravityCenterMesh(
      new GravityCenter({
        weight: 300
      })
    );

    for (const space of vehicle.spaces) {
      const spaceMesh = vehicleMesh.children
        .filter((x) => x.name === 'space-mesh')
        .find((x) => (x as Mesh).userData.uuid === space.uuid);

      const gravityCenterMesh = new GravityCenterMesh(
        new GravityCenter({
          position: new Vector3(0, 0, 0),
          weight: 300
        })
      );
      // gravityCenter.position.x -= vehicle.totalLength / 2;
      this.director.scene.add(gravityCenterMesh);
    }
    this.renderOnRequest();
  }

  private drawFlooringLevels(vehicle: Vehicle) {
    this.director.scene.addFlooringLevels(vehicle);
    this.renderOnRequest();
  }

  private updateAxles(vehicle: Vehicle) {
    vehicle.mesh.updateAxles();
    vehicle.spaces.forEach((x) => x.mesh.updateAxles());
    this.director.scene.drawGravityCenters(
      vehicle,
      this.context?.getAllLoads(),
      this.profileService.currentSettings.labelConfig?.showGravityCenter
    );
    this.renderOnRequest();
  }

  private initClickHandler() {
    this.clickHandlerService.init(this.director.camera, this.canvas);
    this.clickHandlerService.activate([]);
  }

  private resizeCanvasToDisplaySize() {
    const width = this.canvas.clientWidth;
    const height = this.canvas.clientHeight;

    if (this.canvas.width !== width || this.canvas.height !== height) {
      this.sceneService.setLabelRendererSize(width, height);
      this.director.updateSize(width, height);
    }
  }

  private renderOnRequest() {
    this.director.render();
    this.sceneService.renderLabels();
  }

  private drawMatrixDebugVectors() {
    this.debugPointsService.drawMatrixDebugVectors(this.director.scene, this.context);
    this.renderOnRequest();
  }

  private drawDebugPoints() {
    this.debugPointsService.drawPoints(this.director.scene);
  }
}
