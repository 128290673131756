import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { Bus } from './bus';
import { BufferGeometry, Float32BufferAttribute } from 'three';
import { Constants } from '../../../lib/constants';
import { Constants as Config } from 'src/app/config/constants';

export class BusMesh extends VehicleMesh {
  private indices = [];
  private vertices = [];

  public constructor(bus: Bus) {
    super('bus', bus);
    this.init();
  }

  public init() {
    this.mesh.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    const x0 = 0;
    const y0 = 0;
    const z0 =
      (Config.DIMENSION_SCALING / 2) *
      (this.vehicle.maxWidth - this.vehicle.cabinWidth);
    const x1 = x0 + this.vehicle.cabinLength * Config.DIMENSION_SCALING;
    const y1 = y0 + this.vehicle.cabinHeight * Config.DIMENSION_SCALING;
    const z1 = z0 + this.vehicle.cabinWidth * Config.DIMENSION_SCALING;
    const scaledCabinRoofHeight =
      this.vehicle.cabinRoofHeight * Config.DIMENSION_SCALING;
    let groupStart = 0;
    let groupCount = 0;

    // cabin
    this.vertices.push(x0, y0, z1); // 4 left bottom front
    this.vertices.push(x0, y1 - scaledCabinRoofHeight, z1); // 5 left top front
    this.vertices.push(x0, y1 - scaledCabinRoofHeight, z0); // 6 left top back
    this.vertices.push(x0, y0, z0); // 7 left bottom back
    this.vertices.push(x1, y0, z1); // 12 cabin front bottom left
    this.vertices.push(x1, y0, z0); // 13 cabin back bottom left
    this.vertices.push(x1, y1, z0); // 14 cabin back top left
    this.vertices.push(x1, y1, z1); // 15 cabin front top left
    this.vertices.push(x0, y1 - scaledCabinRoofHeight, z1); // 16 cabin back top left
    this.vertices.push(x0, y1 - scaledCabinRoofHeight, z0); // 17 cabin front top left

    this.indices.push(0, 3, 5, 0, 5, 4); // cabin bottom faces
    this.indices.push(0, 4, 7, 0, 7, 8, 1, 8, 7); // cabin front faces
    this.indices.push(3, 5, 9, 5, 6, 9, 2, 9, 6); // cabin back faces
    this.indices.push(4, 6, 7, 4, 5, 6); // cabin left bottom faces
    this.indices.push(1, 2, 7, 2, 6, 7); // cabin left top faces

    groupCount = 6 * 3 + 9 * 2;
    const geometry = this.getGeometry();
    geometry.addGroup(groupStart, groupCount, 0);

    geometry.setIndex(this.indices);
    geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    geometry.computeVertexNormals();

    this.mesh.material = [
      Constants.TRANSPARENT_MATERIAL,
      Constants.FLOOR_MATERIAL,
      Constants.CABIN_MATERIAL,
      Constants.TRANSPARENT_MATERIAL,
      Constants.FLOOR_MATERIAL
    ];

    this.buildWireframe();

    this.addSpaces();
    this.addAxles();
  }
}
