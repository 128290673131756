<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'popup'" [ngTemplateOutlet]="popup">
  </ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="default"> </ng-container>
</ng-container>
<ng-template #default>
  <mat-expansion-panel #colorPanel (opened)="opened = true"
    (closed)="opened = false">
    <mat-expansion-panel-header>
      <mat-panel-title i18n>Dostosuj kolor</mat-panel-title>
    </mat-expansion-panel-header>
    <color-block triangle="hide" [colors]="colors.colorsHexStrings"
      [color]="color" [style.background]="'transparent'"
      (onChangeComplete)="onChange($event)"
      (onChange)="onChange($event)"></color-block>
  </mat-expansion-panel>
</ng-template>

<ng-template #popup>
  <div class="popup-container">
    <button (click)="showColors = !showColors" [style.backgroundColor]="color"
      [style.color]="colors.getLightness(color) < 100 ? 'white' : 'black'">
      <mat-icon>palette</mat-icon>
    </button>
    <div class="popup" [class.hidden]="!showColors">
      <color-block triangle="hide" [colors]="colors.colorsHexStrings"
        [color]="color" [style.background]="'var(--clr-interface-background)'"
        (onChangeComplete)="onChange($event)"
        (onChange)="onChange($event)"></color-block>
    </div>
  </div>
</ng-template>