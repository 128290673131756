import {
  BufferGeometry,
  Float32BufferAttribute,
  LineBasicMaterial,
  LineBasicMaterialParameters,
  LineSegments,
  Vector3
} from 'three';
import { Constants as Config } from 'src/app/config/constants';

export class Grid3DLines {
  readonly xLabelsPositions: Vector3[] = [];
  readonly yLabelsPositions: Vector3[] = [];
  readonly zLabelsPositions: Vector3[] = [];
  public obj: LineSegments;
  public constructor(
    size: Vector3,
    cellSize: number,
    materialOptions: LineBasicMaterialParameters,
    labelSpacing = 200
  ) {
    this.obj = new LineSegments();
    const geometry = new BufferGeometry();
    const positions: number[][] = [];
    const indexPairs: number[] = [];

    let index = 0;
    const xPositions = this.getAxisPositions(size.x, cellSize);
    const yPositions = this.getAxisPositions(size.y, cellSize);
    const zPositions = this.getAxisPositions(size.z, cellSize);
    this.xLabelsPositions = this.spreadOutLabels(
      xPositions.map(
        (x) => new Vector3(x, -180 * Config.DIMENSION_SCALING, size.z)
      ),
      'x',
      labelSpacing * Config.DIMENSION_SCALING
    );
    this.yLabelsPositions = this.spreadOutLabels(
      yPositions.map(
        (y) => new Vector3(-180 * Config.DIMENSION_SCALING, y, size.z)
      ),
      'y',
      labelSpacing * Config.DIMENSION_SCALING
    );

    this.zLabelsPositions = this.spreadOutLabels(
      zPositions.map(
        (z) => new Vector3(size.x + 180 * Config.DIMENSION_SCALING, 0, z)
      ),
      'z',
      labelSpacing * Config.DIMENSION_SCALING
    );

    xPositions.forEach((x) => {
      yPositions.forEach((y) => {
        zPositions.forEach((z) => {
          if (x === 0) {
            positions.push([0, y, z], [size.x, y, z]);
            indexPairs.push(index, index + 1);
            index += 2;
          }
          if (y === 0) {
            positions.push([x, 0, z], [x, size.y, z]);
            indexPairs.push(index, index + 1);
            index += 2;
          }
          if (z === 0) {
            positions.push([x, y, 0], [x, y, size.z]);
            indexPairs.push(index, index + 1);
            index += 2;
          }
        });
      });
    });

    const positionAttribute = new Float32BufferAttribute(positions.flat(), 3);
    geometry.setAttribute('position', positionAttribute);
    geometry.setIndex(indexPairs);
    this.obj.geometry = geometry;
    this.obj.material = new LineBasicMaterial(materialOptions);
  }

  private spreadOutLabels(
    positions: Vector3[],
    prop: string,
    minSpace: number
  ) {
    return positions.reduce((spreadOut, position, posIdx, input) => {
      if (posIdx === 0 || posIdx === input.length - 1) {
        spreadOut.push(position);
        return spreadOut;
      }
      /*if (position.x + minSpace > input[input.length - 1].x) {
        return spreadOut;
      }*/
      const lastPos = spreadOut[spreadOut.length - 1][prop];
      if (position[prop] >= lastPos + minSpace) {
        spreadOut.push(position);
      }
      return spreadOut;
    }, []);
  }

  private getAxisPositions(axisMax: number, cellSize: number) {
    const points = Array.from(this.range(0, axisMax, cellSize));
    if (points[points.length - 1] < axisMax) {
      points.push(axisMax);
    }
    return points;
  }

  private *range(start: number, stop: number, step = 1) {
    for (let i = start; i < stop; i += step) {
      yield i;
    }
  }

  public dispose() {
    this.obj.geometry.dispose();
    (this.obj.material as LineBasicMaterial).dispose();
  }
}
