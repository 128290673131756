import { Constants as Config } from 'src/app/config/constants';
import { SpaceMesh } from '../../../lib/space-mesh';
import { Trailer } from './trailer';
import { BufferGeometry, EdgesGeometry, Float32BufferAttribute, LineBasicMaterial, LineSegments } from 'three';

export class TrailerMesh extends SpaceMesh {
  private length: number;
  private height: number;
  private width: number;
  private indices = [];
  private vertices = [];

  public constructor(trailer: Trailer) {
    super('trailer', trailer);
    this.length = Config.scale(trailer.length);
    this.width = Config.scale(trailer.width);
    this.height = Config.scale(trailer.height);
    this.init();
  }

  public getLength(): number {
    return this.length * 1;
  }

  public getWidth(): number {
    return this.width * 1;
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getName(): string {
    return '(' + this.length + 'x' + this.width + 'x' + this.height + ')';
  }

  private init() {
    this.meshObj.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    const x0 = 0;
    const y0 = 0;
    const z0 = 0;
    const x1 = x0 + this.length;
    const y1 = y0 + this.height;
    const z1 = z0 + this.width;

    let groupStart = 0;
    let groupCount = 0;

    // loading space
    this.vertices.push(x1, y1, z1); // 0 right top front
    this.vertices.push(x1, y1, z0); // 1 right top back
    this.vertices.push(x1, y0, z0); // 2 right bottom back
    this.vertices.push(x1, y0, z1); // 3 right bottom front
    this.vertices.push(x0, y0, z1); // 4 left bottom front
    this.vertices.push(x0, y1, z1); // 5 left top front
    this.vertices.push(x0, y1, z0); // 6 left top back
    this.vertices.push(x0, y0, z0); // 7 left bottom back

    // floor
    this.vertices.push(x0, y0, z0); // 8 floor left back
    this.vertices.push(x1, y0, z0); // 9 floor right back
    this.vertices.push(x1, y0, z1); // 10 floor right front
    this.vertices.push(x0, y0, z1); // 11 floor left front

    // loading space
    this.indices.push(1, 2, 3); // doors bottom triangle
    this.indices.push(0, 1, 3); // doors top triangle

    this.indices.push(3, 4, 5); // front side bottom triangle
    this.indices.push(0, 3, 5); // front side top triangle

    this.indices.push(1, 2, 7); // back side bottom triangle
    this.indices.push(1, 7, 6); // back side top triangle

    this.indices.push(0, 1, 5); // roof front triangle
    this.indices.push(1, 6, 5); // roof back triangle

    this.indices.push(4, 5, 7); // cabin left bottom triangle
    this.indices.push(5, 6, 7); // cabin left top triangle

    groupCount = 5 * 6;
    this.meshObj.geometry.addGroup(groupStart, groupCount, 0);

    // floor
    this.indices.push(4, 11, 8, 4, 7, 8); // left floor faces
    this.indices.push(7, 8, 9, 2, 7, 9); // back floor faces
    this.indices.push(2, 3, 9, 3, 9, 10); // right floor faces
    this.indices.push(4, 11, 10, 3, 11, 10); // front floor faces
    this.indices.push(3, 4, 7, 2, 3, 7); // top floor faces
    this.indices.push(8, 9, 11, 9, 10, 11); // bottom floor faces

    groupStart += groupCount;
    groupCount = 6 * 6;
    this.meshObj.geometry.addGroup(groupStart, groupCount, 1);

    this.meshObj.geometry.setIndex(this.indices);
    this.meshObj.geometry.setAttribute('position', new Float32BufferAttribute(this.vertices, 3));

    this.meshObj.geometry.computeVertexNormals();

    const geo = new EdgesGeometry(this.meshObj.geometry, 1);
    const mat = new LineBasicMaterial({ color: Config.VEHICLE_BORDERS_COLOR });
    const wireframe = new LineSegments(geo, mat);
    this.meshObj.add(wireframe);

    this.addAxles();
    // this.mesh.position.y = Constants.SCENE_FLOOR_Y + y1;
  }

  public dispose(): void {
    super.dispose();
    this.meshObj?.geometry.dispose();
    this.meshObj?.children.forEach((child) => {
      if (child instanceof LineSegments) {
        child.geometry.dispose();
        child.material.dispose();
      }
    });
  }
}
