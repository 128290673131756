import { Color, FrontSide, Material, MeshPhongMaterial, MeshStandardMaterial } from 'three';
import { LoadDisplaySettings } from '../../load/lib/load-display-settings';

export const colorFirebrick = new Color(Color.NAMES.firebrick);
export const colorBlack = new Color(Color.NAMES.black);

export type LoadMaterial = MeshPhongMaterial | MeshStandardMaterial;

export const loadMaterial = (color: number, settings: LoadDisplaySettings): LoadMaterial => {
  if ((settings.loadTransparency ?? 0.4) < 1) {
    return new MeshPhongMaterial({
      // map: textureLoader.load('/assets/recycled-material-texture.jpg'),
      color,
      // });
      side: FrontSide,

      transparent: true, //settings.loadTransparency < 1,
      opacity: settings.loadTransparency ?? 0.4,
      depthWrite: true
      //depthWrite: settings.loadTransparency === 1,
      //depthTest: true
    });
  } else {
    return new MeshStandardMaterial({
      color,
      side: FrontSide,
      transparent: false,
      opacity: 1,
      depthWrite: true
    });
  }
};
