<div class="element" (mouseover)="onHoverGroup(group, true)"
  (mouseout)="onHoverGroup(group, false)">
  <ng-container *ngIf="ui.loadMultiSelectEnabled">
    <mat-icon class="expand-icon" *ngIf="!drag && group.expanded"
      (click)="group.expanded = false">remove</mat-icon>
    <mat-icon class="expand-icon" *ngIf="drag || !group.expanded"
      (click)="group.expanded = drag ? false : true">add</mat-icon>
  </ng-container>
  <mat-icon mat-list-icon (click)="changeColor()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g transform="matrix(.49521-.74511.74511.49521-770.55-496.53)"
        [attr.fill]="fillColor">
        <g fill-opacity=".987">
          <path
            d="m15.237 1042.68l-3.831-2.564v-3.89c-.0001-2.139.009-3.89.022-3.89.013 0 1.742 1.15 3.844 2.555l3.821 2.555v3.901c0 2.146-.005 3.9-.011 3.898-.006 0-1.735-1.157-3.842-2.567"
            opacity=".987" />
        </g>

        <g fill-opacity=".6">
          <path
            d="m7.228 1048.61c-2.056-1.374-3.756-2.516-3.778-2.538-.044-.043 7.525-5.149 7.64-5.154.084 0 7.526 4.976 7.511 5.03-.007.023-6.969 4.733-7.609 5.148-.014.001-1.708-1.108-3.764-2.482"
            opacity=".987" />
          <path
            d="m2.887 1041.45v-3.959l3.836-2.549c2.11-1.402 3.847-2.548 3.859-2.548.013 0 .023 1.779.023 3.954v3.954l-3.834 2.553c-2.109 1.404-3.845 2.553-3.859 2.553-.014 0-.026-1.781-.026-3.959"
            opacity=".987" />
        </g>
      </g>
    </svg>
  </mat-icon>
  <div class="load-name">
    <div mat-line title="{{ group.load.name }}">
      {{ group.load.name }}
    </div>
    <div mat-line>
      {{ group.load | dimensions | async }}
    </div>
    <div mat-line>
      {{ group.load.weight | weightUnit | async | number : '0.0-2'
      }}{{ ui.getWeightUnit() | async }}
    </div>
  </div>
  <div class="floorable-info">
    <div *ngIf="group.isFloorableBoth()" i18n-title
      title="Piętrowalność całkowita">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 20"
        class="floorable-icon">
        <rect x="0" y="0" width="9" height="9" fill="#fe9800"></rect>
        <rect x="0" y="11" width="9" height="9" fill="#fe9800"></rect>
      </svg>
      <span i18n>CAŁK.</span>
    </div>
    <div *ngIf="group.isFloorableOnlyBottom()" i18n-title
      title="Piętrowalność aktywna">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 20"
        class="floorable-icon">
        <rect x="0" y="0" width="9" height="9" fill="#fe9800"></rect>
        <rect x="0" y="11" width="9" height="9" fill="#000000"></rect>
      </svg>
      <span i18n>AKT.</span>
    </div>
    <div *ngIf="group.isFloorableOnlyTop()" i18n-title
      title="Piętrowalność pasywna">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 20"
        class="floorable-icon">
        <rect x="0" y="0" width="9" height="9" fill="#000000"></rect>
        <rect x="0" y="11" width="9" height="9" fill="#fe9800"></rect>
      </svg>
      <span i18n>PAS.</span>
    </div>
    <div *ngIf="group.isNonFloorable()" i18n-title title="Niepiętrowalny">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 20"
        class="floorable-icon">
        <rect x="0" y="0" width="9" height="9" fill="#000000"></rect>
        <rect x="0" y="11" width="9" height="9" fill="#000000"></rect>
      </svg>
      <span i18n>NIE</span>
    </div>
  </div>
  <div class="load-count-container">
    <input type="number" class="load-count" step="1" min="1"
      max="{{ maxCount }}" [(ngModel)]="cnt"
      (ngModelChange)="cntChanged($event)" />
    <div class="arrows">
      <button type="button" class="arrow-up" (click)="increaseCount()">
        ▲
      </button>
      <button type="button" class="arrow-down" (click)="decreaseCount()">
        ▼
      </button>
    </div>
  </div>
  <span (click)="showMenu()" class="more-menu">
    <mat-icon>more_vert</mat-icon>
  </span>
</div>
<div *ngIf="ui.loadMultiSelectEnabled && group.expanded" mat-list-item
  class="list-details" [class.expanded]="group.expanded">
  <ul>
    <li *ngIf="showLessItemsVisible()" class="show-more-items"
      (click)="prevItems()">
      <span i18n>Pokaż mniej ładunków z grupy</span>
    </li>
    <li *ngFor="let item of filteredItems()"
      (mouseover)="onHoverLoad(item, true)"
      (mouseout)="onHoverLoad(item, false)">
      <mat-checkbox (change)="selectedLoadChange($event, item)"
        [(ngModel)]="item.selected">[{{ item.idx }}]
        {{ item.name }}</mat-checkbox>
    </li>
    <li *ngIf="showMoreItemsVisible()" class="show-more-items"
      (click)="nextItems()">
      <span i18n>Pokaż więcej ładunków z grupy</span>
    </li>
  </ul>
</div>